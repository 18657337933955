<template lang="pug">
.course-search-results
  h2.title-with-underline {{ headlineCopy }}
  SearchLoader(:show-image='false', v-if='searchResults === null')
  .no-results(v-else-if='!searchResults.length')
    .copy
      h3.ketch-font-bold {{ $t('search.notFoundText') }}
      h3 {{ $t('search.sorryText') }}
    img(:src='search404')
  .results(v-else)
    .search-item(:key='index', v-for='(item, index) in slicedResults')
      .crumbs
        .crumb.h6(:key='crumb.title', v-for='crumb in searchCrumbs(item)')
          router-link(:to='crumb.url', v-html='crumb.title')
      .badge-title
        Badge(:badge-value='String($t("courses.quizQuestion"))', v-if='item.type === "Question"')
        HTMLRenderer.search-title(
          :format-content='true',
          :html='item.title',
          :search-term='searchTerm',
          @click.native='toLesson(item)',
          link-target='_blank'
        )
      HTMLRenderer.excerpt(
        :content-clamped='true',
        :format-content='true',
        :html='formatExcerpt(item.excerpt)',
        @click.native='toLesson(item)',
        link-target='_blank'
      )
  .load-more-pagination(v-if='offset < itemSize')
    span(@click='offset += limit') {{ $t('loadMore') }} ( {{ itemSize - offset }} )
</template>

<script setup lang="ts">
  import { computed, ref, watch } from 'vue'
  import HTMLRenderer from '@/components/common/HTMLRenderer.vue'
  import SearchApi from '@/services/api/SearchApi'
  import useCourse from '@/composables/useCourse'
  import useIcons from '@/composables/useIcons'
  import Badge from '@/components/common/Badge.vue'
  import { useRouter } from 'vue-router/composables'
  import eventBus from '@/main'
  import useI18n from '@/composables/useI18n'
  import type { Nullable } from '@/services/interfaces/Content'
  import type { CourseLessonSearchItem } from '@/services/interfaces/Course'
  import SearchLoader from '@/components/loaders/SearchLoader.vue'

  const props = defineProps({
    searchTerm: {
      required: true,
      type: String,
    },
  })

  const { translateString } = useI18n()
  const router = useRouter()
  const { currentCourseId } = useCourse()
  const { search404 } = useIcons()
  const searchResults = ref(null as Nullable<CourseLessonSearchItem[]>)
  const limit = 10
  const offset = ref(10)

  const searchCrumbs = computed(() => {
    return (item: CourseLessonSearchItem) => {
      const lessonId = item.lesson?.id || item.id
      return [
        {
          url: `/courses/${item.course.id}/info`,
          title: item.course.title,
        },
        {
          url: `/courses/${item.course.id}/module/${item.module.id}`,
          title: item.module.name,
        },
        {
          url: `/courses/${item.course.id}/module/${item.module.id}/lesson/${lessonId}`,
          title: item.lesson?.title || item.title,
        },
      ]
    }
  })

  const toLesson = async (item: CourseLessonSearchItem) => {
    eventBus.$emit('reset-search-term', '')
    const lessonId = item.lesson?.id || item.id
    let path = `/courses/${item.course.id}/module/${item.module.id}/lesson/${lessonId}`
    if (item.type === 'Question') {
      path = `/courses/${item.course.id}/module/${item.module.id}/lesson/${lessonId}/question/${item.id}`
    }
    router.push(path).catch(() => {
      return
    })
  }

  const slicedResults = computed(() => searchResults.value?.slice(0, offset.value) || [])
  const itemSize = computed((): number => {
    return searchResults.value?.length || 0
  })

  const formatExcerpt = (excerpt: string[] | { [key: string]: string[] | string }) => {
    const _excerpt = Object.values(typeof excerpt === 'string' ? [excerpt] : excerpt || []).flat()
    return _excerpt.join('<br>').replaceAll('\\*', '*') || ''
  }

  watch(
    () => props.searchTerm,
    (value: string) => {
      if (value) handleSearch()
    },
  )

  const handleSearch = () => {
    SearchApi.search(props.searchTerm, currentCourseId.value).then((results) => {
      searchResults.value = results as CourseLessonSearchItem[]
    })
  }

  handleSearch()

  const headlineCopy = computed(() => {
    if (!searchResults.value?.length) return `${translateString('search.topResultsText')} "${props.searchTerm}"`
    return `${searchResults.value.length} ${translateString('search.resultsText')} "${props.searchTerm}"`
  })
</script>

<style lang="postcss">
  .course-search-results {
    .loading {
      @apply ketch-py-c60 ketch-mx-auto;
    }
    .title-with-underline {
      @apply ketch-pb-c10;
    }
    .no-results {
      @apply ketch-block ketch-mt-c60;
    }
    .results {
      @apply ketch-mt-c60;
      .search-item {
        @apply ketch-pb-c20 ketch-border-b ketch-border-border-color ketch-mb-c20;

        .excerpt {
          @apply hover:ketch-cursor-pointer;
        }
        .badge-title {
          @apply ketch-flex ketch-space-x-c8 ketch-items-center;
          .badge {
            @apply ketch-h-[18px] ketch-bg-black ketch-text-white ketch-text-xs ketch-leading-sm ketch-rounded-[5px];
            flex: 0 0 66px;
          }
        }
        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
          @apply ketch-text-sm ketch-leading-lg ketch-font-bold;
        }
        hr {
          @apply ketch-my-c10;
        }
        .search-title {
          @apply ketch-text-lg ketch-leading-lg ketch-overflow-hidden ketch-cursor-pointer;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          line-clamp: 1;
          -webkit-box-orient: vertical;
        }
      }
      .crumbs {
        @apply ketch-flex ketch-items-center ketch-mb-c5;
        .crumb {
          @apply ketch-relative ketch-text-[#999] ketch-pl-c10 ketch-text-ellipsis ketch-overflow-hidden;
          @apply ketch-whitespace-nowrap;
          &:first-of-type {
            @apply ketch-pl-0;
          }
          &:nth-of-type(n + 2):before {
            @apply ketch-absolute ketch-left-c2;
            content: '/';
          }
        }
      }
    }
  }
</style>
