<template lang="pug">
.confetti-container
  ParticlesComponent#tsparticles(:options='options', :particles-loaded='particlesLoaded')
  transition(mode='out-in', name='zoom-in')
    .modal-container(v-if='showTransition')
      img.close(:src='closeIcon', @click='onExit', alt='Close button')
      NewLevelCongratulationsModal(@exit-confetti='onExit')
</template>

<script setup lang="ts">
  import { ref } from 'vue'
  // @ts-ignore
  import { ParticlesComponent } from '@tsparticles/vue2'

  import useIcons from '@/composables/useIcons'
  import eventBus from '@/main'
  import NewLevelCongratulationsModal from '@/components/modals/NewLevelCongratulationsModal.vue'

  const showTransition = ref(false)
  const { closeIcon } = useIcons()

  const options = {
    emitters: {
      position: {
        x: 50,
        y: 100,
      },
      rate: {
        quantity: 5,
        delay: 0.15,
      },
    },
    particles: {
      color: {
        value: ['#1E00FF', '#FF0061', '#E1FF00', '#00FF9E'],
      },
      move: {
        decay: 0.05,
        direction: 'top',
        enable: true,
        gravity: {
          enable: true,
        },
        outModes: {
          top: 'none',
          default: 'destroy',
        },
        speed: {
          min: 75,
          max: 150,
        },
      },
      number: {
        value: 0,
      },
      opacity: {
        value: 1,
      },
      rotate: {
        value: {
          min: 0,
          max: 360,
        },
        direction: 'random',
        animation: {
          enable: true,
          speed: 30,
        },
      },
      tilt: {
        direction: 'random',
        enable: true,
        value: {
          min: 0,
          max: 360,
        },
        animation: {
          enable: true,
          speed: 30,
        },
      },
      size: {
        value: 3,
        animation: {
          enable: true,
          startValue: 'min',
          count: 1,
          speed: 16,
          sync: true,
        },
      },
      roll: {
        darken: {
          enable: true,
          value: 25,
        },
        enlighten: {
          enable: true,
          value: 25,
        },
        enable: true,
        speed: {
          min: 5,
          max: 15,
        },
      },
      wobble: {
        distance: 30,
        enable: true,
        speed: {
          min: -7,
          max: 7,
        },
      },
      shape: {
        type: ['circle', 'square'],
        options: {},
      },
    },
  }

  const onExit = () => {
    eventBus.$emit('hide-confetti')
    document.body.classList.remove('showing-confetti')
  }

  const particlesLoaded = async () => {
    document.body.classList.add('showing-confetti')
    showTransition.value = true
  }
</script>

<style lang="postcss">
  body.showing-confetti #app > *:not(.confetti-container) {
    @apply ketch-pointer-events-none;
  }
  .confetti-container {
    @apply ketch-fixed ketch-left-0 ketch-right-0 ketch-top-0 ketch-bottom-0 ketch-z-10;
    @apply ketch-bg-white ketch-bg-opacity-[0.4];
    #tsparticles {
      canvas {
        @apply ketch-bg-transparent;
      }
    }
    .modal-container {
      @apply md:ketch-min-w-[460px] md:ketch-max-w-fit ketch-flex ketch-flex-col ketch-items-center ketch-justify-between;
    }
  }
</style>
