<script setup lang="ts">
  import { computed, ref, watch } from 'vue'
  import useCommonMixin from '@/composables/useCommonMixin'
  const { getColorVariableValue } = useCommonMixin()

  const props = defineProps({
    phoneNumber: {
      type: String,
      default: '',
    },
  })
  const emit = defineEmits(['phone-input'])
  const phoneInput = ref({ formatInternational: '', countryCode: '', isValid: false })
  const initialPhoneNumber = ref(props.phoneNumber)

  const translations = {
    countrySelectorLabel: '',
    countrySelectorError: 'Wähle ein Land',
    phoneNumberLabel: 'Telefonnummer',
  }

  const validColor = computed(() => getColorVariableValue.value('--primary-color'))

  watch(phoneInput, (value) => {
    if (value.formatInternational && value.isValid) {
      emit('phone-input', value.formatInternational)
    } else {
      emit('phone-input', '')
    }
  })
</script>

<template lang="pug">
.phone-number-input-wrapper
  MazPhoneNumberInput(
    :border-radius='4',
    :default-phone-number='initialPhoneNumber',
    :translations='translations',
    :valid-color='validColor',
    @update='phoneInput = $event',
    clearable,
    default-country-code='DE',
    no-example,
    show-code-on-list,
    v-model='initialPhoneNumber'
  )
</template>

<style lang="postcss">
  .phone-number-input-wrapper {
    .maz-base-component {
      > * {
        font-family: var(--primary-font-name) !important;
      }
    }
    .maz-phone-number-input {
      .country-selector {
        @apply ketch-w-[115px] ketch-flex-none ketch-min-w-0 ketch-max-w-none !important;
        .maz-input__input {
          @apply ketch-pt-0 !important;
        }
      }
      .maz-input {
        @apply ketch-bg-transparent ketch-border-[1px] ketch-border-border-color !important;
      }
      button.maz-phone-number-input__country-flag {
        @apply ketch-z-[1] ketch-bottom-[18px] !important;
      }
    }
  }
</style>
