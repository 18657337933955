<template lang="pug">
.expandable-text-area-wrapper.text-input(:data-value='textInput || textMessage')
  textarea(
    :maxlength='maxLength',
    :placeholder='textAreaPlaceholder',
    :value='textInput || textMessage',
    @input='expandTextAreaSize',
    data-cy='input-textarea',
    rows='1'
  )
</template>

<script setup lang="ts">
  import { ref, watch } from 'vue'

  const props = defineProps({
    textMessage: {
      type: String,
      required: true,
    },
    textAreaPlaceholder: String,
    doNotUpdateSize: {
      type: Boolean,
      default: false,
    },
    maxLength: {
      type: Number,
      default: null,
    },
  })

  const emit = defineEmits(['text-change'])

  const textInput = ref('')

  watch(textInput, (text) => {
    emit('text-change', text)
  })

  watch(
    () => props.textMessage,
    (text) => {
      textInput.value = text
    },
  )

  const expandTextAreaSize = (e: Event) => {
    const target = e.target as HTMLTextAreaElement
    const parent = target.parentNode as HTMLDivElement
    if (parent) {
      if (props.doNotUpdateSize) {
        e.preventDefault()
        return
      }
      textInput.value = target.value
    }
  }
</script>

<style lang="postcss">
  .expandable-text-area-wrapper {
    @apply ketch-relative ketch-inline-grid ketch-align-text-top ketch-items-stretch ketch-w-full ketch-h-[fit-content];
    &::after,
    textarea {
      @apply ketch-w-full ketch-row-start-1 ketch-col-start-1 ketch-m-0 ketch-resize-none;
      @apply ketch-bg-transparent ketch-appearance-none ketch-border-none ketch-max-h-[400px];
      @apply ketch-text-md !important;
    }
    &::after {
      content: attr(data-value) ' ';
      word-break: break-word;
      @apply ketch-invisible ketch-whitespace-pre-wrap;
    }
    textarea:focus {
      @apply ketch-outline-none;
    }
  }
</style>
