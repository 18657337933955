<script setup lang="ts">
  import type { PropType } from 'vue'
  import { computed, ref, watch } from 'vue'
  import type {
    OnboardingSurveyAnswerResponseData,
    CompanyOnboardingSurveyQuestion,
    OnboardingSurveyQuestionAnswer,
    OnboardingSurveyQuestionResponse,
  } from '@/services/interfaces/Auth0'
  import useIcons from '@/composables/useIcons'
  import useCommonMixin from '@/composables/useCommonMixin'
  import KetchUpButton from '@/components/common/KetchUpButton.vue'
  import SVGRenderer from '@/components/common/SVGRenderer.vue'
  import RadioButton from '@/components/common/RadioButton.vue'
  import LoadingSpinner from '@/components/common/LoadingSpinner.vue'
  import ExpandableTextAreaInput from '@/components/common/ExpandableTextAreaInput.vue'
  // @ts-ignore
  import DatePicker from 'vue2-datepicker'
  import OnboardingSurveyAnswerResponseView from '@/components/onboarding-survey/OnboardingSurveyAnswerResponseView.vue'
  import useI18n from '@/composables/useI18n'
  import useBreakpoint from '@/composables/useBreakpoint'

  const { course34iIcon, course34dIcon, course34fIcon, arrowRightIndicator } = useIcons()
  const { extractCourseVertical, disableDateBeforeToday } = useCommonMixin()
  const { translateString } = useI18n()
  const { isMobileDevice } = useBreakpoint()

  const props = defineProps({
    question: {
      type: Object as PropType<CompanyOnboardingSurveyQuestion>,
      required: true,
    },
    questionIndex: {
      type: Number,
      required: true,
    },
    totalQuestions: {
      type: Number,
      required: true,
    },
    questionResponse: {
      type: Object as PropType<OnboardingSurveyQuestionResponse>,
      default: null,
    },
    loadingNextStep: {
      type: Boolean,
      default: false,
    },
    transitionName: {
      type: String,
      default: 'next-slide',
    },
    isLastQuestion: {
      type: Boolean,
      required: true,
    },
  })

  const emit = defineEmits([
    'set-question-response',
    'next-question-or-complete-survey',
    'previous-question',
    'custom-text-answer',
    'exam-date',
  ])

  const customTextAnswer = ref(props.questionResponse?.customText ?? '')
  const examDate = ref(props.questionResponse?.examDate ?? '')

  const questionResponse = computed(() => props.questionResponse)

  const isGeneralQuestion = computed(() => props.question?.courseVertical === 'General')

  const courseVerticalAnswerIcon = computed(() => (answerText: string) => {
    const vertical = extractCourseVertical(answerText)
    if (vertical === '34i') return course34iIcon.value
    if (vertical === '34d') return course34dIcon.value
    return course34fIcon.value
  })

  const answerIsSelected = computed(() => (answerId: string) => {
    if (!questionResponse.value) {
      return false
    }

    const { selectedAnswerIds } = questionResponse.value
    return selectedAnswerIds.length > 0 && selectedAnswerIds.includes(answerId)
  })

  const loading = computed(() => props.loadingNextStep)

  const nextOrCompleteCTADisabled = computed(() => {
    if (!questionResponse.value) return true
    return questionResponse.value.selectedAnswerIds.length === 0
  })

  const isCustomAnswerInput = computed(
    () => (answer: OnboardingSurveyQuestionAnswer) =>
      answer.actionType === 'save_custom_text' || answer.actionType === 'save_exam_date',
  )

  const selectedAnswerWithResponseData = computed(() => {
    return props.question?.answers.find(
      (ans) => answerIsSelected.value(ans.id) && ans.responseEmoji && ans.responseText,
    )
  })

  const answerResponseData = computed((): OnboardingSurveyAnswerResponseData => {
    return {
      responseEmoji: selectedAnswerWithResponseData.value?.responseEmoji ?? '',
      responseTitle: selectedAnswerWithResponseData.value?.responseTitle ?? '',
      responseText: selectedAnswerWithResponseData.value?.responseText ?? '',
    }
  })

  const questionProgress = computed(() => {
    return `${props.questionIndex} ` + translateString('of') + ` ${props.totalQuestions - 1}`
  })

  const handleSetAnswer = (answer: OnboardingSurveyQuestionAnswer) => {
    customTextAnswer.value = ''
    examDate.value = ''
    selectAnswer(answer)
  }

  const selectAnswer = (answer: OnboardingSurveyQuestionAnswer) => {
    emit('set-question-response', {
      questionId: props.question.id,
      questionType: props.question.questionType,
      answer: answer,
      answerWasSelectedBefore: answerIsSelected.value(answer.id),
    })
  }

  const setExamDateAnswer = (answer: OnboardingSurveyQuestionAnswer, date: string) => {
    setCustomAnswer(answer, date)

    examDate.value = date
  }

  const setCustomTextAnswer = (answer: OnboardingSurveyQuestionAnswer, text: string) => {
    setCustomAnswer(answer, text)

    customTextAnswer.value = text || ''
    emit('custom-text-answer', text)
  }

  const setCustomAnswer = (answer: OnboardingSurveyQuestionAnswer, text: string) => {
    const isAnswerSelected = answerIsSelected.value(answer.id)

    if (text) {
      if (!isAnswerSelected) selectAnswer(answer)
    } else {
      selectAnswer(answer)
    }
  }

  const nextStep = () => {
    if (nextOrCompleteCTADisabled.value) return

    emit('next-question-or-complete-survey')
  }

  const previousStep = () => {
    if (selectedAnswerWithResponseData.value) {
      selectAnswer(selectedAnswerWithResponseData.value)
    } else {
      emit('previous-question')
    }
  }

  watch(
    () => examDate.value,
    (value) => {
      emit('exam-date', value)
    },
  )
</script>

<template lang="pug">
.onboarding-survey-question-wrapper(data-cy='onboarding-survey-question-wrapper')
  .onboarding-survey-question
    h2.question-title {{ question.title }}
    .answers(:class='{ "from-general-question": isGeneralQuestion }')
      template(v-for='answer in question.answers')
        .answer-group(:key='answer.id', v-if='!selectedAnswerWithResponseData || answerIsSelected(answer.id)')
          .answer(
            :class='{ selected: answerIsSelected(answer.id), "is-date-answer": answer.actionType === "save_exam_date" }',
            @click='handleSetAnswer(answer)'
          )
            .answer-wrapper
              .answer-description-wrapper
                SVGRenderer(
                  :has-hover='false',
                  :icon='courseVerticalAnswerIcon(answer.description)',
                  v-if='isGeneralQuestion',
                  :width='isMobileDevice ? "60px" : "80px"'
                )
                h3.answer-description {{ answer.description }}
              .radio-button-wrapper
                RadioButton(:active='answerIsSelected(answer.id)', v-if='answer.actionType !== "save_exam_date"')
            .custom-answer-input(@click.stop, v-if='isCustomAnswerInput(answer)')
              DatePicker(
                :disabled-date='disableDateBeforeToday',
                :placeholder='String($t("onboarding.enterAppointment"))',
                :value='examDate',
                @clear='examDate = ""',
                @input='setExamDateAnswer(answer, $event)',
                format='YYYY-MM-DD',
                v-if='answer.actionType === "save_exam_date"',
                value-type='YYYY-MM-DD'
              )
              ExpandableTextAreaInput(
                :max-length='200',
                :text-area-placeholder='question.textPlaceholder',
                :text-message='customTextAnswer',
                @text-change='setCustomTextAnswer(answer, $event)',
                v-if='answer.actionType === "save_custom_text"'
              )
          transition(name='onlyFadeIn')
            OnboardingSurveyAnswerResponseView(
              :answer-response-data='answerResponseData',
              v-if='selectedAnswerWithResponseData'
            )
  .previous-progress-submit-cta
    h5.previous(
      @click='previousStep',
      v-if='questionIndex || selectedAnswerWithResponseData',
      :class='{ change: selectedAnswerWithResponseData }'
    )
      template(v-if='selectedAnswerWithResponseData') {{ $t('change') }}
      template(v-else) <- {{ $t('previous') }}
    h5.progress(v-if='questionIndex > 0', data-cy='question-progress') {{ questionProgress }}
    .next-step
      KetchUpButton.primary(@click.native='nextStep', v-if='!nextOrCompleteCTADisabled')
        LoadingSpinner(v-if='loading')
        template(v-else)
          h5 {{ $t('next') }}
          SVGRenderer(:fill-color='"var(--primary-foreground-color)"', :has-hover='false', :icon='arrowRightIndicator')
</template>

<style lang="postcss">
  .onboarding-survey-question-wrapper {
    @apply ketch-min-w-[300px] ketch-w-full;
    .onboarding-survey-question {
      @apply ketch-flex ketch-flex-col ketch-justify-center ketch-w-full;

      .question-title {
        @apply ketch-text-center ketch-font-bold ketch-mb-c20;
      }

      .answers {
        @apply ketch-flex ketch-flex-col ketch-space-y-c20;

        .answer-group {
          @apply ketch-flex ketch-flex-col;

          &:not(:last-child) {
            &[style*='display: block'] {
              @apply ketch-mb-c20;
            }
          }

          .answer {
            @apply ketch-border-2 ketch-border-border-color ketch-p-c10 ketch-rounded-normal;
            @apply ketch-cursor-pointer hover:ketch-bg-background-color;

            .answer-wrapper {
              @apply ketch-flex ketch-items-center ketch-justify-between;

              .answer-description-wrapper {
                @apply ketch-flex ketch-items-center ketch-space-x-c5 sm:ketch-space-x-c10;

                img {
                  @apply ketch-w-[60px] sm:ketch-w-[80px];
                }
                .answer-description {
                  @apply ketch-font-[600] !important;
                }
              }

              .radio-button-wrapper {
                @apply ketch-ml-c10 ketch-w-c15 sm:ketch-w-c25;
              }
            }

            .custom-answer-input {
              @apply ketch-mt-c10;

              .text-input {
                @apply ketch-rounded-normal;

                textarea {
                  @apply ketch-min-h-[50px] sm:ketch-min-h-0;
                }
              }
              .mx-input {
                @apply ketch-text-md !important;
              }
            }

            &.is-date-answer {
              @apply sm:ketch-flex sm:ketch-items-center sm:ketch-justify-between;

              .custom-answer-input {
                @apply sm:ketch-mt-0;
              }
            }

            &.selected {
              @apply ketch-border-primary-text-color ketch-bg-background-color;
            }
          }
        }

        &.from-general-question {
          .answer {
            @apply ketch-px-c10 ketch-py-c5;
            .answer-description {
              @apply ketch-text-xs xs4:ketch-text-sm sm:ketch-text-md;
            }
          }
        }
      }
    }

    .previous-progress-submit-cta {
      @apply ketch-w-full ketch-flex ketch-items-center ketch-justify-between ketch-mt-c20;

      .previous {
        @apply ketch-cursor-pointer;
        &.change {
          @apply ketch-underline;
        }
      }

      .progress {
        @apply ketch-text-[#00254A] ketch-flex-1 ketch-text-center;
      }

      .next-step {
        @apply ketch-min-w-[110px];

        button {
          @apply ketch-px-c20 ketch-py-c10 ketch-w-[115px] ketch-rounded-normal ketch-space-x-c10;
        }
      }
    }
  }
</style>
