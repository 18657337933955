<template lang="pug">
.course-details-wrapper(:class='{ notranslate: isEditor }')
  CourseInfoLoader(v-if='fetchingCourseInfo')
  template(v-else)
    CourseInfoEditor(
      @has-course-changes='hasCourseChanges = $event',
      @update-training-content='createOrViewModule',
      v-if='inEditorMode && isLargeDesktop'
    )
    .course-info-container(v-else)
      CourseExpiredBanner
      .course-info(data-cy='course-info')
        .course-overview-section
          .video-teaser-image-container(v-if='course?.teaserVideoUrl || course?.teaserImageUrl')
            VideoPlayer(
              :content-type='"course_teaser_video"',
              :course-id='course?.id',
              :poster-image='getPosterImage(course?.teaserVideoUrl, course?.thumbnailTimestamp || 0)',
              :video-source='course?.teaserVideoUrl',
              v-if='course?.teaserVideoUrl'
            )
            img.teaser-image(:src='course?.teaserImageUrl', v-else-if='course?.teaserImageUrl')
          .info-sub-section(ref='courseInfo')
            h5.tags {{ tags }}
            h2.course-title {{ course?.title }}
            transition(mode='out-in', name='fade')
              .mobile-call-to-actions(
                v-if='isMobileDevice',
                v-intersection-observer='[onIntersectionObserver, { courseInfo }]'
              )
                CourseInfoCTA(:course='course', :product-amount='productPrice(course)', v-if='!course?.purchased')
            h5.course-caption(v-if='isMobileDevice') {{ $t('courses.whatTheCourseIsAbout') }}
            CourseDescription(
              :description='course?.description',
              :show-expanded-form='isMobileDevice ? true : !course?.teaserVideoUrl'
            )
            .module-duration-date(v-if='isDesktopDevice')
              CourseModuleDuration(:course='course', :number-of-modules='totalCourseGroupModules || 0')
              .date
                h5 {{ $t('courses.lastEditedOn') }} {{ formatDate(course?.updatedAt || '', 'dd.MM.yyyy') }}
        .teaser-info-section
          transition(name='fadeInDown')
            .teaser-info-container(v-if='showInfoContainer')
              .teaser-image-section(v-if='isDesktopDevice')
                img(:src='course?.cardTeaserImageUrl || course?.teaserImageUrl || cardEmptyImage')
              .teaser-content-section(v-if='!showAdditionalProgressOverview')
                transition(
                  name='bottom-panel',
                  v-if='isMobileDevice && showCourseProgressOverview && (course?.purchased || course?.expired)'
                )
                  CourseProgressOverview(
                    :progress-percentage='progressPercentage(course?.id || "")',
                    @toggle-continue='showCourseProgressOverview = !showCourseProgressOverview',
                    v-if='overviewAnimation'
                  )
                transition(
                  name='bottom-panel',
                  v-else-if='((isMobileDevice && !showCourseProgressOverview) || isDesktopDevice) && (course?.purchased || course?.expired)'
                )
                  .course-progress(v-if='bottomPanelAnimation || isDesktopDevice')
                    .course-progress-item(:key='index', v-for='(group, index) in courseGroups')
                      .name-progress(:class='{ "has-name": group.name }')
                        h6.group-name(v-if='group.name') {{ group.name }}
                        h4.progress-value {{ progressPercentage(course.id, group.id) }}%
                      .progress-container
                        ProgressBar(:percentage='progressPercentage(course.id, group.id)')
                      CourseModuleStatusContainer(:course-group-id='group.id')
                template(v-else)
                  FeedbackPriceSection(
                    :is-purchased='course?.purchased || false',
                    :product-amount='productPrice(course)',
                    :rating='course?.rating || 0',
                    :user-comment='course?.userComment || ""',
                    v-if='isDesktopDevice && !course?.isIddCourse'
                  )
                  .course-info-cta-wrapper
                    LoadingSpinner(v-if='fetchingCourseModules')
                    CourseInfoCTA(:course='course', :product-amount='productPrice(course)', v-else)
                  .package-info(v-if='course?.courseBundle && showPackageDetails && !isSachkundeCompany')
                    router-link(:to='`/packages/${course?.courseBundle?.courseBundleId}`')
                      .title-discount
                        h3 {{ course.courseBundle.title }}
                        .discount {{ $t('packageDiscountLabel') }}
                      .description {{ course.courseBundle.teaserDescription }}
                      h5.view-package {{ $t('courses.package.view') }}
                .exam-cancel(
                  :class='{ "has-exam": course?.exam }',
                  v-if='isMobileDevice && !showCourseProgressOverview'
                )
                  StartCourseExamCTA(:course='course', v-if='course?.exam')
                  h5.cancel(@click='showCourseProgressOverview = !showCourseProgressOverview') {{ $t('courses.cancel') }}
          transition(name='fadeInUp')
            div(v-if='showInfoContainer')
              StartCourseExamCTA(:course='course', v-if='isDesktopDevice && course?.exam')
              KetchUpButton.primary.open-webinar(
                @click.native='openWebinarModal',
                v-if='showWebinar && !inEditorMode && course?.purchased'
              )
                h5 {{ $t('courses.toTheWebinarDates') }}
              h5.trigger-whats-changed-modal(
                @click='showCourseChangesModal(changedLessonsByDate)',
                v-if='showWhatChangedCTA'
              ) {{ $t('courses.lookWhatHasChanged') }}
      .host-course-details(:class='{ "no-host": !course?.host }')
        .host(v-if='course?.host')
          h2(v-if='isDesktopDevice') {{ $t('courses.host') }}
          .host-container
            .host-image
              img(:src='course.host.profileImageUrl')
            .host-info
              h6.caption(v-if='isMobileDevice') {{ $t('courses.trainingPresentedBy') }}
              h3.name {{ course.host.name }}
              h6.position {{ course.host.position }}
            h5.description {{ course.hostDescription }}
        .course-details
          h2 {{ $t('courses.courseDetails') }}
          .details
            CourseModuleDuration(
              :course='course',
              :number-of-modules='totalCourseGroupModules || 0',
              v-if='isMobileDevice'
            )
            .detail(:key='index', v-for='(detail, index) in courseDetails')
              .detail-image
                SVGRenderer(:has-hover='false', :icon='detail.image', :stroke-color='"var(--primary-color)"')
              h5 {{ detail.details }}
      .authors(v-if='course?.authors && course?.authors.length')
        h2 {{ $t('courses.yourExperts') }}
        .authors-wrapper
          .author(:key='author.id', v-for='author in course?.authors')
            PlaceholderShimmer.author-image(:animate='true', height='130px')
              template(v-slot:default='{ isLoadingResource, onResourceLoaded }')
                img(
                  :class='{ "shimmer-asset": isLoadingResource }',
                  :src='author.profileImageUrl',
                  @load='onResourceLoaded'
                )
            h5.name {{ author.name }}
            h6 {{ author.position }}
      TestimonialSlider(
        :testimonials='course?.testimonials',
        v-if='course?.testimonials && course?.testimonials.length'
      )
      GoogleReviewWidget(:course-id='course?.id')
      CourseModules(@fetching-course-modules='fetchingCourseModules = $event')
</template>

<script setup lang="ts">
  import { computed, onMounted, ref, watch } from 'vue'
  import LoadingSpinner from '@/components/common/LoadingSpinner.vue'
  import VideoPlayer from '@/components/VideoPlayer.vue'
  import CourseModules from '@/components/course/CourseModules.vue'
  import CourseDescription from '@/components/course/CourseDescription.vue'
  import PlaceholderShimmer from '@/components/common/PlaceholderShimmer.vue'
  import SVGRenderer from '@/components/common/SVGRenderer.vue'
  import useIcons from '@/composables/useIcons'
  import useCommonMixin from '@/composables/useCommonMixin'
  import useCourse from '@/composables/useCourse'
  import useVideo from '@/composables/useVideo'
  import { CourseModule } from '@/store/modules/course'
  import useSegment from '@/composables/useSegment'
  import TestimonialSlider from '@/components/course/TestimonialSlider.vue'
  import ProgressBar from '@/components/common/ProgressBar.vue'
  import CourseModuleStatusContainer from '@/components/course/CourseModuleStatusContainer.vue'
  import CourseModuleDuration from '@/components/course/CourseModuleDuration.vue'
  import FeedbackPriceSection from '@/components/course/FeedbackPriceSection.vue'
  import useBreakpoint from '@/composables/useBreakpoint'
  import KetchUpButton from '@/components/common/KetchUpButton.vue'
  import type {
    CourseItem,
    CoursePackage,
    LessonChangeLog,
    CourseModule as CourseModuleState,
  } from '@/services/interfaces/Course'
  import GoogleReviewWidget from '@/components/GoogleReviewWidget.vue'
  import { v4 as uuidv4 } from 'uuid'
  import useEditor from '@/composables/useEditor'
  // eslint-disable-next-line
  import CourseInfoEditor from '@/components/editor/CourseInfoEditor.vue'
  import { EditorModule } from '@/store/modules/editor'
  import { onBeforeRouteLeave, useRoute, useRouter } from 'vue-router/composables'
  import eventBus from '@/main'
  import useI18n from '@/composables/useI18n'
  import CourseInfoLoader from '@/components/loaders/CourseInfoLoader.vue'
  import CourseProgressOverview from '@/components/course/CourseProgressOverview.vue'
  import { vIntersectionObserver } from '@vueuse/components'
  import CourseExpiredBanner from '@/components/course/CourseExpiredBanner.vue'
  import CourseInfoCTA from '@/components/course/CourseInfoCTA.vue'
  import { UserModule } from '@/store/modules/user'
  import StartCourseExamCTA from '@/components/course/StartCourseExamCTA.vue'
  import { DateTime } from 'luxon'
  import CookieHelper from '@/helpers/CookieHelper'

  const router = useRouter()
  const route = useRoute()
  const { translateString } = useI18n()
  const { device, lesson, certificate, cardEmptyImage } = useIcons()
  const { formatDate, setIsLoadingComponentViewData, isSachkundeCompany, showCourseChangesModal, isDevCompany } =
    useCommonMixin()
  const { getPosterImage } = useVideo()
  const { isMobileDevice, isDesktopDevice, isLargeDesktop } = useBreakpoint()
  const {
    course,
    currentCourseId,
    routeToFirstCourseModule,
    progressPercentage,
    showWebinar,
    openWebinarModal,
    courseModules,
    fetchingCourseInfo,
    productPrice,
    hasPurchasedAtLeastACourse,
  } = useCourse()
  const { trackPage, pageViewed } = useSegment()
  const { inEditorMode, isEditor } = useEditor(route)

  const showCourseProgressOverview = ref(true)
  const showPackageDetails = ref(false)
  const showInfoContainer = ref(false)
  const hasCourseChanges = ref(false)
  const fetchingCourseModules = ref(true)
  const courseInfo = ref(null)
  const showAdditionalProgressOverview = ref(false)

  const courseDetails = computed(() => {
    return [
      {
        image: device.value,
        details: translateString('courses.accessOnAllDevices'),
      },
      {
        image: lesson.value,
        details: translateString('courses.totalLessonsInAllModules', {
          lessons: course.value?.numberOfLessons || 0,
          modules: course.value?.numberOfModules || 0,
        }),
      },
      {
        image: certificate.value,
        details: translateString('courses.personalCertificate'),
      },
    ]
  })

  const courseGroups = computed(() => {
    return course.value?.courseGroups.length ? course.value?.courseGroups : [{ id: '', name: '' }]
  })

  const tags = computed(() => {
    return course.value?.tags?.map((t) => t.title).join(', ')
  })

  const totalCourseGroupModules = computed(() => {
    const courseGroupsIds = course.value?.courseGroups?.map((c) => c.id) || []
    return CourseModule.totalCourseGroupModules(currentCourseId.value, courseGroupsIds)
  })

  const getCourseInfo = () => {
    if (!currentCourseId.value) return
    CourseModule.getCourse({ courseId: currentCourseId.value }).then(async (courseData: any) => {
      if (courseData?.courseBundle) {
        const bundles = (await CourseModule.getPackageBundle(
          (courseData as CourseItem).courseBundle!.courseBundleId,
        )) as CoursePackage
        if (bundles) showPackageDetails.value = !!bundles.courses?.every((c) => c.state === 'ready' && !c.purchased)
      }
    })
  }

  const createModule = () => {
    router
      .push({
        name: 'CourseModule',
        params: { courseId: currentCourseId.value, moduleId: 'mod-' + uuidv4() },
      })
      .catch(() => {
        return
      })
  }

  const createOrViewModule = () => {
    if (courseModules.value?.length) return routeToFirstCourseModule(currentCourseId.value)
    createModule()
  }

  const setDiscardAllCourseChanges = () => {
    EditorModule.setDiscardCourseChanges(true)
  }

  const setSubmitAllCourseChanges = () => {
    EditorModule.setSubmitCourseChanges(true)
  }

  //@ts-ignore
  function onIntersectionObserver([{ isIntersecting }]) {
    if (!course.value?.purchased) {
      showAdditionalProgressOverview.value = isIntersecting
    }
  }

  getCourseInfo()

  onMounted(() => {
    setIsLoadingComponentViewData(true)
    setTimeout(() => (showInfoContainer.value = true), 1500)
    trackPage('Course Details')
    pageViewed('Course Details')
  })

  const bottomPanelAnimation = ref(false)
  const overviewAnimation = ref(true)
  watch(showCourseProgressOverview, (value: boolean) => {
    if (value) {
      bottomPanelAnimation.value = false
      // wait for bottomPanelAnimation to finish
      setTimeout(() => {
        overviewAnimation.value = true
      }, 300)
    } else {
      // defer bottomPanelAnimation start to callback queue
      setTimeout(() => {
        bottomPanelAnimation.value = true
      }, 0)
      // turn off overviewAnimation only when bottomPanelAnimation is done
      setTimeout(() => {
        overviewAnimation.value = false
      }, 600)
    }
  })

  watch(currentCourseId, (value) => {
    if (value) {
      getCourseInfo()
    }
  })

  watch(fetchingCourseInfo, (value) => {
    setIsLoadingComponentViewData(value)
  })

  const changedLessonsByDate = ref<Record<string, LessonChangeLog[]>>({})
  const showWhatChangedCTA = ref(false)

  const hasWeekPassedSinceStartDate = (fixedStartDate: DateTime): boolean => {
    const now = DateTime.local().startOf('day')
    const weeksElapsed = now.diff(fixedStartDate, 'weeks').weeks
    return Math.floor(weeksElapsed) > 0
  }

  const processLessonChangesByDate = (courseModules: CourseModuleState[]): Record<string, LessonChangeLog[]> => {
    const changesByDate: Record<string, LessonChangeLog[]> = {}

    courseModules.forEach((module) => {
      module.lessons?.forEach((lesson) => {
        const lessonUpdatedAt = DateTime.fromSQL(lesson.updatedAt)
        if (lesson.type !== 'Quiz') {
          const formattedDate = lessonUpdatedAt.toFormat('dd. LLLL yyyy')

          if (!changesByDate[formattedDate]) {
            changesByDate[formattedDate] = []
          }

          changesByDate[formattedDate].push({
            courseId: currentCourseId.value,
            moduleId: module.id,
            lesson,
          })
        }
      })
    })

    return changesByDate
  }

  watch(fetchingCourseModules, (value) => {
    if (!value && UserModule.hasValidUser) {
      const dateModalViewed = CookieHelper.getCookieValue(`${course.value?.id}-course-changes-modal-viewed`)

      const fixedStartDate = dateModalViewed ? DateTime.fromSQL(dateModalViewed) : DateTime.fromSQL('2024-09-12')

      const changesByDate = processLessonChangesByDate(courseModules.value!)
      const sortedDates = Object.keys(changesByDate).sort((a, b) => new Date(b).getTime() - new Date(a).getTime())

      sortedDates.forEach((date) => {
        changedLessonsByDate.value[date] = changesByDate[date]
      })

      if (Object.keys(changedLessonsByDate.value).length > 0 && hasPurchasedAtLeastACourse.value) {
        showWhatChangedCTA.value = true

        if (hasWeekPassedSinceStartDate(fixedStartDate) && !isDevCompany.value) {
          showCourseChangesModal(changedLessonsByDate.value)
          CookieHelper.setCrossDomainCookie(
            `${course.value?.id}-course-changes-modal-viewed`,
            DateTime.local().toISODate(),
          )
        }
      }
    }
  })

  onBeforeRouteLeave((_to, _from, next) => {
    const { inEditorMode } = useEditor(route)
    const { translateString } = useI18n()

    if (!inEditorMode) {
      return next()
    }
    if (hasCourseChanges.value) {
      eventBus.$emit('show-modal', {
        modalContentComponent: 'ConfirmationModal',
        modalTitle: translateString('editor.thereAreChanges'),
        modalProps: {
          confirmationText: translateString('editor.saveOrDiscard'),
          cancelButtonText: translateString('editor.discardChanges'),
          okButtonText: translateString('editor.saveAllChanges'),
          showSvgs: true,
          okCallback: (closeCallback: () => void) => {
            setSubmitAllCourseChanges()
            // next()
            if (typeof closeCallback === 'function') closeCallback()
          },
          cancelCallback: () => {
            setDiscardAllCourseChanges()
            // next()
          },
        },
        cssClass: 'confirmation editor',
        modalCloseCallback: (callback: () => void) => {
          if (typeof callback === 'function') callback()
        },
      })
    } else {
      next()
    }
  })
</script>

<style lang="postcss">
  .course-details-wrapper {
    @apply ketch-mt-c15;
    .course-info-container {
      @apply ketch-space-y-c30 md:ketch-space-y-c50 ketch-pb-c300 md:ketch-pb-0;
      .course-info {
        @apply ketch-grid ketch-grid-cols-1 md:ketch-grid-cols-[auto_300px] xl:ketch-grid-cols-[auto_350px];
        @apply md:ketch-gap-x-c30 xl:ketch-gap-x-c50 ketch-relative ketch-z-[1];
        .teaser-image-section {
          @apply ketch-row-span-1 ketch-mb-c12 md:ketch-mb-0;
          img {
            @apply ketch-w-full ketch-rounded-large ketch-object-cover ketch-object-top ketch-h-[160px];
            @apply xs4:ketch-h-[180px] xl:ketch-h-[220px];
          }
        }
        .course-overview-section {
          @apply ketch-flex ketch-flex-col ketch-space-y-c10 ketch-flex-auto md:ketch-space-y-0;
          .video-teaser-image-container {
            @apply md:ketch-mb-c30;
            .video-player {
              @apply ketch-max-h-[162px] xs1:ketch-max-h-[185px] xs2:ketch-max-h-[193px] xs3:ketch-max-h-[215px];
              @apply xs4:ketch-max-h-[386px] sm:ketch-max-h-[410px] md:ketch-max-h-[338px] xl:ketch-max-h-[371px];
              .plyr {
                @apply ketch-rounded-large;
                .plyr__controls {
                  @apply ketch-pt-c6 sm:ketch-pt-c15;
                }
              }
            }
            img {
              @apply ketch-w-full ketch-rounded-large ketch-object-cover;
              @apply ketch-h-[160px] xs4:ketch-h-[380px] sm:ketch-h-[400px];
            }
          }
          .info-sub-section {
            @apply md:ketch-max-w-[650px];
            .module-duration-date {
              @apply ketch-flex ketch-justify-between ketch-mt-[17px];
              .date {
                @apply ketch-text-gray-400;
              }
            }
            .course-caption {
              @apply ketch-font-bold ketch-mb-c6 ketch-pt-c10 ketch-text-sm ketch-leading-lg;
            }
          }
        }
        .teaser-info-section {
          .start-exam,
          .open-webinar,
          .trigger-whats-changed-modal {
            @apply ketch-mt-c20;
          }
          .trigger-whats-changed-modal {
            @apply ketch-underline ketch-underline-offset-4 ketch-cursor-pointer;
          }
          .open-webinar {
            @apply ketch-w-full;
          }
          .teaser-info-container {
            @apply ketch-flex ketch-flex-col ketch-rounded-none ketch-overflow-hidden ketch-z-[2];
            @apply ketch-bg-module-selector-background-color ketch-fixed ketch-bottom-0 ketch-left-0 ketch-right-0;
            @apply md:ketch-relative md:ketch-left-auto md:ketch-right-auto md:ketch-bottom-auto md:ketch-rounded-large;
            @screen xs {
              box-shadow: 0 0 6px 6px rgba(0, 0, 0, 0.05);
            }
            @apply md:ketch-shadow-none;
            > * {
              @apply ketch-text-module-selector-foreground-color;
            }

            .teaser-content-section {
              @apply ketch-p-c10 xs4:ketch-p-c20;
              .cancel {
                @apply ketch-underline ketch-cursor-pointer ketch-mt-c30 ketch-text-right;
              }
              .exam-cancel {
                @apply ketch-flex ketch-items-center ketch-justify-end ketch-pt-c20;
                &.has-exam {
                  @apply ketch-justify-between;
                }
                > * {
                  @apply ketch-mt-0;
                }
              }
            }
            .course-progress {
              @apply ketch-space-y-c15;
              .course-progress-item {
                &:not(:first-of-type) {
                  @apply ketch-relative;
                  &:before {
                    @apply ketch-absolute ketch-border-t ketch-border-dashed ketch-bg-black ketch-opacity-[0.2];
                    @apply ketch-left-0 ketch-right-0 ketch--mx-c20;
                    content: '';
                  }
                  .group-name {
                    @apply ketch-pt-c15;
                  }
                }
                .group-name {
                  @apply ketch-font-bold ketch-text-primary-text-color;
                }
                .name-progress {
                  @apply ketch-flex ketch-justify-end ketch-items-end ketch-pb-c15 ketch-space-x-c30;
                  &.has-name {
                    @apply ketch-justify-between;
                  }
                }
                .progress-container {
                  @apply ketch-relative ketch-mb-c12;
                }
              }
            }
            .package-info {
              @apply ketch-border-t ketch-border-dashed ketch-border-border-color ketch-mt-c20;
              @apply ketch--mx-c20 ketch-space-y-c10 ketch-p-c20 ketch-pb-0 ketch-block;
              .title-discount {
                @apply ketch-flex ketch-items-center ketch-space-x-c10 ketch-justify-between;
                h3 {
                  @apply ketch-font-bold;
                }
                .discount {
                  @apply ketch-border ketch-border-primary-color ketch-text-primary-color;
                  @apply ketch-rounded-large ketch-px-c10 ketch-py-c5 ketch-flex ketch-items-center;
                }
              }
              .view-package {
                @apply ketch-font-bold;
              }
            }
          }
        }
        .course-progress-overview,
        .course-progress {
          .progress-value {
            @apply ketch-text-primary-color ketch-font-bold ketch-leading-sm;
          }
          .progress-bar-wrapper {
            @apply ketch-w-full ketch-bg-[#E2E2E2] ketch-h-c4 ketch-border-0 ketch-rounded-none;
            @apply ketch-absolute ketch-bottom-0 ketch-left-0 ketch-right-0;
            .progress-bar {
              @apply ketch-bg-primary-color;
            }
          }
        }
      }
      .host-course-details {
        @apply ketch-flex ketch-flex-col md:ketch-flex-row;
        &.no-host {
          @apply md:ketch-justify-end;
        }
        h2 {
          @apply ketch-font-big-daily-short;
        }
        .host {
          @apply ketch-flex-auto md:ketch-mr-c30 xl:ketch-mr-c50 ketch-order-2 md:ketch-order-1 ketch-relative;
          &:before,
          &:after {
            @apply ketch-absolute ketch-border-t ketch-border-dashed ketch-bg-black ketch-opacity-[0.2];
            @apply ketch-left-0 ketch-right-0 md:ketch-hidden;
            content: '';
          }
          .host-container {
            @apply ketch-grid ketch-grid-cols-[auto_1fr] ketch-gap-c20 md:ketch-gap-y-0;
            @apply md:ketch-flex-row md:ketch-mb-0 ketch-my-c30;
            .host-image {
              @apply ketch-col-start-1 ketch-row-start-1 ketch-max-h-[160px];
              @apply md:ketch-row-span-2 xs3:ketch-h-[160px] xs3:ketch-w-[205px];
              img {
                @apply ketch-h-full ketch-w-full ketch-object-top ketch-object-cover ketch-rounded-normal;
              }
            }
            .host-info {
              @apply md:ketch-mt-0 md:ketch-w-[260px];
              @apply ketch-col-start-2 ketch-row-start-1;
              .name,
              .caption {
                @apply ketch-font-bold ketch-mb-c5;
              }
            }
            .description {
              @apply ketch-row-start-2 ketch-col-span-2 md:ketch-row-start-2 md:ketch-col-start-2;
            }
          }
        }
        .course-details {
          @apply ketch-order-1 md:ketch-order-2 ketch-mb-c30 md:ketch-mb-0;
          .course-module-duration {
            .module {
              @apply ketch-hidden;
            }
            .duration {
              @apply ketch-m-0;
            }
          }
          > h2 {
            @apply ketch-hidden md:ketch-block;
          }
          @screen md {
            flex: 0 0 350px;
          }
          .details {
            @apply ketch-space-y-c12 md:ketch-mt-c30;
            .detail {
              @apply ketch-flex ketch-items-center ketch-space-x-c10;
              .detail-image {
                @apply ketch-h-c20;
              }
            }
          }
        }
      }
      .google-review-widget-wrapper {
        @apply ketch-max-w-[100vw] ketch-overflow-hidden;
        div[class*='eapps-google-reviews-'] {
          @apply ketch-z-0;
        }
      }
    }
  }
  .authors {
    h2 {
      @apply ketch-font-big-daily-short;
    }
  }
</style>
