<script setup lang="ts">
  import useCommonMixin from '@/composables/useCommonMixin'
  import { computed } from 'vue'
  import { UserModule } from '@/store/modules/user'
  import { CourseModule } from '@/store/modules/course'

  const { formatDate } = useCommonMixin()

  const expired = computed(() => CourseModule.course?.expired)

  const expiredAt = computed(() => CourseModule.course?.expiredAt ?? null)

  const expiryDuration = computed(() => UserModule.company?.courseExpiryDuration)
</script>

<template lang="pug">
.course-expired-banner(v-if='expired && expiredAt')
  h5 {{ $t('courses.courseExpiredInfo', { expiredDate: formatDate(expiredAt, 'dd.MM.yyyy'), expiryDuration: expiryDuration }) }}
</template>

<style scoped lang="postcss">
  .course-expired-banner {
    @apply ketch-flex ketch-items-center ketch-justify-center ketch-w-full ketch-rounded-lg;
    @apply ketch-px-c10 ketch-py-c5 ketch-text-secondary-foreground-color ketch-bg-secondary-color;
    &.on-module-lesson-layout {
      @apply ketch-mt-c15;
    }
  }
</style>
