// @ts-nocheck
import { Module, VuexModule, Mutation, Action, getModule } from 'vuex-module-decorators'
import store from '../index'
import {
  Lesson,
  CourseModule,
  EditorLessonAudioFile,
  EditorStateBeforeChange,
  EditorCoursePayload,
  LessonDownloadable,
  DataMap,
  EditorCourseSection,
} from '@/services/interfaces/Course'
import Vue from 'vue'
import {
  LessonQuestion,
  EditorChangesPayload,
  EditorStatePayload,
  AuthorMap,
  EditorSlide,
  CourseSection,
  LeuckentextQuiz,
} from '@/services/interfaces/Course'
import { Author } from '@/services/interfaces/Common'
import CourseApi from '@/services/api/CourseApi'
import { Nullable } from '@/services/interfaces/Content'

@Module({ name: 'editor', store, dynamic: true, namespaced: true })
class Editor extends VuexModule {
  public editMode = false
  public course: Nullable<EditorCoursePayload> = null
  public currentLesson: Nullable<Lesson> = null
  public currentModule: Nullable<CourseModule> = null
  public coursesModuleMap: { [key: string]: CourseModule[] } = {}
  public editorSubscriptions: {
    [key: string]: {
      [key: string]: (args?: any, stateBeforeChange?: EditorStateBeforeChange) => void
    }[]
  } = { discard: [], save: [] }
  public currentLessonQuestions: Nullable<LessonQuestion[]> = null
  public currentLessonAudioFiles: Nullable<EditorLessonAudioFile[]> = null
  public editorAllAuthors: Nullable<Author[]> = null
  public lessonDownloadable: Nullable<LessonDownloadable> = null
  public courseSections: Nullable<EditorCourseSection[]> = null
  public editorChanges: { [key: string]: EditorChangesPayload[] } = {}
  public currentStateIndex: { [path: string]: number } = {}
  public undoingOrRedoing = false
  public lessonSlideshows: Nullable<EditorSlide[]> = null
  public leuckentextDeleteParams: { id: string; lessonId: string } | null = null
  public lessonLeuckentext: Nullable<LeuckentextQuiz> = null
  public quizQuestions: Nullable<LessonQuestion[]> = null
  public discardCourseChanges = false
  public submitCourseChanges = false
  public lessonProfileImageData = ''
  public moduleImageData = ''
  public downloadTeaserImageData = ''
  public discardingAllChanges = false

  get courseModules() {
    return (courseId: string, courseGroupId?: string): CourseModule[] => {
      if (!this.coursesModuleMap[courseId]) return []
      const modules = this.coursesModuleMap[courseId]
      if (courseGroupId) {
        return modules
          .filter((mod) => mod.courseGroupId === courseGroupId)
          .sort((a, b) => {
            return a.courseGroupOrderingNumber - b.courseGroupOrderingNumber
          })
      }
      return modules
    }
  }

  get currentModuleAuthors() {
    if (!this.currentModule) return []
    return this.currentModule.authors
  }

  get canUndo() {
    return (path: string) => {
      return !!(this.editorChanges[path] || [])[this.currentStateIndex[path]]
    }
  }

  get canRedo() {
    return (path: string) => {
      return (this.editorChanges[path] || []).length - 1 > this.currentStateIndex[path]
    }
  }

  @Mutation
  private SET_EDIT_MODE(mode: boolean) {
    this.editMode = mode
  }

  @Mutation
  private SET_EDITOR_COURSE(course: EditorCoursePayload | null) {
    this.course = course
  }

  @Mutation
  private SET_CURRENT_LESSON(lesson: Lesson | null) {
    this.currentLesson = lesson
  }

  @Mutation
  private SET_CURRENT_MODULE(module: CourseModule | null) {
    this.currentModule = module
  }

  @Mutation
  private SET_CURRENT_MODULE_LESSONS(lessons: Lesson[]) {
    this.currentModule!.lessons = lessons
  }

  @Mutation
  private SET_COURSE_SECTIONS(sections: CourseSection[] | null) {
    this.courseSections = sections
  }

  @Mutation
  private SET_COURSE_MODULES(payload: { courseId: string; modules: CourseModule[] }) {
    Vue.set(this.coursesModuleMap, payload.courseId, payload.modules)
  }

  @Mutation
  private ADD_EDITOR_CHANGE(payload: EditorStatePayload) {
    if (!this.editorChanges[payload.path]) {
      Vue.set(this.currentStateIndex, payload.path, -1)
      Vue.set(this.editorChanges, payload.path, [])
    }
    // discarding changes ahead
    if (
      this.currentStateIndex[payload.path] > -1 &&
      this.currentStateIndex[payload.path] < this.editorChanges[payload.path].length - 1
    ) {
      this.editorChanges[payload.path].splice(this.currentStateIndex[payload.path] + 1)
    }
    const lastChange = this.editorChanges[payload.path].slice(-1)[0]
    let stateBeforeChange = {
      currentLesson: this.currentLesson ? structuredClone(this.currentLesson) : null,
      currentModule: this.currentModule ? structuredClone(this.currentModule) : null,
      courseSections: this.courseSections ? structuredClone(this.courseSections) : null,
      currentLessonQuestions: this.currentLessonQuestions ? structuredClone(this.currentLessonQuestions) : null,
      currentLessonAudioFiles: this.currentLessonAudioFiles ? structuredClone(this.currentLessonAudioFiles) : null,
      lessonDownloadable: this.lessonDownloadable ? structuredClone(this.lessonDownloadable) : null,
      lessonProfileImageData: this.lessonProfileImageData,
      downloadTeaserImageData: this.downloadTeaserImageData,
      moduleImageData: this.moduleImageData,
    }

    if (!payload.skipGrouping && lastChange?.data.key === payload.key) {
      this.editorChanges[payload.path].splice(this.editorChanges[payload.path].length - 1, 1)
      stateBeforeChange = lastChange.stateBeforeChange
    }
    // TODO unable to clone with ...structuredClone(payload) -- investigate
    this.editorChanges[payload.path].push({
      path: payload.path,
      data: { ...JSON.parse(JSON.stringify(payload)), mutateAndQueue: payload.mutateAndQueue },
      stateBeforeChange,
    })
    this.currentStateIndex[payload.path] = this.editorChanges[payload.path].length - 1
  }

  @Mutation
  private SET_EDITABLE_STATE(payload: EditorStatePayload) {
    if (
      payload.state &&
      payload.property &&
      payload.key !== 'currentLessonprofileImageUrl' &&
      payload.key !== 'currentModuleteaserImageUrl' &&
      payload.key !== 'lessonDownloadabledownloadTeaserImageUrl' &&
      payload.key !== 'lessonDownloadabledownloadUrl'
    ) {
      Vue.set((this as any)[payload.state!], payload.property, payload.value)
    }

    const lesson = this.currentModule?.lessons.find((l) => l.id === this.currentLesson?.id)
    switch (payload.key) {
      case 'currentLessontitle':
        if (lesson) lesson.title = payload.value as string
        return
      case 'currentLessonduration':
        if (lesson) lesson.duration = payload.value as number
        return
      case 'currentLessontype':
        if (payload.value === 'SlideShow' && !this.currentLesson?.slideShow) {
          this.currentLesson!.slideShow = []
        }
        return
      case 'currentLessoncontent':
        if (lesson) lesson.content = payload.value as string
        return
      case 'currentLessonprofileImageUrl':
        this.currentLesson!.profileImageUrl = (payload.value as DataMap).url ?? ''
        this.lessonProfileImageData = (payload.value as DataMap).imageData ?? ''
        return
      case 'lessonDownloadabledownloadTeaserImageUrl':
        if (this.lessonDownloadable) {
          this.lessonDownloadable.downloadTeaserImageUrl = (payload.value as DataMap).url ?? ''
          this.downloadTeaserImageData = (payload.value as DataMap).imageData ?? ''
        }
        return
      case 'lessonDownloadabledownloadUrl':
        if (this.lessonDownloadable) {
          this.lessonDownloadable.downloadUrl = (payload.value as DataMap).url ?? ''
          this.lessonDownloadable.realDownloadData = (payload.value as DataMap).downloadData ?? ''
        }
        return
      case 'currentModuleteaserImageUrl':
        this.currentModule!.teaserImageUrl = (payload.value as DataMap).url ?? ''
        this.moduleImageData = (payload.value as DataMap).imageData ?? ''
        return
    }
  }

  @Mutation
  private SET_CURRENT_STATE_INDEX(payload: { path: string; index: number }) {
    this.currentStateIndex[payload.path] = payload.index
  }

  @Mutation
  private SET_UNDOING_OR_REDOING(undoingOrRedoing: boolean) {
    this.undoingOrRedoing = undoingOrRedoing
  }

  @Mutation
  private SET_DOWNLOADABLE_URL(url: string) {
    if (this.lessonDownloadable) {
      this.lessonDownloadable.downloadUrl = url
    }
  }

  @Mutation
  private SET_DOWNLOADABLE_REAL_DOWNLOAD_DATA(fileData: string) {
    if (this.currentLesson?.downloadables) {
      this.currentLesson.downloadables.realDownloadData = fileData
    }
  }

  @Mutation
  private SET_TEASER_VIDEO_URL(url: string) {
    if (this.currentModule) this.currentModule.teaserVideoUrl = url
  }

  @Mutation
  private SET_TEASER_IMAGE_URL(url: string) {
    if (this.currentModule) this.currentModule.teaserImageUrl = url
  }

  @Mutation
  private SET_EDITOR_ALL_AUTHORS(authors: Author[]) {
    this.editorAllAuthors = authors
  }

  @Mutation
  private SET_LESSON_DOWNLOADABLE(downloadable: LessonDownloadable | null) {
    this.lessonDownloadable = downloadable
  }

  @Mutation
  private SET_LESSON_PROFILE_IMAGE_DATA(imageData: string) {
    this.lessonProfileImageData = imageData
  }

  @Mutation
  private SET_DOWNLOADABLE_TEASER_IMAGE_DATA(imageData: string) {
    this.downloadTeaserImageData = imageData
  }

  @Mutation
  private SET_MODULE_IMAGE_DATA(imageData: string) {
    this.moduleImageData = imageData
  }

  @Mutation
  private REMOVE_MODULE_AUTHOR(index: number) {
    if (this.currentModule?.authors) {
      EditorModule.currentModule?.authors?.splice(index, 1)
    }
  }

  @Mutation
  private ADD_MODULE_AUTHOR(author: Author) {
    if (this.currentModule?.authors) {
      this.currentModule.authors.push(author)
    }
  }

  @Mutation
  private SUBSCRIBE(payload: {
    type: 'discard' | 'save'
    key: string
    callback: () => void
    allowDuplicate?: boolean
  }) {
    const subscriptions = this.editorSubscriptions[payload.type]
    const sub = subscriptions.find((s) => s[payload.key] === payload.callback)
    if (!sub || payload.allowDuplicate) {
      this.editorSubscriptions[payload.type].push({ [payload.key]: payload.callback })
    }
  }

  @Mutation
  private UNSUBSCRIBE(payload: { type: 'discard' | 'save'; callback: () => void }) {
    const subscriptions = this.editorSubscriptions[payload.type]
    const index = subscriptions.findIndex((s) => Object.values(s).includes(payload.callback))
    if (index >= 0) {
      this.editorSubscriptions[payload.type].splice(index, 1)
    }
  }

  @Mutation
  private EXECUTE_ALL_SUBSCRIPTIONS(type: 'discard' | 'save') {
    if (type === 'discard') {
      this.discardingAllChanges = true
    }
    this.editorSubscriptions[type].forEach((subscription) => {
      Object.values(subscription)[0]()
    })
    this.discardingAllChanges = false
    this.editorChanges = {}
    this.currentStateIndex = {}
  }

  @Mutation
  private SET_DISCARDING_ALL_CHANGES(value: boolean) {
    this.discardingAllChanges = value
  }

  @Mutation
  private RESET_EDITOR_CHANGES() {
    this.editorSubscriptions = { discard: [], save: [] }
    this.editorChanges = {}
    this.currentStateIndex = {}
  }

  @Mutation
  private SET_CURRENT_LESSON_QUESTIONS(questions: LessonQuestion[] | null) {
    this.currentLessonQuestions = questions
  }

  @Mutation
  private SET_CURRENT_LESSON_AUDIO_FILES(audioFiles: EditorLessonAudioFile[] | null) {
    this.currentLessonAudioFiles = audioFiles
  }

  @Mutation
  private SET_LESSON_SLIDESHOWS(slideShows: EditorSlide[] | null) {
    this.lessonSlideshows = slideShows
  }

  @Mutation
  private SET_LEUCKENTEXT_DELETE_PARAMS(params: { id: string; lessonId: string } | null) {
    this.leuckentextDeleteParams = params
  }

  @Mutation
  private SET_LEUCKENTEXT(payload: LeuckentextQuiz) {
    this.lessonLeuckentext = payload
  }

  @Mutation
  private SET_SECTION_STATE(payload: { sectionId: string; prop: string; value: string | string[] }) {
    const section = this.courseSections?.find((s) => s.id === payload.sectionId) as any
    if (section) section[payload.prop] = payload.value
  }

  @Mutation
  private SET_QUIZ_QUESTIONS(questions: LessonQuestion[] | null) {
    this.quizQuestions = questions
  }

  @Mutation
  private SET_DISCARD_COURSE_CHANGES(value: boolean) {
    this.discardCourseChanges = value
  }

  @Mutation
  private SET_SUBMIT_COURSE_CHANGES(value: boolean) {
    this.submitCourseChanges = value
  }

  @Action({ commit: 'SET_EDIT_MODE' })
  setEditMode(mode: boolean) {
    return mode
  }

  @Action({ commit: 'SET_EDITOR_COURSE' })
  setEditorCourse(course: EditorCoursePayload | null) {
    return structuredClone(course)
  }

  @Action({ commit: 'SET_CURRENT_LESSON' })
  setCurrentLesson(lesson: Lesson | null) {
    if (lesson) return structuredClone(lesson)
    return lesson
  }

  @Action({ commit: 'SET_CURRENT_MODULE' })
  setCurrentModule(mod: CourseModule | null) {
    return structuredClone(mod)
  }

  @Action({ commit: 'SET_CURRENT_MODULE_LESSONS' })
  setCurrentModuleLessons(lessons: Lesson[]) {
    return structuredClone(lessons)
  }

  @Action({ commit: 'SET_COURSE_SECTIONS' })
  setCourseSections(sections: CourseSection[] | null) {
    if (sections) return new Promise((resolve) => resolve(structuredClone(sections)))
    return new Promise((resolve) => resolve(sections))
  }

  @Action({ commit: 'SET_COURSE_MODULES' })
  setCourseModules(payload: { courseId: string; modules: CourseModule[] }) {
    return structuredClone(payload)
  }

  @Action
  setEditableState(payload: EditorStatePayload) {
    const state = (this as any)[payload.state!]
    if (!this.undoingOrRedoing) {
      if (state && Object.prototype.hasOwnProperty.call(state, payload.property!) && !payload.mutateAndQueue) {
        this.ADD_EDITOR_CHANGE(payload)
        this.SET_EDITABLE_STATE(payload)
      } else if (typeof payload.mutateAndQueue === 'function') {
        payload.mutateAndQueue(payload)
      }
    }
  }

  @Action({ commit: 'ADD_EDITOR_CHANGE' })
  addEditorChange(payload: EditorStatePayload) {
    return payload
  }

  @Action({ commit: 'SET_SECTION_STATE' })
  setSectionState(payload: { sectionId: string; prop: string; value: string | string[] }) {
    return payload
  }

  @Action
  undo(path: string) {
    if (this.canUndo(path)) {
      const { data, stateBeforeChange } = this.editorChanges[path][this.currentStateIndex[path]]
      this.SET_UNDOING_OR_REDOING(true)
      this.SET_CURRENT_STATE_INDEX({ path, index: this.currentStateIndex[path] - 1 })
      this.SET_CURRENT_LESSON(structuredClone(stateBeforeChange.currentLesson))
      this.SET_CURRENT_MODULE(structuredClone(stateBeforeChange.currentModule))
      this.SET_COURSE_SECTIONS(structuredClone(stateBeforeChange.courseSections))
      this.SET_LESSON_DOWNLOADABLE(structuredClone(stateBeforeChange.lessonDownloadable))
      this.SET_LESSON_PROFILE_IMAGE_DATA(stateBeforeChange.lessonProfileImageData ?? '')
      this.SET_DOWNLOADABLE_TEASER_IMAGE_DATA(stateBeforeChange.downloadTeaserImageData ?? '')
      this.SET_MODULE_IMAGE_DATA(stateBeforeChange.moduleImageData ?? '')

      const discardSubscriptions = this.editorSubscriptions['discard'].filter(
        (s) => s[data.key] || s['discard-' + data.key],
      )
      if (discardSubscriptions) {
        discardSubscriptions.forEach((sub) => {
          sub[data.key]?.(data, stateBeforeChange)
          sub['discard-' + data.key]?.(data, stateBeforeChange)
        })
      }
      setTimeout(() => this.SET_UNDOING_OR_REDOING(false), 0)
    }
  }

  @Action
  redo(path: string) {
    if (this.canRedo(path)) {
      this.SET_UNDOING_OR_REDOING(true)
      this.SET_CURRENT_STATE_INDEX({ path, index: this.currentStateIndex[path] + 1 })
      const { data } = this.editorChanges[path][this.currentStateIndex[path]]
      if (!data.mutateAndQueue) {
        this.SET_EDITABLE_STATE(data)
      }

      const saveSubscriptions = this.editorSubscriptions['save'].filter((s) => s[data.key])
      if (saveSubscriptions) saveSubscriptions.forEach((saveSubscription) => saveSubscription[data.key](data))
      setTimeout(() => this.SET_UNDOING_OR_REDOING(false), 0)
    }
  }

  @Action({ commit: 'SET_TEASER_VIDEO_URL' })
  setTeaserVideoUrl(url: string) {
    return url
  }

  @Action({ commit: 'SET_DOWNLOADABLE_URL' })
  setDownloadableDownloadUrl(url: string) {
    return url
  }

  @Action({ commit: 'SET_DOWNLOADABLE_REAL_DOWNLOAD_DATA' })
  setDownloadableRealDownloadData(fileData: string) {
    return fileData
  }

  @Action({ commit: 'SET_TEASER_IMAGE_URL' })
  setTeaserImageUrl(url: string) {
    return url
  }

  @Action({ commit: 'SUBSCRIBE' })
  subscribe(payload: {
    type: 'discard' | 'save'
    key: string
    allowDuplicate?: boolean
    callback: (
      args?: any,
      stateBeforeChange?: {
        currentLesson: Nullable<Lesson>
        currentModule: Nullable<CourseModule>
        courseSections: Nullable<CourseSection[]>
        lessonDownloadable: Nullable<LessonDownloadable>
        lessonSlideshows: Nullable<EditorSlide[]>
      },
    ) => void
  }) {
    return payload
  }

  @Action({ commit: 'UNSUBSCRIBE' })
  unsubscribe(payload: { type: 'discard' | 'save'; callback: (args?: any) => void }) {
    return payload
  }

  @Action({ commit: 'EXECUTE_ALL_SUBSCRIPTIONS' })
  executeAllSubscriptions(type: string) {
    return type
  }

  @Action({ commit: 'SET_DISCARDING_ALL_CHANGES' })
  setDiscardingAllChanges(value: boolean) {
    return value
  }

  @Action
  resetEditorChanges() {
    this.RESET_EDITOR_CHANGES()
  }

  @Action({ commit: 'SET_CURRENT_LESSON_QUESTIONS' })
  setCurrentLessonQuestions(questions: LessonQuestion[] | null) {
    if (questions) return structuredClone(questions)
    return questions
  }

  @Action({ commit: 'SET_CURRENT_LESSON_AUDIO_FILES' })
  setCurrentLessonAudioFiles(audioFiles: EditorLessonAudioFile[] | null) {
    if (audioFiles) return new Promise((resolve) => resolve(structuredClone(audioFiles)))
    return new Promise((resolve) => resolve(audioFiles))
  }

  @Action({ commit: 'SET_LESSON_SLIDESHOWS' })
  setLessonSlideshows(slideShows: EditorSlide[] | null) {
    if (slideShows) return new Promise((resolve) => resolve(structuredClone(slideShows)))
    return new Promise((resolve) => resolve(slideShows))
  }

  @Action({ commit: 'SET_EDITOR_ALL_AUTHORS' })
  setEditorAllAuthors() {
    return CourseApi.getAllAuthors()
      .then((authors) => authors)
      .catch(() => null)
  }

  @Action({ commit: 'SET_LESSON_DOWNLOADABLE' })
  setLessonDownloadable(downloadable: LessonDownloadable | null) {
    if (downloadable) return structuredClone(downloadable)
    return downloadable
  }

  @Action({ commit: 'REMOVE_MODULE_AUTHOR' })
  removeModuleAuthor(payload: { path: string; authorId: string }) {
    const authorIndex = this.currentModule?.authors?.findIndex((author) => author.id === payload.authorId)
    EditorModule.addEditorChange({
      key: 'currentModuleauthors',
      path: payload.path,
      value: { id: payload.authorId, index: authorIndex, operation: 'remove-author' } as AuthorMap,
      skipGrouping: true,
    })
    return authorIndex
  }

  @Action({ commit: 'ADD_MODULE_AUTHOR' })
  addModuleAuthor(payload: { path: string; author: Author }) {
    EditorModule.addEditorChange({
      key: 'currentModuleauthors',
      path: payload.path,
      value: { id: payload.author.id, operation: 'add-author' } as AuthorMap,
      skipGrouping: true,
    })
    return payload.author
  }

  @Action({ commit: 'SET_LEUCKENTEXT_DELETE_PARAMS' })
  setLeuckentextDeleteParams(params: { id: string; lessonId: string } | null) {
    return params
  }

  @Action({ commit: 'SET_LEUCKENTEXT' })
  setLeuckentext(payload: LeuckentextQuiz | null) {
    if (payload) return structuredClone(payload)
    return payload
  }

  @Action({ commit: 'SET_QUIZ_QUESTIONS' })
  setQuizQuestions(questions: LessonQuestion[] | null) {
    if (questions) return structuredClone(questions)
    return questions
  }

  @Action({ commit: 'SET_DISCARD_COURSE_CHANGES' })
  setDiscardCourseChanges(value: boolean) {
    return value
  }

  @Action({ commit: 'SET_SUBMIT_COURSE_CHANGES' })
  setSubmitCourseChanges(value: boolean) {
    return value
  }
}

export const EditorModule = getModule(Editor)
