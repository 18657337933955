import { render, staticRenderFns } from "./UsersAndGroups.vue?vue&type=template&id=c39bdee4&lang=pug"
import script from "./UsersAndGroups.vue?vue&type=script&setup=true&lang=ts"
export * from "./UsersAndGroups.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./UsersAndGroups.vue?vue&type=style&index=0&id=c39bdee4&prod&lang=postcss"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports