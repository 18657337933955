<template lang="pug">
.lesson-modal
  Lesson(
    :class='{ formatContent: formatContent }',
    :fetching-next-lesson='false',
    :format-content='formatContent',
    :lesson-c-t-a='""',
    :lesson-course-id='courseId',
    :lesson-id='lessonId',
    :lesson-module-id='moduleId',
    :lesson-to-render='lessonToRender',
    ref='lesson'
  )
  .button-wrapper
    KetchUpButton.tertiary(@click.native='openLesson')
      h5(v-if='lessonIsForbidden') {{ $t('courses.courseDetails') }}
      h5(v-else) {{ $t('courses.openLesson') }}
</template>

<script setup lang="ts">
  import { computed, ref } from 'vue'
  import Lesson from '@/views/Lesson.vue'
  import KetchUpButton from '@/components/common/KetchUpButton.vue'
  import Vue from 'vue'
  import { useRoute, useRouter } from 'vue-router/composables'
  import type { PropType } from 'vue'
  import type { Nullable } from '@/services/interfaces/Content'
  import type { Lesson as ILesson } from '@/services/interfaces/Course'

  const props = defineProps({
    lessonId: {
      required: true,
      type: String,
    },
    moduleId: {
      required: true,
      type: String,
    },
    courseId: String,
    teaserImageUrl: String,
    formatContent: {
      type: Boolean,
      default: false,
    },
    lessonToRender: {
      type: Object as PropType<ILesson>,
      default: null,
    },
  })
  const emit = defineEmits(['close'])
  const router = useRouter()
  const route = useRoute()
  const getCourseId = computed(() => props.courseId || route.params.courseId)

  const openLesson = () => {
    emit('close')
    if (lessonIsForbidden.value) {
      router.push(`/courses/${getCourseId.value}/info`).catch(() => {
        return
      })
    } else {
      router
        .push({
          path: `/courses/${getCourseId.value}/module/${props.moduleId}/lesson/${props.lessonId}`,
          query: { modal: 'true' },
        })
        .catch(() => {
          return
        })
    }
  }

  const lesson = ref(null as Nullable<Vue>)
  const lessonIsForbidden = computed(() => (lesson.value as any)?.forbidden)
</script>

<style lang="postcss">
  .lesson-modal {
    @apply ketch-pb-c30;
    .lesson-title {
      @apply ketch-font-bold ketch-pb-c10 ketch-mb-c10 ketch-relative;
      &::after {
        content: '';
        @apply ketch-h-c1 ketch-left-0 ketch-w-c50 ketch-bg-secondary-color ketch-absolute ketch-bottom-0;
      }
    }
    .lesson-wrapper {
      &.formatContent {
        em.special,
        em.special * {
          @apply ketch-font-bold ketch-text-primary-color;
        }
      }
      .video-or-image-renderer {
        .video-player {
          @apply ketch-max-h-[158px] xs1:ketch-max-h-[188px] xs3:ketch-max-h-[217px] sm:ketch-max-h-[398px];
          @apply md:ketch-max-h-[400px];
        }
      }
    }
    .cta-wrapper {
      @apply ketch-mt-c30;
      &.hasDownloadable {
        @apply ketch-flex ketch-justify-between;
      }
      .cta {
        .bookmark-lesson {
          @apply ketch-flex ketch-justify-end;
          svg {
            @apply ketch-cursor-pointer;
          }
        }
      }
    }
    .button-wrapper {
      @apply ketch-flex ketch-justify-end ketch-mt-c50 ketch-absolute ketch-bottom-c30 ketch-right-c30;
      button {
        @apply ketch-h-[44px];
      }
    }
  }
</style>
