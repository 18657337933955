<template lang="pug">
.header.component-spacing(:class='{ extension: isExtension }')
  .logo-area(@mouseover='hoveringTrainings = false')
    router-link(:to='{ path: $route.name === "Trainings" ? "/trainings" : "/" }')
      img(:src='logoUrl || logo', alt='logo')
    h6.powered-by(v-html='$t("poweredBy")', v-if='isExtension')
  .nav-bar(:class='{ mobile: isMobileDevice, open: panelAnimation }', v-if='!$route.meta?.hideHeaderElements')
    transition(name='fade', v-if='!isExtension')
      .grey-background(@click='closeSidePanel', v-if='isMobileDevice && openSidePanel')
    transition(:name='isMobileDevice ? "left-panel" : ""', v-if='!isExtension')
      .nav-items(v-if='(isMobileDevice && openSidePanel) || !isMobileDevice')
        nav
          .buy-course-preview(v-if='showBuyCoursePreview')
            .preview-cta
              img.card-image(:src='course?.cardTeaserImageUrl || cardEmptyImage')
              .buy-cta(@click='shopifyCheckout')
                h5 {{ $t('courses.buyThisCourseFor', { price: productPrice(course) }) }}
          router-link.nav-item(
            :data-cy='item.dataCy',
            :key='item.name',
            :to='item.path',
            @click.native='navItemCTA(item)',
            @mouseover.native='navItemMouseOver(item.navEntity)',
            exact-path,
            v-for='item in filteredNavOptions'
          )
            .nav-item-content
              h5 {{ item.name }}
              SVGRenderer(
                :has-hover='false',
                :icon='arrowDown',
                :stroke-color='"var(--header-foreground-color)"',
                v-if='item.navEntity === "trainings" && !isKnowledgeCheckCompany'
              )
            .new-news-items(v-if='item.navEntity === "news" && newNewsContentLength > 0')
              .count {{ newNewsContentLength }}
          UserReferralCode(:referral-code='userReferralCode', v-if='false && userReferralCode && isSachkundeCompany')
        Search(
          :in-side-panel='true',
          :open-side-panel='openSidePanel',
          @close-side-panel='closeSidePanel',
          v-if='!isKnowledgeCheckCompany'
        )
    .editor-additional-ctas
      .editor-switch(v-if='isEditable && isLargeDesktop')
        h5 Editor
        ToggleSwitch.editor(:value='inEditorMode', @toggled='toggleEditMode')
      .additional-nav-ctas
        transition(name='wobble')
          .re-trigger-onboarding-popup(
            @click='showCompanyOnboardingPopupDetails',
            v-if='showReTriggerOnboardingPopupCTA && $auth.isAuthenticated && !isKnowledgeCheckCompany && !isExtension'
          )
            h5(v-tooltip.bottom='{ content: $t("navigation.watchIntroVideo") }') {{ $t('intro') }}
        Search.search(
          @create-space-for-search-input='createSpaceForSearchInput = $event',
          v-if='!isExtension && !isKnowledgeCheckCompany'
        )
        .bookmarks(data-cy='bookmarks', v-if='!isExtension && $auth.isAuthenticated && !isKnowledgeCheckCompany')
          router-link(:to='{ name: "AllUserBookmarks" }')
            .bookmarks(@mouseleave='hoveringBookmark = false', @mouseover='hoveringBookmark = true')
              SVGRenderer(
                :icon='allBookmarks',
                :toggle-hover='hoveringBookmark',
                height='20px',
                stroke-color='var(--header-foreground-color)',
                stroke-hover-color='var(--header-foreground-color)',
                v-tooltip.bottom='{ content: $t("bookmarks.allBookmarks") }'
              )
        .help(v-if='false')
          span {{ $t('help') }}
          ToggleSwitch(:value='toursAndHintsEnabled', @toggled='toggleToursAndHints')
        .blurred-out-non-editable-section.on-header(v-if='inEditorMode')
      .user-area
        UserArea(:create-space-for-search-input='createSpaceForSearchInput')
      .hamburger-menu(
        :class='{ open: panelAnimation }',
        v-if='isMobileDevice && !isExtension && !isKnowledgeCheckCompany'
      )
        input#menu-toggle.h5(type='checkbox', v-model='checkedButton')
        label.menu-btn(@click.stop='toggleHamburger', for='menu-toggle')
          span
  transition(mode='out-in', name='fade')
    TrainingsMenuDropdown(
      @hide-dropdown='hoveringTrainings = false',
      @show-dropdown='hoveringTrainings = true',
      v-if='hoveringTrainings && !isKnowledgeCheckCompany'
    )
</template>

<script setup lang="ts">
  import { computed, onMounted, ref, watch } from 'vue'
  import Search from '@/components/Search.vue'
  import UserArea from '@/components/UserArea.vue'
  import SVGRenderer from '@/components/common/SVGRenderer.vue'
  import CookieHelper from '@/helpers/CookieHelper'
  import { TOURS_AND_HINTS } from '@/helpers/Constants'
  import useBreakpoint from '@/composables/useBreakpoint'
  import useIcons from '@/composables/useIcons'
  import useToursAndHints from '@/composables/useToursAndHints'
  import { NewsModule } from '@/store/modules/News'
  import ToggleSwitch from '@/components/common/ToggleSwitch.vue'
  import { EditorModule } from '@/store/modules/editor'
  import useEditor from '@/composables/useEditor'
  import { CourseModule } from '@/store/modules/course'
  import { UserModule } from '@/store/modules/user'
  import TrainingsMenuDropdown from '@/components/TrainingsMenuDropdown.vue'
  import useCommonMixin from '@/composables/useCommonMixin'
  import useCourse from '@/composables/useCourse'
  import { useRoute } from 'vue-router/composables'
  import UserReferralCode from '@/components/common/UserReferralCode.vue'
  import eventBus from '@/main'
  import useI18n from '@/composables/useI18n'
  import useShopifyClient from '@/composables/useShopifyClient'

  export interface NavOption {
    name: string
    navEntity: NavEntity
    path: string
    show: boolean
    dataCy: string
  }

  export type NavEntity = 'trainings' | 'news' | 'library'

  defineProps({
    logoUrl: {
      type: String,
      required: true,
    },
  })

  const emit = defineEmits(['hide-widgets', 'show-widgets'])

  const route = useRoute()

  const { isMobileDevice, isDesktopDevice, isLargeDesktop } = useBreakpoint()
  const { logo, allBookmarks, arrowDown, cardEmptyImage } = useIcons()
  const { showOrHideToursAndHints, hideAllHints } = useToursAndHints()
  const { inEditorMode, onEditorPages } = useEditor(route)
  const { showCompanyOnboardingPopupDetails, isSachkundeCompany, isKnowledgeCheckCompany } = useCommonMixin()
  const { hasPurchasedAtLeastACourse, course, trackCoursePurchaseIntended, productPrice } = useCourse()
  const { translateString } = useI18n()
  const { createShopifyCheckout } = useShopifyClient()

  const ancestorFolder = ref('')
  const openFolder = ref(false)
  const openSidePanel = ref(false)
  const checkedButton = ref(false)
  const panelAnimation = ref(false)
  const hoveringBookmark = ref(false)
  const hoveringTrainings = ref(false)
  const createSpaceForSearchInput = ref(false)
  const enableToursAndHints = ref(false)

  const showNewsItem = computed(() => UserModule.currentCompany?.showNewsSection ?? false)

  const showLibraryItem = computed(() => UserModule.currentCompany?.showLibrarySection ?? false)

  const navOptions = computed(
    () =>
      [
        {
          name: translateString('navigation.trainings'),
          navEntity: 'trainings',
          path: '/trainings',
          show: isDesktopDevice.value,
          dataCy: 'trainings',
        },
        {
          name: translateString('navigation.news'),
          navEntity: 'news',
          path: '/news',
          show: !isExtension.value && showNewsItem.value && hasPurchasedAtLeastACourse.value,
          dataCy: 'news-nav',
        },
        {
          name: translateString('navigation.library'),
          navEntity: 'library',
          path: '/library',
          show: showLibraryNavItem.value,
          dataCy: 'library',
        },
      ] as NavOption[],
  )

  const filteredNavOptions = computed(() => navOptions.value.filter((option) => option.show))

  const showLibraryNavItem = computed(() => {
    if (!eventBus?.$auth.isAuthenticated) return false
    return !isExtension.value && showLibraryItem.value && hasPurchasedAtLeastACourse.value
  })

  const routePath = computed(() => {
    return route.path
  })

  const toursAndHintsEnabled = computed(() => {
    if (enableToursAndHints.value) return true
    const cookie = CookieHelper.getCookieValue(TOURS_AND_HINTS)
    return !!cookie
  })

  const isEditable = computed(() => {
    return (
      (route.name === 'Trainings' && CourseModule.courseSections?.some((s) => s.isEditable)) ||
      !!(onEditorPages.value && CourseModule.course?.isEditable)
    )
  })

  const showReTriggerOnboardingPopupCTA = computed(() => UserModule.reTriggerOnboardingPopup)

  const newNewsContentLength = computed(() => {
    return NewsModule.newNewsContentLength
  })

  const userReferralCode = computed(() => UserModule.userReferralCode)

  const showBuyCoursePreview = computed(
    () =>
      isMobileDevice.value &&
      route.matched.some((route) => ['CourseDetails', 'Lesson', 'CourseModule'].includes(route.name!)) &&
      course.value?.trial &&
      course.value?.shopifyProductId &&
      !course.value?.isIddCourse,
  )

  const isExtension = computed(() => route.name === 'ChromeTabExtension')

  const toggleEditMode = (value: boolean) => {
    EditorModule.setEditMode(value)
    CookieHelper.setCrossDomainCookie('EDITOR_MODE', value.toString())
  }

  const toggleToursAndHints = (enable: boolean) => {
    enableToursAndHints.value = enable
    CookieHelper.setCookieValue(TOURS_AND_HINTS, enable.toString())
    if (enable) {
      if (isDesktopDevice.value) showOrHideToursAndHints(4000)
    } else {
      hideAllHints()
    }
  }

  const onRouteUpdate = (path: string) => {
    if (['Trainings', 'Library', 'AllNews'].includes(route.name as string)) {
      openFolder.value = false
      closeSidePanel()
      ancestorFolder.value = path.split('/')[1] as string
    } else {
      ancestorFolder.value = ''
    }
  }

  const closeSidePanel = () => {
    checkedButton.value = false
    openSidePanel.value = false
    if (document.documentElement.scrollTop > 0) {
      const wrapper = document.querySelector('#app > .header-wrapper')
      wrapper?.classList.add('apply-drop-shadow')
    }
    setTimeout(() => {
      panelAnimation.value = false
    }, 300)
  }

  const activateHelp = () => {
    eventBus?.$on('set-tours-and-hints', () => {
      CookieHelper.setCookieValue(TOURS_AND_HINTS, 'true')
      enableToursAndHints.value = true
    })
  }

  const toggleHamburger = () => {
    openSidePanel.value = !openSidePanel.value
    const wrapper = document.querySelector('#app > .header-wrapper')

    if (!openSidePanel.value) {
      if (document.documentElement.scrollTop > 0) {
        wrapper?.classList.add('apply-drop-shadow')
      }
      setTimeout(() => {
        panelAnimation.value = false
      }, 300)
    } else {
      if (wrapper?.classList.contains('apply-drop-shadow')) {
        wrapper.classList.remove('apply-drop-shadow')
      }
      panelAnimation.value = true
    }
  }

  const navItemCTA = (item: NavOption) => {
    if (item.navEntity === 'trainings' && isMobileDevice.value) {
      eventBus.$emit('show-modal', {
        modalContentComponent: 'TrainingsMenuDropdownModal',
        modalCloseCallback: (callback: () => void) => {
          if (typeof callback === 'function') callback()
        },
      })
      closeSidePanel()
    }
  }

  const shopifyCheckout = async () => {
    trackCoursePurchaseIntended()
    closeSidePanel()
    eventBus.$emit('turn-on-loader')
    await createShopifyCheckout()
    setTimeout(() => {
      eventBus.$emit('turn-off-loader')
    }, 2500)
  }

  const navItemMouseOver = (navEntity: string) => {
    if (navEntity === 'trainings' && !isMobileDevice.value) {
      hoveringTrainings.value = true
    }
  }

  watch(routePath, (value) => {
    onRouteUpdate(value)
  })

  watch(openSidePanel, (value) => {
    if (value) {
      emit('hide-widgets')
    } else {
      emit('show-widgets')
    }
  })

  onMounted(() => {
    onRouteUpdate(route.path)
    activateHelp()
  })
</script>

<style lang="postcss">
  .header {
    @apply ketch-relative ketch-h-c40 ketch-flex ketch-items-center ketch-justify-start md:ketch-h-c80;
    .logo-area {
      @apply ketch-flex ketch-items-center ketch-max-h-[40px] ketch-max-w-[150px];
      @apply md:ketch-max-h-[80px] md:ketch-max-w-[250px];
      a {
        @apply ketch-px-c15 ketch-py-c5 ketch--ml-c15 md:ketch-px-c20 md:ketch-py-c10 md:ketch--ml-c20;
        img {
          @apply ketch-max-h-[40px] md:ketch-max-h-[80px];
        }
      }
    }
    .nav-bar {
      @apply ketch-flex ketch-items-center ketch-flex-1 ketch-h-full ketch-justify-end;
      .grey-background {
        @apply ketch-fixed ketch-top-0 ketch-bottom-0 ketch-left-0 ketch-right-0 ketch-bg-white ketch-bg-opacity-75;
        z-index: 21;
      }
      .nav-items {
        @apply ketch-flex ketch-justify-between ketch-items-center ketch-flex-1;
        nav {
          @apply ketch-flex ketch-items-center;
          .buy-course-preview {
            @apply ketch-w-full ketch-pb-c20 ketch-border-b ketch-border-border-color;
            .preview-cta {
              @apply ketch-flex ketch-flex-col ketch-space-y-c10;
              .card-image {
                @apply ketch-w-full ketch-object-cover ketch-rounded-normal;
              }
              .buy-cta {
                @apply ketch-bg-primary-color ketch-text-primary-foreground-color ketch-text-center;
                @apply ketch-rounded-normal ketch-underline ketch-p-c10;
              }
            }
          }
          .nav-item {
            @apply ketch-h-c80 ketch-cursor-pointer ketch-flex ketch-items-center ketch-relative ketch-whitespace-nowrap;
            @apply ketch-text-header-foreground-color md:ketch-mx-c15;
            &.router-link-active {
              h5 {
                @apply ketch-font-bold;
              }
            }
            .nav-item-content {
              @apply ketch-flex ketch-items-center ketch-space-x-c10;
            }
          }
        }
      }
      &.mobile {
        .nav-items {
          @apply ketch-fixed ketch-left-0 ketch-top-0 ketch-bottom-0 ketch-p-c15 ketch-bg-white ketch-flex-col ketch-space-y-c20;
          @apply ketch-justify-end ketch-items-start ketch-w-3/4 xs4:ketch-w-1/2 sm:ketch-w-2/5 ketch-h-full ketch-shadow-side-bar;
          z-index: 21;
          nav {
            @apply ketch-w-full ketch-h-full ketch-flex-col ketch-items-start ketch-py-c30 ketch-mx-0;
            .nav-item {
              @apply ketch-h-auto ketch-py-c15 ketch-uppercase ketch-text-header-foreground-color;
              @apply ketch-tracking-wider sm:ketch-tracking-normal ketch-text-sm ketch-leading-lg;
            }
          }
        }
        &:not(.open) .nav-items,
        &:not(.open) .grey-background {
          @apply ketch-hidden;
        }
      }
      .new-news-items {
        @apply ketch-absolute ketch-w-c15 ketch-h-c15 ketch-rounded-full ketch-bg-primary-color ketch-text-primary-foreground-color;
        @apply ketch-flex ketch-items-center ketch-justify-center ketch-top-c20 ketch--right-c10;
        .count {
          @apply ketch-text-xxs ketch-leading-sm;
        }
      }
      .editor-additional-ctas {
        @apply ketch-flex ketch-items-center ketch-h-full ketch-space-x-c15;
        .editor-switch {
          @apply ketch-flex ketch-items-center ketch-space-x-c15;
          h5 {
            @apply ketch-text-header-foreground-color;
          }
        }
        .additional-nav-ctas {
          @apply ketch-flex ketch-items-center ketch-h-full ketch-relative ketch-space-x-c15;
          .re-trigger-onboarding-popup {
            @apply ketch-px-c5 ketch-border ketch-rounded-[5px] ketch-cursor-pointer;
            @apply ketch-border-header-foreground-color ketch-text-header-foreground-color;
          }
          .search {
            @apply ketch-hidden ketch-relative md:ketch-block;
          }
          .help {
            @apply ketch-flex ketch-items-center ketch-space-x-c5;
            span {
              @apply ketch-text-secondary-text-color;
            }
          }
          .bookmarks {
            svg {
              @apply ketch-outline-none;
            }
          }
        }
        .user-area {
          @apply ketch-h-full;
        }
        .hamburger-menu {
          &.open {
            @apply ketch-fixed ketch-left-0;
            z-index: 22;
            .menu-btn > span,
            .menu-btn > span::before,
            .menu-btn > span::after {
              @apply ketch-bg-gray-700;
            }
          }
          #menu-toggle {
            @apply ketch-hidden;
          }
          #menu-toggle:checked ~ .menu-btn > span {
            @apply ketch-rotate-45;
          }
          #menu-toggle:checked ~ .menu-btn > span::before {
            @apply ketch-top-0 ketch-rotate-0;
          }
          #menu-toggle:checked ~ .menu-btn > span::after {
            @apply ketch-top-0 ketch-rotate-90;
          }
          .menu-btn {
            @apply ketch-flex ketch-items-center ketch-relative ketch-w-c20 ketch-h-c20 ketch-cursor-pointer;
            @apply md:ketch-w-c25 md:ketch-h-c25;
            z-index: 19;
          }

          .menu-btn > span,
          .menu-btn > span::before,
          .menu-btn > span::after {
            @apply ketch-block ketch-absolute ketch-w-full ketch-h-c2 ketch-bg-primary-text-color ketch-duration-300;
          }
          .menu-btn > span::before {
            content: '';
            @apply ketch--top-c8;
          }
          .menu-btn > span::after {
            content: '';
            @apply ketch-top-c8;
          }
        }
      }
    }
    &.extension {
      @apply ketch-justify-between ketch-relative;
      .nav-bar,
      .editor-additional-ctas .user-area {
        @apply ketch-border-l-0;
      }
      .user-area-wrapper .user-icon {
        @apply ketch-ml-0;
      }
      .nav-bar {
        @apply ketch-flex-none ketch-max-w-[120px] ketch-absolute ketch-right-c120 xl2:ketch-right-0;
      }
      .logo-area {
        @apply ketch-flex-1 ketch-max-w-full ketch-mr-0 ketch-justify-center ketch-flex-col;
        .powered-by {
          @apply ketch-mt-c5;
          span {
            @apply ketch-font-bold;
          }
        }
      }
    }
  }
</style>
