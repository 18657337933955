<template lang="pug">
.invite-user-modal(data-cy='invite-user-modal')
  h3.title-with-underline {{ $t('homepage.inviteUser.title') }}
  .invite-user-form
    h6.email {{ $t('homepage.inviteUser.emailAddress') }}
    .form-group(:key='inputKey', v-for='inputKey in inputItems')
      input(
        :class='{ "has-error": hasValidationError(inputKey), success: formState[inputKey] && !hasValidationError(inputKey) }',
        :placeholder='String($t("homepage.inviteUser.inputPlaceholder"))',
        @blur='onInputChange(inputKey)',
        @input='onInputChange(inputKey)',
        type='email',
        v-model='formState[inputKey]'
      )
      SVGRenderer(
        :has-hover='false',
        :icon='checkmark',
        :stroke-color='"var(--primary-color)"',
        v-if='formState[inputKey] && !hasValidationError(inputKey)',
        width='13px'
      )
  h5.description {{ $t('homepage.inviteUser.description') }}
  .cta
    KetchUpButton.primary(
      :class='{ disable: !filteredFormState.length || validator.$invalid }',
      @click.stop.native='sendInvite'
    )
      LoadingSpinner(v-if='sendingInvite')
      h5(v-else) {{ $t('homepage.inviteUser.sendInvitations') }}
</template>

<script setup lang="ts">
  import { ref } from 'vue'
  import KetchUpButton from '@/components/common/KetchUpButton.vue'
  import LoadingSpinner from '@/components/common/LoadingSpinner.vue'
  import useIcons from '@/composables/useIcons'
  import SVGRenderer from '@/components/common/SVGRenderer.vue'
  import useUserManagement from '@/composables/useUserManagement'
  import UsersApi from '@/services/api/UsersApi'
  import { UserModule } from '@/store/modules/user'
  import pluralize from 'pluralize'
  import eventBus from '@/main'
  import useI18n from '@/composables/useI18n'

  const emit = defineEmits(['close'])

  const { checkmark } = useIcons()
  const { translateString } = useI18n()
  const { validator, setInputItems, formState, onInputChange, inputItems, hasValidationError, filteredFormState } =
    useUserManagement()
  setInputItems(['email1', 'email2', 'email3'], 'email')

  const sendingInvite = ref(false)
  const sendInvite = () => {
    validator.value.$touch()
    if (!validator.value.$invalid) {
      eventBus.$emit('turn-on-loader')
      sendingInvite.value = true
      UsersApi.inviteUser({
        companyId: UserModule.currentCompany!.id,
        emails: filteredFormState.value,
      })
        .then(async (resp) => {
          const users = pluralize(translateString('homepage.inviteUser.user'), filteredFormState.value.length, true)
          UserModule.appendUserMembers({ users: resp.users, action: 'invite-user' })
          eventBus.$toasted.success(translateString('homepage.inviteUser.successMessage', { users }))
          await UserModule.getCompanyActivityLog()
          eventBus.$emit('turn-off-loader')
        })
        .finally(() => {
          emit('close')
          sendingInvite.value = false
        })
    }
  }
</script>

<style lang="postcss">
  .invite-user-modal {
    .title-with-underline {
      @apply ketch-pb-c12;
    }
    .invite-user-form {
      @apply ketch-space-y-c8 ketch-mb-c25;
      .email {
        @apply ketch-leading-sm ketch-font-bold;
      }
    }
    .description {
      @apply ketch-mb-c50;
    }
    .cta {
      @apply ketch-flex ketch-justify-center;
      button {
        @apply ketch-w-auto;
        &.disable {
          @apply ketch-opacity-50;
        }
      }
    }
    .form-group {
      @apply ketch-relative;
      input {
        @apply ketch-w-full ketch-py-c8 ketch-pl-c12 ketch-pr-c40 ketch-border ketch-border-border-color;
        @apply focus:ketch-border-primary-color ketch-outline-none ketch-rounded-normal;
        &.has-error {
          @apply ketch-border-red-500;
        }
        &.success {
          @apply ketch-border-primary-color;
        }
      }
      svg {
        @apply ketch-absolute ketch-right-c15 ketch-top-c15;
      }
    }
  }
</style>
