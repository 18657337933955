<template lang="pug">
.testimonial-slider
  h2.header-title {{ $t('courses.testimonials') }}
  CarouselSwiper(
    :break-points='breakPoints',
    :key='rerenderKey',
    :nav-class='`nav-${testimonials[0].id}`',
    :slides-per-group='slidesPerGroup',
    :slides-per-view='slidesPerView',
    :space-between='40',
    :swiper-items='testimonials',
    v-if='isDesktopDevice'
  )
    template(#slide-slot='{ item }')
      Testimonial(:testimonial='item')
    template(#slide-nav)
      .navigation(:class='`nav-${testimonials[0].id}`')
        .prev-nav-button
          img(:src='leftNavigation')
        .next-nav-button
          img(:src='rightNavigation')
  .testimonial-container(v-else)
    Testimonial(:key='item.id', :testimonial='item', v-for='item in testimonials.slice(0, 3)')
</template>

<script setup lang="ts">
  import { ref, watch } from 'vue'
  import useIcons from '@/composables/useIcons'
  import useBreakpoint from '@/composables/useBreakpoint'
  import CarouselSwiper from '@/components/common/CarouselSwiper.vue'
  import Testimonial from '@/components/course/Testimonial.vue'
  import type { PropType } from 'vue'
  import type { Testimonial as TestimonialState } from '@/services/interfaces/Course'

  defineProps({
    testimonials: {
      required: true,
      type: Array as PropType<TestimonialState[]>,
    },
    slidesPerGroup: {
      default: 4,
      type: Number,
    },
    slidesPerView: {
      default: 3.35,
      type: Number,
    },
  })

  const { leftNavigation, rightNavigation } = useIcons()
  const { isMobileDevice, isDesktopDevice } = useBreakpoint()

  const breakPoints = {
    1200: {
      slidesPerView: '2.35',
      spaceBetween: '30',
      slidesPerGroup: '2',
    },
    1024: {
      slidesPerView: '2.25',
      spaceBetween: '30',
      slidesPerGroup: '2',
    },
    991: {
      slidesPerView: '1.65',
      spaceBetween: '20',
      slidesPerGroup: '1',
    },
    767: {
      slidesPerView: '1.25',
      spaceBetween: '20',
      slidesPerGroup: '1',
    },
    540: {
      slidesPerView: '1.2',
      spaceBetween: '15',
      slidesPerGroup: '1',
    },
  }

  const rerenderKey = ref('')
  watch(isMobileDevice, () => {
    rerenderKey.value = Math.random().toString()
  })
</script>

<style lang="postcss">
  .testimonial-slider {
    .swiper-slide {
      @apply ketch-px-c1;
    }
    .header-title {
      @apply ketch-mb-c40 ketch-font-big-daily-short;
    }
    .swiper-wrapper {
      @apply ketch-min-h-[250px];
    }
    .testimonial {
      @apply md:ketch-min-h-[250px];
    }
    .testimonial-container {
      @apply ketch-space-y-c30 xs4:ketch-space-y-c40;
    }
  }
</style>
