<script setup lang="ts">
  import { onMounted } from 'vue'
  import type { PropType } from 'vue'
  import type { LessonChangeLog } from '@/services/interfaces/Course'
  import SVGRenderer from '@/components/common/SVGRenderer.vue'
  import useIcons from '@/composables/useIcons'
  import useCourse from '@/composables/useCourse'
  import KetchUpButton from '@/components/common/KetchUpButton.vue'
  import LoadingSpinner from '@/components/common/LoadingSpinner.vue'
  import useSegment from '@/composables/useSegment'
  import { UserModule } from '@/store/modules/user'

  const { course } = useCourse()
  const { lessonCompletedIcon, open } = useIcons()
  const { courseChangesModalShown } = useSegment()

  defineProps({
    lessonChanges: {
      type: Object as PropType<{ [key: string]: LessonChangeLog[] }>,
      required: true,
    },
  })

  onMounted(() => {
    if (course.value) {
      courseChangesModalShown(UserModule.email, course.value.id)
    }
  })
</script>

<template lang="pug">
.course-changes-modal
  LoadingSpinner(v-if='!course')
  template(v-else)
    h3.title-with-underline {{ $t('courses.weHaveImprovedTheFollowingLessons') }}
    .changes-wrapper
      .date-grouped-changes(v-for='(value, key) in lessonChanges', :key='key')
        h4.date {{ key }}
        .grouped-changes
          .change(v-for='change in value', :key='change.lesson.id')
            .change-icon-lesson-title
              .change-icon
                SVGRenderer(
                  :has-hover='false',
                  :icon='lessonCompletedIcon',
                  stroke-color='var(--primary-color)',
                  v-if='change.lesson.completed'
                )
                SVGRenderer(:has-hover='false', :icon='open', v-else)
              router-link.lesson-title(
                :to='`/courses/${course.id}/module/${change.moduleId}/lesson/${change.lesson.id}`',
                @click.native='$emit("close")'
              ) {{ change.lesson.title }}
            .lesson-duration {{ change.lesson.duration }}min
    .button-wrapper
      KetchUpButton.tertiary(@click.native='$emit("close")')
        h5 {{ $t('okThankYou') }}
</template>

<style lang="postcss">
  .course-changes-modal {
    @apply ketch-flex ketch-flex-col md:ketch-max-h-[500px] ketch-overflow-hidden ketch-overflow-y-auto;
    .changes-wrapper {
      @apply ketch-flex ketch-flex-col ketch-space-y-c15 ketch-mb-c40;
      .date-grouped-changes {
        @apply ketch-flex ketch-flex-col ketch-space-y-c5 md:ketch-space-y-c10;
        .date {
          @apply ketch-font-bold ketch-bg-white ketch-py-c5;
        }
        .grouped-changes {
          @apply ketch-flex ketch-flex-col ketch-space-y-c10 ketch-pl-c10 ketch-border-l ketch-border-border-color md:ketch-pl-c20;
          .change {
            @apply ketch-flex ketch-items-center ketch-justify-between ketch-space-x-c5 md:ketch-space-x-c10;
            .change-icon-lesson-title {
              @apply ketch-flex ketch-items-center ketch-space-x-c15;
              .lesson-title {
                @apply hover:ketch-underline hover:ketch-underline-offset-2;
              }
            }
            .lesson-duration {
              @apply ketch-text-gray-400;
            }
          }
        }
      }
    }
    .button-wrapper {
      @apply ketch-flex ketch-justify-end ketch-bg-white ketch-absolute ketch-bottom-c20 ketch-right-0;
      @apply ketch-pr-c20 ketch-w-full;
      button {
        @apply ketch-h-[44px] ketch-w-[fit-content];
      }
    }
  }
</style>
