<template lang="pug">
.question-with-buttons
  .question-content(:class='{ "is-embedded-guided-quiz": isEmbeddedGuidedQuiz }')
    transition(:name='transitionName', mode='out-in')
      Question(
        :has-new-questions='hasNewQuestions',
        :in-results-mode='inResultsMode',
        :is-choice-selected='isChoiceSelected',
        :key='currentQuestion.questionId',
        :on-choice-click='onChoiceClick',
        :question='currentQuestion',
        :question-helper-text='helperText',
        :question-number='questionNumber',
        :show-question-number='showQuestionNumber',
        :total-number-of-questions='totalQuestions || questions.length'
      )
    slot(
      :current-question='currentQuestion',
      :selected-answers='selectedAnswers',
      :total-number-of-questions='questions.length',
      name='question-result'
    )
  .question-pagination(v-if='showPagination')
    Pagination(
      :allow-page-click='false',
      :submit-text='$t("submit")',
      :total-pages='questions.length',
      @next='onNextPage',
      @previous='onPrevPage'
    )
  .buttons-container(
    :class='{ "showing-prev-btn": showPreviousButton || showAbortButton || showButtonSlot, "is-embedded-guided-quiz": isEmbeddedGuidedQuiz }',
    v-else-if='showButtons'
  )
    slot(name='button-text', v-if='showButtonSlot')
    KetchUpButton.tertiary(@click.native='$emit("abort")', v-if='showAbortButton')
      h5 {{ $t('quiz.questionQuizModal.abortButtonText') }}
    KetchUpButton.tertiary(
      :class='{ disable: questionNumber === 1 }',
      @click.native='onPrevPage',
      data-cy='on-previous-page',
      v-if='showPreviousButton'
    )
      LoadingSpinner(v-if='fetchingPrevQuestion')
      h5(v-else) {{ $t('quiz.questionQuizModal.backButtonText') }}
    KetchUpButton.primary(
      :class='{ disable: !questionAnswered || (inResultsMode && postingAnswers) }',
      @click.native='onNextPage',
      data-cy='on-next-page',
      v-if='showNextButton'
    )
      LoadingSpinner(v-if='postingAnswers')
      h5(v-else) {{ isLastQuestion && submitButtonText ? submitButtonText : $t('quiz.questionQuizModal.nextButtonText') }}
  .additional-buttons-container(v-if='showNextWrongQuestionButton || showSkipQuestionButton')
    h6 {{ $t('or') }}
    .buttons-container
      KetchUpButton.tertiary(@click.native='onNextWrongQuestion', v-if='showNextWrongQuestionButton')
        LoadingSpinner(v-if='loadingNextWrongQuestion')
        h5(v-else) {{ $t('quiz.nextWrongQuestion') }}
      VPopover(placement='right')
        KetchUpButton.tertiary(@click.native='onSkipQuestion', v-if='showSkipQuestionButton')
          LoadingSpinner(v-if='loadingFromSkippedQuestion')
          h5(v-else) {{ $t('quiz.skipQuestion') }}
        template(#popover)
          .skip-question-tooltip
            h6 {{ $t('courses.examSimulation.skipQuestionTooltip') }}

  slot(:current-question='currentQuestion', :selected-answers='selectedAnswers', name='question-comment')
  transition(name='fade')
    .comment-area(
      data-cy='comment-area',
      v-if='inResultsMode && (currentQuestion.comment || currentQuestion.lesson) && !isEmbeddedGuidedQuiz'
    )
      .correct-attempts(data-cy='correct-attempts')
        SVGRenderer(:has-hover='false', :icon='quizIcon', stroke-color='var(--secondary-hover-color)')
        h5(
          :class='{ hasValue: totalCorrectPercentage > 0 }',
          v-html='$t("answeredCorrectlyText", { percentage: totalCorrectPercentage })'
        )
      template(v-if='currentQuestion.comment')
        h3 {{ $t('comment') }}
        HTMLRenderer(:html='currentQuestion.comment', :increase-padding='true', link-target='_blank')
      h5.lesson(@click='showLessonModal', v-if='currentQuestion.lesson && !isKnowledgeCheckCompany') {{ currentQuestion.lesson.title }}
  Feedback(
    :feedback-description='$t("feedback.questionDescription")',
    :feedback-modal-title='$t("feedback.questionFeedback")',
    :question-id='currentQuestion.questionId',
    :question-title='currentQuestion.title',
    :show-rating='false',
    v-if='inResultsMode && !isEmbeddedGuidedQuiz'
  )
</template>

<script setup lang="ts">
  import { computed, ref, watch } from 'vue'
  import Question from '@/components/common/Question.vue'
  import Pagination from '@/components/common/Pagination.vue'
  import KetchUpButton from './KetchUpButton.vue'
  import LoadingSpinner from './LoadingSpinner.vue'
  import { CourseModule } from '@/store/modules/course'
  import useCourse from '@/composables/useCourse'
  import useIcons from '@/composables/useIcons'
  import HTMLRenderer from '@/components/common/HTMLRenderer.vue'
  import SVGRenderer from '@/components/common/SVGRenderer.vue'
  import Feedback from '@/components/common/Feedback.vue'
  import eventBus from '@/main'
  import useI18n from '@/composables/useI18n'
  import type { Question as IQuestion, QuestionAnswer } from '@/services/interfaces/QuizQuestion'
  import type { PropType } from 'vue'
  import type { CourseExamQuestion } from '@/services/interfaces/Course'
  import useCommonMixin from '@/composables/useCommonMixin'

  const props = defineProps({
    questions: {
      required: true,
      type: Array as PropType<(IQuestion | CourseExamQuestion)[]>,
    },
    submitButtonText: {
      type: String,
      default: '',
    },
    showButtons: {
      type: Boolean,
      default: true,
    },
    showAbortButton: {
      type: Boolean,
      default: false,
    },
    showPreviousButton: {
      type: Boolean,
      default: true,
    },
    showNextButton: {
      type: Boolean,
      default: true,
    },
    showButtonSlot: {
      type: Boolean,
      default: false,
    },
    showHelperText: {
      type: Boolean,
      default: true,
    },
    showTransition: {
      type: Boolean,
      default: true,
    },
    showPagination: {
      type: Boolean,
      default: false,
    },
    inResultsMode: {
      type: Boolean,
      default: false,
    },
    showQuestionNumber: {
      type: Boolean,
      default: true,
    },
    currentQuestionNumber: {
      type: Number,
      default: null,
    },
    totalQuestions: {
      type: Number,
      default: null,
    },
    isGuidedQuiz: {
      type: Boolean,
      default: false,
    },
    retainOnlyCurrentQuestionAnswer: {
      type: Boolean,
      default: true,
    },
    parentCourseId: {
      type: String,
      default: '',
    },
    showNextWrongQuestionButton: {
      type: Boolean,
      default: false,
    },
    showSkipQuestionButton: {
      type: Boolean,
      default: false,
    },
    hasNewQuestions: {
      type: Boolean,
      default: false,
    },
    isEmbeddedGuidedQuiz: {
      type: Boolean,
      default: false,
    },
  })

  const emit = defineEmits(['number-answer', 'submit', 'previous', 'next-wrong-question', 'abort', 'skip-question'])

  const { quizIcon } = useIcons()
  const { openLessonModal } = useCourse()
  const { translateString } = useI18n()
  const { isKnowledgeCheckCompany } = useCommonMixin()

  const currentQuestionIndex = ref(0)
  const selectedAnswers = ref({} as { [key: string]: QuestionAnswer })

  const totalCorrectPercentage = computed(() => {
    return parseFloat(Number(currentQuestion.value.correctAttemptsPercentage).toFixed(2))
  })

  const postingAnswers = ref(false)
  const fetchingPrevQuestion = ref(false)
  const loadingNextWrongQuestion = ref(false)
  const loadingFromSkippedQuestion = ref(false)
  const currentQuestion = computed(() => props.questions[currentQuestionIndex.value])

  const questionId = computed(() => currentQuestion.value.questionId)

  const questionNumber = computed(() => props.currentQuestionNumber || currentQuestionIndex.value + 1)

  const transitionName = computed(() =>
    props.showTransition ? (pageDirection.value === 'next' ? 'next-slide' : 'prev-slide') : '',
  )

  const setAnswers = () => {
    props.questions?.forEach((question) => {
      const selectedAnswerIds: string[] = []
      if (question.questionType === 'number' && question.userNumberAnswer) {
        if (typeof question.userNumberAnswer === 'object') {
          selectedAnswerIds.push(question.userNumberAnswer!.answer)
        } else if (question.userNumberAnswer) {
          selectedAnswerIds.push(question.userNumberAnswer)
        }
      } else if (['multiple', 'single'].includes(question.questionType)) {
        question.answers?.forEach((a) => {
          if (a.selected) {
            selectedAnswerIds.push(a.answerId)
          }
        })
      }
      if (selectedAnswerIds.length) {
        const payload: any = {
          questionId: question.questionId,
          selectedAnswerIds,
        }
        if (question.questionType === 'number' && question.userNumberAnswer) {
          if (typeof question.userNumberAnswer === 'object') {
            payload.numberAnswer = question.userNumberAnswer!.answer
          } else {
            payload.numberAnswer = question.userNumberAnswer
          }
        }
        eventBus.$set(selectedAnswers.value, question.questionId, payload)
      }
    })
  }

  setAnswers()

  const onChoiceClick = (answerIdOrNumberAnswer: string) => {
    if (props.inResultsMode) return
    const questionAnswer = selectedAnswers.value[questionId.value]

    // if reactivity has been established
    if (questionAnswer) {
      if (['single', 'number'].includes(currentQuestion.value.questionType)) {
        questionAnswer.selectedAnswerIds.splice(0, 1, answerIdOrNumberAnswer)
        if (currentQuestion.value.questionType === 'number') questionAnswer.numberAnswer = answerIdOrNumberAnswer
      } else {
        addOrRemoveMultipleChoiceAnswer(questionAnswer, answerIdOrNumberAnswer)
      }
    } else {
      const payload: any = {
        questionId: questionId.value,
        selectedAnswerIds: [answerIdOrNumberAnswer],
      }
      if (currentQuestion.value.questionType === 'number') {
        payload.numberAnswer = answerIdOrNumberAnswer
        emit('number-answer', answerIdOrNumberAnswer)
      }
      eventBus.$set(selectedAnswers.value, questionId.value, payload)
    }
  }

  const addOrRemoveMultipleChoiceAnswer = (questionAnswer: QuestionAnswer, answerId: string) => {
    const index = questionAnswer.selectedAnswerIds.indexOf(answerId)
    if (index > -1) {
      questionAnswer.selectedAnswerIds.splice(index, 1)
    } else {
      questionAnswer.selectedAnswerIds.push(answerId)
    }
  }

  const isChoiceSelected = computed(() => {
    return (answerId: string) => {
      return selectedAnswers.value[questionId.value]?.selectedAnswerIds.includes(answerId) || false
    }
  })

  const questionAnswered = computed(() => !!selectedAnswers.value[questionId.value]?.selectedAnswerIds.length)

  const isLastQuestion = computed(() => currentQuestionIndex.value === props.questions.length - 1)

  const pageDirection = ref('next')
  const onNextPage = () => {
    if (postingAnswers.value) return
    if (!questionAnswered.value) {
      eventBus.$toasted.error(translateString('courses.selectAnOption'))
      return
    }
    pageDirection.value = 'next'
    if (isLastQuestion.value) {
      postingAnswers.value = true
      emit('submit', Object.values(selectedAnswers.value))
      return
    }
    currentQuestionIndex.value += 1
  }

  const onPrevPage = () => {
    if (questionNumber.value === 1) return
    pageDirection.value = 'prev'
    fetchingPrevQuestion.value = true

    if (props.currentQuestionNumber) {
      emit('previous')
      return
    }
    currentQuestionIndex.value -= 1
  }

  const onNextWrongQuestion = () => {
    loadingNextWrongQuestion.value = true
    pageDirection.value = 'next'
    emit('next-wrong-question')
  }

  const onSkipQuestion = () => {
    loadingFromSkippedQuestion.value = true
    pageDirection.value = 'next'
    emit('skip-question')
  }

  const resetLoaders = () => {
    if (fetchingPrevQuestion.value) {
      fetchingPrevQuestion.value = false
    }
    if (postingAnswers.value) {
      postingAnswers.value = false
    }
    if (loadingNextWrongQuestion.value) {
      loadingNextWrongQuestion.value = false
    }
  }

  watch(
    () => props.inResultsMode,
    () => {
      if (postingAnswers.value && props.isGuidedQuiz) {
        postingAnswers.value = false
      }
    },
  )

  watch(questionId, (newQuestionId: string, oldQuestionId: string) => {
    if (props.retainOnlyCurrentQuestionAnswer) {
      setAnswers()
      if (oldQuestionId) eventBus.$delete(selectedAnswers.value, oldQuestionId)
    }
    if (loadingFromSkippedQuestion.value) {
      loadingFromSkippedQuestion.value = false
    }
  })

  watch(questionNumber, () => {
    resetLoaders()
    if (props.inResultsMode && !props.isGuidedQuiz) setAnswers()
  })

  watch(currentQuestion, () => {
    resetLoaders()
  })

  const helperText = computed(() => {
    if (!props.showHelperText) return ''
    if (currentQuestion.value.questionType === 'number') {
      return translateString('quiz.questionQuizModal.numberQuestionType')
    }
    if (currentQuestion.value.questionType === 'single') {
      return translateString('quiz.questionQuizModal.singleQuestionType')
    }
    return translateString('quiz.questionQuizModal.multipleQuestionType')
  })

  const { currentCourseId } = useCourse()
  const showLessonModal = () => {
    const lessonId = currentQuestion.value?.lesson?.id
    const _courseId = props.parentCourseId || currentCourseId.value
    const moduleId = CourseModule.lessonModule(
      props.parentCourseId || currentCourseId.value,
      currentQuestion.value!.lesson!.id,
    )?.id
    if (lessonId && _courseId && moduleId) {
      openLessonModal({
        lessonId,
        courseId: _courseId,
        moduleId,
      })
    }
  }
</script>

<style lang="postcss">
  .question-with-buttons {
    @apply ketch-max-w-[unset] xs4:ketch-max-w-[480px];
    button {
      @apply ketch-w-c120 xs2:ketch-min-w-[160px] xs4:ketch-min-w-[200px];
    }
    .buttons-container {
      @apply ketch-flex ketch-justify-end ketch-items-center;
      &.showing-prev-btn {
        @apply ketch-justify-between;
      }
      button.disable {
        @apply ketch-opacity-50 ketch-cursor-default hover:ketch-border-border-color;
      }
    }
    .additional-buttons-container {
      @apply ketch-flex ketch-flex-col ketch-space-y-c10 ketch-mt-c10;
      h6 {
        @apply ketch-text-end ketch-mr-c50 xs2:ketch-mr-c70 xs4:ketch-mr-c90;
      }
      .buttons-container {
        @apply ketch-flex ketch-justify-end ketch-items-center;
      }
    }
    .comment-area {
      @apply ketch-p-c20 ketch-border ketch-border-secondary-hover-color ketch-overflow-hidden ketch-w-auto sm:ketch-w-[460px];
      @apply ketch-rounded-normal ketch-bg-secondary-hover-color ketch-bg-opacity-[0.04] ketch-mt-c30;
      > h3 {
        @apply ketch-font-bold ketch-mb-c5;
      }
      .content-container {
        @apply ketch-mb-c15;
      }
      .lesson {
        @apply ketch-underline ketch-text-secondary-hover-color ketch-cursor-pointer;
      }
      .correct-attempts {
        @apply ketch-mb-c12 ketch-pb-c8 ketch-border-b ketch-border-secondary-hover-color ketch-flex;
        h5 {
          @apply ketch-ml-c6 ketch-text-secondary-hover-color;
          span {
            @apply ketch-font-bold;
          }
        }
      }
    }
    .feedback-container {
      @apply ketch-mt-c30;
    }
  }
</style>
