<template lang="pug">
.edit-or-delete-course-group-modal
  h3.title-with-underline {{ editTitle }}
  .delete-course-group-info(:class='{ "has-modules": courseGroupModules.length }', v-if='deleteCourseGroup')
    h5(v-if='!courseGroupModules.length') {{ $t('courses.safeToDeleteCourseGroup') }}
    h5(v-else) {{ $t('courses.notSafeToDeleteCourseGroup') }}
  TextRenderer(
    :placeholder='String($t("courses.enterNewCourseGroupTitle"))',
    :show-text-input='true',
    :source='courseGroupTitle',
    @text-input='courseGroupTitle = $event',
    v-else
  )
  .create-course-group-cta
    KetchUpButton.editor(:disabled='disabledCTA', @click.native='editOrDeleteCourseGroup')
      LoadingSpinner(v-if='submittingRequest')
      h5(v-else) {{ $t('proceed') }}
</template>

<script setup lang="ts">
  import { computed, ref } from 'vue'
  import TextRenderer from '@/components/editor/TextRenderer.vue'
  import KetchUpButton from '@/components/common/KetchUpButton.vue'
  import LoadingSpinner from '@/components/common/LoadingSpinner.vue'
  import useCourse from '@/composables/useCourse'
  import { EditorModule } from '@/store/modules/editor'
  import CourseApi from '@/services/api/CourseApi'
  import { CourseModule } from '@/store/modules/course'
  import eventBus from '@/main'
  import useI18n from '@/composables/useI18n'

  const props = defineProps({
    courseGroupId: {
      type: String,
      required: true,
    },
    _courseGroupTitle: {
      type: String,
      default: '',
    },
    deleteCourseGroup: {
      type: Boolean,
      default: false,
    },
  })

  const { course } = useCourse()
  const { translateString } = useI18n()
  const courseGroupTitle = ref(props._courseGroupTitle)
  const submittingRequest = ref(false)

  const editTitle = computed(() =>
    props.deleteCourseGroup ? translateString('courses.deleteCourseGroup') : translateString('courses.editCourseGroup'),
  )

  const disabledCTA = computed(
    () =>
      submittingRequest.value ||
      (props.deleteCourseGroup
        ? courseGroupModules.value.length > 0
        : courseGroupTitle.value.trim().length && props._courseGroupTitle === courseGroupTitle.value.trim()),
  )

  const courseGroupModules = computed(() => EditorModule.courseModules(course.value!.id, props.courseGroupId) || [])

  const editOrDeleteCourseGroup = () => {
    submittingRequest.value = true
    if (props.deleteCourseGroup) {
      CourseApi.deleteCourseGroup(props.courseGroupId)
        .then(() => {
          const courseGroupIndex = CourseModule.course?.courseGroups.findIndex((cG) => cG.id === props.courseGroupId)
          if (typeof courseGroupIndex === 'number' && courseGroupIndex > -1) {
            CourseModule.course!.courseGroups.splice(courseGroupIndex, 1)
            const firstCourseGroupId = CourseModule.course?.courseGroups[0].id
            if (firstCourseGroupId) {
              CourseModule.setCurrentCourseGroup(firstCourseGroupId)
            } else {
              CourseModule.setCurrentCourseGroup('')
            }
          }
          eventBus.$toasted.success(translateString('courses.deletedCourseGroupSuccessfully'))
        })
        .finally(() => {
          submittingRequest.value = false
          eventBus.$emit('close-modal')
        })
    } else {
      CourseApi.updateCourseGroup({
        name: courseGroupTitle.value,
        courseGroupId: props.courseGroupId,
      })
        .then(() => {
          const courseGroup = CourseModule.course?.courseGroups.find((cG) => cG.id === props.courseGroupId)
          if (courseGroup) courseGroup.name = courseGroupTitle.value
          eventBus.$toasted.success(translateString('courses.updatedCourseGroupSuccessfully'))
        })
        .finally(() => {
          submittingRequest.value = false
          eventBus.$emit('close-modal')
        })
    }
  }
</script>

<style lang="postcss">
  .edit-or-delete-course-group-modal {
    @apply ketch-flex ketch-flex-col;
    .delete-course-group-info {
      @apply ketch-bg-editor-primary-color ketch-bg-opacity-5 ketch-py-c10 ketch-text-center ketch-rounded-normal;
      &.has-modules {
        @apply ketch-bg-red-400 ketch-text-white;
      }
    }
    .text-renderer {
      .text-input {
        @apply ketch-rounded-normal;
      }
    }
    .create-course-group-cta {
      @apply ketch-flex ketch-justify-end ketch-mt-c20;
      button {
        @apply ketch-w-auto;
      }
    }
  }
</style>
