<template lang="pug">
.search-loader.generic-content-container
  .content-section
    .results
      .course-search-item(:key='item', v-for='item in 4')
        .image-container(v-if='showImage')
          PlaceholderShimmer(animate, height='120px')
        .search-content-container
          .search-breadcrumbs
            PlaceholderShimmer(:width='isMobileDevice ? "100%" : "550px"', animate, height='15px')
          .title
            PlaceholderShimmer(:width='isMobileDevice ? "100%" : "350px"', animate, height='20px')
          .excerpt
            PlaceholderShimmer(animate, height='75px')
</template>

<script setup lang="ts">
  import PlaceholderShimmer from '@/components/common/PlaceholderShimmer.vue'
  import useBreakpoint from '@/composables/useBreakpoint'

  defineProps({
    showImage: {
      type: Boolean,
      default: true,
    },
  })

  const { isMobileDevice } = useBreakpoint()
</script>

<style lang="postcss" scoped>
  .search-loader {
    .content-section {
      @apply ketch-w-full;
    }
  }
</style>
