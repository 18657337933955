<template lang="pug">
.audio-lesson-editor
  .audio-lesson-files(v-if='filteredLessonAudioFiles')
    AudioAuthor(
      :add-author='addAuthor(audio)',
      :audio-editor-id='audio.editorId',
      :audio-file-id='audio.id',
      :audio-url='audio.audioUrl',
      :author-id='audioFileAuthorId(audio)',
      :current-index='index',
      :edit-author-subtext='String($t("editor.authorOfThisAudioFile"))',
      :items-length='filteredLessonAudioFilesLength',
      :key='audio.editorId',
      :remove-author='removeAuthor(audio)',
      :set-audio-url='setAudioUrl(audio)',
      @move-down='onMoveAudioEntry(index, audio?.orderingNumber ?? 0, "down")',
      @move-up='onMoveAudioEntry(index, audio?.orderingNumber ?? 0, "up")',
      @remove-entry='removeAudioEntry(audio?.id ?? "")',
      show-audio-title,
      show-remove-audio-entry,
      v-for='(audio, index) in filteredLessonAudioFiles'
    )
  KetchUpButton.editor(@click.native='addNewAudio')
    h5 {{ $t('editor.addNewAudio') }}
  TextRenderer(
    :edit-sub-title='String($t("editor.editAudioLessonContent"))',
    :edit-title='String($t("content"))',
    :placeholder='String($t("editor.addAudioLessonContent"))',
    :source='originalLessonContent',
    editor-property='content',
    editor-state='currentLesson'
  )
</template>

<script setup lang="ts">
  import { computed, onBeforeUnmount } from 'vue'
  import TextRenderer from '@/components/editor/TextRenderer.vue'
  import { EditorModule } from '@/store/modules/editor'
  import AudioAuthor from '@/components/editor/AudioAuthor.vue'
  import useEditor from '@/composables/useEditor'
  import KetchUpButton from '@/components/common/KetchUpButton.vue'
  import { CourseModule } from '@/store/modules/course'
  import { v4 as uuidv4 } from 'uuid'
  import { useRoute } from 'vue-router/composables'
  import eventBus from '@/main'
  import type { AudioFile, EditorLessonAudioFile, EditorStatePayload, LessonMap } from '@/services/interfaces/Course'

  const route = useRoute()
  const { lessonAudioFiles } = useEditor(route)

  const filteredLessonAudioFiles = computed(
    () => lessonAudioFiles.value?.filter((a) => !a._destroy) as EditorLessonAudioFile[],
  )

  const filteredLessonAudioFilesLength = computed(() => filteredLessonAudioFiles.value.length)

  const audioFileAuthorId = computed(() => (audioFile: EditorLessonAudioFile) => {
    return audioFile.audioAuthor?.id ?? ''
  })

  const originalLessonContent = computed(() => CourseModule.currentLesson?.content ?? '')

  const maxOrderingNumber = computed(() => {
    const files = filteredLessonAudioFiles.value
    return files && files.length > 0 ? files[files.length - 1].orderingNumber : null
  })

  const addNewAudio = async () => {
    const newAudioLesson = {
      editorId: 'new-' + uuidv4(),
      title: '',
      audioUrl: '',
      audioAuthor: null,
      orderingNumber: maxOrderingNumber.value === null ? 0 : maxOrderingNumber.value + 1,
    }

    if (!lessonAudioFiles.value) {
      await EditorModule.setCurrentLessonAudioFiles([])
    }

    lessonAudioFiles.value?.push(newAudioLesson)
  }

  const setAudioUrl = (audioFile: EditorLessonAudioFile) => {
    return (audioUrl: string) => {
      onAudioFileChange(audioFile, 'audioUrl', audioUrl)
    }
  }

  const addAuthor = (audioFile: EditorLessonAudioFile) => {
    return (authorId: string) => {
      const selectedAuthor = EditorModule.editorAllAuthors!.find((author) => author.id === authorId)
      if (selectedAuthor) {
        onAudioFileChange(audioFile, 'audioAuthor', selectedAuthor)
      }
    }
  }

  const removeAuthor = (audioFile: EditorLessonAudioFile) => {
    return () => {
      if (audioFile.audioAuthor && audioFile.editorId) {
        onAudioFileChange(audioFile, 'audioAuthor', null)
        setTimeout(() => eventBus.$delete(audioFile, 'audioAuthor'), 0)
      }
    }
  }

  const onAudioFileChange = (audioFile: EditorLessonAudioFile, property: keyof EditorLessonAudioFile, value: any) => {
    mutateAndQueueFunc(audioFile.editorId!)({
      key: `currentLessonAudio-${audioFile.editorId}-${property}`,
      path: route.path,
      state: 'currentLessonAudioFiles',
      property,
      value,
      mutateAndQueue: () => {
        return
      },
    })
  }

  const removeAudioEntry = (id: string) => {
    const audioFile = lessonAudioFiles.value?.find((audio) => audio.id === id)
    if (audioFile) eventBus.$set(audioFile, '_destroy', true)
  }

  const unsetLessonAudioFiles = () => {
    EditorModule.setCurrentLessonAudioFiles(null)
  }

  const audioFilesRedoCallback = (data: EditorStatePayload) => {
    if (!data) return
    const audioFile: EditorLessonAudioFile | undefined = filteredLessonAudioFiles.value?.find(
      (a) => a.editorId === (data.value as LessonMap).editorId,
    )
    if (audioFile) eventBus.$set(audioFile, data.property!, data.apiPayload)
    const existingAudioFile = EditorModule.currentLessonAudioFiles?.find(
      (a: AudioFile) => a.id === (audioFile as EditorLessonAudioFile)?.id,
    )
    if (existingAudioFile) eventBus.$set(existingAudioFile, `${data.property!}`, data.apiPayload)
  }

  const audioFilesUndoCallback = (data: EditorStatePayload) => {
    if (!data) return
    const audioFile = filteredLessonAudioFiles.value?.find((a) => a.editorId === (data.value as LessonMap).editorId)
    if (audioFile) eventBus.$set(audioFile, data.property!, (data.value as LessonMap).prevPropValue)
  }

  const mutateAndQueueFunc = (editorId: string) => {
    return async (data: EditorStatePayload) => {
      const changesPayload = EditorModule.editorChanges[data.path]?.find((c) => c.data.key === data.key)
      const audioFile: any = filteredLessonAudioFiles.value?.find((a) => a.editorId === editorId)

      const payload = Object.assign({}, data, {
        apiPayload: data.value,
        value: {
          editorId,
          prevPropValue: (changesPayload?.data.value as LessonMap)?.prevPropValue ?? audioFile[data.property!],
        },
      })
      EditorModule.addEditorChange(payload)
      if (audioFile) eventBus.$set(audioFile, payload.property!, payload.apiPayload as string)
      EditorModule.subscribe({
        type: 'save',
        key: payload.key,
        callback: audioFilesRedoCallback,
      })
      EditorModule.subscribe({
        type: 'discard',
        key: 'discard-' + payload.key,
        callback: audioFilesUndoCallback,
      })
    }
  }

  const onMoveAudioEntry = (index: number, orderingNumber: number, direction: 'up' | 'down') => {
    const lessonAudioFilesCopy = structuredClone(lessonAudioFiles.value ?? [])

    if (direction === 'up') {
      if (index == 0) return
      const audioEntryAbove = lessonAudioFiles.value![index - 1]
      const movedAudioEntry = lessonAudioFiles.value![index]

      eventBus.$set(audioEntryAbove, 'orderingNumber', orderingNumber)
      eventBus.$set(movedAudioEntry, 'orderingNumber', orderingNumber - 1)

      lessonAudioFiles.value?.splice(index, 1, audioEntryAbove)
      lessonAudioFiles.value?.splice(index - 1, 1, movedAudioEntry)
      registerOrderingChanges(lessonAudioFilesCopy)
    } else if (direction === 'down') {
      if (index === lessonAudioFiles.value!.length - 1) return
      const audioEntryBelow = lessonAudioFiles.value![index + 1]
      const movedAudioEntry = lessonAudioFiles.value![index]

      eventBus.$set(audioEntryBelow, 'orderingNumber', orderingNumber)
      eventBus.$set(movedAudioEntry, 'orderingNumber', orderingNumber + 1)

      lessonAudioFiles.value?.splice(index, 1, audioEntryBelow)
      lessonAudioFiles.value?.splice(index + 1, 1, movedAudioEntry)
      registerOrderingChanges(lessonAudioFilesCopy)
    }
  }

  const registerOrderingChanges = (lessonAudioFilesCopy: EditorLessonAudioFile[]) => {
    const key = 'lesson-audio-files-reordering-' + uuidv4()

    EditorModule.addEditorChange({
      key,
      path: route.path,
      skipGrouping: true,
      value: {
        prevPropValue: lessonAudioFilesCopy,
      } as LessonMap,
      apiPayload: lessonAudioFiles.value,
    })

    EditorModule.subscribe({
      type: 'discard',
      key,
      callback: registerOrderingUndoCallback,
    })
    EditorModule.subscribe({
      type: 'save',
      key,
      callback: registerOrderingRedoCallback,
    })
  }

  const registerOrderingUndoCallback = (data: EditorStatePayload) => {
    EditorModule.setCurrentLessonAudioFiles((data.value as LessonMap)?.prevPropValue as EditorLessonAudioFile[])
  }

  const registerOrderingRedoCallback = (data: EditorStatePayload) => {
    EditorModule.setCurrentLessonAudioFiles(data.apiPayload as EditorLessonAudioFile[])
  }

  onBeforeUnmount(() => {
    unsetLessonAudioFiles()
    EditorModule.unsubscribe({ type: 'discard', callback: unsetLessonAudioFiles })
    EditorModule.unsubscribe({ type: 'discard', callback: audioFilesUndoCallback })
    EditorModule.unsubscribe({ type: 'save', callback: audioFilesRedoCallback })
    EditorModule.unsubscribe({ type: 'discard', callback: registerOrderingUndoCallback })
    EditorModule.unsubscribe({ type: 'save', callback: registerOrderingRedoCallback })
  })
</script>

<style lang="postcss">
  .audio-lesson-editor {
    @apply ketch-flex ketch-flex-col ketch-space-y-c30;
    .audio-lesson-files {
      .audio-author-wrapper {
        @apply ketch-border-b ketch-border-dashed ketch-border-editor-primary-color ketch-py-c30;

        &:first-child {
          @apply ketch-border-t;
        }
        .audio-author-entry {
          @apply ketch-flex ketch-flex-col ketch-space-y-c10;
        }
      }
    }
  }
</style>
