<template lang="pug">
.trial-or-purchased-courses
  CarouselSwiper(
    :break-points='breakPoints',
    :nav-class='`nav-${courses[0].id}`',
    :slides-per-group='2',
    :slides-per-view='2',
    :space-between='15',
    :swiper-items='courses'
  )
    template(#slide-slot='{ item }')
      .image-container
        PlaceholderShimmer(:animate='true', height='137px')
          template(v-slot:default='{ isLoadingResource, onResourceLoaded }')
            img.content-image(
              :class='{ "shimmer-asset": isLoadingResource }',
              :src='item.cardTeaserImageUrl || item.teaserImageUrl',
              @load='onResourceLoaded'
            )
    template(#slide-nav, v-if='isDesktopDevice')
      .navigation(:class='`nav-${courses[0].id}`')
        .prev-nav-button(data-cy='prev-nav-button')
          img(:src='leftNavigation')
        .next-nav-button(data-cy='next-nav-button')
          img(:src='rightNavigation')
</template>

<script setup lang="ts">
  import CarouselSwiper from '@/components/common/CarouselSwiper.vue'
  import useBreakpoint from '@/composables/useBreakpoint'
  import useIcons from '@/composables/useIcons'
  import PlaceholderShimmer from '@/components/common/PlaceholderShimmer.vue'
  import type { PropType } from 'vue'

  defineProps({
    courses: {
      type: Array as PropType<{ id: string; cardTeaserImageUrl: string; teaserImageUrl: string }[]>,
      required: true,
    },
  })

  const { isDesktopDevice } = useBreakpoint()
  const { leftNavigation, rightNavigation } = useIcons()

  const breakPoints = {
    1200: {
      slidesPerView: '2',
      spaceBetween: '15',
      slidesPerGroup: '2',
    },
    1024: {
      slidesPerView: '1.2',
      spaceBetween: '15',
      slidesPerGroup: '1',
    },
    991: {
      slidesPerView: '1.45',
      spaceBetween: '15',
      slidesPerGroup: '1',
    },
    767: {
      slidesPerView: '1.2',
      spaceBetween: '15',
      slidesPerGroup: '1',
    },
    540: {
      slidesPerView: '1.2',
      spaceBetween: '15',
      slidesPerGroup: '1',
    },
  }
</script>

<style lang="postcss" scoped>
  .trial-or-purchased-courses {
    .slider-wrapper .image-container {
      @apply ketch-overflow-hidden ketch-relative ketch-rounded-[5px] ketch-cursor-pointer;
      .content-image {
        @apply ketch-max-h-[140px];
      }
    }
    .navigation {
      .prev-nav-button {
        left: -17px;
      }
      .next-nav-button {
        right: -17px;
      }
    }
  }
</style>
