<script setup lang="ts">
  import useIcons from '@/composables/useIcons'
  import SVGRenderer from '@/components/common/SVGRenderer.vue'

  defineProps({
    currentIndex: {
      type: Number,
      required: true,
    },
    itemsLength: {
      type: Number,
      required: true,
    },
  })

  const { arrowDown } = useIcons()
</script>

<template lang="pug">
.reorder-up-down
  .up(:class='{ disabled: currentIndex === 0 }', @click='$emit("move-up")')
    SVGRenderer(:has-hover='false', :icon='arrowDown', :stroke-color='"var(--editor-foreground-color)"')
  .down(:class='{ disabled: currentIndex === itemsLength - 1 }', @click='$emit("move-down")')
    SVGRenderer(:has-hover='false', :icon='arrowDown', :stroke-color='"var(--editor-foreground-color)"')
</template>

<style lang="postcss">
  .reorder-up-down {
    @apply ketch-flex ketch-flex-col ketch-space-y-c10;

    .up,
    .down {
      @apply ketch-bg-editor-primary-color ketch-p-[8px] ketch-cursor-pointer ketch-max-w-[25px] ketch-max-h-[25px];
      @apply ketch-flex ketch-items-center ketch-justify-center ketch-rounded-[5px];

      &.disabled {
        @apply ketch-opacity-20 ketch-cursor-auto;
      }
    }

    .up {
      svg {
        @apply ketch-rotate-180;
      }
    }
  }
</style>
