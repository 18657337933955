<template lang="pug">
.checkout-modal
  .wrapper
    img(:src='hourGlassIcon')
    h2 {{ $t('checkout.success.modal.header') }}
    h5 {{ $t('checkout.success.modal.description') }}
</template>

<script setup lang="ts">
  import useIcons from '@/composables/useIcons'

  const { hourGlassIcon } = useIcons()
</script>

<style lang="postcss" scoped>
  .checkout-modal {
    @apply ketch-flex ketch-items-center ketch-justify-center ketch-my-c45;
    .wrapper {
      @apply ketch-w-[375px] ketch-flex ketch-flex-col ketch-items-center ketch-justify-center;
      img {
        @apply ketch--ml-c20;
      }
      h2 {
        @apply ketch-mt-c60 ketch-mb-c30;
      }
      h5 {
        @apply ketch-text-center;
      }
    }
  }
</style>
