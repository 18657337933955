<template lang="pug">
li.leuckentext-question-item(ref='liElement')
  HTMLRenderer(:html='question')
</template>

<script setup lang="ts">
  import { computed, ref, watch, nextTick } from 'vue'
  import HTMLRenderer from '@/components/common/HTMLRenderer.vue'
  import useEditor from '@/composables/useEditor'
  import type { PropType } from 'vue'
  import type { LeuckentextQuestionItem } from '@/services/interfaces/Course'
  import { useRoute } from 'vue-router/composables'

  const props = defineProps({
    item: {
      required: true,
      type: Object as PropType<LeuckentextQuestionItem>,
    },
    selectedAnswer: {
      type: String,
      default: '',
    },
    inResultsMode: {
      type: Boolean,
      default: false,
    },
  })
  const emit = defineEmits(['on-answer-drag-start', 'drag-end', 'reset-answer'])

  const route = useRoute()
  const { decodeAndParseSource } = useEditor(route)

  const liElement = ref<HTMLElement | null>(null)

  const question = computed(() => {
    const decodedText = decodeAndParseSource(props.item.text)
    const textMatch = decodedText.match(/{{.*}}/)
    return decodedText.replace(/{{.*}}/, () => {
      return `<span class="fill-in ${setFillInClass.value}"><span></span><span class="answer">${
        props.inResultsMode ? textMatch?.[0].replace(/[{}]/g, '') || '' : props.selectedAnswer
      }</span></span>`
    })
  })

  const setFillInClass = computed(() => {
    if (props.inResultsMode) return 'selected results-mode'
    if (props.selectedAnswer) return 'selected'
    return ''
  })

  watch(
    question,
    () => {
      nextTick(() => onSpanClick())
    },
    { immediate: true },
  )

  const onSpanClick = () => {
    if (liElement.value) {
      const fillInSpan = liElement.value.querySelector('.fill-in.selected') as HTMLSpanElement
      if (fillInSpan) {
        fillInSpan.draggable = !props.inResultsMode
        fillInSpan.addEventListener('dragstart', onAnswerDragStart)
        fillInSpan.addEventListener('dragend', onAnswerDragEnd)
        fillInSpan.addEventListener('click', onAnswerClick)
      }
    }
  }

  const onAnswerDragStart = () => {
    emit('on-answer-drag-start', props.item.id)
  }

  const onAnswerDragEnd = () => {
    emit('drag-end')
  }

  const onAnswerClick = () => {
    if (props.inResultsMode) return
    emit('reset-answer', props.item.id)
  }
</script>

<style lang="postcss">
  .leuckentext-question-item {
    @apply ketch-text-sm ketch-leading-lg ketch-list-none ketch-rounded-large ketch-p-c15 ketch-relative;
    @apply ketch-bg-primary-color ketch-bg-opacity-[0.05] ketch-border ketch-border-primary-color;
    @apply md:ketch-list-item md:ketch-rounded-none md:ketch-bg-transparent md:ketch-p-0 md:ketch-border-none;
    &.dragged {
      @apply ketch-border-2 ketch-border-dashed ketch-border-editor-primary-color;
    }
    .fill-in {
      @apply ketch-border-b-2 ketch-border-dashed ketch-border-primary-text-color;
      @apply ketch-w-c80 ketch-whitespace-nowrap ketch-inline-block ketch-relative;
      @apply md:ketch-bg-rose-400 md:ketch-bg-opacity-[0.07] md:ketch-border-rose-400;

      span:not(.answer) {
        @screen md {
          @apply ketch-bg-rose-400 ketch-bg-opacity-20 ketch-w-[14px] ketch-h-[14px] ketch-rounded-full;
          @apply ketch-inline-flex ketch-items-center ketch-justify-center ketch-mb-[-2px];
          &:before {
            content: '';
            @apply ketch-absolute ketch-w-c6 ketch-h-c6 ketch-rounded-full ketch-bg-rose-400;
          }
        }
      }
      &.selected {
        @apply ketch-w-auto ketch-px-c6 ketch-cursor-pointer;
        span:not(.answer) {
          @apply ketch-m-0 ketch-top-[9px];
          &,
          &:before,
          &:after {
            @apply ketch-rounded-none ketch-h-c2 ketch-w-c16 ketch-absolute ketch-bg-rose-400;
            @apply ketch-bg-opacity-70;
            content: '';
          }
          &:before {
            @apply ketch--top-c4;
          }
          &:after {
            @apply ketch-top-c4;
          }
        }
        span.answer {
          @apply ketch-pl-c20 ketch-font-bold ketch-text-rose-400;
        }
      }
      &.results-mode {
        @apply ketch-cursor-auto;
      }
    }
    &.default-behaviour {
      @apply ketch-bg-transparent ketch-border-none ketch-p-0;
      .fill-in {
        @apply ketch-bg-rose-400 ketch-bg-opacity-[0.07] ketch-border-rose-400;
      }
    }
  }
</style>
