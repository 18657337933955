<template lang="pug">
.audio-author-wrapper
  ReorderUpAndDown(
    :current-index='currentIndex || 0',
    :items-length='itemsLength || 0',
    @move-down='$emit("move-down")',
    @move-up='$emit("move-up")',
    v-if='showReorderButtons'
  )
  .audio-author-entry
    .discard-audio-entry-cta(v-if='showRemoveAudioEntry')
      h5(@click.stop='$emit("remove-entry")') {{ $t('editor.removeAudioFileEntry') }}
    .audio-title(v-if='showAudioTitle')
      TextRenderer(
        :edit-sub-title='String($t("editor.editAudioTitle"))',
        :edit-title='String($t("editor.audioTitle"))',
        :editor-state='`lesson-audio-title-${audioEditorId || audioFileId}`',
        :mutate-and-queue-func='mutateAndQueueFunc(audioEditorId || audioFileId || "")',
        :placeholder='String($t("editor.addAudioTitle"))',
        :source='originalAudioTitle',
        editor-property='title',
        required
      )
    .audio-uploader
      .audio-item(v-if='audioUrl')
        h6(@click='setAudioUrl("")') {{ $t('editor.removeAudioFile') }}
        AudioPlayer(:audio-source='audioUrl')
      MediaUploader(:audio-only='true', :file-type='""', @audio-url='setAudioUrl', v-else)
    EditAuthors(
      :add-author='addAuthor',
      :author-subtext='authorSubtext',
      :authors='audioAuthor',
      :remove-author='removeAuthor',
      v-if='showAddAuthor'
    )
</template>

<script setup lang="ts">
  import { computed, onUnmounted } from 'vue'
  import { EditorModule } from '@/store/modules/editor'
  import MediaUploader from '@/components/common/MediaUploader.vue'
  import AudioPlayer from '@/components/AudioPlayer.vue'
  import TextRenderer from '@/components/editor/TextRenderer.vue'
  import useEditor from '@/composables/useEditor'
  import { CourseModule } from '@/store/modules/course'
  import EditAuthors from '@/components/editor/EditAuthors.vue'
  import useI18n from '@/composables/useI18n'
  import eventBus from '@/main'
  import type { PropType } from 'vue'
  import type { AudioFile, EditorLessonAudioFile, EditorStatePayload, LessonMap } from '@/services/interfaces/Course'
  import ReorderUpAndDown from '@/components/common/ReorderUpAndDown.vue'
  import { useRoute } from 'vue-router/composables'

  const props = defineProps({
    authorId: {
      type: String,
      default: '',
    },
    addAuthor: {
      type: Function as PropType<(authorId: string) => void>,
      required: true,
    },
    removeAuthor: {
      type: Function as PropType<() => void>,
      required: true,
    },
    setAudioUrl: {
      type: Function as PropType<(url: string) => void>,
      required: true,
    },
    showAudioTitle: {
      type: Boolean,
      default: false,
    },
    showRemoveAudioEntry: {
      type: Boolean,
      default: false,
    },
    showAddAuthor: {
      type: Boolean,
      default: false,
    },
    audioEditorId: String,
    audioFileId: String,
    audioUrl: String,
    editAuthorSubtext: String,
    currentIndex: Number,
    itemsLength: Number,
  })

  defineEmits(['remove-entry', 'move-down', 'move-up'])

  const route = useRoute()
  const { lessonAudioFiles } = useEditor(route)
  const { translateString } = useI18n()

  const showReorderButtons = computed(() => props.currentIndex !== undefined)

  const originalAudioTitle = computed(() => {
    const audioFile = CourseModule.originalLessonAudioFiles?.find((a) => a.id === props.audioFileId)
    return audioFile ? audioFile.title : ''
  })

  const authorSubtext = computed(() => {
    return props.editAuthorSubtext ? props.editAuthorSubtext : translateString('editor.editAuthorsSubtext')
  })

  const audioAuthor = computed(() => {
    const author = EditorModule.editorAllAuthors?.find((author) => author.id === props.authorId)
    return author ? [author] : []
  })

  const mutateAndQueueFunc = (audioEditorId: string) => {
    return async (data: EditorStatePayload) => {
      const changesPayload = EditorModule.editorChanges[data.path]?.find((c) => c.data.key === data.key)
      const audioFile: any =
        lessonAudioFiles.value?.find((a) => [audioEditorId].includes(a.editorId!)) ||
        lessonAudioFiles.value?.find((a) => a.id === props.audioFileId)

      const payload = Object.assign({}, data, {
        apiPayload: data.value,
        value: {
          editorId: audioEditorId || props.audioFileId,
          prevPropValue: (changesPayload?.data.value as LessonMap)?.prevPropValue ?? audioFile[data.property!] ?? '',
        },
      })
      await EditorModule.addEditorChange(payload)
      if (audioFile) eventBus.$set(audioFile, payload.property!, payload.apiPayload as string)
      EditorModule.subscribe({
        type: 'save',
        key: payload.key,
        callback: audioRedoCallback,
      })
      EditorModule.subscribe({
        type: 'discard',
        key: 'discard-' + payload.key,
        callback: audioUndoCallback,
      })
    }
  }

  const audioRedoCallback = (data: EditorStatePayload) => {
    if (!data) return
    const audioFile: EditorLessonAudioFile | AudioFile | undefined = lessonAudioFiles.value?.find(
      (a) => a.editorId === (data.value as LessonMap).editorId,
    )
    if (audioFile) (audioFile as any)[data.property!] = data.apiPayload as string
  }

  const audioUndoCallback = (data: EditorStatePayload) => {
    if (!data) return
    const audioFile = lessonAudioFiles.value?.find((a) => a.editorId === (data.value as LessonMap).editorId)
    if (audioFile) (audioFile as any)[data.property!] = (data.value as LessonMap).prevPropValue
  }

  onUnmounted(() => {
    EditorModule.unsubscribe({ type: 'discard', callback: audioUndoCallback })
    EditorModule.unsubscribe({ type: 'save', callback: audioRedoCallback })
  })
</script>

<style lang="postcss" scoped>
  .audio-author-wrapper {
    @apply ketch-flex ketch-space-x-c20;
    .audio-author-entry {
      @apply ketch-w-full;
      .discard-audio-entry-cta,
      .audio-item {
        h5,
        h6 {
          @apply ketch-text-editor-primary-color ketch-text-right hover:ketch-underline ketch-cursor-pointer;
        }
      }
    }
  }
</style>
