<template lang="pug">
.tile-block(:style='{ height: windowHeight }')
  .tile.top-tile
  .tile.middle-tile
</template>

<script setup lang="ts">
  import { computed, ref, watch, onMounted, onBeforeUnmount } from 'vue'
  import useBreakpoint from '@/composables/useBreakpoint'
  import { useRoute } from 'vue-router/composables'
  import eventBus from '@/main'

  const route = useRoute()
  const { current } = useBreakpoint()
  const windowHeight = ref('100vh')

  const routePath = computed(() => {
    return route.path
  })

  watch(current, () => {
    windowHeight.value = '100vh'
  })

  watch(routePath, () => {
    setTimeout(() => {
      windowHeight.value = '100vh'
    }, 500)
  })

  const setScrollHeight = (value: string) => {
    windowHeight.value = value
  }

  const scrollHandler = () => {
    setScrollHeight(`${document.documentElement.scrollHeight}px`)
  }

  onMounted(() => {
    setScrollHeight(`${document.documentElement.scrollHeight}px`)
    window.addEventListener('scroll', scrollHandler)
    eventBus.$on('reset-background-tile-height', () => {
      setScrollHeight('100vh')
    })
  })

  onBeforeUnmount(() => {
    window.removeEventListener('scroll', scrollHandler)
  })
</script>

<style lang="postcss">
  .tile-block {
    @apply ketch-top-0 ketch-bottom-0 ketch-left-0 ketch-right-0 ketch-absolute ketch-overflow-hidden;
    .tile {
      @apply ketch-absolute ketch-bg-gray-700 ketch-bg-opacity-[0.03] ketch-rounded-[140px];
    }
    .top-tile {
      @apply ketch-w-screen ketch-h-[590px] ketch-top-[-240px] ketch-left-[35px];
      @apply sm:ketch-w-[1080px] sm:ketch-h-[1470px] sm:ketch-top-[-1050px] 2xl:ketch-top-[-950px];
      @apply sm:ketch-left-[-205px] md:ketch-left-[105px] 2xl:ketch-left-[505px];
      transform: rotate(-33deg);
    }
    .middle-tile {
      @apply ketch-w-screen ketch-h-[384px] ketch-top-[1024px] ketch-left-[-175px];
      @apply sm:ketch-w-[1130px] sm:ketch-h-[1220px] sm:ketch-top-[1200px] sm:ketch-left-[-598px];
      transform: rotate(-53deg);
    }
  }
  .ketch-dark .tile-block {
    .tile {
      @apply ketch-bg-white ketch-bg-opacity-[0.03];
    }
  }
</style>
