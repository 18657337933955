import { render, staticRenderFns } from "./CourseExamDate.vue?vue&type=template&id=168095c4&scoped=true&lang=pug"
import script from "./CourseExamDate.vue?vue&type=script&setup=true&lang=ts"
export * from "./CourseExamDate.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./CourseExamDate.vue?vue&type=style&index=0&id=168095c4&prod&lang=postcss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "168095c4",
  null
  
)

export default component.exports