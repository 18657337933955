import { render, staticRenderFns } from "./Pagination.vue?vue&type=template&id=ec027854&scoped=true&lang=pug"
import script from "./Pagination.vue?vue&type=script&setup=true&lang=ts"
export * from "./Pagination.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./Pagination.vue?vue&type=style&index=0&id=ec027854&prod&lang=postcss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ec027854",
  null
  
)

export default component.exports