<template lang="pug">
.non-free-lesson-view
  LoadingSpinner(v-if='loadingCourse')
  template(v-else)
    h2.lesson-title.title-with-underline(v-if='currentLessonTitle') {{ currentLessonTitle }}
    .image-container
      img(:src='course.cardTeaserImageUrl')
    .testimonials(v-if='courseTestimonials.length')
      TestimonialSlider(:slides-per-group='2', :slides-per-view='2.3', :testimonials='courseTestimonials')
    .cta-wrapper(v-if='!isSearching')
      .cta
        h5.by-course-to-continue {{ $t('courses.buyCourseToContinue') }}
        .lesson-cta.buyCourse(@click='shopifyCheckout')
          h5 {{ $t('courses.buyCourse') }}
          SVGRenderer(:has-hover='false', :icon='arrowRight', stroke-color='var(--primary-foreground-color)')
</template>

<script setup lang="ts">
  import { computed } from 'vue'
  import TestimonialSlider from '@/components/course/TestimonialSlider.vue'
  import useIcons from '@/composables/useIcons'
  import useCourse from '@/composables/useCourse'
  import useSegment from '@/composables/useSegment'
  import { CourseModule } from '@/store/modules/course'
  import SVGRenderer from '@/components/common/SVGRenderer.vue'
  import { useRoute } from 'vue-router/composables'
  import useShopifyClient from '@/composables/useShopifyClient'
  import LoadingSpinner from '@/components/common/LoadingSpinner.vue'

  const props = defineProps({
    currentLessonTitle: {
      type: String,
      required: true,
    },
    lessonCourseId: String,
  })

  const route = useRoute()
  const { arrowRight } = useIcons()
  const { currentCourseId } = useCourse()
  const { coursePurchaseIntended } = useSegment()
  const { createShopifyCheckout } = useShopifyClient()

  const loadingCourse = computed(() => course.value === null)
  const isSearching = computed(() => !!route.query.q)

  const course = computed(() => {
    return CourseModule.course
  })

  const courseTestimonials = computed(() => {
    return course.value?.testimonials || []
  })

  const trackCoursePurchaseIntended = () => {
    coursePurchaseIntended(currentCourseId.value, course.value!.title, 'lesson_view_cta')
  }

  const shopifyCheckout = async () => {
    trackCoursePurchaseIntended()
    await createShopifyCheckout()
  }

  if ((props.lessonCourseId || course.value?.id) && isSearching.value) {
    CourseModule.getCourse({ courseId: course.value ? course.value.id : props.lessonCourseId! })
  }
</script>

<style lang="postcss">
  .non-free-lesson-view {
    @apply ketch-w-full;
    .loading {
      @apply ketch-flex ketch-justify-center;
    }
    .image-container {
      @apply ketch-mt-c45;
      img {
        @apply ketch-w-full;
      }
    }
    .testimonials {
      @apply ketch-mt-c40;
      .testimonial-slider {
        @apply md:ketch-w-[calc(1200px-450px)] xl:ketch-w-[calc(1200px-560px)];
      }
    }
    .cta-wrapper {
      @apply ketch-mt-c60;
    }
  }
</style>
