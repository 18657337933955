<template lang="pug">
.get-started-panel-wrapper.component-spacing
  GetStartedPanelLoader(v-if='fetchingOnboardingDetails')
  .get-started-panel(v-else)
    .panel-header-info-completion-status(
      :class='{ "not-showing-panel-body": !showPanelBody }',
      @click='togglePanelBody'
    )
      .panel-header-info
        .panel-title(:class='{ h2: showPanelBody, h3: !showPanelBody }') {{ $t('homepage.getStartedPanel.title') }}
        h5.panel-description(v-if='showPanelBody') {{ $t('homepage.getStartedPanel.description') }}
      .completion-status
        transition(mode='out-in')
          .completed-steps(v-if='allStepsCompleted')
            SVGRenderer(
              :has-hover='false',
              :icon='checkmark',
              :stroke-color='"var(--editor-foreground-color)"',
              width='13'
            )
            h4 {{ $t('homepage.getStartedPanel.closed') }}
          h5(v-else) {{ completionStatus }}
    transition(name='menu-slide')
      .panel-body(v-if='showPanelBody')
        .panel-body-steps
          .get-started-step(
            :key='step.stepNumber',
            @click='setActiveStep(step.stepNumber)',
            v-for='step in getStartedSteps'
          )
            .step-info-wrapper
              .step-info(
                :class='{ active: step.stepNumber === activeStep, completed: stepIsCompleted(step.stepNumber), selectable: stepIsSelectable(step.stepNumber) }'
              )
                .step-count-text
                  h5.step-count {{ step.stepNumber }}
                  h5.step-text {{ step.text }}
                .complete-step-icon
                  SVGRenderer(
                    :has-hover='false',
                    :icon='checkmark',
                    :stroke-color='"var(--editor-primary-color)"',
                    v-if='stepIsCompleted(step.stepNumber)',
                    width='15'
                  )
            .active-step-indicator(v-if='!isMobileDevice')
              .some-random-class(v-show='activeStep === step.stepNumber')
                SVGRenderer(
                  :fill-color='"var(--secondary-text-color)"',
                  :has-hover='false',
                  :icon='arrowRightIndicator',
                  width='20'
                )
            transition(name='menu-slide')
              .step-accord(v-if='step.stepNumber === activeStep && activeStepEntity && isMobileDevice')
                PanelStepContent(
                  :active-step='activeStep',
                  :active-step-entity='activeStepEntity',
                  :in-trial-or-purchased-courses='inTrialOrPurchasedCourses || []',
                  :key='rerenderKey',
                  :onboarding-details='onboardingDetails',
                  :step-empty-state-c-t-a='stepEmptyStateCTA',
                  :step-empty-state-description='stepEmptyStateDescription',
                  :step-empty-state-title='stepEmptyStateTitle',
                  :step-is-completed='stepIsCompleted(activeStep)',
                  :total-questions-answered='totalQuestionsAnswered',
                  :total-questions-answered-percentage='totalQuestionsAnsweredPercentage'
                )
        PanelStepContent(
          :active-step='activeStep',
          :active-step-entity='activeStepEntity',
          :in-trial-or-purchased-courses='inTrialOrPurchasedCourses || []',
          :key='rerenderKey',
          :onboarding-details='onboardingDetails',
          :step-empty-state-c-t-a='stepEmptyStateCTA',
          :step-empty-state-description='stepEmptyStateDescription',
          :step-empty-state-title='stepEmptyStateTitle',
          :step-is-completed='stepIsCompleted(activeStep)',
          :total-questions-answered='totalQuestionsAnswered',
          :total-questions-answered-percentage='totalQuestionsAnsweredPercentage',
          v-if='!isMobileDevice && activeStepEntity'
        )
</template>

<script setup lang="ts">
  import { computed, onMounted, ref, watch } from 'vue'
  import SVGRenderer from '@/components/common/SVGRenderer.vue'
  import useIcons from '@/composables/useIcons'
  import UsersApi from '@/services/api/UsersApi'
  import useBreakpoint from '@/composables/useBreakpoint'
  import PanelStepContent from '@/components/get-started-panel/PanelStepContent.vue'
  import useSegment from '@/composables/useSegment'
  import GetStartedPanelLoader from '@/components/loaders/GetStartedPanelLoader.vue'
  import useI18n from '@/composables/useI18n'
  import type { GetStartedStepDetails, OnboardingDetails } from '@/services/interfaces/Common'
  import { UserModule } from '@/store/modules/user'

  const { checkmark, arrowRightIndicator } = useIcons()
  const { isMobileDevice } = useBreakpoint()
  const { getStartedStepClicked } = useSegment()
  const { translateString } = useI18n()

  const activeStep = ref(1)
  const onboardingDetails = ref(null as OnboardingDetails | null)
  const completedSteps = ref([] as number[])
  const selectableSteps = ref([] as number[])
  const showPanelBody = ref(true)
  const rerenderKey = ref('')

  const getStartedSteps: GetStartedStepDetails[] = [
    {
      stepNumber: 1,
      text: translateString('homepage.getStartedPanel.chooseTrainings'),
      emptyStateTitle: translateString('homepage.getStartedPanel.selectTrainingsTitle'),
      emptyStateDescription: translateString('homepage.getStartedPanel.selectTrainingsDescription'),
      emptyStateCTA: translateString('homepage.getStartedPanel.andSoItGoes'),
      entity: 'select-trainings',
    },
    {
      stepNumber: 2,
      text: translateString('homepage.getStartedPanel.getTrainingsOverview'),
      emptyStateTitle: translateString('homepage.getStartedPanel.trainingOverviewTitle'),
      emptyStateDescription: translateString('homepage.getStartedPanel.trainingOverviewDescription'),
      emptyStateCTA: translateString('homepage.getStartedPanel.andItsThatEasy'),
      entity: 'trainings-overview',
    },
    {
      stepNumber: 3,
      text: translateString('homepage.getStartedPanel.completeYourFirstQuiz'),
      emptyStateTitle: translateString('homepage.getStartedPanel.completeGuidedQuiz'),
      emptyStateDescription: translateString('homepage.getStartedPanel.completeGuidedQuizDescription'),
      emptyStateCTA: translateString('homepage.getStartedPanel.andItsThatEasy'),
      entity: 'guided-quiz',
    },
    {
      stepNumber: 4,
      text: translateString('homepage.getStartedPanel.answer100Questions'),
      emptyStateTitle: translateString('homepage.getStartedPanel.answeredQuestionsTitle'),
      emptyStateDescription: translateString('homepage.getStartedPanel.answeredQuestionsDescription'),
      emptyStateCTA: '',
      entity: 'questions-answered',
    },
  ]

  const fetchingOnboardingDetails = computed(() => onboardingDetails.value === null)

  const inTrialOrPurchasedCourses = computed(() => onboardingDetails.value?.courses)

  const completedSettingCourseTrial = computed(() => {
    if (!inTrialOrPurchasedCourses.value) return false
    return inTrialOrPurchasedCourses.value.length > 0
  })

  const completedAtLeastOneLesson = computed(() => {
    if (!onboardingDetails.value) return false
    return onboardingDetails.value.totalLessonsCompleted > 0
  })

  const completedAtLeastOneQuiz = computed(() => {
    if (!onboardingDetails.value) return false
    return onboardingDetails.value.guidedQuizzesCompleted > 0
  })

  const totalQuestionsAnswered = computed(() => onboardingDetails.value?.totalQuestionsAnswered ?? 0)

  const totalQuestionsAnsweredPercentage = computed(() => {
    if (!onboardingDetails.value) return 0
    const percentage = Math.round((totalQuestionsAnswered.value / 100) * 100)
    if (percentage > 100) return 100
    return percentage
  })

  const stepIsCompleted = computed(() => (stepNumber: number) => completedSteps.value.includes(stepNumber))

  const stepIsSelectable = computed(() => (stepNumber: number) => selectableSteps.value.includes(stepNumber))

  const completionStatus = computed(() => `[${completedSteps.value.length}/${getStartedSteps.length}]`)

  const allStepsCompleted = computed(() => completedSteps.value.length === getStartedSteps.length)

  const stepEmptyStateTitle = computed(
    () => getStartedSteps.find((step) => step.stepNumber === activeStep.value)?.emptyStateTitle,
  )

  const stepEmptyStateDescription = computed(
    () => getStartedSteps.find((step) => step.stepNumber === activeStep.value)?.emptyStateDescription,
  )

  const stepEmptyStateCTA = computed(
    () => getStartedSteps.find((step) => step.stepNumber === activeStep.value)?.emptyStateCTA,
  )

  const activeStepEntity = computed(() => getStartedSteps.find((step) => step.stepNumber === activeStep.value)?.entity)

  const togglePanelBody = () => {
    if (!allStepsCompleted.value) return
    showPanelBody.value = !showPanelBody.value
  }

  watch(showPanelBody, (value: boolean) => {
    if (value) {
      setTimeout(() => (rerenderKey.value = Math.random().toString(36)), 100)
    }
  })

  const setActiveStep = (stepNumber: number) => {
    if (selectableSteps.value.includes(stepNumber)) {
      activeStep.value = stepNumber
      if (activeStepEntity.value) {
        getStartedStepClicked(activeStep.value, activeStepEntity.value, stepIsCompleted.value(activeStep.value))
      }
    }
  }

  const setCompletedSteps = () => {
    completedSteps.value = []
    if (completedSettingCourseTrial.value) {
      completedSteps.value.push(1)
    }
    if (completedAtLeastOneLesson.value) {
      completedSteps.value.push(2)
    }
    if (completedAtLeastOneQuiz.value) {
      completedSteps.value.push(3)
    }
    if (totalQuestionsAnsweredPercentage.value === 100) {
      completedSteps.value.push(4)
    }
    if (allStepsCompleted.value) showPanelBody.value = false
  }

  const setSelectableSteps = () => {
    // the first step is selectable by default
    selectableSteps.value = [1]

    // a step is selectable if all steps preceding it are completed
    const inCompleteSteps: number[] = []
    for (let i = 1; i < 5; i++) {
      if (stepIsCompleted.value(i)) {
        selectableSteps.value.push(i)
      } else {
        inCompleteSteps.push(i)
      }
    }
    selectableSteps.value.push(inCompleteSteps[0])
    setActivePanelStep()
  }

  const setActivePanelStep = () => {
    if (allStepsCompleted.value) {
      activeStep.value = 1
    } else {
      // basically the first selectable step that is not completed
      const sortedSelectableSteps = selectableSteps.value.sort()
      const inCompleteSelectableSteps = sortedSelectableSteps.filter(function (obj) {
        return completedSteps.value.indexOf(obj) == -1
      })
      activeStep.value = inCompleteSelectableSteps[0]
    }
    if (activeStepEntity.value) {
      getStartedStepClicked(activeStep.value, activeStepEntity.value, stepIsCompleted.value(activeStep.value))
    }
  }

  const getOnboardingDetails = () => {
    UsersApi.getOnboardingDetails()
      .then((details) => {
        onboardingDetails.value = details
      })
      .finally(() => {
        setCompletedSteps()
        setSelectableSteps()
      })
  }

  watch(allStepsCompleted, (value) => {
    if (value) {
      UserModule.setShowGetStartedPanel(false)
    }
  })

  onMounted(() => {
    getOnboardingDetails()
  })
</script>

<style lang="postcss">
  .get-started-panel-wrapper {
    @apply ketch-mt-c30 ketch-z-[2] md:ketch-mt-c50;
    .get-started-panel {
      @apply ketch-px-c16 ketch-py-c15 ketch-bg-module-selector-background-color ketch-rounded-normal;
      @apply md:ketch-px-c25 md:ketch-py-c20;
      box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.05);
      > * {
        @apply ketch-text-module-selector-foreground-color;
      }
      .panel-header-info-completion-status {
        @apply ketch-flex ketch-items-center ketch-justify-between ketch-mb-c25 ketch-cursor-pointer;
        @apply ketch-flex-wrap md:ketch-mb-c45;
        &.not-showing-panel-body {
          @apply ketch-mb-0;
          .panel-header-info {
            .panel-title {
              @apply ketch-font-normal;
            }
          }
        }
        .panel-header-info {
          @apply ketch-flex ketch-flex-col ketch-space-y-c8;
          .panel-title {
            @apply ketch-font-bold;
          }
          .panel-description {
            @apply ketch-opacity-50;
          }
        }
        .completion-status {
          @apply ketch-mt-c10 md:ketch-mt-0;
          .completed-steps {
            @apply ketch-flex ketch-items-center ketch-bg-editor-primary-color ketch-text-editor-foreground-color;
            @apply ketch-space-x-c10 ketch-px-c10 ketch-rounded-large;
            h4 {
              @apply ketch-font-bold;
            }
          }
        }
      }
      .panel-body {
        @apply md:ketch-flex md:ketch-space-x-c25;
        .panel-body-steps {
          @apply ketch-flex ketch-flex-col ketch-space-y-c10;
          .get-started-step {
            @apply ketch-flex ketch-flex-col ketch-space-y-c10;
            @apply md:ketch-flex-row md:ketch-space-x-c25;
            .step-info-wrapper {
              @apply ketch-flex ketch-items-center;
              .step-info {
                @apply ketch-py-c5 ketch-px-c8 ketch-w-full ketch-flex ketch-items-center ketch-justify-between;
                @apply ketch-cursor-pointer ketch-border ketch-border-transparent ketch-rounded-normal md:ketch-w-[435px];
                &.active {
                  &.completed {
                    @apply ketch-border-editor-primary-color ketch-bg-editor-primary-color ketch-bg-opacity-[0.1];
                  }

                  &:not(.completed) {
                    @apply ketch-border-border-color ketch-bg-primary-text-color ketch-bg-opacity-[0.05];
                  }
                }
                &.completed {
                  .step-count-text {
                    .step-count {
                      @apply ketch-bg-editor-primary-color ketch-text-editor-foreground-color;
                    }
                  }
                }
                &:not(.selectable) {
                  @apply ketch-opacity-[0.3] ketch-cursor-default;
                }
                .step-count-text {
                  @apply ketch-flex ketch-items-center ketch-space-x-c10 md:ketch-space-x-c15;
                  .step-count {
                    @apply ketch-h-c25 ketch-w-c25 ketch-rounded-full ketch-bg-primary-color ketch-font-bold;
                    @apply ketch-text-primary-foreground-color ketch-flex ketch-items-center ketch-justify-center;
                  }
                  .step-text {
                    @apply ketch-flex-1;
                  }
                  .complete-step-icon {
                    @apply ketch-w-c15;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
</style>
