<template lang="pug">
.answer-response-wrapper
  img(:src='icon')
  h5 {{ $t('homepage.modalQuestionOfTheDayText') }}
  h2.answer-response {{ passed ? $t('homepage.correctlyAnswered') : $t('homepage.incorrectlyAnswered') }}
  .cta
    KetchUpButton.shut-down(@click.native='$emit("close")', v-if='!passed')
      h5 {{ $t('homepage.shutDown') }}
    KetchUpButton.primary(@click.native='confirmAction(passed)')
      h5 {{ passed ? $t('homepage.allRight') : $t('homepage.toTheLesson') }}
</template>

<script setup lang="ts">
  import { computed } from 'vue'
  import KetchUpButton from '@/components/common/KetchUpButton.vue'
  import useIcons from '@/composables/useIcons'
  import { useRouter } from 'vue-router/composables'
  import eventBus from '@/main'

  const props = defineProps({
    passed: {
      type: Boolean,
      required: true,
    },
    learningUnitRoute: {
      type: String,
      required: true,
    },
  })
  defineEmits(['close'])

  const router = useRouter()
  const { success, thumbsDown } = useIcons()

  const icon = computed((): string => {
    return props.passed ? success.value : thumbsDown.value
  })

  const confirmAction = (passed: boolean) => {
    if (passed) {
      eventBus.$emit('close-modal')
    } else {
      eventBus.$emit('close-modal')
      router.push(props.learningUnitRoute)
    }
  }
</script>

<style lang="postcss" scoped>
  .answer-response-wrapper {
    @apply ketch-w-full ketch-h-full ketch-flex ketch-flex-col ketch-items-center ketch-text-gray-600 ketch-my-[14px];
    img {
      @apply ketch-h-c100 ketch-w-c100 ketch-pb-c30;
    }
    .answer-response {
      @apply ketch-font-bold ketch-pb-c35 ketch-text-center;
    }
    .cta {
      @apply ketch-w-full ketch-flex ketch-items-center ketch-justify-center;
      .shut-down {
        @apply ketch-w-[140px] ketch-text-gray-600 ketch-bg-white ketch-mr-c20;
      }
      .primary {
        @apply ketch-w-[140px] ketch-border-none;
      }
    }
  }
</style>
