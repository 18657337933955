<template lang="pug">
.user-referral-code-wrapper(@click='openReferralCodeModal')
  h5 {{ $t('referrals.earnReferral') }}
</template>

<script setup lang="ts">
  import useSegment from '@/composables/useSegment'
  import { UserModule } from '@/store/modules/user'
  import eventBus from '@/main'

  const props = defineProps({
    referralCode: {
      type: String,
      required: true,
    },
  })

  const { referralCandyInviteCodeModalShown } = useSegment()
  const openReferralCodeModal = () => {
    referralCandyInviteCodeModalShown(UserModule.email)
    eventBus.$emit('show-modal', {
      modalContentComponent: 'ReferralCodeModal',
      cssClass: 'referral-code-modal',
      modalProps: {
        referralCode: props.referralCode,
      },
      modalCloseCallback: (callback: () => void) => {
        if (typeof callback === 'function') callback()
      },
    })
  }
</script>

<style lang="postcss" scoped>
  .user-referral-code-wrapper {
    @apply ketch-bg-background-color ketch-text-header-foreground-color ketch-cursor-pointer;
    @apply ketch-p-c5 ketch-rounded-[5px] ketch-text-center ketch-h-fit;
  }
</style>
