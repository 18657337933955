<template lang="pug">
.feedback-price-section
  .user-feedback-price
    .feedback-rating(v-if='userComment')
      h6.course-rating {{ $t('courses.userRating') }}
      .user-comment
        span.h3 {{ userComment }}
        span.quote ”
      StarRating(:rating='rating', color='var(--primary-color)')
    SVGRenderer.purchase-icon(
      :has-hover='false',
      :icon='purchaseIcon',
      fill-color='var(--primary-color)',
      secondary-fill-color='var(--primary-foreground-color)',
      stroke-color='var(--primary-foreground-color)',
      v-if='isPurchased'
    )
    .price(v-else-if='productAmount && canBuyCourses')
      h5.has-discount(v-if='discount') {{ discountValue }}
      h3.actual-price(v-if='productAmount') {{ currencyFormatDE(productAmount) }}
</template>

<script setup lang="ts">
  import { computed } from 'vue'
  import SVGRenderer from '@/components/common/SVGRenderer.vue'
  import StarRating from '@/components/common/StarRating.vue'
  import useIcons from '@/composables/useIcons'
  import useCommonMixin from '@/composables/useCommonMixin'
  import useCourse from '@/composables/useCourse'
  import { useRoute } from 'vue-router/composables'

  const props = defineProps({
    rating: {
      type: Number,
      required: true,
    },
    userComment: {
      type: String,
      required: true,
    },
    isPurchased: {
      type: Boolean,
      required: true,
    },
    productAmount: {
      type: Number,
      required: true,
    },
  })

  const route = useRoute()
  const { purchaseIcon } = useIcons()
  const { currencyFormatDE, productDiscountValue } = useCommonMixin()
  const { canBuyCourses } = useCourse()

  const discount = computed(() => {
    if (route.params.discount) return Number(route.params.discount)
    return productDiscountValue
  })

  const discountValue = computed(() => {
    const amount = props.productAmount || 0
    return currencyFormatDE(amount + Number(discount.value) * amount)
  })
</script>

<style lang="postcss" scoped>
  .feedback-price-section {
    @apply ketch--mx-c20 ketch-mb-c20 ketch-space-y-c10;
    .user-feedback-price {
      @apply ketch-flex ketch-items-end ketch-justify-between ketch-px-c20 ketch-space-x-c10;
      .price {
        @apply ketch-whitespace-nowrap;
        .has-discount {
          @apply ketch-text-secondary-text-color ketch-line-through;
        }
      }
    }
    .course-price-wrapper {
      @apply ketch-px-c20;
      .purchase-icon {
        @apply ketch-mr-c10;
      }
    }
  }
</style>
