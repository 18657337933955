<template lang="pug">
.coupon
  template(v-if='isShowingCouponBox')
    .coupon-label
      h6.text {{ $t('checkout.coupon') }}
      h6.error(v-if='couponResponse && couponResponse.error') {{ $t('checkout.invalidCouponCode') }}
    .coupon-input(v-if='!couponResponse || couponResponse.error')
      input(
        :placeholder='String($t("checkout.couponCode"))',
        @keyup.enter='applyCouponCode',
        name='coupon',
        ref='couponInput',
        v-model='couponModel',
        v-tooltip.bottom='{ content: $t("referrals.canNotUseYourOwnReferCode"), show: !canUseAnyCode, trigger: "manual" }'
      )
      .h5.apply(@click.stop='applyCouponCode')
        LoadingSpinner(v-if='isApplyingCoupon')
        span(v-else) Apply
    .coupon-success.h5(v-if='couponResponse && couponResponse.valid')
      span {{ couponModel }}
      span ({{ couponValue }}%)

  h5(@click='showCouponInput', v-else) {{ $t('checkout.addCoupon') }}
</template>

<script setup lang="ts">
  import CheckoutApi from '@/services/api/CheckoutApi'
  import { computed, ref, watch, nextTick } from 'vue'
  import { AxiosError } from 'axios'
  import LoadingSpinner from '@/components/common/LoadingSpinner.vue'
  import { UserModule } from '@/store/modules/user'
  import type { Coupon as ICoupon } from '@/services/interfaces/Checkout'
  import type { Nullable } from '@/services/interfaces/Content'
  import type { PropType } from 'vue'

  const emit = defineEmits(['input'])

  const props = defineProps({
    value: {
      type: String,
      default: '',
    },
    courseProductTitle: {
      type: String,
      required: true,
    },
    courseFeedbackEmails: {
      type: Array as PropType<string[]>,
      required: true,
    },
  })

  const isApplyingCoupon = ref(false)
  const isShowingCouponBox = ref(false)
  const couponResponse = ref(null as Nullable<ICoupon>)
  const canUseAnyCode = ref(true)

  const couponInput = ref(null as HTMLInputElement | null)

  defineExpose({ couponResponse })
  const showCouponInput = () => {
    isShowingCouponBox.value = true
    nextTick(() => {
      couponInput.value!.focus()
    })
  }

  const couponModel = computed({
    get(): string {
      return props.value
    },
    set(value: string) {
      emit('input', value)
    },
  })

  const couponValue = computed(() => {
    if (couponResponse.value?.percentOff) return Math.round(couponResponse.value.percentOff * 100)
    return couponResponse.value?.amountOff || 0
  })

  const referrerCode = computed(() => UserModule.userReferralCode)

  watch(couponModel, () => {
    nextTick(() => {
      canUseAnyCode.value = true
    })
  })

  const applyCouponCode = () => {
    if (referrerCode.value === props.value) {
      canUseAnyCode.value = false
      return
    }
    isApplyingCoupon.value = true
    couponResponse.value = null
    let transformedCode = props.value
    if (props.value?.startsWith('FRIEND-')) {
      transformedCode = props.value?.split('FRIEND-')[1]
    }
    CheckoutApi.couponDetails(transformedCode)
      .then((coupon) => {
        couponResponse.value = coupon
      })
      .catch((err: AxiosError) => {
        couponResponse.value = { ...((err.response?.data as any) || {}) }
      })
      .finally(() => {
        isApplyingCoupon.value = false
      })
  }
</script>

<style lang="postcss" scoped>
  .coupon {
    @apply ketch-flex ketch-flex-col;
    .coupon-label {
      @apply ketch-flex ketch-justify-between ketch-mb-c8;
      .text {
        @apply ketch-text-secondary-text-color ketch-font-bold;
      }
      .error {
        @apply ketch-text-red-500 ketch-font-bold;
      }
    }
    .coupon-input {
      @apply ketch-border ketch-border-border-color ketch-rounded-normal ketch-flex ketch-justify-between;
      .apply {
        @apply ketch-inline-flex ketch-items-center ketch-px-c15 ketch-border-l;
        @apply ketch-border-gray-450 ketch-rounded-normal ketch-cursor-pointer ketch-h-c40;
      }
      input {
        @apply ketch-w-c150 ketch-text-primary-text-color;
      }
    }
    .coupon-success {
      @apply ketch-space-x-c5;
    }
    h5:not(.apply) {
      @apply ketch-underline ketch-cursor-pointer;
    }
    input {
      @apply ketch-h-c40 ketch-px-c15 ketch-py-c12 ketch-bg-transparent;
      @apply ketch-outline-none ketch-text-primary-text-color;
    }
  }
</style>
