<template lang="pug">
.referral-code-modal
  h3.title-with-underline {{ $t('referrals.referralModalTitle') }}
  .referral-code
    h5.code {{ referralCode }}
    h6 {{ $t('referrals.yourCode') }}
  .referral-code-info
    h5(:key='index', v-for='(item, index) in infoItems') {{ `${index + 1}. ` + item }}
  KetchUpButton.primary(@click.native='copyReferralCode')
    h5 {{ $t('referrals.copyCodeAndCloseWindow') }}
</template>

<script setup lang="ts">
  import { onMounted } from 'vue'
  import KetchUpButton from '@/components/common/KetchUpButton.vue'
  import { useClipboard } from '@vueuse/core'
  import UsersApi from '@/services/api/UsersApi'
  import useSegment from '@/composables/useSegment'
  import { UserModule } from '@/store/modules/user'
  import eventBus from '@/main'
  import useI18n from '@/composables/useI18n'

  const props = defineProps({
    referralCode: {
      type: String,
      required: true,
    },
  })

  const { copy, copied } = useClipboard()
  const { referralCandyInviteCodeCopied } = useSegment()
  const { translateString } = useI18n()

  const infoItems = [
    translateString('referrals.copyYourReferralCode'),
    translateString('referrals.recommendUs'),
    translateString('referrals.yourFriendsWillReceive'),
    translateString('referrals.youGet20FromUs'),
  ]

  const copyReferralCode = () => {
    copy(props.referralCode)
    if (copied) {
      referralCandyInviteCodeCopied(UserModule.email, props.referralCode)
      eventBus.$toasted.success(translateString('copied'), { duration: 1000 })
    }
    eventBus.$emit('close-modal')
  }

  onMounted(() => {
    const code = props.referralCode.split('-')
    UsersApi.createReferralCodeInStripe(code[1])
  })
</script>

<style lang="postcss" scoped>
  .referral-code-modal {
    .referral-code {
      @apply ketch-flex ketch-justify-center ketch-items-center ketch-flex-col;
      @apply ketch-bg-background-color ketch-py-c10 ketch-space-y-c5;
      .code {
        @apply ketch-uppercase ketch-font-bold;
      }
    }
    .referral-code-info {
      @apply ketch-pt-c15 ketch-pb-c30 ketch-space-y-c10;
    }
    button {
      @apply ketch-w-full;
    }
  }
</style>
