<template lang="pug">
.checkout-modal(:class='{ done: checkoutDone }')
  .save-25-percent(
    @click='routeToPackageCheckout',
    v-if='course && showCustomCheckoutTitle && showPackageCTA && course.courseBundle'
  )
    h4 {{ $t('save25Percent') }}
    h5 {{ $t('withThePackageTitle', { packageTitle: course.courseBundle.title }) }}
  h5.buying-for(
    v-html='$t("homepage.buyingCourseFor", { name: user.name })',
    v-if='!(checkoutDone || checkoutCancel) && user.name'
  )
  h5.success-message(v-if='checkoutDone') {{ $t('homepage.assignedCourses.successfullyPurchasedCourse') }}
  h5.cancel-message(v-else-if='checkoutCancel') {{ $t('checkout.cancel') }}
  Checkout(
    :in-modal='true',
    :package-checkout='isPackageCheckout',
    :user='user',
    @done='checkoutDone = true',
    @show-package-c-t-a='showPackageCTA = $event',
    v-else
  )
  .button-wrapper(v-if='showPreviousCTA')
    KetchUpButton.quaternary(@click.native='openAssignCoursesModal(user)')
      span <-
      h5 {{ $t('previous') }}
</template>

<script setup lang="ts">
  import { onBeforeUnmount, onMounted, ref, watch } from 'vue'
  import type { PropType } from 'vue'
  import Checkout from '@/components/checkout/Checkout.vue'
  import KetchUpButton from '@/components/common/KetchUpButton.vue'
  import useCommonMixin from '@/composables/useCommonMixin'
  import { CourseModule } from '@/store/modules/course'
  import useCourse from '@/composables/useCourse'
  import { useRoute, useRouter } from 'vue-router/composables'
  import eventBus from '@/main'
  import useI18n from '@/composables/useI18n'

  const props = defineProps({
    user: {
      type: Object as PropType<{ id: string; name: string; email: string }>,
      required: true,
    },
    isPackageCheckout: {
      type: Boolean,
      default: false,
    },
    showPreviousCTA: {
      type: Boolean,
      default: true,
    },
    inModuleOrLessonView: {
      type: Boolean,
      default: false,
    },
    showCustomCheckoutTitle: {
      type: Boolean,
      default: false,
    },
  })

  const route = useRoute()
  const router = useRouter()
  const checkoutDone = ref(false)
  const checkoutCancel = ref(false)
  const { openAssignCoursesModal } = useCommonMixin()
  const { course } = useCourse()
  const { translateString } = useI18n()
  const showPackageCTA = ref(false)

  const routeToPackageCheckout = () => {
    eventBus.$emit('close-modal')
    router
      .push({
        name: 'PackageCheckout',
        params: { packageId: course.value!.courseBundle!.courseBundleId },
      })
      .catch(() => {
        return
      })
  }

  watch(checkoutDone, (value: boolean) => {
    if (value) {
      eventBus.$emit('update-modal-title', translateString('homepage.assignedCourses.successfullyBought'))
      if (props.inModuleOrLessonView) {
        window.location.reload()
      } else {
        CourseModule.getMemberCourses({ auth0Id: props.user.id })
      }
    }
  })

  onMounted(() => {
    if (route.query.status) {
      const status = (route.query.status as string)?.split('?')[0]
      if (status === 'success') {
        checkoutDone.value = true
      } else if (status === 'cancel') {
        checkoutCancel.value = true
      }
      router.replace({ query: {} })
    }
  })

  onBeforeUnmount(() => {
    if (!props.inModuleOrLessonView) {
      CourseModule.setCourseBundle(null)
      CourseModule.setCourse(null)
    }
  })
</script>

<style lang="postcss">
  .checkout-modal {
    @apply ketch-overflow-hidden;
    &:not(.done) {
      @apply ketch-pb-c60;
    }
    .save-25-percent {
      @apply ketch-cursor-pointer;
      h5 {
        @apply ketch-underline;
      }
    }
    .checkout-form {
      @apply ketch-max-w-full;
    }
    .buying-for {
      @apply ketch-mb-c20;
      span {
        @apply ketch-font-bold;
      }
    }
    .button-wrapper {
      @apply ketch-bg-white ketch-absolute ketch-bottom-0 ketch-left-0 ketch-right-0;
      @apply ketch-pl-c30 ketch-h-c50 ketch-z-[2];
      button {
        @apply ketch-h-[44px] ketch-flex ketch-items-center ketch-space-x-c10 ketch-p-0;
        > * {
          @apply ketch-no-underline;
        }
      }
    }
  }
</style>
