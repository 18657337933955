<template lang="pug">
.footer.h5(:class='{ "top-margin": $route.name === "Start" }')
  span {{ footerText }}
  a(:href='`mailto:${footerEmailAddress}`') {{ footerEmailAddress }}
</template>

<script setup lang="ts">
  import { computed } from 'vue'
  import { UserModule } from '@/store/modules/user'

  const footerText = computed(() => UserModule.currentCompany?.footerText)
  const footerEmailAddress = computed(() => UserModule.currentCompany?.footerEmailAddress)
</script>

<style lang="postcss" scoped>
  .footer {
    @apply ketch-flex ketch-justify-center ketch-border-t ketch-border-border-color ketch-w-screen;
    @apply ketch-h-c40 ketch-py-c10 ketch-space-x-c5 ketch-left-0 ketch-z-[1];
    a {
      @apply ketch-underline ketch-cursor-pointer;
    }
    &.top-margin {
      @apply ketch-mt-c60;
    }
  }
</style>
