<template lang="pug">
.lesson-script-container
  LoadingSpinner(v-if='loadingScripts')
  .lesson-script(ref='elRef', v-html='content')
</template>

<script setup lang="ts">
  import { onMounted, ref } from 'vue'
  import LoadingSpinner from '@/components/common/LoadingSpinner.vue'

  defineProps({
    content: {
      type: String,
      required: true,
    },
  })

  const elRef = ref(null as HTMLDivElement | null)
  const loadingScripts = ref(true)

  onMounted(() => {
    if (elRef.value) {
      resolveScripts()
    }
  })

  const addScript = (src: string, textContent: string, callback?: () => void) => {
    const s = document.createElement('script')
    if (callback) s.onload = callback
    if (textContent) s.textContent = textContent
    if (src) s.setAttribute('src', src)
    document.body.appendChild(s)
  }

  const resolveScripts = async () => {
    const promisedExternalScripts: Promise<string>[] = []
    const deferredInlineScripts: string[] = []
    const scripts = elRef.value!.querySelectorAll('script')
    ;[...scripts].forEach((script) => {
      if (script.src) {
        promisedExternalScripts.push(
          new Promise((resolve) => {
            addScript(script.src, script.textContent as string, () => {
              resolve(script.src)
            })
          }),
        )
      } else {
        deferredInlineScripts.push(script.textContent as string)
      }
      script.remove()
    })
    await Promise.all(promisedExternalScripts)
    deferredInlineScripts.forEach((script) => {
      addScript('', script)
    })
    loadingScripts.value = false
  }
</script>

<style lang="postcss" scoped>
  .lesson-script-container {
    @apply ketch-w-full ketch-h-[fit-content] ketch-py-c40;
  }
</style>
