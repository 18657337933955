<template lang="pug">
.speak-to-expert-modal
  h2.title-with-underline {{ $t('courses.talkToExpert.speakToMichael') }}
  h5.helper-text {{ $t('courses.talkToExpert.letMichaelAdviseYou') }}
  .expert-avatar-phone
    img.expert(:src='michaelAvatar')
    .phone
      img(:src='phoneIcon')
      h5.tel-number
        span {{ $t('courses.talkToExpert.callDirectly') }}
        a(href='tel:030 22052389') 030 22052389
  h6.if-unavailable {{ $t('courses.talkToExpert.leaveYourNumber') }}
</template>

<script setup lang="ts">
  import useIcons from '@/composables/useIcons'

  const { michaelAvatar, phoneIcon } = useIcons()
</script>

<style lang="postcss" scoped>
  .speak-to-expert-modal {
    .title-with-underline {
      @apply ketch-pb-c10 ketch-mb-c15;
    }
    .helper-text {
      @apply ketch-mb-c30;
    }
    .expert-avatar-phone {
      @apply ketch-w-full ketch-flex ketch-flex-col ketch-items-center ketch-justify-center ketch-mb-c30;
      .expert {
        @apply ketch-flex ketch-items-center ketch-w-[105px] ketch-h-[105px];
        @apply ketch-border ketch-border-[3px] ketch-border-primary-color ketch-rounded-full;
      }
      .phone {
        @apply ketch-rounded-large ketch-bg-[#F0F0F0] ketch-flex ketch-items-center ketch-space-x-c10;
        @apply ketch-py-c10 ketch-px-c20 ketch--mt-c10;
        h5 {
          > a {
            @apply ketch-ml-c5 ketch-font-bold;
          }
        }
      }
    }
    .if-unavailable {
      @apply ketch-text-secondary-text-color;
    }
  }
</style>
