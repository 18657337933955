<template lang="pug">
.lesson-loader.lesson-wrapper
  .lesson-title
    PlaceholderShimmer(:animate='true', height='35px')
  .intro-text
    PlaceholderShimmer(:animate='true', height='30px')
  .lesson-content
    PlaceholderShimmer(:animate='true', height='150px')
  .outro-text
    PlaceholderShimmer(:animate='true', height='30px')
  .cta-wrapper
    .cta
      .bookmark-lesson
        PlaceholderShimmer(:animate='true', height='35px', width='30px')
      .next-lesson
        PlaceholderShimmer(:animate='true', height='35px', width='200px')
  .notes-feedback
    hr.separator
    .notes
      .your-notes
        PlaceholderShimmer(:animate='true', height='25px', width='150px')
      .message-box
        PlaceholderShimmer(:animate='true', :width='isMobileDevice ? "auto" : "400px"', height='45px')
    .feedback-container-wrapper
      PlaceholderShimmer(:animate='true', :height='isMobileDevice ? "85px" : "130px"')
</template>

<script setup lang="ts">
  import PlaceholderShimmer from '@/components/common/PlaceholderShimmer.vue'
  import useBreakpoint from '@/composables/useBreakpoint'

  const { isMobileDevice } = useBreakpoint()
</script>

<style lang="postcss" scoped>
  .lesson-loader {
    @apply md:ketch-mt-c15;
    .lesson-title {
      @apply ketch-max-w-[400px];
    }
    .cta-wrapper {
      .cta {
        .next-lesson {
          @apply ketch-border-none;
        }
      }
    }
    .notes-feedback {
      .notes {
        @apply ketch-flex ketch-flex-col ketch-justify-between md:ketch-flex-row;
      }
    }
  }
</style>
