<template lang="pug">
.progress-bar-wrapper(:class='{ "slim-with-no-border": slimWithNoBorder }')
  .progress-bar(:style='{ width: `${percentage}%` }')
  .badge-container(v-if='showBadge')
    .badge(:style='{ left: `calc(${percentage}% - ${leftWidth}px` }', ref='badge')
      h5 {{ actualValue }}
</template>

<script setup lang="ts">
  import { onMounted, ref, watch } from 'vue'

  const props = defineProps({
    percentage: {
      required: true,
      type: Number,
    },
    actualValue: Number,
    showBadge: Boolean,
    slimWithNoBorder: Boolean,
  })
  const emit = defineEmits(['badge-left-width'])

  const badge = ref(null as HTMLDivElement | null)
  const leftWidth = ref(20)
  const timerId = ref(0)

  const setLeftWidth = () => {
    timerId.value = window.setTimeout(() => {
      if (badge.value) {
        leftWidth.value = Math.round(badge.value.clientWidth / 2)
        emit('badge-left-width', leftWidth.value)
      }
    }, 300)
  }

  onMounted(() => {
    setLeftWidth()
  })

  watch(
    () => props.actualValue,
    () => {
      setLeftWidth()
    },
  )
</script>

<style lang="postcss" scoped>
  .progress-bar-wrapper {
    @apply ketch-w-c300 ketch-h-c10 ketch-rounded-normal ketch-border ketch-border-gray-700 ketch-bg-white ketch-relative;
    @apply dark:ketch-bg-gray-600 dark:ketch-border-white;
    .progress-bar {
      @apply ketch-absolute ketch-h-full ketch-rounded-normal ketch-bg-gray-700;
      @apply ketch-ease-in-out ketch-duration-1000 ketch-transition-width;
    }
    .badge-container {
      @apply ketch-relative;
      .badge {
        @apply ketch-bg-black ketch-h-c20 ketch-min-w-[35px] ketch-rounded-large;
        @apply ketch-flex ketch-items-center ketch-justify-center ketch-px-c5 ketch-absolute ketch-top-c10;
        &:before {
          @apply ketch-absolute ketch-w-c2 ketch-h-c5 ketch--top-c5 ketch-bg-black;
          content: '';
        }
        h5 {
          @apply ketch-text-white ketch-font-bold;
        }
      }
    }
    &.slim-with-no-border {
      @apply ketch-h-c5 ketch-border-none ketch-bg-background-color;
      .progress-bar {
        @apply ketch-bg-editor-primary-color;
      }
    }
  }
  .progress-bar-wrapper .progress-bar {
    @apply dark:ketch-bg-white;
  }
</style>
