<template lang="pug">
.upsell-modal
  CourseItem(:course='recommendedCourse', :discount='discount', :used-in-upsell-modal='true')
  .cta-container
    h6 {{ $t('upsellSection.modal.underDevelopment') }}
    KetchUpButton.primary(:disabled='submittingInterestedCourse', @click.native='submitInterestedCourse')
      h5 {{ $t('upsellSection.modal.notifyMe') }}
</template>

<script setup lang="ts">
  import { onMounted, ref } from 'vue'
  import CourseItem from '@/components/course/CourseItem.vue'
  import KetchUpButton from '@/components/common/KetchUpButton.vue'
  import useSegment from '@/composables/useSegment'
  import CuratedContentApi from '@/services/api/CuratedContentApi'
  import eventBus from '@/main'
  import useI18n from '@/composables/useI18n'
  import type { CourseItem as RecommendedCourseItem } from '@/services/interfaces/Course'

  const props = defineProps({
    recommendedCourse: {
      type: Object as () => RecommendedCourseItem,
      required: true,
    },
    discount: {
      type: Number,
      default: 0,
    },
  })
  const emit = defineEmits(['close'])
  const { recommendedCourseClicked, recommendedCourseGetNotified } = useSegment()
  const { translateString } = useI18n()
  const submittingInterestedCourse = ref(false)

  const submitInterestedCourse = () => {
    submittingInterestedCourse.value = true
    CuratedContentApi.postInterestedCourse(props.recommendedCourse.id)
      .then(() => {
        recommendedCourseGetNotified(props.recommendedCourse.id, props.recommendedCourse.title)
        eventBus.$toasted.success(translateString('upsellSection.modal.notifySuccessMessage'))
      })
      .finally(() => {
        submittingInterestedCourse.value = false
        emit('close')
      })
  }

  onMounted(() => {
    recommendedCourseClicked(props.recommendedCourse.id, props.recommendedCourse.title)
  })
</script>

<style lang="postcss" scoped>
  .upsell-modal {
    @apply ketch-flex ketch-flex-col ketch-space-y-c30;
    .cta-container {
      @apply ketch-flex ketch-items-center ketch-justify-between;
      .primary {
        @apply ketch-w-auto ketch-px-c20 ketch-outline-none;
        h5 {
          @apply ketch-font-bold;
        }
      }
    }
  }
</style>
