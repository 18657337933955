import { render, staticRenderFns } from "./CompanyCourses.vue?vue&type=template&id=ef1d44a6&lang=pug"
import script from "./CompanyCourses.vue?vue&type=script&setup=true&lang=ts"
export * from "./CompanyCourses.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./CompanyCourses.vue?vue&type=style&index=0&id=ef1d44a6&prod&lang=postcss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports