<template lang="pug">
.course-start-page(v-if='course')
  PlaceholderShimmer(
    :animate='true',
    height='400px',
    v-if='isMobileDevice ? course.cardTeaserImageUrl : course.teaserImageUrl'
  )
    template(v-slot:default='{ isLoadingResource, onResourceLoaded }')
      img.teaser-image(
        :class='{ "shimmer-asset": isLoadingResource }',
        :src='isMobileDevice ? course.cardTeaserImageUrl : course.teaserImageUrl',
        @load='onResourceLoaded'
      )
  VideoOrImageRenderer(
    :file-type='""',
    :video-url='course.teaserVideoUrl',
    v-else-if='course.teaserVideoUrl',
    video-only
  )
  h1.first-name Hey {{ userFirstName }}!
  h5.completed-modules-text(
    v-html='$t("courses.completionScoreText", { completedModules: completedModules(course), totalModules: totalCourseGroupModules })',
    v-if='totalCourseGroupModules !== null'
  )
  h5.remaining-modules-text(v-if='remainingModules > 0') {{ $t('courses.remainingModulesText', { remainingModulesText: remainingModules }) }}
  .progress-bar-module-container(v-if='course.courseGroups.length')
    .course-group-progress-wrapper(:key='courseGroup.id', v-for='courseGroup in course.courseGroups')
      .progress-bar-cert(:key='courseGroup.id', v-if='hasCourseModules(courseGroup.id)')
        CourseProgressBar(:course-group-id='courseGroup.id', :course-id='course.id')
        .certificate
          img(:src='courseCertIcon')
          h6 {{ $t('certificate') }}
        .course-group-name
          h5 {{ courseGroup.name }}
      CourseModuleStatusContainer(:course-group-id='courseGroup.id', v-if='hasCourseModules(courseGroup.id)')
  .progress-bar-module-container(v-else)
    .progress-bar-cert
      CourseProgressBar(:course-id='course.id')
      .certificate
        img(:src='courseCertIcon')
        h6 {{ $t('certificate') }}
    CourseModuleStatusContainer
  template(v-if='false')
    h2.title-header {{ $t('courses.startPageTitle') }}
    CourseDescription(:description='course?.description ?? ""')
</template>

<script setup lang="ts">
  import { CourseModule } from '@/store/modules/course'
  import { UserModule } from '@/store/modules/user'
  import { computed, onMounted } from 'vue'
  import PlaceholderShimmer from '@/components/common/PlaceholderShimmer.vue'
  import CourseProgressBar from '@/components/course/CourseProgressBar.vue'
  import useIcons from '@/composables/useIcons'
  import CourseDescription from '@/components/course/CourseDescription.vue'
  import useCourse from '@/composables/useCourse'
  import useSegment from '@/composables/useSegment'
  import CourseModuleStatusContainer from '@/components/course/CourseModuleStatusContainer.vue'
  import useBreakpoint from '@/composables/useBreakpoint'
  import VideoOrImageRenderer from '@/components/course/VideoOrImageRenderer.vue'

  const { course, completedModules, remainingModules } = useCourse()
  const { courseCertIcon } = useIcons()
  const { trackPage, pageViewed } = useSegment()
  const { isMobileDevice } = useBreakpoint()

  const userFirstName = computed(() => UserModule.firstName)

  const hasCourseModules = computed(() => {
    return (courseGroupId: string): number => {
      return CourseModule.numberOfCourseModules(course.value?.id || '', courseGroupId)
    }
  })

  const totalCourseGroupModules = computed(() => {
    return CourseModule.totalCourseGroupModules(
      course.value?.id || '',
      course.value?.courseGroups?.map((c) => c.id) || [],
    )
  })

  onMounted(() => {
    trackPage('Course Start')
    pageViewed('Course Start')
  })
</script>

<style lang="postcss">
  .course-start-page {
    img.teaser-image {
      @apply ketch-w-full;
    }
    .first-name {
      @apply ketch-font-big-daily-short ketch-mt-c30 ketch-mb-c20;
    }
    .completed-modules-text {
      span {
        @apply ketch-font-bold;
      }
    }
    .course-module-status-container {
      @apply ketch-pb-c30 ketch-mb-c40;
    }
    .progress-bar-cert {
      @apply ketch-flex ketch-space-x-c10 ketch-my-c30 ketch-relative;
      .certificate {
        @apply ketch-inline-flex ketch-flex-col ketch-items-center ketch-space-y-c5;
        img {
          @apply ketch-w-[32px] ketch-h-[32px];
        }
      }
      .course-progress-bar {
        @apply ketch--mt-c20;
      }
      .course-group-name {
        @apply ketch-absolute ketch--left-c10 ketch-bottom-0;
        h5 {
          @apply ketch-text-secondary-color ketch-font-bold;
        }
      }
    }
    .title-header {
      @apply ketch-pb-c10 ketch-mb-c20 ketch-relative;
      &:after {
        @apply ketch-absolute ketch-h-c2 ketch-w-c50 ketch-bg-primary-color ketch-left-0 ketch-bottom-0;
        content: '';
      }
    }
  }
</style>
