import { render, staticRenderFns } from "./FeedbackModal.vue?vue&type=template&id=7d201d42&lang=pug"
import script from "./FeedbackModal.vue?vue&type=script&setup=true&lang=ts"
export * from "./FeedbackModal.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./FeedbackModal.vue?vue&type=style&index=0&id=7d201d42&prod&lang=postcss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports