<template lang="pug">
.trainings-menu-dropdown-loader.trainings-menu-dropdown(@mouseleave='$emit("hide-dropdown")')
  .sections-menu-wrapper
    .trainings-menu-title
      PlaceholderShimmer(animate, height='20px', width='150px')
    .training-sections
      .training-section(:key='item', v-for='item in 3')
        .section-details
          .random-icon
            PlaceholderShimmer(animate, height='25px', width='25px')
          .name-description
            PlaceholderShimmer(animate, height='20px')
            PlaceholderShimmer(animate, height='80px')
  .trainings-menu
    .purchased-trainings-wrapper
      .trainings-menu-title
        PlaceholderShimmer(animate, height='20px', width='150px')
      .purchased-trainings
        .purchased-training(:key='item', v-for='item in 3')
          .training-image(v-if='isDesktopDevice')
            PlaceholderShimmer(animate, height='80px', width='145px')
          .training-title
            PlaceholderShimmer(animate, height='80px')
</template>

<script setup lang="ts">
  import PlaceholderShimmer from '@/components/common/PlaceholderShimmer.vue'
  import useBreakpoint from '@/composables/useBreakpoint'

  defineEmits(['hide-dropdown'])
  const { isDesktopDevice } = useBreakpoint()
</script>

<style lang="postcss">
  .trainings-menu-dropdown-loader {
    .sections-menu-wrapper,
    .trainings-menu {
      @apply ketch-w-full;
    }
    .sections-menu-wrapper {
      @apply ketch-max-w-[320px];
      .training-sections {
        .training-section {
          .section-details {
            .random-icon {
              @apply ketch-w-c25;
            }
            .name-description {
              @apply ketch-w-full;
            }
          }
        }
      }
    }
    .trainings-menu {
      .purchased-trainings-wrapper {
        .purchased-trainings {
          .purchased-training {
            .training-title {
              @apply ketch-w-full;
            }
          }
        }
      }
    }
  }
</style>
