<template lang="pug">
#app(:class='{ "white-bg": $route.meta?.whiteBackground }', :key='appKey')
  template(v-if='!loadingApp')
    .header-wrapper(
      :class='{ fluid: isMobileDevice && isCourseLayoutRoute }',
      class='!ketch-min-h-fit',
      ref='appHeader',
      v-if='!iframeMode && !$route.meta?.hideNav'
    )
      .company-header-banners
        MaintenanceBanner(@maintenance-banner-height='maintenanceBannerHeight = $event', v-if='showMaintenanceBanner')
        SaleBanner(@sale-banner-height='saleBannerHeight = $event', v-if='showSaleBanner')
        transition(mode='out-in', name='fade')
          GetYourOwnAcademyBanner(
            @academy-banner-height='academyBannerHeight = $event',
            @hide-banner='showGetYourOwnAcademyBanner = false',
            v-if='showGetYourOwnAcademyBanner && hasPurchasedAtLeastACourse && false'
          )
      KetchUpHeader(
        :logo-url='logoUrl || ""',
        @hide-widgets='showOrHideWidgets("hide")',
        @show-widgets='showOrHideWidgets("show")',
        v-if='showKetchupHeader'
      )
    .company-header.parent-component-wrapper(
      class='!ketch-min-h-fit',
      ref='companyHeader',
      v-if='$route.meta?.showCompanyHeader'
    )
      CompanyHeaderSection
    .admin-nav.parent-component-wrapper(
      :style='{ top: `${companyHeaderPaddingTop}px` }',
      class='!ketch-min-h-fit',
      ref='adminNav',
      v-if='showAdminNav'
    )
      AdminNav
    EditorButtons(ref='editorButtons', v-if='inEditorMode && isLargeDesktop && $route.name === "Trainings"')
    LastUserActivity(v-if='$route.name === "Trainings"')
    .parent-component-wrapper(class='!ketch-min-h-fit', v-if='showGetStartedPanelOrCourseTrials')
      GetStartedPanel(ref='getStartedPanel', v-if='showGetStartedPanel')
      CourseTrials(ref='courseTrials', v-else-if='showCourseTrialsPanel')
    #component-markup.components-wrapper.parent-component-wrapper(
      :class='{ "center-viewport": $route.meta?.centerComponent }'
    )
      router-view.component-spacing(
        :class='{ "components-view": isMobileDevice ? true : !$route.meta?.noPadding, loader: showFullscreenLoader, fluid: isMobileDevice && isCourseLayoutRoute }'
      )
    FullScreenLoader(v-if='showFullscreenLoader')
    Confetti(v-if='showConfetti')
    Modal(
      :class='[cssClass]',
      :close-modal-on-outside-click='closeModalOnOutsideClick',
      :hide-close-button='hideCloseButton',
      :hide-container='hideContainer',
      :title='modalTitle',
      @close='closeModal',
      v-if='showModal'
    )
      component(
        :error-callback='modalSuccessCallback',
        :is='modalContentComponent',
        :success-callback='modalSuccessCallback',
        @click.native.prevent,
        @close='closeModal',
        v-bind='modalProps'
      )
  CustomCompanyWidgets(:calendly-widget-height='calendlyWidgetHeight', v-if='showCustomCompanyWidgets')
</template>

<script lang="ts">
  import { computed, defineComponent, onMounted, onUnmounted, ref, watch } from 'vue'
  import KetchUpHeader from '@/components/KetchUpHeader.vue'
  import KetchUpFooter from '@/components/KetchUpFooter.vue'
  import Modal from '@/components/common/Modal.vue'
  import IframePopup from '@/components/modals/IframePopup.vue'
  import ConfirmationModal from '@/components/modals/ConfirmationModal.vue'
  import YoutubeModal from '@/components/modals/YoutubeModal.vue'
  import UpsellModal from '@/components/modals/UpsellModal.vue'
  import UpgradePopup from '@/components/personal-landing-page/UpgradePopup.vue'
  import CheckoutProgressModal from '@/components/modals/CheckoutProgressModal.vue'
  import InviteUserModal from '@/components/modals/InviteUserModal.vue'
  import AssignUserCourseModal from '@/components/modals/AssignUserCourseModal.vue'
  import AssignGroupCourseModal from '@/components/modals/AssignGroupCourseModal.vue'
  import AnswerQuestionResponseModal from '@/components/modals/AnswerQuestionResponseModal.vue'
  import BackgroundTile from '@/components/common/BackgroundTile.vue'
  import LessonModal from '@/components/modals/LessonModal.vue'
  import CourseExamTimeoutModal from '@/components/modals/CourseExamTimeoutModal.vue'
  import FeedbackModal from '@/components/modals/FeedbackModal.vue'
  import FullScreenLoader from '@/components/common/FullScreenLoader.vue'
  import LessonSlideshowModal from '@/components/course/LessonSlideshowModal.vue'
  import LoadingSpinner from '@/components/common/LoadingSpinner.vue'
  import StartCourseForFreeModal from '@/components/modals/StartCourseForFreeModal.vue'
  import SpeakToExpertModal from '@/components/modals/SpeakToExpertModal.vue'
  import BrowserExtensionModal from '@/components/modals/BrowserExtensionModal.vue'
  import CompanyOnboardingModal from '@/components/modals/CompanyOnboardingModal.vue'
  import GetStartedOnboardingModal from '@/components/modals/GetStartedOnboardingModal.vue'
  import TrainingsMenuDropdownModal from '@/components/modals/TrainingsMenuDropdownModal.vue'
  import AssignCourseGroupModal from '@/components/modals/AssignCourseGroupModal.vue'
  import SkgQuizModal from '@/components/modals/SkgQuizModal.vue'
  import FollowupModal from '@/components/course/followup/FollowupModal.vue'
  import CreateCourseModal from '@/components/modals/CreateCourseModal.vue'
  import CreateOrUpdateCourseGroupModal from '@/components/modals/CreateOrUpdateCourseGroupModal.vue'
  import EditOrDeleteCourseGroupModal from '@/components/modals/EditOrDeleteCourseGroupModal.vue'
  import MoveCourseToSectionModal from '@/components/modals/MoveCourseToSectionModal.vue'
  import ReferralCodeModal from '@/components/modals/ReferralCodeModal.vue'
  import ExamAppointmentModal from '@/components/modals/ExamAppointmentModal.vue'
  import CourseChangesModal from '@/components/modals/CourseChangesModal.vue'
  import IDDCoursesCheckoutModal from '@/components/modals/IDDCoursesCheckoutModal.vue'
  import GuidedQuizRegisterModal from '@/components/modals/GuidedQuizRegisterModal.vue'
  import LearningProgressPolicyModal from '@/components/modals/LearningProgressPolicyModal.vue'
  import useCommonMixin from '@/composables/useCommonMixin'
  import { TOURS_AND_HINTS } from '@/helpers/Constants'
  import { BookmarksModule } from '@/store/modules/Bookmarks'
  import { ThemeModule } from '@/store/modules/theme'
  import { UserModule } from '@/store/modules/user'
  import ThemeApi from '@/services/api/ThemeApi'
  import { CompanyTheme } from '@/services/interfaces/Theme'
  import CookieHelper from '@/helpers/CookieHelper'
  import hexRgb from 'hex-rgb'
  import WebinarModal from '@/components/modals/WebinarModal.vue'
  import UsersApi from '@/services/api/UsersApi'
  import useGamification from '@/composables/useGamification'
  import CompanyHeaderSection from '@/components/CompanyHeaderSection.vue'
  import AdminNav from '@/components/AdminNav.vue'
  import useBreakpoint from '@/composables/useBreakpoint'
  import { Company } from '@/services/interfaces/Auth0'
  import CheckoutModal from '@/components/checkout/CheckoutModal.vue'
  import EditorButtons from '@/components/editor/EditorButtons.vue'
  import useEditor from './composables/useEditor'
  import useAuthentication from './composables/useAuthentication'
  import MaintenanceBanner from '@/components/MaintenanceBanner.vue'
  import SaleBanner from '@/components/SaleBanner.vue'
  import useToursAndHints from './composables/useToursAndHints'
  import auth_config from '../auth_config.json'
  import useIcons from './composables/useIcons'
  import GetStartedPanel from '@/components/GetStartedPanel.vue'
  import Confetti from '@/components/common/Confetti.vue'
  import CustomCompanyWidgets from '@/components/common/CustomCompanyWidgets.vue'
  import useSegment from '@/composables/useSegment'
  import GetYourOwnAcademyBanner from '@/components/GetYourOwnAcademyBanner.vue'
  import { CourseModule } from '@/store/modules/course'
  import useCourse from '@/composables/useCourse'
  import { EditorModule } from '@/store/modules/editor'
  import { v4 as uuidv4 } from 'uuid'
  import { useRoute, useRouter } from 'vue-router/composables'
  import eventBus from '@/main'
  import useI18n from '@/composables/useI18n'
  import LastUserActivity from '@/components/course/LastUserActivity.vue'
  import { DateTime } from 'luxon'
  import * as Sentry from '@sentry/vue'
  import CourseTrials from '@/components/course/CourseTrials.vue'

  const { staging, production } = auth_config
  const tokenKey = process.env.APP_ENV === 'production' ? production.tokenKey : staging.tokenKey

  interface ModalPayload {
    modalProps?: any
    modalSuccessCallback: () => void
    modalErrorCallback: () => void
    modalCloseCallback: (callback: () => void) => void
    modalContentComponent: any
    cssClass?: string
    modalTitle?: string
    hideContainer: boolean
    hideCloseButton: boolean
    closeModalOnOutsideClick: boolean
  }
  interface ITheme {
    lightVariants: { [key: string]: string }
    darkVariants: { [key: string]: string }
  }

  export default defineComponent({
    name: 'App',
    metaInfo: function () {
      return {
        meta: [
          {
            vmid: 'description',
            name: 'description',
            content:
              (this as any).getCompany?.headerSubtitle ||
              'Branchen-Experten aus ganz Deutschland bringen Theorie- & Praxiswissen in deinen Alltag. Bei uns findest du hunderte Videos, hilfreiche Vertriebsideen und mehr',
          },
          {
            vmid: 'twitter:image',
            name: 'twitter:image',
            content: (this as any).logoUrl || '',
          },
          {
            vmid: 'twitter:title',
            name: 'twitter:title',
            content: `${(this as any).getCompany?.name || 'KetchUp'}: ${
              (this as any).getCompany?.headerTitle || 'Weiterbildung in der Finanzdienstleistung'
            }`,
          },
          {
            vmid: 'twitter:description',
            name: 'twitter:description',
            content:
              (this as any).getCompany?.headerSubtitle ||
              'Branchen-Experten aus ganz Deutschland bringen Theorie- & Praxiswissen in deinen Alltag. Bei uns findest du hunderte Videos, hilfreiche Vertriebsideen und mehr',
          },
        ],
        title: (this as any).getCompany?.headerTitle || 'Weiterbildung in der Finanzdienstleistung',
        titleTemplate: `${(this as any).getCompany?.name || 'KetchUp'}: %s`,
      }
    },
    components: {
      CourseTrials,
      LastUserActivity,
      GetYourOwnAcademyBanner,
      CustomCompanyWidgets,
      GetStartedPanel,
      MaintenanceBanner,
      SaleBanner,
      AdminNav,
      CompanyHeaderSection,
      KetchUpHeader,
      KetchUpFooter,
      Modal,
      IframePopup,
      ConfirmationModal,
      YoutubeModal,
      AnswerQuestionResponseModal,
      BackgroundTile,
      UpsellModal,
      CheckoutProgressModal,
      UpgradePopup,
      LessonModal,
      LoadingSpinner,
      CourseExamTimeoutModal,
      InviteUserModal,
      AssignUserCourseModal,
      AssignGroupCourseModal,
      FullScreenLoader,
      LessonSlideshowModal,
      FeedbackModal,
      WebinarModal,
      StartCourseForFreeModal,
      SpeakToExpertModal,
      BrowserExtensionModal,
      CompanyOnboardingModal,
      CheckoutModal,
      EditorButtons,
      GetStartedOnboardingModal,
      Confetti,
      TrainingsMenuDropdownModal,
      FollowupModal,
      SkgQuizModal,
      AssignCourseGroupModal,
      CreateCourseModal,
      CreateOrUpdateCourseGroupModal,
      EditOrDeleteCourseGroupModal,
      MoveCourseToSectionModal,
      ReferralCodeModal,
      ExamAppointmentModal,
      CourseChangesModal,
      IDDCoursesCheckoutModal,
      GuidedQuizRegisterModal,
      LearningProgressPolicyModal,
    },
    setup() {
      const { progress } = useIcons()
      const {
        iframeMode,
        toKhebabCase,
        subDomainFromUrl,
        redirectToNewDomain,
        isSachkundeCompany,
        isKetchUpCompany,
        isDevCompany,
        showAdminNav,
        showCompanyOnboardingPopupDetails,
        logoUrl,
        highlightedCourseBundlePrefix,
        fromEmbeddedQuiz,
        showExamAppointmentModal,
        companySubdomainDetails,
        hasAccessToCompanySubdomain,
        hasAccessToCompany,
        setSkipSettingExamAppointment,
      } = useCommonMixin()
      const { isDesktopDevice, isMobileDevice, isLargeDesktop } = useBreakpoint()
      const { trackGamificationActivity, currentLevel, totalPoints, companyHasSetGamificationActivity } =
        useGamification()
      const { redirect, companyKey } = useAuthentication()
      const { showTourAfterFirstMarch } = useToursAndHints()
      const { newGamificationLevelPopupShown } = useSegment()
      const {
        inTrialCourses,
        hasPurchasedAtLeastACourse,
        fetchGroupCourses,
        findFreeModule,
        courseSectionsAssignedCourses,
      } = useCourse()

      const { translateString } = useI18n()

      const route = useRoute()
      const router = useRouter()

      const { isEditor, inEditorMode, courseSections } = useEditor(route)

      const modalContentComponent = ref(null)
      const modalProps = ref({})
      const showModal = ref(false)
      const cssClass = ref('')
      const modalTitle = ref('')
      const hideContainer = ref(false)
      const hideCloseButton = ref(false)
      const closeModalOnOutsideClick = ref(true)
      const appKey = ref('app')
      const showFullscreenLoader = ref(false)
      const showConfetti = ref(false)
      const appHeader = ref(null as HTMLElement | null)
      const companyHeader = ref(null as HTMLElement | null)
      const adminNav = ref(null as HTMLElement | null)
      const editorButtons = ref(null as HTMLElement | null)
      const getStartedPanel = ref(null as HTMLElement | null)
      const calendlyWidgetHeight = ref(0)
      const maintenanceBannerHeight = ref(0)
      const saleBannerHeight = ref(0)
      const academyBannerHeight = ref(0)
      const hideCustomWidgets = ref(false)
      const showGetYourOwnAcademyBanner = ref(false)

      const modalErrorCallback = ref(undefined as undefined | (() => void))
      const modalSuccessCallback = ref(undefined as undefined | (() => void))
      let modalCloseCallback = (callback: () => void) => {
        if (typeof callback === 'function') callback()
      }

      const loadingApp = computed(() => {
        return (
          !['Logout'].includes(route.name as string) &&
          (fetchingTheme.value ||
            route.name === 'LoginCallback' ||
            authPopupOpen.value ||
            (requiresAuth.value && !UserModule.isLoggedInUser))
        )
      })

      const authPopupOpen = computed(() => {
        if (eventBus.$auth.popupOpen) return true
        return false
      })

      const requiresAuth = computed(() => route.matched.some((record: any) => record.meta.requiresAuth))

      const theme = computed(() => 'ketch-' + ThemeModule.theme)

      const showMaintenanceBanner = computed(() => process.env.VUE_APP_MAINTENANCE_BANNER_VISIBLE === 'true')

      const showSaleBanner = computed(
        () =>
          process.env.VUE_APP_SALE_BANNER_VISIBLE === 'true' &&
          isSachkundeCompany.value &&
          UserModule.hasValidUser &&
          courseSectionsAssignedCourses.value !== null &&
          !hasPurchasedAtLeastACourse.value,
      )

      const showKetchupHeader = computed(() => {
        const isLoginOrResetPage = ['Login', 'ResetPassword'].includes(route.name as string)
        const showLogoIfPresent = isLoginOrResetPage ? logoUrl.value : true
        return isKetchUpCompany.value ? !isLoginOrResetPage && showLogoIfPresent : showLogoIfPresent
      })

      const emailIsVerified = computed(() => UserModule.emailVerified)

      const bannersTotalHeight = computed(
        () => maintenanceBannerHeight.value + saleBannerHeight.value + academyBannerHeight.value,
      )

      const companyHeaderPaddingTop = computed(() => {
        if (isMobileDevice.value) return bannersTotalHeight.value + 40
        return bannersTotalHeight.value + 80
      })

      const showGetStartedPanelOrCourseTrials = computed(
        () =>
          isSachkundeCompany.value &&
          isDesktopDevice.value &&
          (courseSectionsAssignedCourses.value ? courseSectionsAssignedCourses.value.length > 0 : false) &&
          route.meta?.showGetStartedPanelOrCourseTrials &&
          !inEditorMode.value,
      )

      const showGetStartedPanel = computed(() => hasPurchasedAtLeastACourse.value && UserModule.showGetStartedPanel)

      const showCourseTrialsPanel = computed(() => inTrialCourses.value.length > 0 && !hasPurchasedAtLeastACourse.value)

      const setToursAndHintsCookie = () => {
        const cookie = CookieHelper.getCookieValue(TOURS_AND_HINTS)
        if (!cookie) {
          CookieHelper.setCookieValue(TOURS_AND_HINTS, 'true')
        }
      }

      const bindRootEvents = () => {
        eventBus.$on('show-modal', (payload: ModalPayload) => {
          modalContentComponent.value = payload.modalContentComponent
          modalSuccessCallback.value = payload.modalSuccessCallback
          modalErrorCallback.value = payload.modalErrorCallback
          modalCloseCallback = payload.modalCloseCallback
          modalProps.value = payload.modalProps || {}
          hideContainer.value = payload.hideContainer || false
          hideCloseButton.value = payload.hideCloseButton || false
          closeModalOnOutsideClick.value = payload.closeModalOnOutsideClick
          cssClass.value = payload.cssClass || ''
          modalTitle.value = payload.modalTitle || ''
          showModal.value = true
        })
        eventBus.$on('update-modal-title', (title: string) => {
          modalTitle.value = title
        })
        eventBus.$on('close-modal', () => {
          resetProps()
        })
        eventBus.$on('refresh', () => {
          appKey.value = Math.random().toString()
        })
        eventBus.$on('show-confetti', () => {
          showConfetti.value = true
        })
        eventBus.$on('hide-confetti', () => {
          showConfetti.value = false
        })
        eventBus.$on('turn-on-loader', () => {
          showFullscreenLoader.value = true
        })
        eventBus.$on('turn-off-loader', () => {
          showFullscreenLoader.value = false
        })
      }

      const resetProps = () => {
        showModal.value = false
        modalContentComponent.value = null
        modalSuccessCallback.value = undefined
        modalErrorCallback.value = undefined
        modalProps.value = {}
        modalTitle.value = ''
        hideContainer.value = false
        hideCloseButton.value = false
        closeModalOnOutsideClick.value = true
      }

      const closeModal = () => {
        modalCloseCallback(() => {
          resetProps()
        })
      }

      const setTheme = () => {
        const theme = localStorage.getItem('userTheme') as 'light' | 'dark'
        if (theme) {
          ThemeModule.SET_THEME(theme)
        }
      }

      const updateThemeInDOM = () => {
        if (theme.value === 'ketch-dark') {
          document.documentElement.classList.remove('ketch-light')
          document.documentElement.classList.add('ketch-dark')
        } else {
          document.documentElement.classList.remove('ketch-dark')
          document.documentElement.classList.add('ketch-light')
        }
      }

      watch(theme, () => updateThemeInDOM())

      const setRgbColors = (theme: ITheme) => {
        const themeProperties = [
          'primary-color',
          'secondary-color',
          'secondary-hover-color',
          'primary-text-color',
          'module-selector-background-color',
          'header-foreground-color',
          'editor-primary-color',
        ]
        if (theme) {
          themeProperties.forEach((prop) => {
            if (theme.lightVariants[prop]) {
              const rgb = hexRgb(theme.lightVariants[prop])
              theme.lightVariants[`${prop}-rgb`] = `${rgb.red},${rgb.green},${rgb.blue}`
            }
            if (theme.darkVariants[prop]) {
              const rgb = hexRgb(theme.darkVariants[prop])
              theme.darkVariants[`${prop}-rgb`] = `${rgb.red},${rgb.green},${rgb.blue}`
            }
          })
        }
      }

      const setDefaultRootStyles = () => {
        const sheets = document.styleSheets
        sheets[sheets.length - 1]?.insertRule(
          [
            ':root {',
            '--tile-primary-shadow: 0px 0px 10px 6px rgba(var(--primary-color-rgb), 0.3);',
            '--tile-secondary-shadow: 0px 0px 10px 6px rgba(var(--secondary-color-rgb), 0.3);',
            '}',
          ].join(''),
        )
      }

      const fetchingTheme = ref(true)
      const setCompanyTheme = () => {
        if (getCompany.value?.id) {
          ThemeApi.getTheme(getCompany.value.id)
            .then(handleThemeResponse)
            .catch((error) => {
              Sentry.captureException(error)
              setTheme()
              updateThemeInDOM()
            })
            .finally(() => {
              fetchingTheme.value = false
            })
        } else {
          fetchingTheme.value = false
          setTheme()
          updateThemeInDOM()
        }
      }

      const handleThemeResponse = async (response: CompanyTheme) => {
        try {
          UserModule.setLogoUrls({
            desktopLogoUrl: response.logoUrl || '',
            mobileLogoUrl: response.mobileLogoUrl || '',
            headerImageUrl: response.headerImageUrl || '',
          })
          CookieHelper.setCrossDomainCookie('logoUrl', response.logoUrl || '')

          UserModule.setAllowDarkMode(response.allowDarkMode)

          // Only apply theme if dark mode is allowed or we're in light mode
          const currentTheme = localStorage.getItem('userTheme') as 'light' | 'dark'
          if (response.allowDarkMode || currentTheme === 'light') {
            const theme: ITheme = {
              lightVariants: toKhebabCase(response.lightVariants),
              darkVariants: toKhebabCase(response.darkVariants),
            }
            setRgbColors(theme)

            let darkVariantStyle = ':root.ketch-dark {'
            let lightVariantStyle = ':root {'

            for (const key in theme.lightVariants) {
              if (
                Object.prototype.hasOwnProperty.call(theme.lightVariants, key) ||
                Object.prototype.hasOwnProperty.call(theme.darkVariants, key)
              ) {
                const darkValue = theme.darkVariants[key]
                const lightValue = theme.lightVariants[key]
                if (darkValue) darkVariantStyle = `${darkVariantStyle}--${key}:${darkValue};`
                if (lightValue) lightVariantStyle = `${lightVariantStyle}--${key}:${lightValue};`
              }
            }

            const fontStyles = await addExternalFont(response)

            const sheets = document.styleSheets
            sheets[sheets.length - 1]?.insertRule(`${darkVariantStyle}${fontStyles[0] ?? ''}${fontStyles[1] ?? ''}}`)
            sheets[sheets.length - 1]?.insertRule(`${lightVariantStyle}${fontStyles[0] ?? ''}${fontStyles[1] ?? ''}}`)
          }

          setTheme()
          updateThemeInDOM()
        } catch (error) {
          Sentry.captureException(error)
          setTheme()
          updateThemeInDOM()
        }
      }

      const addExternalFont = (response: CompanyTheme): Promise<any> => {
        const { fontName, fontUrl, titleFontName, titleFontUrl } = response

        const primaryFont = new Promise((resolve) => {
          if (!fontUrl || !fontName) return resolve('')
          prependFontLinkToDocument(fontUrl)

          resolve(`--primary-font-name:${fontName};`)
        })

        const titleFont = new Promise((resolve) => {
          if (!titleFontUrl || !titleFontName) return resolve('--title-font-name:Big Daily Short')
          prependFontLinkToDocument(titleFontUrl)
          resolve(`--title-font-name:${titleFontName};`)
        })

        return Promise.all([primaryFont, titleFont])
      }

      const prependFontLinkToDocument = (fontUrl: string) => {
        const linkElement = getLinkElement(fontUrl)
        if (linkElement) linkElement.remove()

        const link = document.createElement('link')
        link.rel = 'stylesheet'
        link.href = fontUrl
        document.head.prepend(link)
      }

      const getLinkElement = (url: string) => {
        const links = document.getElementsByTagName('link')
        for (let i = 0; i < links.length; i++) {
          if (links[i].getAttribute('href') === url) {
            return links[i]
          }
        }
        return null
      }

      const checkToShowGetYourOwnAcademyBanner = () => {
        const showBanner = CookieHelper.getCookieValue('hide-get-your-own-academy-banner')
        if (isSachkundeCompany.value || isKetchUpCompany.value) {
          if (showBanner === true) {
            showGetYourOwnAcademyBanner.value = false
          }
          showGetYourOwnAcademyBanner.value = true
        }
      }

      const customCompanySubdomain = computed(() => {
        const companySubdomainDetailsFromCookie = CookieHelper.getCookieValue(
          'company_subdomain_details',
        ) as Company | null
        if (!companySubdomainDetailsFromCookie) return UserModule.company?.subDomain
        return companySubdomainDetailsFromCookie.subDomain
      })

      const isAccessPage = computed(() =>
        [
          'ProfilePage',
          'Login',
          'LoginCallback',
          'Logout',
          'CustomRegistration',
          'ResetPassword',
          'Signup',
          'CourseTrialPage',
        ].includes(route.name as string),
      )

      const getCompany = computed(() => {
        if (UserModule.onboarded && UserModule.company) return UserModule.company
        return companySubdomainDetails.value || UserModule.company
      })

      const hideWidgets = computed(
        () =>
          (['CourseDetails', 'Module', 'Lesson'].includes(route.name as string) || hideCustomWidgets.value) &&
          isMobileDevice.value,
      )

      const inEmbeddedQuiz = computed(() => route.name === 'EmbeddedGuidedQuiz')

      const showCustomCompanyWidgets = computed(
        () => !isAccessPage.value && !hideWidgets.value && !loadingApp.value && !inEmbeddedQuiz.value,
      )

      const headerItemsWrapperHeight = computed(() => {
        if (isMobileDevice.value)
          return calculateElementHeight(companyHeader.value) + calculateElementHeight(appHeader.value)
        return (
          calculateElementHeight(appHeader.value) +
          calculateElementHeight(companyHeader.value) +
          calculateElementHeight(adminNav.value) +
          calculateElementHeight(getStartedPanel.value) +
          calculateElementHeight(editorButtons.value)
        )
      })

      const isLoadingComponentViewData = computed(() => UserModule.loadingComponentViewContent)

      const calculateElementHeight = (element: HTMLElement | null) => {
        if (element instanceof HTMLElement) {
          return (element as HTMLElement).getBoundingClientRect().height
        }
        return 0
      }

      const redirectIfNotInCorrectSubdomain = async () => {
        // Redirection occurs based on the company a user belongs to
        if (!['production', 'qa'].includes(process.env.APP_ENV!)) return

        const company = UserModule.company
        const companyFromSubdomain = companySubdomainDetails.value
        const companyMismatch = companyFromSubdomain && company && companyFromSubdomain.id !== company.id

        if (companyMismatch) {
          if (hasAccessToCompanySubdomain.value || hasAccessToCompany.value) {
            handleSubdomainChange(hasAccessToCompanySubdomain.value ? companyFromSubdomain : company)
          } else {
            if (UserModule.hasValidUser) await UserModule.logout()
            handleSubdomainChange(companyFromSubdomain)
          }
        } else if (shouldRedirectToCustomDomain.value) {
          /* for users who signed up through custom registration
          and are coming back from a previous domain
          */
          redirectToNewDomain(customCompanySubdomain.value!)
        }
      }

      const handleSubdomainChange = (company: Company) => {
        CookieHelper.setCrossDomainCookie(companyKey.value, company.id)
        redirectToNewDomain(company.subDomain)
      }

      const shouldRedirectToCustomDomain = computed(() => {
        return (
          subDomainFromUrl.value &&
          customCompanySubdomain.value &&
          subDomainFromUrl.value !== customCompanySubdomain.value &&
          !isAccessPage.value
        )
      })

      const getDomainCompanyDetails = async () => {
        if (subDomainFromUrl.value || (!UserModule.onboarded && UserModule.companies.length === 0)) {
          let domain = ['production', 'qa'].includes(process.env.APP_ENV!) ? subDomainFromUrl.value : 'staging'
          if (route.query.testDomain) {
            domain = route.query.testDomain as string
          }

          try {
            const data = await UsersApi.getCompanies(domain!)
            if (data[0]) UserModule.setSubdomainCompany(data[0])
          } catch (error) {
            eventBus.$toasted.error(translateString('homepage.noCompanyFound', { domain }))
          }
        }
        return
      }

      const setUserCompany = async () => {
        let companyId = CookieHelper.getCookieValue(companyKey.value)
        // where only one company is returned, we can safely ignore the cookie value if not set
        if (companyId || (!companyId && UserModule.companies.length === 1)) {
          companyId = companyId ?? UserModule.companies[0].id
          UserModule.setCompany({ companyId })
        }
      }

      const setUserCompanyBySelection = (toCompanySelection: { value: boolean }) => {
        if (!UserModule.company && !isAccessPage.value) {
          if (UserModule.companies.length > 1) {
            if (route.name === 'CompanySelection') return
            if (['staging', 'app'].includes(subDomainFromUrl.value!) || !hasAccessToCompanySubdomain.value) {
              toCompanySelection.value = true
              router.push(`/company-selection?redirect=${redirect.value}`).catch(() => {
                return
              })
              return
            }
          }
          UserModule.setCompany({ company: companySubdomainDetails.value! })
        }
        return
      }

      const trackEmailVerifiedActivity = () => {
        const emailVerifiedGamificationActivityTracked = CookieHelper.getCookieValue('email_verified_tracked')

        if (
          emailIsVerified.value &&
          !emailVerifiedGamificationActivityTracked &&
          companyHasSetGamificationActivity.value('EMAIL_VERIFIED')
        ) {
          trackGamificationActivity('EMAIL_VERIFIED', null)
          CookieHelper.setCrossDomainCookie('email_verified_tracked', 'true')
        }
      }

      const handleLoggedInUserActions = async () => {
        if (!UserModule.hasValidUser || !UserModule.currentCompany) return

        try {
          await Promise.all([
            UserModule.setUserLastCourseLog(),
            BookmarksModule.getUserBookmarks(),
            UserModule.getCompanyGamificationActivities(),
          ])

          const { analytics } = window as any
          if (analytics) {
            analytics.identify(UserModule.auth0Id, {
              name: UserModule.fullname,
              email: UserModule.email,
              company: UserModule.currentCompany?.name ?? '',
              inviteSource: UserModule.inviteSource ?? '',
              created_at: DateTime.fromSQL(UserModule.createdAt),
            })
          }

          UserModule.getUserGamificationStats().finally(() => trackEmailVerifiedActivity())

          if (isEditor.value || UserModule.isCompanyAdmin) {
            await fetchGroupCourses()
          }
        } catch (error) {
          Sentry.captureException(error)
        }
      }

      const addCalendlyWidget = async () => {
        const { Calendly } = window as any
        const calendlyWidget = UserModule.currentCompany?.widgets?.find((widget) => widget.type === 'calendly')
        if (
          calendlyWidget &&
          calendlyWidget.displayText &&
          calendlyWidget.url &&
          Calendly &&
          !isAccessPage.value &&
          !hideWidgets.value
        ) {
          Calendly.initBadgeWidget({
            url: calendlyWidget.url,
            text: calendlyWidget.displayText,
            color: 'var(--primary-color)',
            textColor: 'var(--primary-foreground-color)',
            branding: false,
          })
          const calendlyDocument = document.getElementsByClassName('calendly-badge-content')[0] as HTMLDivElement
          calendlyDocument.style.fontFamily = 'var(--primary-font-name)'
          calendlyWidgetHeight.value = calendlyDocument?.getBoundingClientRect().height
        }
      }

      const setMouseflowTrackingCode = async () => {
        if (UserModule.currentCompany && isSachkundeCompany.value) {
          // Initialize the Mouseflow queue
          ;(window as any)._mfq = (window as any)._mfq || []

          // we can add the mouseflow projectId on a company if we'll need to add to more companies
          const projectId = '6b2736ca-d260-405e-83f2-c2545f0d6eaf'

          // Create and append the Mouseflow script
          const mfScript = document.createElement('script')
          mfScript.type = 'text/javascript'
          mfScript.defer = true
          mfScript.src = `//cdn.mouseflow.com/projects/${projectId}.js`
          document.getElementsByTagName('head')[0].appendChild(mfScript)
        }
      }

      const addVideoAskWidget = () => {
        if (UserModule.currentCompany?.videoAskScriptUrl) {
          const script = document.createElement('script')
          const videoAskEmbedScript = document.createElement('script')

          // Set the videoAsk script content
          script.textContent =
            'window.VIDEOASK_EMBED_CONFIG = {\n' +
            '  "kind": "widget",\n' +
            '  "url": "' +
            UserModule.currentCompany.videoAskScriptUrl +
            '",\n' +
            '  "options": {\n' +
            '    "widgetType": "VideoThumbnailExtraLarge",\n' +
            '    "text": "",\n' +
            '    "backgroundColor": "#7D00FE",\n' +
            '    "position": "bottom-right",\n' +
            '    "dismissible": true\n' +
            '  }\n' +
            '}'

          // Append the script element to the body
          document.body.appendChild(script)
          videoAskEmbedScript.src = 'https://www.videoask.com/embed/embed.js'
          document.body.appendChild(videoAskEmbedScript)
        }
      }

      const downloadYTApi = () => {
        const tag = document.createElement('script')
        tag.src = 'https://www.youtube.com/iframe_api'
        const firstScriptTag = document.getElementsByTagName('script')[0] as HTMLScriptElement
        firstScriptTag.parentNode?.insertBefore(tag, firstScriptTag)
      }

      const grantOrDenyPublicAccess = () => {
        if (
          !isAccessPage.value &&
          !UserModule.isLoggedInUser &&
          UserModule.currentCompany &&
          !UserModule.currentCompany!.isPublic
        ) {
          router.push(`/login?redirect=${route.fullPath}`).catch(() => {
            return
          })
        }
      }

      const enforceFullMaintenanceMode = async () => {
        const isEnabled = false
        const inFullMaintenanceMode = process.env.VUE_APP_ENABLE_COMPLETE_MAINTENANCE_MODE === 'true'

        if (inFullMaintenanceMode && route.name !== 'Maintenance' && isEnabled) {
          await UserModule.logout()
          return router.push('/maintenance')
        } else if (!inFullMaintenanceMode && route.name === 'Maintenance') {
          return router.replace({ path: '/' })
        }
        return Promise.resolve()
      }

      const getQueryParams = () => {
        const query: { [key: string]: string } = {}
        const urlParams = new URLSearchParams(window.location.search)

        urlParams.forEach((value, key) => {
          query[key] = value
        })

        // Check for the redirect parameter and parse its URL parameters
        if (query.redirect) {
          const redirectUrl = new URL(query.redirect, window.location.origin)
          const redirectParams = new URLSearchParams(redirectUrl.search)

          redirectParams.forEach((value, key) => {
            // Override duplicates
            query[key] = value
          })

          // Remove the redirect parameter since it's not needed in the final URL
          delete query.redirect
        }

        if (highlightedCourseBundlePrefix.value) {
          query.bundlePrefix = highlightedCourseBundlePrefix.value
        }

        return new URLSearchParams(query)
      }

      const enforceSetUserProfile = async () => {
        const token = CookieHelper.getCookieValue(tokenKey)

        if (!token) return

        const isAcademySignup = CookieHelper.getCookieValue('is-academy-signup')

        if (UserModule.onboarded) return

        // Prevent redirects for certain routes
        if (['Logout', 'LoginCallback'].includes(route.name as string)) {
          return Promise.resolve()
        }

        if (isAcademySignup) {
          if (route.name === 'CustomRegistration') return

          // Redirect to registration page with any existing query parameters
          const uniqueParams = getQueryParams()
          return router.replace({
            path: '/registrierung',
            query: Object.fromEntries(uniqueParams.entries()),
          })
        } else if (!['Logout', 'ProfilePage'].includes(route.name!)) {
          return router.replace({ path: '/profile-page' })
        }
        return Promise.resolve()
      }

      const checkToReTriggerCompanyOnboardingPopup = async () => {
        await UserModule.setCompanyOnboardingPopupDetails()

        /*
          we first the check if the company has configured the onboarding/intro properties;
          then if showCompanyPopup is false that means the user had already seen the popup before;
          we then allow them to re-trigger the popup by showing the button on the nav
        */
        if (UserModule.companyOnboardingPopupDetails && !UserModule.currentCompany?.showCompanyPopup) {
          UserModule.setReTriggerOnboardingPopup(true)
        }
      }

      // force users with token in localstorage to logout
      localStorage.removeItem('ketch-jwt')
      CookieHelper.removeCookie('COMPANY_ID')
      CookieHelper.removeCookie('EXISTING_USER')

      const bootstrapApp = async () => {
        try {
          await enforceFullMaintenanceMode()

          setDefaultRootStyles()
          bindRootEvents()

          await Promise.all([getDomainCompanyDetails(), UserModule.getUser()])
          await enforceSetUserProfile()
          await setUserCompany()
          await redirectIfNotInCorrectSubdomain()

          const toCompanySelection = { value: false }
          setUserCompanyBySelection(toCompanySelection)
          setCompanyTheme()

          if (!toCompanySelection.value && !isAccessPage.value) {
            await handleLoggedInUserActions()
          }

          grantOrDenyPublicAccess()

          if (getCompany.value?.id) {
            const companyId = getCompany.value.id

            if (!UserModule.onboarded) await UserModule.getOnboardingFlowProperties(companyId)

            await CourseModule.getCourseSections(companyId)

            const companyFeatures = [
              getCompany.value.enableGlossaries && UserModule.setGlossaryItems(companyId),
              getCompany.value.enableOnboardingSurvey &&
                !getCompany.value.onboardingSurveyCompleted &&
                UserModule.setCompanyOnboardingSurveyQuestions(),
              !CourseModule.courseBundles?.length && CourseModule.getPackageBundles(),
            ]
            await Promise.all(companyFeatures.filter(Boolean))

            if (!hasPurchasedAtLeastACourse.value) {
              addVideoAskWidget()
            }

            await checkToReTriggerCompanyOnboardingPopup()
            UserModule.setLanguageTranslations()
            addCalendlyWidget()
            setMouseflowTrackingCode()
          }

          showTourAfterFirstMarch(setToursAndHintsCookie)
          checkToShowGetYourOwnAcademyBanner()
          downloadYTApi()
        } catch (error) {
          Sentry.captureException(error)
        }
      }

      const isCourseLayoutRoute = computed(() =>
        route.matched.some((route) => route.name === 'CourseLayout' || route.name === 'PackageLayout'),
      )

      const setOrRemoveDropShadowToNav = () => {
        if (document.documentElement.scrollTop > 0 && !isCourseLayoutRoute.value) {
          if (appHeader.value) {
            appHeader.value?.classList.add('apply-drop-shadow')
          }
        } else {
          if (appHeader.value) {
            appHeader.value?.classList.remove('apply-drop-shadow')
          }
        }
      }

      const removeOrReInitializeCalendly = (action: 'remove' | 're-initialize') => {
        const calendlyDocument = document.getElementsByClassName('calendly-badge-content')[0] as HTMLDivElement
        switch (action) {
          case 'remove':
            if (calendlyDocument) {
              calendlyDocument.remove()
              calendlyWidgetHeight.value = 0
            }
            return
          case 're-initialize':
            // re-initialize calendly if it's not already there
            if (!calendlyDocument) {
              addCalendlyWidget()
            }
            return
        }
      }

      const showOrHideWidgets = (action: 'show' | 'hide') => {
        switch (action) {
          case 'hide':
            hideCustomWidgets.value = true
            removeOrReInitializeCalendly('remove')
            return
          case 'show':
            hideCustomWidgets.value = false
            removeOrReInitializeCalendly('re-initialize')
            return
        }
      }

      const setComponentsViewMinHeight = () => {
        setTimeout(() => {
          const element = document.getElementById('component-markup')
          if (element) {
            const minHeight = inEmbeddedQuiz.value ? '350px' : `calc(100vh - ${headerItemsWrapperHeight.value}px)`
            element.style.setProperty('min-height', minHeight)
          }
        }, 50)
      }

      const checkToShowCompanyOnboardingPopup = async () => {
        if (UserModule.companyOnboardingPopupDetails === null) {
          await UserModule.setCompanyOnboardingPopupDetails()
        }
        if (UserModule.company?.showCompanyPopup) {
          showCompanyOnboardingPopupDetails()
        }
      }

      const redirectToGuidedQuiz = async () => {
        try {
          const course = courseSectionsAssignedCourses.value?.find(
            (c) => c.title.includes(fromEmbeddedQuiz.value!) && c.title.toLowerCase().includes('schriftlich'),
          )

          if (!course) return

          const courseModules = CourseModule.courseModules(course.id)
          if (!courseModules.length) {
            await CourseModule.getCourseModules(course.id)
          }

          const moduleId = findFreeModule(course.id)?.id
          if (moduleId) {
            await router.push(`/courses/${course.id}/module/${moduleId}/guided-quiz`).catch(() => {
              return
            })
          }
        } catch (error) {
          Sentry.captureException(error)
        }
      }

      watch(loadingApp, (loading: boolean) => {
        const loader = document.getElementById('loader')
        if (loading) {
          loader?.classList.add('progress')
        } else {
          loader?.classList.remove('progress')
        }
      })

      watch(
        () => currentLevel.value?.id,
        (newValue, oldValue) => {
          if (oldValue && newValue) {
            setTimeout(() => {
              showConfetti.value = true
              if (UserModule.currentCompany?.id && totalPoints.value && currentLevel.value?.name) {
                newGamificationLevelPopupShown(
                  UserModule.currentCompany.id,
                  currentLevel.value?.name,
                  totalPoints.value,
                )
              }
            }, 1000)
          }
        },
      )

      watch(
        () => route.path,
        () => {
          if (isAccessPage.value) {
            removeOrReInitializeCalendly('remove')
          } else {
            removeOrReInitializeCalendly('re-initialize')
          }
          setComponentsViewMinHeight()
        },
      )

      watch(hideWidgets, (value) => {
        if (value) {
          removeOrReInitializeCalendly('remove')
        } else {
          removeOrReInitializeCalendly('re-initialize')
        }
      })

      watch(inEditorMode, async (value) => {
        try {
          if (value) {
            await Promise.all([CourseModule.getTags(), EditorModule.setEditorAllAuthors()])

            if (CourseModule.courseSections && route.name === 'Trainings') {
              await EditorModule.setCourseSections(CourseModule.courseSections)
              // Generate UUIDs in chunks to prevent blocking
              const sections = courseSections.value || []
              for (let i = 0; i < sections.length; i += 2) {
                const chunk = sections.slice(i, i + 10)
                chunk.forEach((section) => {
                  eventBus.$set(section, 'editorId', uuidv4())
                })
                await new Promise((resolve) => setTimeout(resolve, 0))
              }
            }
          } else {
            setComponentsViewMinHeight()
            EditorModule.setCourseSections(null)
          }
        } catch (error) {
          Sentry.captureException(error)
          EditorModule.setEditMode(false)
        }
      })

      watch(bannersTotalHeight, (value) => {
        UserModule.setBannersTotalHeight(value)
      })

      watch(isLoadingComponentViewData, (value) => {
        if (!value) {
          setComponentsViewMinHeight()
        }
      })

      const onboardingRequiresTrialSelection = computed(() =>
        UserModule.onboardingPages.some((page) => page.pageName === 'CourseTrialPage'),
      )

      const onboardingRequiresSettingExamAppointment = computed(() =>
        UserModule.onboardingPages.some((page) => page.pageName === 'ExamDatePicker'),
      )

      const hasSetCourseTrial = computed(() => {
        return onboardingRequiresTrialSelection.value ? UserModule.hasSelectedTrial : true
      })

      const hasSetOrSkippedSettingExamAppointment = computed(() => {
        return onboardingRequiresSettingExamAppointment.value
          ? UserModule.company?.skipSettingExamAppointment || UserModule.hasSetExamDate
          : true
      })

      const enforceSettingCourseTrial = () => {
        if (!hasSetCourseTrial.value) {
          if (highlightedCourseBundlePrefix.value) {
            return router.replace({
              path: '/trial-selector',
              query: { bundlePrefix: highlightedCourseBundlePrefix.value },
            })
          }

          router.replace({ path: '/trial-selector' })
          return Promise.resolve()
        }
        return Promise.resolve()
      }

      const enforceSettingExamAppointment = async () => {
        const shouldSkipAppointment =
          ((route.query.testDomain || isDevCompany.value) && !UserModule.company?.skipSettingExamAppointment) ||
          fromEmbeddedQuiz.value

        if (shouldSkipAppointment) {
          setSkipSettingExamAppointment(true)
          return
        }

        const shouldShowModal =
          hasSetCourseTrial.value && !hasSetOrSkippedSettingExamAppointment.value && !fromEmbeddedQuiz.value

        if (shouldShowModal) {
          showExamAppointmentModal(null)
        }
      }

      const onboardingCheckReady = computed(
        () =>
          UserModule.company &&
          UserModule.onboardingPages.length > 0 &&
          UserModule.hasValidUser &&
          UserModule.onboarded,
      )

      watch(onboardingCheckReady, (value) => {
        if (value && !isAccessPage.value) {
          if (route.name !== 'CourseTrialPage') {
            enforceSettingCourseTrial()
          }
          enforceSettingExamAppointment()
        }
      })

      const userHasFullyOnboarded = computed(
        () =>
          courseSectionsAssignedCourses.value &&
          UserModule.hasValidUser &&
          UserModule.onboarded &&
          hasSetCourseTrial.value &&
          hasSetOrSkippedSettingExamAppointment.value,
      )

      watch(userHasFullyOnboarded, (value) => {
        if (value && !isAccessPage.value) {
          setTimeout(() => {
            if (fromEmbeddedQuiz.value) {
              // prevent showing onboarding popup
              UserModule.setReTriggerOnboardingPopup(true)
              UserModule.setShowCompanyOnboardingPopup(false)
              UsersApi.updateShowOnboardingPopup(UserModule.auth0Id, false)

              redirectToGuidedQuiz()
            } else {
              checkToShowCompanyOnboardingPopup()
            }
          }, 2000)
        }
      })

      const setWindowWidth = () => {
        UserModule.setWindowWidth(window.innerWidth)
      }

      setWindowWidth()
      bootstrapApp()

      const onResize = () => {
        setComponentsViewMinHeight()
        setWindowWidth()
      }

      const editorModeCheck = () => {
        const cookie = CookieHelper.getCookieValue('EDITOR_MODE')
        if (cookie === 'true' && !inEditorMode.value) {
          EditorModule.setEditMode(true)
        }
      }

      onMounted(() => {
        window.onscroll = setOrRemoveDropShadowToNav
        window.onunhandledrejection = (event: PromiseRejectionEvent) => {
          eventBus.$logger.error('unhandled promise rejection: ', event, event.reason)
          event.preventDefault()
        }
        window.addEventListener('resize', onResize)
        editorModeCheck()
      })

      onUnmounted(() => {
        window.removeEventListener('resize', onResize)
      })

      return {
        logoUrl,
        iframeMode,
        calendlyWidgetHeight,
        cssClass,
        hideContainer,
        hideCloseButton,
        closeModalOnOutsideClick,
        showConfetti,
        modalTitle,
        hasPurchasedAtLeastACourse,
        showModal,
        modalProps,
        modalErrorCallback,
        modalSuccessCallback,
        appKey,
        modalContentComponent,
        showFullscreenLoader,
        appHeader,
        companyHeader,
        editorButtons,
        getStartedPanel,
        adminNav,
        loadingApp,
        showAdminNav,
        isDesktopDevice,
        isMobileDevice,
        isLargeDesktop,
        isCourseLayoutRoute,
        getCompany,
        inEditorMode,
        showMaintenanceBanner,
        showSaleBanner,
        showKetchupHeader,
        maintenanceBannerHeight,
        saleBannerHeight,
        academyBannerHeight,
        companyHeaderPaddingTop,
        progress,
        isSachkundeCompany,
        isAccessPage,
        hideWidgets,
        showCustomCompanyWidgets,
        fetchingTheme,
        showGetYourOwnAcademyBanner,
        showGetStartedPanelOrCourseTrials,
        showGetStartedPanel,
        showCourseTrialsPanel,
        inTrialCourses,
        showOrHideWidgets,
        closeModal,
        userHasFullyOnboarded,
      }
    },
  })
</script>

<style lang="postcss">
  #app {
    @apply ketch-w-full ketch-text-left ketch-text-primary-text-color lg:ketch-mx-auto;
    &.white-bg {
      @apply ketch-bg-white;
    }
    > .editor-buttons {
      @apply ketch-z-[2];
    }
    .header-wrapper {
      @apply ketch-sticky ketch-z-[4] ketch-left-0 ketch-top-0 ketch-w-full ketch-bg-header-background-color;
      @apply lg:ketch-flex lg:ketch-flex-col lg:ketch-items-center lg:ketch-justify-center;
      &.apply-drop-shadow {
        @apply ketch-drop-shadow-md;
      }
      &.fluid {
        @apply ketch-relative;
      }
      .company-header-banners {
        @apply ketch-flex ketch-flex-col ketch-h-auto ketch-w-full;
      }
    }
    .admin-nav {
      @apply ketch-bg-background-color ketch-sticky ketch-left-0 ketch-z-[3];
    }
    .company-header {
      @apply ketch-bg-header-background-color;
    }
    .components-wrapper {
      @apply ketch-relative ketch-z-[1];
      &.center-viewport {
        @apply ketch-flex ketch-items-center ketch-justify-center;
      }
      .components-view {
        @apply ketch-relative ketch-z-[1] ketch-py-c16 md:ketch-py-c60;
        &.loader {
          @apply ketch-z-[4];
        }
        &.fluid {
          @apply ketch-pt-0;
        }
        &.login-page {
          @apply ketch-min-h-[calc(100vh-41px)];
        }
        &.is-academy-signup {
          @apply ketch-py-0;
        }
      }
    }
    .modal.company-onboarding-modal {
      .modal-body {
        @apply ketch-mt-0 ketch-h-full ketch-flex ketch-items-center ketch-justify-end;
      }
    }
    .modal.iframe-popup {
      @apply ketch-fixed ketch-top-0 ketch-left-0 ketch-right-0 ketch-bottom-0 ketch-z-30;
      .modal-container {
        @apply ketch-flex ketch-flex-col ketch-justify-center ketch-min-h-[330px] ketch-bg-none ketch-p-0 md:ketch-w-[900px];
        .modal-body {
          @apply ketch-mt-0;
        }
      }
    }
    .modal.iframe-popup.backdrop,
    .modal.upgrade-modal-popup.backdrop {
      backdrop-filter: blur(8px);
    }
    .modal.webinar-modal-wrapper {
      .modal-container {
        @apply ketch-pb-c100;
      }
      .modal-body {
        @apply ketch-m-0 ketch-max-h-[calc(100vh-200px)];
      }
    }
    .modal.slideshow-modal {
      .modal-container {
        @apply ketch-w-c1200 ketch-px-0 ketch-pt-0 ketch-overflow-hidden;
        .close {
          @apply ketch-top-c10 ketch-right-c10 ketch-bg-transparent;
        }
        .modal-body {
          @apply ketch-m-0;
        }
      }
    }
    .modal.youtube {
      .modal-container {
        @apply ketch-flex ketch-flex-col ketch-justify-center md:ketch-w-[960px];
        .modal-body {
          @apply ketch-w-full;
        }
      }
    }
    .modal.answer-response-modal {
      .modal-container {
        @apply ketch-bg-white;
        @screen md {
          min-width: 335px;
          max-width: 380px;
        }
        .modal-body {
          @apply ketch-w-full;
        }
      }
    }
    .modal.confirmation {
      &.editor {
        .cta {
          @apply ketch-flex-row-reverse ketch-justify-start;
          button {
            @apply ketch-w-auto;
            &.primary {
              @apply ketch-bg-editor-primary-color ketch-border-editor-primary-color ketch-mr-c20 ketch-ml-0;
              > * {
                @apply ketch-text-editor-foreground-color;
              }
            }
          }
        }
      }
      .modal-container {
        @apply md:ketch-w-[550px];
      }
    }
    .modal.course-exam-timeout-modal {
      .modal-container {
        @apply md:ketch-w-c500;
        .modal-body {
          @apply ketch-mt-0;
        }
      }
    }
    .modal.invite-user-modal {
      .modal-container {
        @apply md:ketch-w-c400;
        .close {
          @apply ketch-border-none;
        }
        .modal-body {
          @apply ketch-mt-0;
        }
      }
    }
    .modal.assign-user-course-modal,
    .modal.assign-group-course-modal,
    .modal.assign-course-group-modal,
    .modal.create-course-group-modal,
    .modal.edit-or-delete-course-group-modal,
    .move-course-to-section-modal,
    .referral-code-modal,
    .idd-courses-checkout-modal,
    .guided-quiz-register-modal {
      .modal-container {
        @apply md:ketch-w-[500px];
        .close {
          @apply ketch-border-none;
        }
        .modal-body {
          @apply ketch-mt-0;
        }
      }
    }
    .modal.checkout-progress {
      .modal-container {
        @apply ketch-border ketch-border-border-color md:ketch-w-[640px];
        .modal-body {
          @apply ketch-m-0;
        }
      }
    }
    .modal.checkout-modal {
      .modal-container {
        @apply ketch-overflow-hidden;
      }
      &.done .modal-container {
        @apply md:ketch-w-[520px];
      }
    }
    .modal.upsell-modal {
      .modal-container {
        @apply md:ketch-w-[615px];
      }
    }
    .modal.lesson-modal {
      .modal-container {
        @apply ketch-pb-c100;
        .modal-body {
          @apply ketch-max-h-[calc(100vh-205px)];
        }
      }
    }
    .modal.feedback-modal {
      .modal-container {
        @apply md:ketch-w-c500;
      }
    }
    .modal.speak-to-expert-modal {
      .modal-container {
        @apply md:ketch-w-[340px];
      }
    }
    .modal.followup-modal {
      .modal-container {
        @apply md:ketch-w-[500px];
        .close {
          @apply ketch-border-none;
        }
      }
    }
    .modal.start-course-for-free-modal {
      .modal-container {
        @apply md:ketch-w-c480;
      }
    }
    .modal.company-onboarding-modal,
    .modal.skg-quiz-modal,
    .modal.get-started-onboarding-modal {
      .modal-container {
        @apply md:ketch-w-[700px];
      }
    }
    .modal.skg-quiz-modal {
      .modal-container {
        @apply ketch-h-auto;
        .modal-body {
          @apply ketch-h-full;
        }
      }
    }
    .modal.create-course-modal {
      .modal-container {
        @apply ketch-p-0 ketch-h-full xl:ketch-w-[900px];
        .modal-body {
          @apply ketch-h-full ketch-w-full;
        }
      }
    }
    .modal.exam-appointment-modal {
      .modal-container {
        @apply ketch-pt-c20 md:ketch-w-[650px];
        .modal-body {
          @apply ketch-mt-0 ketch-max-h-none ketch-h-full;
        }
      }
    }
    .course-changes-modal {
      .modal-container {
        @apply md:ketch-w-[600px];
        .modal-body {
          @apply ketch-mt-c30 md:ketch-mt-0;
        }
      }
    }
    .idd-courses-checkout-modal,
    .guided-quiz-register-modal,
    .learning-progress-policy-modal {
      .modal-container {
        .modal-body {
          @apply ketch-h-full ketch-flex ketch-items-center ketch-justify-center;
        }
      }
    }
    .guided-quiz-register-modal {
      .modal-container {
        &.in-mobile {
          @apply ketch-bg-opacity-70;
        }
      }
    }
  }
</style>
