<template lang="pug">
transition(mode='out-in', name='overlay')
  .modal
    .full-page-overlay
    .modal-container(
      :class='{ "in-mobile": isMobileDevice }',
      v-if='!hideContainer',
      v-on-click-outside='closeModalWhenClickedOutside'
    )
      img.close(:src='closeIcon', @click='closeModal', alt='Close button', v-if='!hideCloseButton')
      .modal-header(v-if='title')
        h2.title-with-underline {{ title }}
      .modal-body
        slot
    slot(v-else)
</template>

<script setup lang="ts">
  import { onBeforeUnmount, onMounted } from 'vue'
  import { vOnClickOutside } from '@vueuse/components'
  import useIcons from '@/composables/useIcons'
  import useBreakpoint from '@/composables/useBreakpoint'

  const emit = defineEmits(['close'])

  const props = defineProps({
    title: {
      type: String,
      default: '',
    },
    hideContainer: {
      type: Boolean,
      default: false,
    },
    hideCloseButton: {
      type: Boolean,
      default: false,
    },
    closeModalOnOutsideClick: {
      type: Boolean,
      default: true,
    },
  })

  const { closeIcon } = useIcons()
  const { isMobileDevice } = useBreakpoint()

  const closeModal = () => {
    emit('close')
  }

  const closeModalWhenClickedOutside = () => {
    if (props.closeModalOnOutsideClick) {
      closeModal()
    }
  }

  const onKeydown = (e: KeyboardEvent) => {
    if (e.key === 'Escape') closeModal()
  }

  onMounted(() => {
    document.body.style.setProperty('overflow', 'hidden')
    document.addEventListener('keydown', onKeydown)
  })

  onBeforeUnmount(() => {
    document.body.style.setProperty('overflow', '')
    document.removeEventListener('keydown', onKeydown)
  })
</script>

<style lang="postcss">
  .modal-container {
    @apply ketch-fixed ketch-top-0 ketch-bottom-0 ketch-left-0 ketch-right-0 ketch-rounded-none ketch-h-full md:ketch-h-[max-content];
    @apply ketch-p-c20 xs4:ketch-p-c30 ketch-z-50 md:ketch-rounded-large md:ketch-shadow-l-modal md:dark:ketch-shadow-d-modal;
    @apply md:ketch-w-[770px] md:ketch-min-h-[200px] ketch-bg-module-selector-background-color md:ketch-top-1/2;
    @apply md:ketch--translate-y-1/2 md:ketch-my-0 md:ketch-mx-auto md:ketch-max-h-[calc(100vh-60px)];
    > * {
      @apply ketch-text-module-selector-foreground-color;
    }
    .close {
      @apply ketch-absolute ketch-right-c20 ketch-top-c20 ketch-z-[2];
      @apply ketch-border ketch-border-border-color ketch-cursor-pointer ketch-bg-module-selector-background-color;
    }
    .modal-header {
      @apply ketch-pr-c25;
      h2 {
        @apply ketch-font-bold;
      }
    }
    .modal-body {
      @apply ketch-mt-c20 ketch-overflow-y-auto ketch-max-h-[calc(100vh-104px)] md:ketch-max-h-[calc(100vh-164px)];
    }
  }
</style>
