import { computed, ComputedRef } from 'vue'
import useBreakpoint from './useBreakpoint'

interface useIconsReturn {
  defaultLogo: ComputedRef<string>
  logo: ComputedRef<string>
  smallLogo: ComputedRef<string>
  allBookmarks: ComputedRef<string>
  bookmarkInactive: ComputedRef<string>
  bookmarkActive: ComputedRef<string>
  expandMoreOptions: ComputedRef<string>
  quizIcon: ComputedRef<string>
  arrowRight: ComputedRef<string>
  closeIcon: ComputedRef<string>
  thumbsUp: ComputedRef<string>
  heavyThumbsUp: ComputedRef<string>
  thumbsDown: ComputedRef<string>
  checkedCheckboxSquare: ComputedRef<string>
  checkedCheckboxSquareWhite: ComputedRef<string>
  checkedCheckboxSquareGreen: ComputedRef<string>
  checkboxSquare: ComputedRef<string>
  checkboxSquareWhite: ComputedRef<string>
  noVideoImage: ComputedRef<string>
  checkedCircle: ComputedRef<string>
  checkedRadio: ComputedRef<string>
  uncheckedCircle: ComputedRef<string>
  crossDanger: ComputedRef<string>
  wrongAnswerIcon: ComputedRef<string>
  artTrack: ComputedRef<string>
  caretDown: ComputedRef<string>
  waitingIcon: ComputedRef<string>
  lockIcon: ComputedRef<string>
  searchIcon: ComputedRef<string>
  folderIcon: ComputedRef<string>
  documentIcon: ComputedRef<string>
  arrowDown: ComputedRef<string>
  arrowAltRight: ComputedRef<string>
  search404: ComputedRef<string>
  playButton: ComputedRef<string>
  articleImage: ComputedRef<string>
  arrowUpLeft: ComputedRef<string>
  courseCheckmark: ComputedRef<string>
  googleAvatar: ComputedRef<string>
  facebookAvatar: ComputedRef<string>
  editIcon: ComputedRef<string>
  checkmark: ComputedRef<string>
  defaultUserIcon: ComputedRef<string>
  baufiLogo: ComputedRef<string>
  finlinkLogo: ComputedRef<string>
  kreditplanetLogo: ComputedRef<string>
  zinskonzeptLogo: ComputedRef<string>
  certLogo: ComputedRef<string>
  logoMarker: ComputedRef<string>
  play: ComputedRef<string>
  document: ComputedRef<string>
  sidebarArrow: ComputedRef<string>
  shuffle: ComputedRef<string>
  success: ComputedRef<string>
  successWhite: ComputedRef<string>
  list: ComputedRef<string>
  checkboxCircleGreen: ComputedRef<string>
  openNewTab: ComputedRef<string>
  externalIcon: ComputedRef<string>
  arrowRightIndicator: ComputedRef<string>
  rightNavigation: ComputedRef<string>
  leftNavigation: ComputedRef<string>
  timelineImage: ComputedRef<string>
  timelineImageLightMode: ComputedRef<string>
  checkedListItem: ComputedRef<string>
  profileCompleted: ComputedRef<string>
  upsellImage: ComputedRef<string>
  diamondIcon: ComputedRef<string>
  diamondBlackIcon: ComputedRef<string>
  upsellCheckbox: ComputedRef<string>
  globeIcon: ComputedRef<string>
  authorIcon: ComputedRef<string>
  authorVerifiedIcon: ComputedRef<string>
  premiumCourseIcon: ComputedRef<string>
  signet: ComputedRef<string>
  categoryInterestImage: ComputedRef<string>
  sepa: ComputedRef<string>
  mastercard: ComputedRef<string>
  hourGlassIcon: ComputedRef<string>
  specialCheckmark: ComputedRef<string>
  specialBlackCheckmark: ComputedRef<string>
  vectorRight: ComputedRef<string>
  durationIcon: ComputedRef<string>
  zigZagIcon: ComputedRef<string>
  device: ComputedRef<string>
  lesson: ComputedRef<string>
  certificate: ComputedRef<string>
  moduleIcon: ComputedRef<string>
  open: ComputedRef<string>
  activeLessonIcon: ComputedRef<string>
  download: ComputedRef<string>
  bigDownloadIcon: ComputedRef<string>
  previousIcon: ComputedRef<string>
  nextIcon: ComputedRef<string>
  lessonCompletedIcon: ComputedRef<string>
  playIcon: ComputedRef<string>
  documentAltIcon: ComputedRef<string>
  courseCertIcon: ComputedRef<string>
  courseCertIconWhite: ComputedRef<string>
  purchaseIcon: ComputedRef<string>
  iconPdf: ComputedRef<string>
  starIcon: ComputedRef<string>
  star5Icon: ComputedRef<string>
  beginnerCoursesIcon: ComputedRef<string>
  subjectCoursesIcon: ComputedRef<string>
  expertCoursesIcon: ComputedRef<string>
  userSectionIcon: ComputedRef<string>
  openCategory: ComputedRef<string>
  closedCategory: ComputedRef<string>
  iconPause: ComputedRef<string>
  pauseBtn: ComputedRef<string>
  playBtn: ComputedRef<string>
  usersEmptyStateHero: ComputedRef<string>
  checkedStar: ComputedRef<string>
  checkboxLight: ComputedRef<string>
  xMarkIcon: ComputedRef<string>
  eclipseLoaderIcon: ComputedRef<string>
  feedbackIcon: ComputedRef<string>
  brainSuggestionIcon: ComputedRef<string>
  checkmarkIconMobile: ComputedRef<string>
  curvedDownArrow: ComputedRef<string>
  curvedDownArrow2: ComputedRef<string>
  cornerLeftDown: ComputedRef<string>
  paypalIcon: ComputedRef<string>
  michaelAvatar: ComputedRef<string>
  phoneIcon: ComputedRef<string>
  pencilIcon: ComputedRef<string>
  news: ComputedRef<string>
  chrome: ComputedRef<string>
  edge: ComputedRef<string>
  trashIcon: ComputedRef<string>
  plusIcon: ComputedRef<string>
  cardEmptyImage: ComputedRef<string>
  openCourseIcon: ComputedRef<string>
  cardInProgressPattern: ComputedRef<string>
  vectorArrow: ComputedRef<string>
  menuIcon: ComputedRef<string>
  curvedArrowLeft: ComputedRef<string>
  thickCheckmark: ComputedRef<string>
  undoIcon: ComputedRef<string>
  unlockIcon: ComputedRef<string>
  warning: ComputedRef<string>
  plusVectorIcon: ComputedRef<string>
  progress: ComputedRef<string>
  bookIcon: ComputedRef<string>
  playCircleIcon: ComputedRef<string>
  zapIcon: ComputedRef<string>
  trophy: ComputedRef<string>
  heart: ComputedRef<string>
  signupBackground: ComputedRef<string>
  sale: ComputedRef<string>
  headphones: ComputedRef<string>
  course34iIcon: ComputedRef<string>
  course34dIcon: ComputedRef<string>
  course34fIcon: ComputedRef<string>
}

export default function useIcons(): useIconsReturn {
  const { isMobileDevice } = useBreakpoint()

  const logo = computed((): string => {
    if (isMobileDevice.value) return require('@/assets/images/logo-sm.svg')
    return require('@/assets/images/logo.svg')
  })
  const defaultLogo = computed((): string => require('@/assets/images/logo.svg'))
  const smallLogo = computed((): string => require('@/assets/images/logo-sm.svg'))
  const allBookmarks = computed((): string => require('@/assets/images/bookmarks.svg'))
  const bookmarkInactive = computed((): string => require('@/assets/images/bookmarkInactive.svg'))
  const bookmarkActive = computed((): string => require('@/assets/images/bookmarkActive.svg'))
  const expandMoreOptions = computed((): string => require('@/assets/images/expandMore.svg'))
  const quizIcon = computed((): string => require('@/assets/images/quizIcon.svg'))
  const arrowRight = computed((): string => require('@/assets/images/right.svg'))
  const closeIcon = computed((): string => require('@/assets/images/close.svg'))
  const thumbsUp = computed((): string => require('@/assets/images/thumbsUp.svg'))
  const heavyThumbsUp = computed((): string => require('@/assets/images/heavy-thumbs-up.svg'))
  const thumbsDown = computed((): string => require('@/assets/images/thumbsDown.svg'))
  const checkedCheckboxSquare = computed((): string => require('@/assets/images/checked-checkbox-square.svg'))
  const checkedCheckboxSquareWhite = computed((): string => require('@/assets/images/checkboxChecked_white.svg'))
  const checkedCheckboxSquareGreen = computed((): string => require('@/assets/images/checkboxSquareCheckedGreen.svg'))
  const checkboxSquare = computed((): string => require('@/assets/images/checkbox-square.svg'))
  const checkboxSquareWhite = computed((): string => require('@/assets/images/checkbox_white.svg'))
  const noVideoImage = computed((): string => require('@/assets/images/no-video.svg'))
  const checkedCircle = computed((): string => require('@/assets/images/checkedCircle.svg'))
  const checkedRadio = computed((): string => require('@/assets/images/checked-radio.svg'))
  const uncheckedCircle = computed((): string => require('@/assets/images/uncheckedCircle.svg'))
  const crossDanger = computed((): string => require('@/assets/images/danger.svg'))
  const wrongAnswerIcon = computed((): string => require('@/assets/images/wrongAnswerIcon.svg'))
  const artTrack = computed((): string => require('@/assets/images/art_track.svg'))
  const caretDown = computed((): string => require('@/assets/images/caret-down.svg'))
  const waitingIcon = computed((): string => require('@/assets/images/waitingIcon.svg'))
  const lockIcon = computed((): string => require('@/assets/images/lock.svg'))
  const searchIcon = computed((): string => require('@/assets/images/search.svg'))
  const folderIcon = computed((): string => require('@/assets/images/thumb_folder.svg'))
  const documentIcon = computed((): string => require('@/assets/images/thumb_document.svg'))
  const arrowDown = computed((): string => require('@/assets/images/arrow-down.svg'))
  const arrowAltRight = computed((): string => require('@/assets/images/arrow-alt-right.svg'))
  const search404 = computed((): string => require('@/assets/images/search-404.svg'))
  const playButton = computed((): string => require('@/assets/images/play-button.svg'))
  const articleImage = computed((): string => require('@/assets/images/article.svg'))
  const arrowUpLeft = computed((): string => require('@/assets/images/small-arrow-up-left.svg'))
  const courseCheckmark = computed((): string => require('@/assets/images/course-checkmark.svg'))
  const googleAvatar = computed((): string => require('@/assets/images/google-avatar.svg'))
  const facebookAvatar = computed((): string => require('@/assets/images/facebook-avatar.svg'))
  const editIcon = computed((): string => require('@/assets/images/edit-icon.svg'))
  const checkmark = computed((): string => require('@/assets/images/checkmark.svg'))
  const defaultUserIcon = computed((): string => require('@/assets/images/default_user_icon.svg'))
  const baufiLogo = computed((): string => require('@/assets/images/logo_baufi.png'))
  const finlinkLogo = computed((): string => require('@/assets/images/logo_finlink.png'))
  const kreditplanetLogo = computed((): string => require('@/assets/images/logo_kreditplanet.png'))
  const zinskonzeptLogo = computed((): string => require('@/assets/images/logo_zinskonzept.png'))
  const certLogo = computed((): string => require('@/assets/images/certLogo.svg'))
  const logoMarker = computed((): string => require('@/assets/images/marker.svg'))
  const play = computed((): string => require('@/assets/images/play.svg'))
  const document = computed((): string => require('@/assets/images/document.svg'))
  const sidebarArrow = computed((): string => require('@/assets/images/sidebarArrow.svg'))
  const shuffle = computed((): string => require('@/assets/images/shuffle.svg'))
  const success = computed((): string => require('@/assets/images/success.svg'))
  const successWhite = computed((): string => require('@/assets/images/successWhite.svg'))
  const list = computed((): string => require('@/assets/images/list.svg'))
  const checkboxCircleGreen = computed((): string => require('@/assets/images/checkboxCircleGreen.svg'))
  const openNewTab = computed((): string => require('@/assets/images/openNewTab.svg'))
  const externalIcon = computed((): string => require('@/assets/images/external-icon.svg'))
  const arrowRightIndicator = computed((): string => require('@/assets/images/arrowRight.svg'))
  const rightNavigation = computed((): string => require('@/assets/images/rightNavigation.svg'))
  const leftNavigation = computed((): string => require('@/assets/images/leftNavigation.svg'))
  const timelineImage = computed((): string => require('@/assets/images/setup_checklist_hero.png'))
  const timelineImageLightMode = computed((): string => require('@/assets/images/timeline-image-light-mode.png'))
  const checkedListItem = computed((): string => require('@/assets/images/checked-list-item.svg'))
  const profileCompleted = computed((): string => require('@/assets/images/profile-completed.svg'))
  const upsellImage = computed((): string => require('@/assets/images/upsell-image.svg'))
  const diamondIcon = computed((): string => require('@/assets/images/diamond.svg'))
  const diamondBlackIcon = computed((): string => require('@/assets/images/diamond-black.svg'))
  const upsellCheckbox = computed((): string => require('@/assets/images/upsell-checkbox.svg'))
  const globeIcon = computed((): string => require('@/assets/images/globe.svg'))
  const authorIcon = computed((): string => require('@/assets/images/author.svg'))
  const authorVerifiedIcon = computed((): string => require('@/assets/images/author-verified.png'))
  const premiumCourseIcon = computed((): string => require('@/assets/images/premium-course.svg'))
  const signet = computed((): string => require('@/assets/images/signet.svg'))
  const categoryInterestImage = computed((): string => require('@/assets/images/interest-image.svg'))
  const sepa = computed((): string => require('@/assets/images/sepa.svg'))
  const mastercard = computed((): string => require('@/assets/images/mastercard.svg'))
  const hourGlassIcon = computed((): string => require('@/assets/images/hour-glass.svg'))
  const specialCheckmark = computed((): string => require('@/assets/images/checkmark_light.svg'))
  const specialBlackCheckmark = computed((): string => require('@/assets/images/checkmark_dark.svg'))
  const vectorRight = computed((): string => require('@/assets/images/vectorRight.svg'))
  const durationIcon = computed((): string => require('@/assets/images/duration.svg'))
  const zigZagIcon = computed((): string => require('@/assets/images/zigzag.svg'))
  const device = computed((): string => require('@/assets/images/device.svg'))
  const lesson = computed((): string => require('@/assets/images/lesson.svg'))
  const certificate = computed((): string => require('@/assets/images/certificate.svg'))
  const moduleIcon = computed((): string => require('@/assets/images/module_icon.svg'))
  const open = computed((): string => require('@/assets/images/open.svg'))
  const activeLessonIcon = computed((): string => require('@/assets/images/active_lesson_icon.svg'))
  const download = computed((): string => require('@/assets/images/download.svg'))
  const bigDownloadIcon = computed((): string => require('@/assets/images/icon-download.svg'))
  const previousIcon = computed((): string => require('@/assets/images/back.svg'))
  const nextIcon = computed((): string => require('@/assets/images/next.svg'))
  const lessonCompletedIcon = computed((): string => require('@/assets/images/lesson_completed_icon.svg'))
  const playIcon = computed((): string => require('@/assets/images/playIcon.svg'))
  const documentAltIcon = computed((): string => require('@/assets/images/docs.svg'))
  const courseCertIcon = computed((): string => require('@/assets/images/zert.svg'))
  const courseCertIconWhite = computed((): string => require('@/assets/images/zertWhite.svg'))
  const purchaseIcon = computed((): string => require('@/assets/images/purchased_icon.svg'))
  const iconPdf = computed((): string => require('@/assets/images/icon-pdf.svg'))
  const iconPause = computed((): string => require('@/assets/images/icon-pause-btn.svg'))
  const pauseBtn = computed((): string => require('@/assets/images/pause-btn.svg'))
  const playBtn = computed((): string => require('@/assets/images/play-btn.svg'))
  const starIcon = computed((): string => require('@/assets/images/star.svg'))
  const star5Icon = computed((): string => require('@/assets/images/star-5.svg'))
  const beginnerCoursesIcon = computed((): string => require('@/assets/images/beginner-courses.svg'))
  const subjectCoursesIcon = computed((): string => require('@/assets/images/subject-courses.svg'))
  const expertCoursesIcon = computed((): string => require('@/assets/images/expert-courses.svg'))
  const userSectionIcon = computed((): string => require('@/assets/images/user-section-icon.svg'))
  const openCategory = computed((): string => require('@/assets/images/open-category.svg'))
  const closedCategory = computed((): string => require('@/assets/images/closed-category.svg'))
  const usersEmptyStateHero = computed((): string => require('@/assets/images/usersEmptyStateHero.svg'))
  const checkedStar = computed((): string => require('@/assets/images/checkedStar.svg'))
  const checkboxLight = computed((): string => require('@/assets/images/checkboxLight.svg'))
  const xMarkIcon = computed((): string => require('@/assets/images/xMarkIcon.svg'))
  const eclipseLoaderIcon = computed((): string => require('@/assets/images/eclipse-loader.svg'))
  const feedbackIcon = computed((): string => require('@/assets/images/feedback-icon.svg'))
  const brainSuggestionIcon = computed((): string => require('@/assets/images/brainSuggestionIcon.svg'))
  const checkmarkIconMobile = computed((): string => require('@/assets/images/checkmarkIconMobile.svg'))
  const curvedDownArrow = computed((): string => require('@/assets/images/curvedDownArrow.svg'))
  const curvedDownArrow2 = computed((): string => require('@/assets/images/curvedDownArrow2.svg'))
  const cornerLeftDown = computed((): string => require('@/assets/images/cornerLeftDown.svg'))
  const paypalIcon = computed((): string => require('@/assets/images/paypal-mark-color.svg'))
  const michaelAvatar = computed((): string => require('@/assets/images/michaelAvatar.png'))
  const phoneIcon = computed((): string => require('@/assets/images/phoneIcon.svg'))
  const pencilIcon = computed((): string => require('@/assets/images/pencil.svg'))
  const news = computed((): string => require('@/assets/images/news.svg'))
  const chrome = computed((): string => require('@/assets/images/chrome.svg'))
  const edge = computed((): string => require('@/assets/images/edge.svg'))
  const trashIcon = computed((): string => require('@/assets/images/trash.svg'))
  const plusIcon = computed((): string => require('@/assets/images/plus.svg'))
  const cardEmptyImage = computed((): string => require('@/assets/images/card_empty_image.png'))
  const openCourseIcon = computed((): string => require('@/assets/images/open_course_icon.svg'))
  const cardInProgressPattern = computed((): string => require('@/assets/images/card_in_progress_pattern.svg'))
  const vectorArrow = computed((): string => require('@/assets/images/vectorArrow.svg'))
  const menuIcon = computed((): string => require('@/assets/images/menu-icon.svg'))
  const curvedArrowLeft = computed((): string => require('@/assets/images/curved-arrow-left.svg'))
  const thickCheckmark = computed((): string => require('@/assets/images/thick-checkmark.svg'))
  const undoIcon = computed((): string => require('@/assets/images/undo-icon.svg'))
  const unlockIcon = computed((): string => require('@/assets/images/unlocked_icon.svg'))
  const warning = computed((): string => require('@/assets/images/warning.svg'))
  const plusVectorIcon = computed((): string => require('@/assets/images/plusVectorIcon.svg'))
  const progress = computed((): string => require('@/assets/images/progress.svg'))
  const bookIcon = computed((): string => require('@/assets/images/bookIcon.svg'))
  const playCircleIcon = computed((): string => require('@/assets/images/playCircleIcon.svg'))
  const zapIcon = computed((): string => require('@/assets/images/zapIcon.svg'))
  const trophy = computed((): string => require('@/assets/images/trophy.svg'))
  const heart = computed((): string => require('@/assets/images/Heart.svg'))
  const signupBackground = computed((): string => require('@/assets/images/signupBackground.svg'))
  const sale = computed((): string => require('@/assets/images/sale.svg'))
  const headphones = computed((): string => require('@/assets/images/headphones.svg'))
  const course34iIcon = computed((): string => require('@/assets/images/34i.svg'))
  const course34dIcon = computed((): string => require('@/assets/images/34d.svg'))
  const course34fIcon = computed((): string => require('@/assets/images/34f.svg'))

  return {
    defaultLogo,
    logo,
    smallLogo,
    allBookmarks,
    bookmarkInactive,
    bookmarkActive,
    expandMoreOptions,
    quizIcon,
    arrowRight,
    closeIcon,
    thumbsUp,
    heavyThumbsUp,
    thumbsDown,
    checkedCheckboxSquare,
    checkedCheckboxSquareWhite,
    checkedCheckboxSquareGreen,
    checkboxSquare,
    checkboxSquareWhite,
    noVideoImage,
    checkedCircle,
    checkedRadio,
    uncheckedCircle,
    crossDanger,
    wrongAnswerIcon,
    artTrack,
    caretDown,
    waitingIcon,
    lockIcon,
    searchIcon,
    folderIcon,
    documentIcon,
    arrowDown,
    arrowAltRight,
    search404,
    playButton,
    articleImage,
    arrowUpLeft,
    courseCheckmark,
    googleAvatar,
    facebookAvatar,
    editIcon,
    checkmark,
    defaultUserIcon,
    baufiLogo,
    finlinkLogo,
    kreditplanetLogo,
    zinskonzeptLogo,
    certLogo,
    logoMarker,
    play,
    document,
    sidebarArrow,
    shuffle,
    success,
    successWhite,
    list,
    checkboxCircleGreen,
    openNewTab,
    externalIcon,
    arrowRightIndicator,
    rightNavigation,
    leftNavigation,
    timelineImage,
    timelineImageLightMode,
    checkedListItem,
    profileCompleted,
    upsellImage,
    diamondIcon,
    diamondBlackIcon,
    upsellCheckbox,
    globeIcon,
    authorIcon,
    authorVerifiedIcon,
    premiumCourseIcon,
    signet,
    categoryInterestImage,
    sepa,
    mastercard,
    hourGlassIcon,
    specialCheckmark,
    specialBlackCheckmark,
    vectorRight,
    durationIcon,
    zigZagIcon,
    device,
    lesson,
    certificate,
    moduleIcon,
    open,
    activeLessonIcon,
    download,
    bigDownloadIcon,
    previousIcon,
    nextIcon,
    lessonCompletedIcon,
    playIcon,
    documentAltIcon,
    courseCertIcon,
    courseCertIconWhite,
    purchaseIcon,
    iconPdf,
    starIcon,
    star5Icon,
    iconPause,
    pauseBtn,
    playBtn,
    beginnerCoursesIcon,
    subjectCoursesIcon,
    expertCoursesIcon,
    userSectionIcon,
    openCategory,
    closedCategory,
    usersEmptyStateHero,
    checkedStar,
    checkboxLight,
    xMarkIcon,
    eclipseLoaderIcon,
    feedbackIcon,
    brainSuggestionIcon,
    checkmarkIconMobile,
    curvedDownArrow,
    curvedDownArrow2,
    cornerLeftDown,
    paypalIcon,
    michaelAvatar,
    phoneIcon,
    pencilIcon,
    news,
    chrome,
    edge,
    trashIcon,
    plusIcon,
    cardEmptyImage,
    openCourseIcon,
    cardInProgressPattern,
    vectorArrow,
    menuIcon,
    curvedArrowLeft,
    thickCheckmark,
    undoIcon,
    unlockIcon,
    warning,
    plusVectorIcon,
    progress,
    bookIcon,
    playCircleIcon,
    zapIcon,
    trophy,
    heart,
    signupBackground,
    sale,
    headphones,
    course34iIcon,
    course34dIcon,
    course34fIcon,
  }
}
