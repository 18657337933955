<template lang="pug">
.upgrade-popup(@click='routeToPremium')
  h5.free-content(v-if='showFreeContentCopy') {{ $t('upsellSection.copyBlock.alreadyUsedUpFreeContent') }}
  h2 {{ headerTitle }}
  h5.our-mission {{ $t('upsellSection.copyBlock.ourMission') }}
  .checked-items
    .check(:key='item', v-for='item in checkedItems')
      img(:src='specialBlackCheckmark')
      h5(v-html='item')
  .button-container
    KetchUpButton.secondary(@click.native.stop='routeToPremium')
      SVGRenderer(:has-hover='false', :icon='diamondIcon', :stroke-color='isDarkMode ? "#fff" : "#111122"')
      h5 {{ $t('upsellSection.copyBlock.becomePro') }}
    .cta
      h5.strike 299,00€
      h3 199,00€ im Jahr
  img.upsell-image(:src='upsellImage', v-if='!isSmallerMobileDevice')
</template>

<script setup lang="ts">
  import useIcons from '@/composables/useIcons'
  import KetchUpButton from '@/components/common/KetchUpButton.vue'
  import useBreakpoint from '@/composables/useBreakpoint'
  import { UserModule } from '@/store/modules/user'
  import useCommonMixin from '@/composables/useCommonMixin'
  import SVGRenderer from '@/components/common/SVGRenderer.vue'
  import useI18n from '@/composables/useI18n'

  defineProps({
    headerTitle: {
      required: true,
      type: String,
    },
    showFreeContentCopy: {
      type: Boolean,
      default: false,
    },
  })

  const { isSmallerMobileDevice } = useBreakpoint()
  const { upsellImage, diamondIcon, specialBlackCheckmark } = useIcons()
  const { showVerifyEmailToasterMessage, isDarkMode } = useCommonMixin()
  const { translateString } = useI18n()

  const checkedItems = [
    translateString('upsellSection.copyBlock.checkItem1'),
    translateString('upsellSection.copyBlock.checkItem2'),
    translateString('upsellSection.copyBlock.checkItem3'),
  ]

  const routeToPremium = () => {
    if (!UserModule.emailVerified) {
      showVerifyEmailToasterMessage()
      return
    }
    window.location.href = '/premium'
  }
</script>

<style lang="postcss">
  .upgrade-popup {
    @apply ketch-rounded-large ketch-flex-1 ketch-bg-white ketch-text-gray-700;
    @apply ketch-px-c20 md:ketch-px-c30 ketch-py-c20 ketch-relative;
    .free-content {
      @apply ketch-mb-c20;
    }
    h2 {
      @apply ketch-px-c12 ketch-py-c10 ketch-bg-primary-color ketch-text-primary-foreground-color;
      @apply ketch-inline-block ketch-rounded-normal;
    }
    .our-mission {
      @apply ketch-mt-c15;
    }
    .checked-items {
      @apply ketch-space-y-c8 ketch-my-c30;
      .check {
        @apply ketch-flex ketch-items-center;
        img {
          @apply ketch-mr-c5;
        }
        span {
          @apply ketch-font-bold;
        }
      }
    }
    .button-container {
      @apply ketch-flex ketch-space-x-c10;
      button {
        @apply ketch-outline-none ketch-space-x-c10;
        h5 {
          @apply ketch-font-bold;
        }
      }
      .cta {
        .strike {
          @apply ketch-line-through;
        }
        h3 {
          @apply ketch-font-bold ketch-inline-block;
        }
      }
    }
    .upsell-image {
      @apply ketch-absolute ketch-top-c20 lg:ketch-top-c30 xl:ketch-top-c40 ketch--right-c20;
      @apply ketch-right-c20 lg:ketch--right-c10 xl:ketch-right-c55;
      @apply md:ketch-scale-75 lg:ketch-scale-[0.85] xl:ketch-scale-100;
      h4 {
        @apply ketch-font-bold;
      }
    }
  }
</style>
