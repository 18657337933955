<template lang="pug">
.group-management
  OverviewActionBar(:count='totalGroups', :text='String($t("homepage.groupsInYourAcademy"))')
    .add-groups
      KetchUpButton.primary(:disabled='hasNewGroup', @click.native='addNewGroup')
        h5 {{ $t('homepage.createGroup') }}
        SVGRenderer(
          :has-hover='false',
          :icon='plusVectorIcon',
          :stroke-color='"var(--primary-foreground-color)"',
          width='12'
        )
  .group-management-container(ref='container')
    .group-management-container__group(:class='{ new: group.id === "" }', :key='group.id', v-for='group in userGroups')
      .group--wrapper
        .title-description(
          :class='{ "all-users": group.name === "All Users" }',
          @click='editingKey = group.id',
          v-if='![editingKey, ""].includes(group.id)'
        )
          .title-section
            .left-side
              .color(:style='{ "background-color": groupBackgroundColor(group.colorId) }', v-if='group.colorId')
              TextRenderer.title(:source='group.name || ""')
              template(v-if='specificGroupUsersCount(group.id)')
                h5.total-users [{{ specificGroupUsersCount(group.id) }}]
                h6.badge(v-if='group.name === "All Users"') SYSTEM
            SVGRenderer(:fill-color='"var(--primary-color)"', :has-hover='false', :icon='pencilIcon')
          TextRenderer.description(:source='group.description || ""')
        UserGroupEditor(
          :cancel='onCancel',
          :group='group',
          @reload-user-members='$emit("reload-user-members")',
          @reset-editing-key='editingKey = ""',
          v-if='[editingKey, ""].includes(group.id)'
        )
        h6.course-access(
          @click.stop='openAssignCoursesToGroupModal(group)',
          v-if='![editingKey, ""].includes(group.id)'
        ) {{ $t('homepage.accessToCourses', { totalCourses: totalCourses(group) }) }}
      .user-table-wrapper-pagination-cta(v-if='groupUsers(group.id)')
        UserTable(:no-users-text='String($t("homepage.noUsersInGroup"))', :users='groupUsers(group.id)')
        LoadingSpinner(v-if='loadingMoreUsers')
        .load-more-or-all(v-else-if='!loadedAllGroupUsers(group.id) && xMoreUsersToLoad(group.id) > 0')
          h5
            span(@click='loadMoreGroupUsers(group.id)') {{ loadXMore(group.id) }}
            span {{ $t('or') }}
            span(@click='loadAllGroupUsers(group.id)') {{ $t('loadAll') }}
      .user-table(v-else)
</template>

<script setup lang="ts">
  import { computed, onMounted, ref } from 'vue'
  import { UserModule } from '@/store/modules/user'
  import KetchUpButton from '@/components/common/KetchUpButton.vue'
  import SVGRenderer from '@/components/common/SVGRenderer.vue'
  import useIcons from '@/composables/useIcons'
  import OverviewActionBar from '@/components/user-management/OverviewActionBar.vue'
  import UserTable from '@/components/user-management/UserTable.vue'
  import TextRenderer from '@/components/editor/TextRenderer.vue'
  import LoadingSpinner from '@/components/common/LoadingSpinner.vue'
  import { CourseModule } from '@/store/modules/course'
  import useCommonMixin from '@/composables/useCommonMixin'
  import eventBus from '@/main'
  import useI18n from '@/composables/useI18n'
  import type { MemberGroup } from '@/services/interfaces/Auth0'
  import UserGroupEditor from '@/components/user-management/UserGroupEditor.vue'

  defineEmits(['reload-user-members'])

  const { plusVectorIcon, pencilIcon } = useIcons()
  const { groupBackgroundColor, userMembersPerPageLimit } = useCommonMixin()
  const { translateString } = useI18n()

  const editingKey = ref('')
  const loadingMoreUsers = ref(false)

  const userGroups = computed(() => UserModule.userGroups || [])

  const hasNewGroup = computed(() => userGroups.value.some((g) => g.id === ''))

  const allUserGroupMembers = computed(() => UserModule.userMembers?.groups)

  const totalGroups = computed(() => UserModule.userGroups?.length ?? 0)

  const specificGroupMembersResponse = computed(() => (groupId: string) => {
    return allUserGroupMembers.value?.find((g) => g.groupId === groupId)
  })

  const specificGroupTotalUsersCount = computed(() => (groupId: string) => {
    if (!specificGroupMembersResponse.value(groupId)) return 0
    return specificGroupMembersResponse.value(groupId)?.totalUsers ?? 0
  })

  const specificGroupUsersCount = computed(() => (groupId: string) => {
    if (!specificGroupMembersResponse.value(groupId)) return 0
    return specificGroupMembersResponse.value(groupId)?.users.length ?? 0
  })

  const loadedAllGroupUsers = computed(() => (groupId: string) => {
    return specificGroupTotalUsersCount.value(groupId) === specificGroupUsersCount.value(groupId)
  })

  const xMoreUsersToLoad = computed(() => (groupId: string) => {
    return specificGroupTotalUsersCount.value(groupId) - specificGroupUsersCount.value(groupId) >
      userMembersPerPageLimit.value
      ? userMembersPerPageLimit.value
      : specificGroupTotalUsersCount.value(groupId) - specificGroupUsersCount.value(groupId)
  })

  const loadXMore = computed(
    () => (groupId: string) => translateString('loadXMore', { number: xMoreUsersToLoad.value(groupId) }),
  )

  const groupUsers = computed(() => (groupId: string) => specificGroupMembersResponse.value(groupId)?.users ?? [])

  const totalCourses = computed(() => (group: MemberGroup) => CourseModule.groupAssignedCourses(group.id).length)

  const onCancel = (key: string) => {
    if (key === '') {
      const index = UserModule.userGroups?.findIndex((g) => g.id === key)
      if (index && index > -1) {
        UserModule.userGroups?.splice(index, 1)
      }
      return
    } else {
      editingKey.value = ''
    }
  }

  const groupColor = computed(() => {
    return (index: number) => {
      const colors = UserModule.groupColors
      if (!colors) return { id: '' }
      return colors[index % colors.length]
    }
  })

  const container = ref<any>(null)
  const addNewGroup = () => {
    if (!UserModule.userGroups?.some((g) => g.id === '')) {
      UserModule.userGroups?.push({
        id: '',
        name: '',
        description: '',
        includeInGroupProfileQuestion: false,
        colorId: groupColor.value(UserModule.userGroups.length).id,
      })
      setTimeout(() => {
        const el = container.value?.querySelector('.group-management-container__group.new') as HTMLDivElement
        if (el) {
          window.scroll({
            top: el.offsetTop,
            behavior: 'smooth',
          })
          container.value?.querySelector('.group-management-container__group.new .title input')?.focus()
        }
      }, 1)
    }
  }

  const showModal = (group: MemberGroup) => {
    eventBus.$emit('show-modal', {
      modalContentComponent: 'AssignGroupCourseModal',
      modalProps: {
        group,
      },
      cssClass: 'assign-group-course-modal',
      modalCloseCallback: (callback: () => void) => {
        if (typeof callback === 'function') callback()
      },
    })
  }

  const openAssignCoursesToGroupModal = (group: MemberGroup) => {
    if (!CourseModule.groupCourses) {
      eventBus.$emit('turn-on-loader')

      const interval = setInterval(() => {
        if (CourseModule.groupCourses) {
          clearInterval(interval)
          eventBus.$emit('turn-off-loader')
          showModal(group)
        }
      }, 100)
    } else {
      showModal(group)
    }
  }

  const loadMoreGroupUsers = (groupId: string) => {
    if (specificGroupMembersResponse.value(groupId)) {
      loadingMoreUsers.value = true
      UserModule.loadMoreUsers({
        perPage: userMembersPerPageLimit.value,
        page: specificGroupMembersResponse.value(groupId)!.currentPage + 1,
        groups: [groupId],
        userCategory: 'groups',
      }).finally(() => (loadingMoreUsers.value = false))
    }
  }

  const loadAllGroupUsers = (groupId: string) => {
    if (specificGroupMembersResponse.value(groupId)) {
      loadingMoreUsers.value = true
      UserModule.loadMoreUsers({
        perPage: userMembersPerPageLimit.value,
        page: specificGroupMembersResponse.value(groupId)!.currentPage + 1,
        groups: [groupId],
        userCategory: 'groups',
        loadAll: true,
      }).finally(() => (loadingMoreUsers.value = false))
    }
  }

  onMounted(() => {
    if (!CourseModule.groupCourses) {
      CourseModule.getGroupCourses()
    }
  })
</script>

<style lang="postcss">
  .group-management {
    &-container {
      @apply ketch-flex ketch-flex-col ketch-space-y-c40 ketch-mt-c40;
      &__group {
        @apply ketch-flex ketch-justify-between ketch-pb-c40 ketch-border-b ketch-border-dashed;
        @apply ketch-border-border-color;
        &:last-of-type {
          @apply ketch-border-0 ketch-pb-0;
        }
        .group--wrapper {
          @apply ketch-flex-auto ketch-pr-c80;
          .title-description {
            &.all-users {
              @apply ketch-pointer-events-none ketch-cursor-auto;
            }

            &:not(.editing):hover {
              @apply ketch--mx-c20 ketch--my-c20 ketch-px-c20 ketch-py-c20 ketch-cursor-pointer;
              @apply ketch-h-[fit-content] ketch-bg-white ketch-rounded-normal;
              .title-section svg {
                @apply ketch-block;
              }
            }

            .title-section {
              @apply ketch-flex ketch-justify-between ketch-mb-c10;
              .left-side {
                @apply ketch-flex ketch-items-center ketch-space-x-c15;
              }
              .color {
                @apply ketch-w-[20px] ketch-h-[20px];
                flex: 0 0 20px;
              }
              .title h2 {
                @apply ketch-text-md ketch-leading-sm ketch-font-bold;
              }

              .badge {
                @apply ketch-text-black ketch-text-opacity-[0.3] ketch-bg-black ketch-bg-opacity-[0.03];
                @apply ketch-border ketch-border-[#d9d9d9] ketch-rounded-[5px] ketch-text-[10px] ketch-leading-sm;
                @apply ketch-px-c8 ketch-flex ketch-items-center;
              }
              svg {
                @apply ketch-hidden;
              }
            }
          }
          .course-access {
            @apply ketch-mt-c40 ketch-underline ketch-cursor-pointer ketch-inline-block;
          }
        }
      }
    }
  }
</style>
