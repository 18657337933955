<template lang="pug">
.assign-group-course-modal
  h3.title-with-underline {{ $t('homepage.selectGroupCourses') }}
  h5.info {{ $t('homepage.assignGroupCourseInfo1') }}
  h5.info.next
    span.important {{ $t('important') }}:
    span {{ $t('homepage.assignGroupCourseInfo2') }}
  .courses-wrapper
    Tabs(:active-tab-index='activeTabIndex', :tabs='tabs', @tab-index='activeTabIndex = $event')
    keep-alive
      GroupCoursesTabView(
        :group-id='group.id',
        :grouped-courses='groupedOwnedCourses',
        :header-title='headerTitle',
        :submit-courses='submitAssignedCourses',
        @has-selected-courses='hasSelectedCourses = $event',
        @has-unassigned-courses='hasUnassignedCourses = $event',
        @submitting-courses='submittingAssignedCourses = $event',
        v-if='coursesOwnedByCompany.length && isActiveTab(String($t("homepage.assignedCourses.ownCourses")))'
      )
      GroupCoursesTabView(
        :group-id='group.id',
        :grouped-courses='groupedExternalCourses',
        :header-title='headerTitle',
        :submit-courses='submitAssignedCourses',
        @has-selected-courses='hasSelectedCourses = $event',
        @has-unassigned-courses='hasUnassignedCourses = $event',
        @submitting-courses='submittingAssignedCourses = $event',
        v-if='externalCourses.length && isActiveTab(String($t("homepage.assignedCourses.externalCourses")))'
      )
  .apply
    KetchUpButton.primary(
      :disabled='submittingAssignedCourses || !(hasSelectedCourses || hasUnassignedCourses)',
      @click.native='submitAssignedCourses = true'
    )
      LoadingSpinner(v-if='submittingAssignedCourses')
      h5(v-else) {{ $t('homepage.applyChanges') }}
</template>

<script setup lang="ts">
  import { computed, ref } from 'vue'
  import { CourseModule } from '@/store/modules/course'
  import Tabs from '@/components/common/Tabs.vue'
  import GroupCoursesTabView from '@/components/course/GroupCoursesTabView.vue'
  import KetchUpButton from '@/components/common/KetchUpButton.vue'
  import LoadingSpinner from '@/components/common/LoadingSpinner.vue'
  import useCourse from '@/composables/useCourse'
  import useI18n from '@/composables/useI18n'
  import type { MemberGroup } from '@/services/interfaces/Auth0'
  import type { PropType } from 'vue'
  import type { CourseItem } from '@/services/interfaces/Course'

  defineProps({
    group: {
      type: Object as PropType<MemberGroup>,
      required: true,
    },
  })

  const { groupCourses } = useCourse()
  const { translateString } = useI18n()
  const groupedExternalCourses = ref({} as { [key: string]: CourseItem[] })
  const groupedOwnedCourses = ref({} as { [key: string]: CourseItem[] })
  const activeTabIndex = ref(0)
  const submitAssignedCourses = ref(false)
  const submittingAssignedCourses = ref(false)
  const hasSelectedCourses = ref(false)
  const hasUnassignedCourses = ref(false)

  const coursesOwnedByCompany = computed(() => {
    if (!CourseModule.groupCourses) return []
    return CourseModule.groupCourses?.filter(
      (course) => course.ownedByCompany && ['ready', 'in_progress'].includes(course.state),
    )
  })

  const coursesOwnedByCompanySize = computed(() => {
    return Object.values(groupedOwnedCourses.value).flat().length
  })

  const externalCourses = computed(() => {
    if (!CourseModule.groupCourses) return []
    return CourseModule.groupCourses?.filter(
      (course) => !course.ownedByCompany && ['ready', 'in_progress'].includes(course.state),
    )
  })

  const externalCoursesSize = computed(() => {
    return Object.values(groupedExternalCourses.value).flat().length
  })

  const tabs = computed(() => {
    const _tabs = []
    if (coursesOwnedByCompany.value.length) {
      _tabs.push(
        `${translateString('homepage.assignedCourses.ownCourses')} <span>${coursesOwnedByCompanySize.value}</span>`,
      )
    }
    if (externalCourses.value.length) {
      _tabs.push(
        `${translateString('homepage.assignedCourses.externalCourses')} <span>${externalCoursesSize.value}</span>`,
      )
    }
    return _tabs
  })
  const isActiveTab = computed(() => (name: string) => tabs.value[activeTabIndex.value].startsWith(name))

  const headerTitle = computed(() => {
    return (key: string) => {
      if (key === 'trial') return translateString('homepage.assignedCourses.inTrial')
      if (key === 'inProgress') return translateString('homepage.assignedCourses.inprogressTrainings')
      return translateString('homepage.assignedCourses.otherTrainings')
    }
  })

  groupCourses(externalCourses.value, true, true, true).then((data) => {
    groupedExternalCourses.value = data as { [key: string]: CourseItem[] }
  })

  groupCourses(coursesOwnedByCompany.value, true, true, true).then((data) => {
    groupedOwnedCourses.value = data as { [key: string]: CourseItem[] }
  })
</script>

<style lang="postcss">
  .assign-group-course-modal {
    .title-with-underline {
      @apply ketch-pb-c12;
    }
    .info {
      @apply ketch-mb-c25;
      &.next {
        @apply ketch-space-x-c5;
        .important {
          @apply ketch-font-bold;
        }
      }
    }
    .courses-wrapper {
      @apply ketch-max-h-[270px] ketch-overflow-auto;
    }
    .apply {
      @apply ketch-flex ketch-justify-end ketch-mt-c20;
      button {
        @apply ketch-w-auto;
      }
    }
  }
</style>
