import { render, staticRenderFns } from "./Coupon.vue?vue&type=template&id=74b43736&scoped=true&lang=pug"
import script from "./Coupon.vue?vue&type=script&setup=true&lang=ts"
export * from "./Coupon.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./Coupon.vue?vue&type=style&index=0&id=74b43736&prod&lang=postcss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "74b43736",
  null
  
)

export default component.exports