<template lang="pug">
.assign-course-group-modal
  h3.title-with-underline {{ $t('trainings.updateGroupsAssigned') }}
  .member-groups(v-if='allMemberCourseGroups')
    Choice(
      :id='group.id',
      :key='group.id',
      :selected='isChoiceSelected(group.id)',
      :svg-renderer-props='choiceIcon(group.id)',
      :title='group.name',
      @click.native='onChoiceClick(group.id)',
      v-for='group in allMemberCourseGroups'
    )
  .apply
    KetchUpButton.primary(:disabled='submittingGroups || !hasGroupChanges', @click.native='submitAssignedGroups')
      LoadingSpinner(v-if='submittingGroups')
      h5(v-else) {{ $t('homepage.applyChanges') }}
</template>

<script setup lang="ts">
  import { computed, onMounted, ref } from 'vue'
  import { UserModule } from '@/store/modules/user'
  import Choice from '@/components/common/Choice.vue'
  import useIcons from '@/composables/useIcons'
  import KetchUpButton from '@/components/common/KetchUpButton.vue'
  import LoadingSpinner from '@/components/common/LoadingSpinner.vue'
  import useEditor from '@/composables/useEditor'
  import CourseApi from '@/services/api/CourseApi'
  import { CourseModule } from '@/store/modules/course'
  import { EditorModule } from '@/store/modules/editor'
  import eventBus from '@/main'
  import useI18n from '@/composables/useI18n'
  import type { PropType } from 'vue'
  import type { CourseItem } from '@/services/interfaces/Course'
  import { useRoute } from 'vue-router/composables'

  const props = defineProps({
    course: {
      type: Object as PropType<CourseItem>,
      required: true,
    },
    groupIds: {
      type: Array as PropType<string[]>,
      required: true,
    },
    sectionId: {
      type: String,
      required: true,
    },
  })

  const { checkedCheckboxSquareWhite, checkboxSquareWhite } = useIcons()
  const route = useRoute()
  const { hasLocalChanges } = useEditor(route)
  const { translateString } = useI18n()

  const selectedGroups = ref([] as string[])
  const submittingGroups = ref(false)

  const allMemberCourseGroups = computed(() => UserModule.userGroups || [])

  const isChoiceSelected = computed(() => (groupId: string) => selectedGroups.value.includes(groupId))

  const choiceIcon = computed(() => {
    return (groupId: string) => {
      const svgProps: any = {
        strokeColor: 'var(--primary-text-color)',
        icon: checkboxSquareWhite.value,
      }
      if (isChoiceSelected.value(groupId)) {
        svgProps.icon = checkedCheckboxSquareWhite.value
      }
      return svgProps
    }
  })

  const hasGroupChanges = computed(() => hasLocalChanges.value(props.groupIds, selectedGroups.value))

  const onChoiceClick = (groupId: string) => {
    if (selectedGroups.value.includes(groupId)) {
      const index = selectedGroups.value.indexOf(groupId)
      selectedGroups.value.splice(index, 1)
    } else {
      selectedGroups.value.push(groupId)
    }
  }

  const setVisibleToGroupToFalse = () => {
    const section = CourseModule.courseSections?.find((s) => s.id === props.sectionId)
    const editorSection = EditorModule.courseSections?.find((s) => s.id === props.sectionId)
    const course = section!.assignedCourses.find((c) => (c as CourseItem).id === props.course.id) as CourseItem
    const editorCourse = editorSection!.assignedCourses.find(
      (c) => (c as CourseItem).id === props.course.id,
    ) as CourseItem
    course['visibleToGroup'] = false
    editorCourse['visibleToGroup'] = false
  }

  const submitAssignedGroups = () => {
    const removedGroupsIds = props.groupIds.filter((g) => !selectedGroups.value.includes(g))
    const addedGroupsIds = selectedGroups.value.filter((g) => !props.groupIds.includes(g))
    const addOrRemovePromises: any[] = []

    submittingGroups.value = true
    removedGroupsIds.forEach((gId) => {
      addOrRemovePromises.push(
        CourseApi.deleteCompanyCourseFromGroup(gId, UserModule.currentCompany!.id, [props.course.id]),
      )
    })
    addedGroupsIds.forEach((gId) => {
      addOrRemovePromises.push(
        CourseApi.assignCompanyCourseToGroup(gId, UserModule.currentCompany!.id, [props.course.id]),
      )
    })

    if (selectedGroups.value.length === 0 && !props.course.purchased) {
      setVisibleToGroupToFalse()
    }

    Promise.all(addOrRemovePromises)
      .then(() => {
        CourseModule.updateGroupAssignedCourses({
          groupIds: addedGroupsIds,
          course: props.course,
          action: 'add',
        })
        CourseModule.updateGroupAssignedCourses({
          groupIds: removedGroupsIds,
          course: props.course,
          action: 'remove',
        })
        eventBus.$toasted.success(translateString('trainings.updateCourseGroupsSuccessfully') as string)
        eventBus.$emit('close-modal')
      })
      .finally(() => {
        submittingGroups.value = false
      })
  }

  onMounted(() => {
    selectedGroups.value = props.groupIds.slice()
  })
</script>

<style lang="postcss" scoped>
  .assign-course-group-modal {
    .member-groups {
      @apply ketch-flex ketch-flex-col ketch-space-y-c10;
      .choice {
        @apply ketch-w-full;
      }
    }
    .apply {
      @apply ketch-mt-c20 ketch-flex ketch-justify-end;
      button {
        @apply ketch-w-auto;
      }
    }
  }
</style>
