<script setup lang="ts">
  import { watch } from 'vue'
  import { CourseModule } from '@/store/modules/course'
  import LoadingSpinner from '@/components/common/LoadingSpinner.vue'
  import ExamStartPage from '@/components/course/ExamStartPage.vue'
  import useCourse from '@/composables/useCourse'
  import useCommonMixin from '@/composables/useCommonMixin'

  const { fetchingCourseInfo, currentCourseId } = useCourse()
  const { setIsLoadingComponentViewData } = useCommonMixin()

  setIsLoadingComponentViewData(true)
  CourseModule.getCourse({ courseId: currentCourseId.value })

  watch(fetchingCourseInfo, (value) => {
    setIsLoadingComponentViewData(value)
  })
</script>

<template lang="pug">
.course-exam-info
  LoadingSpinner(v-if='fetchingCourseInfo')
  ExamStartPage(v-else)
</template>

<style scoped lang="postcss">
  .course-exam-info {
    @apply ketch-flex ketch-items-center ketch-justify-center ketch-mt-c15 lg:ketch-mt-0;
  }
</style>
