<template lang="pug">
.admin-navigation
  .admin-navigation-wrapper
    .items-wrapper.component-spacing(
      :class='{ "showing-dashboard-view-cta": inEditorMode && $route.name === "Trainings" }'
    )
      .dashboard-view-cta(v-if='inEditorMode && $route.name === "Trainings"')
        Dropdown(
          :items='dashboardViewItems',
          :stroke-color-for-expand-icon='"var(--primary-text-color)"',
          :value='selectedDashboardView',
          @input='setDashboardViewQueryParam($event)',
          default-dropdown-behavior
        )
      .admin-navigation-items(:class='{ "in-editor-mode": inEditorMode }')
        router-link.h5(:key='index', :to='item.path', exact-path, v-for='(item, index) in navOptions') {{ item.name }}
</template>

<script setup lang="ts">
  import { computed, watch, ref } from 'vue'
  import useCommonMixin from '@/composables/useCommonMixin'
  import useEditor from '@/composables/useEditor'
  import Dropdown from '@/components/common/Dropdown.vue'
  import { UserModule } from '@/store/modules/user'
  import { useRoute, useRouter } from 'vue-router/composables'
  import useI18n from '@/composables/useI18n'

  const route = useRoute()
  const router = useRouter()
  const { isSachkundeCompany, isDrKleinCompany } = useCommonMixin()
  const { inEditorMode } = useEditor(route)
  const { translateString } = useI18n()
  const selectedDashboardView = ref('')

  const navOptions = [
    { name: translateString('navigation.admin.followups'), path: '/admin/followups' },
    { name: translateString('navigation.admin.gamification'), path: '/admin/gamification' },
    // { name: translateString('navigation.admin.theme'), path: '/admin/theme' },
    { name: translateString('navigation.admin.usersAndGroups'), path: '/admin/users-and-groups' },
  ]

  const dashboardViewItems = computed(() => {
    const items = [
      {
        type: 'editor',
        title: translateString('trainings.seeDashboardAs', { view: 'Editor' }),
        selectable: true,
      },
    ]
    if (UserModule.userGroups) {
      UserModule.userGroups.forEach((g) => {
        items.push({
          type: g.name,
          title: translateString('trainings.seeDashboardAs', { view: g.name }),
          selectable: true,
        })
      })
    }
    return items
  })

  const setDashboardViewQueryParam = (view: string) => {
    if (route.query.viewAs !== view) {
      selectedDashboardView.value = view
      router.replace({ path: route.path, query: { ...route.query, viewAs: view } })
    }
  }

  if (isSachkundeCompany.value || isDrKleinCompany.value) {
    navOptions.push({
      name: translateString('navigation.admin.analytics'),
      path: '/admin/analytics',
    })
  }

  watch(inEditorMode, (value) => {
    if (value && route.name === 'Trainings') {
      selectedDashboardView.value = 'editor'
      setDashboardViewQueryParam('editor')
    } else if (route.name === 'Trainings') {
      router.replace('/trainings').catch(() => {
        return
      })
    }
  })
</script>

<style lang="postcss">
  .admin-navigation {
    @apply ketch-flex ketch-items-center ketch-justify-center ketch-w-full;
    @apply ketch-bg-black ketch-bg-opacity-[0.04];
    .admin-navigation-wrapper {
      @apply ketch-w-full ketch-flex ketch-items-center ketch-justify-center;
      .items-wrapper {
        &.showing-dashboard-view-cta {
          @apply ketch-flex ketch-items-center ketch-justify-between;
        }
        .dashboard-view-cta {
          .dropdown {
            @apply ketch-max-w-[245px];
            .selected-item {
              @apply ketch-border-primary-text-color ketch-py-c5 ketch-h-auto ketch-max-h-[30px];
            }
            .selected-item,
            .dropdown-items {
              @apply ketch-bg-white;
            }
          }
        }
        .admin-navigation-items {
          @apply ketch-flex ketch-items-center ketch-justify-end ketch-w-full ketch-space-x-c30 ketch-py-c10;
          &.in-editor-mode {
            @apply ketch-w-auto;
          }
          a.h5 {
            @apply ketch-text-primary-text-color;
            &.router-link-active {
              @apply ketch-font-bold;
            }
          }
        }
      }
    }
  }
  .ketch-dark .admin-navigation {
    @apply ketch-bg-white ketch-bg-opacity-[0.04];
  }
</style>
