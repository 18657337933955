<script setup lang="ts">
  import { computed, onMounted, ref, watch } from 'vue'
  import { UserModule } from '@/store/modules/user'
  import OnboardingSurveyQuestion from '@/components/onboarding-survey/OnboardingSurveyQuestion.vue'
  import type {
    OnboardingSurveyAnswerActionType,
    OnboardingSurveyQuestionAnswer,
    OnboardingSurveyQuestionResponse,
  } from '@/services/interfaces/Auth0'
  import useCommonMixin from '@/composables/useCommonMixin'
  import type { CourseItem, CourseProductType, CourseVertical } from '@/services/interfaces/Course'
  import UsersApi from '@/services/api/UsersApi'
  import eventBus from '@/main'
  import useI18n from '@/composables/useI18n'
  import { CourseModule } from '@/store/modules/course'
  import CourseApi from '@/services/api/CourseApi'
  import CookieHelper from '@/helpers/CookieHelper'
  import useSegment from '@/composables/useSegment'
  import * as Sentry from '@sentry/vue'
  import AcademyProfileUpdateFormLoader from '@/components/loaders/AcademyProfileUpdateFormLoader.vue'

  const {
    extractCourseVertical,
    highlightedCourseBundlePrefix,
    identifyUserInSegmentWithExamDates,
    assignNewUserToCompany,
    setSkipSettingExamAppointment,
  } = useCommonMixin()
  const { translateString } = useI18n()
  const { frontendCourseTrialStarted, examAppointmentAdded, onboardingSurveyStarted, onboardingSurveyCompleted } =
    useSegment()

  const questionIndex = ref(0)
  const onboardingSurveyResponses = ref<OnboardingSurveyQuestionResponse[]>([])
  const loadingNextStep = ref(false)
  const lockedTrialBundle = ref<CourseVertical | null>(null)
  const examDate = ref<string | null>(null)
  const transitionName = ref<'next-slide' | 'prev-slide'>('next-slide')

  const rawCompanyOnboardingSurveyQuestions = computed(() => UserModule.companyOnboardingSurveyQuestions)

  const companyOnboardingSurveyQuestions = computed(() => {
    if (!rawCompanyOnboardingSurveyQuestions.value) return null

    // Sort questions by position, keeping General questions first
    const sortedQuestions = [...rawCompanyOnboardingSurveyQuestions.value].sort((a, b) => {
      if (a.courseVertical === 'General') return -1
      if (b.courseVertical === 'General') return 1
      return a.position - b.position
    })

    // If no locked bundle, return all sorted questions
    if (!lockedTrialBundle.value) {
      return sortedQuestions
    }

    // Filter questions to only include General and those matching locked bundle
    return sortedQuestions.filter((question) => {
      return question.courseVertical === 'General' || question.courseVertical === lockedTrialBundle.value
    })
  })

  const loadingQuestions = computed(() => companyOnboardingSurveyQuestions.value === null)

  const currentQuestion = computed(() => companyOnboardingSurveyQuestions.value?.[questionIndex.value])

  const questionResponse = computed(
    () => (questionId?: string) => onboardingSurveyResponses.value.find((resp) => resp.questionId === questionId),
  )

  const highlightedCourseBundle = computed(() =>
    CourseModule.courseBundles?.find((bundle) => bundle.title.includes(lockedTrialBundle.value!)),
  )

  const totalQuestions = computed(() => companyOnboardingSurveyQuestions.value!.length)

  const isLastQuestion = computed(() => totalQuestions.value - 1 === questionIndex.value)

  const setQuestionAnswer = (selection: {
    questionId: string
    questionType: 'single' | 'multiple'
    answer: OnboardingSurveyQuestionAnswer
    answerWasSelectedBefore: boolean
  }) => {
    cleanUpResponsesOnAnswerChange(selection.questionId, selection.answer.actionType)

    const existingResponse = questionResponse.value(selection.questionId)
    const currentlySelected = selection.answerWasSelectedBefore
    const isNowSelected = !currentlySelected

    if (existingResponse) {
      handleUpdatingExistingQuestionResponse(selection.questionType, existingResponse, selection.answer, isNowSelected)
    } else if (isNowSelected) {
      handleSettingNewQuestionResponse(selection.questionId, selection.answer)
    }
  }

  const cleanUpResponsesOnAnswerChange = (questionId: string, answerActionType: OnboardingSurveyAnswerActionType) => {
    const response = questionResponse.value(questionId)

    // Reset everything if the user is changing the locked trial course
    if (questionIndex.value === 0) {
      onboardingSurveyResponses.value = []
      lockedTrialBundle.value = null
      examDate.value = null
      return
    }

    if (!response) return

    const shouldRemoveResponse =
      (answerActionType !== 'save_custom_text' && response.customText) ||
      (answerActionType !== 'save_exam_date' && response.examDate) ||
      (response.lockedCourseType &&
        !['lock_package_course_type', 'lock_written_course_type', 'lock_oral_course_type'].includes(answerActionType))

    if (shouldRemoveResponse) {
      onboardingSurveyResponses.value = onboardingSurveyResponses.value.filter((res) => res.questionId !== questionId)
    }
  }

  const handleUpdatingExistingQuestionResponse = (
    questionType: 'single' | 'multiple',
    existingResponse: OnboardingSurveyQuestionResponse,
    answer: OnboardingSurveyQuestionAnswer,
    isNowSelected: boolean,
  ) => {
    const updateSelectedAnswers = () => {
      if (questionType === 'single') {
        existingResponse.selectedAnswerIds = isNowSelected ? [answer.id] : []
      } else {
        existingResponse.selectedAnswerIds = isNowSelected
          ? [...existingResponse.selectedAnswerIds, answer.id]
          : existingResponse.selectedAnswerIds.filter((id) => id !== answer.id)
      }
    }

    const updateLockedCourseType = (courseType: CourseProductType) => {
      existingResponse.selectedAnswerIds = isNowSelected ? [answer.id] : []
      if (isNowSelected) {
        existingResponse.lockedCourseType = courseType
      } else {
        delete existingResponse.lockedCourseType
      }
    }

    switch (answer.actionType) {
      case 'save_selected_option':
      case 'save_custom_text':
        updateSelectedAnswers()
        break

      case 'lock_trial_bundle':
        if (isNowSelected) {
          const courseVertical = extractCourseVertical(answer.description)
          existingResponse.selectedAnswerIds = [answer.id]

          if (courseVertical) {
            existingResponse.lockedTrialBundle = courseVertical
            lockedTrialBundle.value = courseVertical
          }
        } else {
          existingResponse.selectedAnswerIds = []
          delete existingResponse.lockedTrialBundle
        }
        break

      case 'save_exam_date':
        existingResponse.selectedAnswerIds = isNowSelected ? [answer.id] : []
        if (!isNowSelected) {
          delete existingResponse.examDate
        }
        break

      case 'lock_package_course_type':
        updateLockedCourseType('Package')
        break

      case 'lock_written_course_type':
        updateLockedCourseType('Written')
        break

      case 'lock_oral_course_type':
        updateLockedCourseType('Oral')
        break
    }
  }

  const handleSettingNewQuestionResponse = (questionId: string, answer: OnboardingSurveyQuestionAnswer) => {
    const baseResponse = {
      questionId,
      selectedAnswerIds: [answer.id],
    }

    const courseTypeMap: Record<string, 'Package' | 'Written' | 'Oral'> = {
      lock_package_course_type: 'Package',
      lock_written_course_type: 'Written',
      lock_oral_course_type: 'Oral',
    }

    if (answer.actionType === 'lock_trial_bundle') {
      const courseVertical = extractCourseVertical(answer.description)

      if (courseVertical) {
        onboardingSurveyResponses.value.push({ ...baseResponse, lockedTrialBundle: courseVertical })
        lockedTrialBundle.value = courseVertical
      }
      return
    }

    if (courseTypeMap[answer.actionType]) {
      onboardingSurveyResponses.value.push({
        ...baseResponse,
        lockedCourseType: courseTypeMap[answer.actionType],
      })
      return
    }

    onboardingSurveyResponses.value.push(baseResponse)
  }

  const nextQuestionOrCompleteSurvey = async () => {
    loadingNextStep.value = true
    transitionName.value = 'next-slide'

    if (isLastQuestion.value) {
      await UsersApi.setOnboardingSurveyResponses(onboardingSurveyResponses.value)
        .then(() => {
          UsersApi.updateOnboardingSurveyCompleted(UserModule.auth0Id, true)
          UserModule.setOnboardingSurveyCompleted(true)
        })
        .finally(() => {
          completeSurveyCallbacks()
          eventBus.$toasted.success(translateString('onboarding.onboardingSurveyCompleted'))
        })
    } else {
      if (questionIndex.value === 0) {
        onboardingSurveyStarted(UserModule.email)
      }
      questionIndex.value++
      loadingNextStep.value = false
    }
  }

  const completeSurveyCallbacks = () => {
    onboardingSurveyCompleted(UserModule.email)
    handleSettingCourseTrialsAndExamDate()
    loadingNextStep.value = false
  }

  const handleSettingCourseTrialsAndExamDate = () => {
    if (!highlightedCourseBundle.value) return

    const courses = highlightedCourseBundle.value.courses
    const courseIds = courses.map((course) => course.id)

    startCourseTrials(courseIds, courses)
    scheduleExamDateIfApplicable(courses)
  }

  const startCourseTrials = (courseIds: string[], courses: CourseItem[]) => {
    try {
      CourseApi.startCourseTrial(courseIds)
      CookieHelper.removeCrossDomainCookie('bundlePrefix')

      courses.forEach((course) => {
        frontendCourseTrialStarted(UserModule.email, course.id, course.title)
      })
    } catch (error) {
      Sentry.captureException(error)
    }
  }

  const scheduleExamDateIfApplicable = (courses: CourseItem[]) => {
    if (!examDate.value) {
      setSkipSettingExamAppointment(true)
      return
    }

    const examCourse = courses.find((course) => course.exam)
    if (!examCourse) return

    try {
      UsersApi.setExamAppointments([{ courseId: examCourse.id, date: examDate.value }])

      examAppointmentAdded(examCourse.id, examCourse.title, examDate.value)
      identifyUserInSegmentWithExamDates(examCourse.shortTitle, examDate.value)
    } catch (error) {
      Sentry.captureException(error)
    }
  }

  const previousQuestion = () => {
    transitionName.value = 'prev-slide'
    questionIndex.value--
  }

  const setCustomTextAnswer = (textAnswer: string) => {
    const existingResponse = questionResponse.value(currentQuestion.value?.id)

    if (existingResponse) {
      if (textAnswer.trim()) {
        existingResponse.customText = textAnswer.trim()
      } else {
        delete existingResponse.customText
      }
    }
  }

  const setExamDateAnswer = (date: string | null) => {
    const existingResponse = questionResponse.value(currentQuestion.value?.id)

    if (existingResponse) {
      if (date) {
        existingResponse.examDate = date
        examDate.value = date
      } else {
        delete existingResponse.examDate
      }
    }
  }

  const preSelectResponseFromBundlePrefix = () => {
    if (highlightedCourseBundlePrefix.value && currentQuestion.value && questionIndex.value === 0) {
      const answer = currentQuestion.value?.answers.find((a) =>
        a.description.includes(highlightedCourseBundlePrefix.value!),
      )

      if (answer) {
        setQuestionAnswer({
          questionId: currentQuestion.value!.id,
          questionType: currentQuestion.value!.questionType,
          answer: answer,
          answerWasSelectedBefore: false,
        })
      }
    }
  }

  watch(loadingQuestions, (value) => {
    if (!value) {
      preSelectResponseFromBundlePrefix()
    }
  })

  onMounted(() => {
    assignNewUserToCompany()
    preSelectResponseFromBundlePrefix()
  })
</script>

<template lang="pug">
transition(:name='transitionName', mode='out-in')
  .onboarding-survey(:key='currentQuestion?.id')
    AcademyProfileUpdateFormLoader(v-if='loadingQuestions || !currentQuestion')
    OnboardingSurveyQuestion(
      :is-last-question='isLastQuestion',
      :loading-next-step='loadingNextStep',
      :question='currentQuestion',
      :question-index='questionIndex',
      :question-response='questionResponse(currentQuestion?.id)',
      :transition-name='transitionName',
      @custom-text-answer='setCustomTextAnswer',
      @exam-date='setExamDateAnswer',
      @next-question-or-complete-survey='nextQuestionOrCompleteSurvey',
      @previous-question='previousQuestion',
      @set-question-response='setQuestionAnswer',
      :total-questions='totalQuestions',
      v-else
    )
</template>

<style lang="postcss">
  .onboarding-survey {
    @apply ketch-w-full ketch-max-w-[650px] ketch-bg-white ketch-rounded-xl ketch-p-c10 sm:ketch-p-c25;
    @apply ketch-flex ketch-flex-col ketch-items-center ketch-justify-center ketch-space-y-c20;
  }
</style>
