<template lang="pug">
.course-info-editor
  CreateOrUpdateCourse(
    :editor-title='String($t("editor.createCourse.updateCourse"))',
    :show-update-content-c-t-a='!fetchingCourseModules',
    @has-course-changes='$emit("has-course-changes", $event)',
    @update-training-content='$emit("update-training-content")',
    update-course
  )
</template>

<script setup lang="ts">
  import { computed } from 'vue'
  import CreateOrUpdateCourse from '@/components/editor/CreateOrUpdateCourse.vue'
  import { CourseModule } from '@/store/modules/course'
  import useCourse from '@/composables/useCourse'

  defineEmits(['has-course-changes', 'update-training-content'])

  const { currentCourseId } = useCourse()

  const fetchingCourseModules = computed(() => CourseModule.coursesModuleMap[currentCourseId.value] === undefined)

  if (fetchingCourseModules.value && currentCourseId.value) {
    CourseModule.getCourseModules(currentCourseId.value)
  }
</script>

<style lang="postcss">
  .course-info-editor {
    .host-description {
      .text-renderer {
        @apply xl:ketch-max-w-[500px];
      }
    }
    .state-and-feedback-email {
      .text-renderer {
        @apply xl:ketch-max-w-[250px];
      }
    }
    .course-cta-wrapper {
      @apply ketch-w-screen ketch-bg-white ketch-right-0 ketch-bottom-0 ketch-py-c10 ketch-shadow-[5px_0_0_rgb(0,0,0,10%)];
      .course-cta {
        @apply ketch-relative ketch-right-[150px] xl:ketch-right-[calc((100%-1060px)/2)];
      }
    }
  }
</style>
