<template lang="pug">
.interested-categories-contents
  LoadingSpinner.loading-content(v-if='loadingContentItems')
  .content-wrapper(data-cy='library-content', v-else-if='contentItems && contentItems.length')
    .content-slider(:key='contentItem.rootFolder.id', v-for='contentItem in contentItems')
      .slider-header
        h3 {{ contentItem.rootFolder.name }}
      CarouselSwiper(
        :break-points='breakPoints',
        :key='rerenderKey',
        :nav-class='`nav-${contentItem.rootFolder.id}`',
        :slides-per-group='4',
        :slides-per-view='4',
        :space-between='30',
        :swiper-items='contentItem.content'
      )
        template(#slide-slot='{ item }')
          .item-wrapper(@click='launchYoutubeModal(item)')
            .image-container
              PlaceholderShimmer(:animate='true', v-if='!imageLoaded(`${item.id}-${contentItem.rootFolder.id}`)')
                template(v-slot:default='{ isLoadingResource, onResourceLoaded }')
                  img.content-image(
                    :class='{ "shimmer-asset": isLoadingResource }',
                    :src='getPosterImageUrl(item)',
                    @load='onImageLoad(`${item.id}-${contentItem.rootFolder.id}`, onResourceLoaded)'
                  )
              .cover-image(:style='{ backgroundImage: `url("${getPosterImageUrl(item)}")` }')
            h5 {{ item.title }}
        template(#slide-nav, v-if='!isMobileDevice')
          .navigation(:class='`nav-${contentItem.rootFolder.id}`')
            .prev-nav-button(data-cy='prev-nav-button')
              img(:src='leftNavigation')
            .next-nav-button(data-cy='next-nav-button')
              img(:src='rightNavigation')
  .interests(v-else)
    .category-section
      InterestedCategories(
        :header='String($t("homepage.chooseYourInterests"))',
        @selected-categories='selectedInterestedCategories = $event'
      )
      KetchUpButton.primary(@click.native='postInterestedCategories')
        LoadingSpinner(v-if='isPostingInterests')
        h5(v-else) {{ $t('homepage.getCuratedContent') }}
    img(:src='categoryInterestImage', v-if='!isSmallerMobileDevice')
</template>

<script setup lang="ts">
  import { computed, ref, watch } from 'vue'
  import useBreakpoint from '@/composables/useBreakpoint'
  import useIcons from '@/composables/useIcons'
  import useCuratedContent from '@/composables/useCuratedContent'
  import CarouselSwiper from '@/components/common/CarouselSwiper.vue'
  import LoadingSpinner from '@/components/common/LoadingSpinner.vue'
  import PlaceholderShimmer from '@/components/common/PlaceholderShimmer.vue'
  import InterestedCategories from '@/components/common/InterestedCategories.vue'
  import KetchUpButton from '@/components/common/KetchUpButton.vue'
  import RootFoldersApi from '@/services/api/RootFoldersApi'
  import eventBus from '@/main'
  import useI18n from '@/composables/useI18n'
  import type { ContentItem } from '@/services/interfaces/Content'
  import useCommonMixin from '@/composables/useCommonMixin'

  const { actualContentItems, getPosterImageUrl, itemHasImage, getYoutubeId, fetchInterestedCategories } =
    useCuratedContent()
  const { leftNavigation, rightNavigation, categoryInterestImage } = useIcons()
  const { translateString } = useI18n()
  const { isMobileDevice, isSmallerMobileDevice } = useBreakpoint()
  const { setIsLoadingComponentViewData } = useCommonMixin()
  const rerenderKey = ref('')

  const breakPoints = {
    1200: {
      slidesPerView: '4',
      spaceBetween: '30',
      slidesPerGroup: '4',
    },
    1024: {
      slidesPerView: '3.2',
      spaceBetween: '30',
      slidesPerGroup: '3',
    },
    991: {
      slidesPerView: '2.45',
      spaceBetween: '30',
      slidesPerGroup: '3',
    },
    767: {
      slidesPerView: '2.2',
      spaceBetween: '20',
      slidesPerGroup: '2',
    },
    540: {
      slidesPerView: '1.2',
      spaceBetween: '15',
      slidesPerGroup: '1',
    },
  }

  const contentItems = computed(() => {
    if (!actualContentItems.value) return null
    return actualContentItems.value
      .map((item) => {
        return {
          rootFolder: item.rootFolder,
          content: item.content.filter((i) => itemHasImage(i)),
        }
      })
      .filter((item) => item.content.length)
  })

  const loadingContentItems = computed(() => contentItems.value === null)

  const launchYoutubeModal = (item: ContentItem): void => {
    const youtubeId = getYoutubeId(item.contentUrl!)
    if (youtubeId) {
      eventBus.$emit('show-modal', {
        modalContentComponent: 'YoutubeModal',
        cssClass: 'youtube',
        modalProps: {
          videoId: youtubeId,
          contentId: item.id,
        },
        modalCloseCallback: (callback: () => void) => {
          if (typeof callback === 'function') callback()
        },
      })
    }
  }

  const loadedImages: string[] = []
  const onImageLoad = (id: string, onResourceLoaded: () => void): void => {
    loadedImages.push(id)
    onResourceLoaded()
  }

  const imageLoaded = computed(() => {
    return (id: string): boolean => {
      return loadedImages.includes(id)
    }
  })

  const selectedInterestedCategories = ref([] as string[])
  const isPostingInterests = ref(false)
  const postInterestedCategories = () => {
    if (selectedInterestedCategories.value.length) {
      isPostingInterests.value = true
      RootFoldersApi.postInterestedCategories(selectedInterestedCategories.value).finally(() => {
        isPostingInterests.value = false
        fetchInterestedCategories()
      })
    } else {
      eventBus.$toasted.error(translateString('homepage.selectAnOption'))
    }
  }

  watch(isMobileDevice, () => {
    rerenderKey.value = Math.random().toString()
  })

  watch(loadingContentItems, (value) => {
    setIsLoadingComponentViewData(value)
  })
</script>

<style lang="postcss">
  .interested-categories-contents {
    @apply ketch-space-y-c50 ketch-relative;
    .loading-content {
      @apply ketch-py-c40;
    }
    .slider-header {
      @apply ketch-mb-c25;
      h3 {
        @apply ketch-font-bold;
      }
    }
    .item-wrapper {
      @apply ketch-cursor-pointer;
    }
    .slider-wrapper .image-container {
      @apply ketch-overflow-hidden ketch-rounded-large ketch-mb-c15 ketch-border;
      @apply ketch-h-[135px] xs4:ketch-h-[130px] sm:ketch-h-[155px] md:ketch-h-[142px];
      @apply lg:ketch-h-[120px] xl:ketch-h-[142px] ketch-border-transparent ketch-relative;
      img {
        filter: grayscale(1) hue-rotate(180deg) brightness(1.2);
        @apply ketch-rounded-large;
      }
      .cover-image {
        filter: grayscale(1) hue-rotate(180deg) brightness(1.2);
        @apply ketch-bg-no-repeat ketch-bg-cover ketch-absolute ketch-top-0 ketch-bottom-0 ketch-left-0 ketch-right-0;
        background-position: top -30px left;
        @screen md {
          background-position: top -25px left;
        }
      }
      &:hover {
        .cover-image {
          filter: none;
        }
        @apply ketch-border-primary-color;
      }
      .shimmer-bg {
        @apply ketch-mb-0;
      }
    }
    .navigation {
      .prev-nav-button,
      .next-nav-button {
        @apply ketch-top-[-15%] xl:ketch-top-[-13%];
      }
    }
  }
  .interests {
    @apply ketch-flex ketch-justify-between;
    .category-section .primary {
      @apply ketch-outline-none ketch-w-auto ketch-px-c20 ketch-mt-c20;
    }
    img {
      @apply ketch-scale-50 sm:ketch-scale-[0.8] md:ketch-scale-100;
      filter: invert(1);
    }
  }
  .ketch-dark .interested-categories-contents {
    .interests {
      img {
        filter: none;
      }
    }
  }
</style>
