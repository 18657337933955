<template lang="pug">
.lesson-slideshow-modal
  transition(mode='out-in', name='fade')
    .slide-image(:key='currentPage')
      PlaceholderShimmer(:animate='true', height='400px')
        template(v-slot:default='{ isLoadingResource, onResourceLoaded }')
          img(
            :class='{ "shimmer-asset": isLoadingResource }',
            :src='currentSlide.imageUrl || ""',
            @load='onResourceLoaded'
          )

  .slide-pagination
    Pagination(
      :current-page='currentPage',
      :total-pages='slides.length',
      @next='currentPage += 1',
      @page='currentPage = $event',
      @previous='currentPage -= 1'
    )

  .content-area(:key='currentPage')
    AuthorAudioSection(
      :audio-author='currentSlide.audioAuthor',
      :audio-source='currentSlide.audioUrl',
      v-if='currentSlide.audioUrl'
    )
    .slide-content
      TextRenderer.slide-title(:source='currentSlide.title || ""')
      TextRenderer(:source='currentSlide.description || ""')
</template>

<script setup lang="ts">
  import { computed, ref } from 'vue'
  import Pagination from '@/components/common/Pagination.vue'
  import PlaceholderShimmer from '@/components/common/PlaceholderShimmer.vue'
  import AuthorAudioSection from '@/components/course/AuthorAudioSection.vue'
  import TextRenderer from '@/components/editor/TextRenderer.vue'
  import type { PropType } from 'vue'
  import type { Slideshow } from '@/services/interfaces/Course'

  const props = defineProps({
    slides: {
      type: Array as PropType<Slideshow[]>,
      required: true,
    },
    pageNumber: {
      type: Number,
      required: true,
    },
  })

  const currentPage = ref(props.pageNumber)
  const currentSlide = computed(() => props.slides[currentPage.value - 1])
</script>

<style lang="postcss">
  .lesson-slideshow-modal {
    .slide-pagination {
      @apply ketch-pt-c35 ketch-pb-c25 ketch-px-c50;
    }
    .slide-image {
      .placeholder-shimmer .shimmer-wrapper {
        @apply ketch-flex ketch-justify-center;
      }
      img {
        @apply ketch-object-cover ketch-object-center ketch-max-w-full;
      }
    }
    .content-area {
      @apply ketch-px-c50;
      .author-audio-section {
        @apply ketch-mb-c55;
      }
      .slide-content {
        .slide-title {
          @apply ketch-mb-c20 ketch-font-bold;
        }
      }
    }
  }
</style>
