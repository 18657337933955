<template lang="pug">
.course-trial-expired-wrapper
  .course-trial-expired-container
    h5 {{ $t('courses.courseTrials.yourFreeTrialHasExpired') }}
    h3 {{ $t('courses.courseTrials.buyThisTrainingToPrepare') }}
    KetchUpButton.tertiary(:disabled='!course?.shopifyProductId', @click.native='shopifyCheckout')
      h5 {{ $t('courses.courseTrials.buyTraining') }}
      SVGRenderer(:has-hover='false', :icon='arrowRight', stroke-color='var(--primary-text-color)')
</template>

<script setup lang="ts">
  import { onMounted } from 'vue'
  import KetchUpButton from '@/components/common/KetchUpButton.vue'
  import SVGRenderer from '@/components/common/SVGRenderer.vue'
  import useIcons from '@/composables/useIcons'
  import useSegment from '@/composables/useSegment'
  import useCourse from '@/composables/useCourse'
  import useShopifyClient from '@/composables/useShopifyClient'

  const { arrowRight } = useIcons()
  const { coursePurchaseIntended, courseTrialExpiredPopupShown } = useSegment()
  const { course } = useCourse()
  const { createShopifyCheckout } = useShopifyClient()

  const shopifyCheckout = async () => {
    await coursePurchaseIntended(course.value!.id, course.value!.title, 'course_trial_expired_modal')
    await createShopifyCheckout()
  }

  onMounted(() => {
    courseTrialExpiredPopupShown(course.value!.id, course.value!.title)
  })
</script>

<style lang="postcss" scoped>
  .course-trial-expired-wrapper {
    @apply ketch-absolute ketch-left-0 ketch-right-0 ketch-bottom-0 ketch-top-40;
    @apply ketch-flex ketch-justify-center ketch-bg-opacity-10 ketch-h-fit;
    .course-trial-expired-container {
      @apply ketch-p-c20 ketch-bg-module-selector-background-color ketch-rounded-normal;
      @apply ketch-flex ketch-flex-col ketch-space-y-c15 ketch-max-w-[455px] ketch-shadow-lg;
      @apply md:ketch-space-y-c20;
      h3 {
        @apply ketch-font-bold;
      }
      button {
        @apply ketch-w-fit;
      }
    }
  }
</style>
