import { render, staticRenderFns } from "./CourseExamHeader.vue?vue&type=template&id=64a6acbe&scoped=true&lang=pug"
import script from "./CourseExamHeader.vue?vue&type=script&setup=true&lang=ts"
export * from "./CourseExamHeader.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./CourseExamHeader.vue?vue&type=style&index=0&id=64a6acbe&prod&lang=postcss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "64a6acbe",
  null
  
)

export default component.exports