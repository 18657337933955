import { Component, Vue } from 'vue-property-decorator'
import {
  User,
  Auth0Client,
  createAuth0Client,
  Auth0ClientOptions,
  IdToken,
  RedirectLoginOptions,
  GetTokenSilentlyOptions,
  LogoutOptions,
} from '@auth0/auth0-spa-js'
import { VueConstructor } from 'vue/types/umd'
import { v4 as uuidv4 } from 'uuid'
import * as jose from 'jose'
import * as Sentry from '@sentry/vue'
import CookieHelper from '../../helpers/CookieHelper'
import { UserModule } from '@/store/modules/user'

// Define a default action to perform after authentication
const DEFAULT_REDIRECT_CALLBACK = () => window.history.replaceState({}, document.title, window.location.pathname)

interface Auth0ClientOptionsExtension extends Auth0ClientOptions {
  audience: string
  tokenKey: string
}

let instance: VueAuth

export type RedirectCallback = (appState: { targetUrl: string }) => void

@Component
export default class VueAuth extends Vue {
  loading = true
  isAuthenticated = false
  user: User | undefined = {}
  auth0Client: Auth0Client | null = null
  popupOpen = false
  userId = ''
  options: any = {}

  async loginWithPopup(options: any): Promise<void> {
    this.popupOpen = true

    try {
      await this.auth0Client?.loginWithPopup(options)
      this.user = await this.auth0Client?.getUser()
      this.isAuthenticated = (await this.auth0Client?.isAuthenticated()) as boolean
      const token = await this.getTokenSilently(options)

      if (token) {
        localStorage.setItem(this.options.tokenKey, token)
        const url = options.redirect_uri.slice(options.redirect_uri.indexOf('?'))
        const path = options.redirect_uri.includes('redirect=') ? this.redirectPath(url) : '/'
        window.location.href = `/profile-page?redirect=${path}`
      }
    } catch (e) {
      Sentry.captureException(e)
    } finally {
      this.popupOpen = false
    }
  }

  // Handles the callback when logging in using a redirect
  async handleRedirectCallback(): Promise<void> {
    this.loading = true
    try {
      await this.auth0Client?.handleRedirectCallback()
      this.user = await this.auth0Client?.getUser()
      this.isAuthenticated = true
    } catch (e) {
      Sentry.captureException(e)
    } finally {
      this.loading = false
    }
  }

  // Authenticates the user using the redirect method
  loginWithRedirect(options?: RedirectLoginOptions): Promise<void> | undefined {
    return this.auth0Client?.loginWithRedirect(options)
  }

  // Returns all the claims present in the ID token
  getIdTokenClaims(): Promise<IdToken | undefined> | undefined {
    return this.auth0Client?.getIdTokenClaims()
  }

  // Returns the access token. If the token is invalid or missing, a new one is retrieved
  async getTokenSilently(options?: GetTokenSilentlyOptions): Promise<any> {
    return this.auth0Client?.getTokenSilently(options)
  }

  // Gets the access token using a popup window
  getTokenWithPopup(o?: any): Promise<string | undefined> | undefined {
    return this.auth0Client?.getTokenWithPopup(o)
  }

  // Logs the user out and removes their session on the authorization server
  logout(options?: LogoutOptions): void {
    this.auth0Client?.logout(options)
  }

  isSocialConnectionResponse(hash: string): boolean {
    const state = localStorage.getItem('token-state')

    return (
      !!hash &&
      !!state &&
      hash.includes(state) &&
      hash.includes('access_token=') &&
      hash.includes('state=') &&
      hash.includes('code=')
    )
  }

  signInWithSocialConnection(o: any): void {
    const state = uuidv4()
    localStorage.setItem('token-state', state)
    localStorage.setItem('redirect-to', encodeURI(o.redirect))
    const scope = 'openid profile email'
    const redirectUri = `${window.location.origin}/login/callback`
    let url = `https://${o.domain}/authorize?client_id=${o.clientId}&audience=${o.audience}&scope=${scope}&`
    url += `connection=${o.connection}&response_type=code token&state=${state}&redirect_uri=${redirectUri}`
    ;(window as any).top.location.href = url
  }

  isAcademySignupCookie(): any {
    return CookieHelper.getCookieValue('is-academy-signup')
  }

  async handleSocialSignInCallback(tokenKey: string): Promise<{ targetUrl: string } | undefined> {
    const { hash } = window.location
    if (this.isSocialConnectionResponse(hash)) {
      const [, token] = hash.split('&')[0].split('=')
      if (token) {
        CookieHelper.setCrossDomainCookie(tokenKey, token)
        localStorage.removeItem('token-state')
      }

      try {
        // Get user data from our backend to check onboarding status
        await UserModule.getUser()
        if (UserModule.onboarded) {
          window.location.href = '/'
          return { targetUrl: '/' }
        }
      } catch (error) {
        Sentry.captureException(error)
      }

      let targetUrl = this.isAcademySignupCookie() === 'true' ? '/registrierung' : '/profile-page'
      const redirectTo = localStorage.getItem('redirect-to')

      if (redirectTo) {
        localStorage.removeItem('redirect-to')
        targetUrl += '?redirect=' + redirectTo
      }

      return { targetUrl }
    }
    return
  }

  redirectPath(url?: string): string {
    const search = (url || window.location.search).replace('?', '')
    const path = search.split('&').find((str) => str.includes('redirect'))
    return path ? decodeURIComponent(path.replace(/redirect=/, '')) : ''
  }

  isUniversalLoginCallback(): boolean {
    const search = window.location.search
    return search.includes('code=') && search.includes('state=')
  }

  async handleUniversalLoginCallback(tokenKey: string): Promise<{ targetUrl: string } | undefined> {
    if (this.isUniversalLoginCallback()) {
      // handle the redirect and retrieve tokens
      const result = await this.auth0Client?.handleRedirectCallback()
      const token = await this.getTokenSilently({})

      if (token) {
        CookieHelper.setCrossDomainCookie(tokenKey, token)

        try {
          // Get user data from our backend to check onboarding status
          await UserModule.getUser()
          if (UserModule.onboarded) {
            window.location.href = '/'
            return { targetUrl: '/' }
          }
        } catch (error) {
          Sentry.captureException(error)
        }

        const path = window.location.search.includes('redirect=') ? this.redirectPath() : '/'
        const targetUrl =
          this.isAcademySignupCookie() === 'true' ? `/registrierung?redirect=${path}` : `/profile-page?redirect=${path}`

        return (result?.appState as { targetUrl: string }) || { targetUrl: targetUrl }
      }
    }
    return
  }

  // Use this lifecycle method to instantiate the SDK client
  async init(
    onRedirectCallback: RedirectCallback,
    redirectUri: string,
    options: Auth0ClientOptionsExtension,
  ): Promise<void> {
    this.options = structuredClone(options)
    const { tokenKey } = options
    // @ts-ignore
    delete options.tokenKey
    // Create a new instance of the SDK client using members of the given options object
    this.auth0Client = await createAuth0Client({
      ...options,
      domain: options.domain,
      clientId: options.clientId,
      authorizationParams: {
        audience: options.audience,
        redirect_uri: redirectUri,
      },
    })

    try {
      // If the user is returning to the app after authentication..
      let appState = await this.handleSocialSignInCallback(tokenKey)
      if (!appState) {
        appState = await this.handleUniversalLoginCallback(tokenKey)
      }

      // Notify subscribers that the redirect callback has happened, passing the appState
      // (useful for retrieving any pre-authentication state)
      if (appState) onRedirectCallback(appState)
    } catch (e) {
      Sentry.captureException(e)
    } finally {
      // Initialize our internal authentication state
      const tokenValue = CookieHelper.getCookieValue(tokenKey)
      if (tokenValue) {
        const decodedToken = jose.decodeJwt(tokenValue)
        this.isAuthenticated = !!decodedToken?.sub
        this.userId = decodedToken?.sub ?? ''
      }
      this.loading = false
    }
  }
}

// Creates an instance of the Auth0 SDK. If one has already been created, it returns that instance
export const useAuth0 = ({
  onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
  redirectUri = `${window.location.origin}/login/callback`,
  ...options
}): VueAuth => {
  if (instance) return instance

  // The "instance" is simply a Vue object
  instance = new VueAuth()
  instance.init(onRedirectCallback, redirectUri, options as Auth0ClientOptionsExtension)

  return instance
}

// Create a simple Vue plugin to expose the wrapper object throughout the application
export const Auth0Plugin = {
  install(Vue: VueConstructor, options: Auth0ClientOptionsExtension) {
    Vue.prototype.$auth = useAuth0(options)
  },
}
