<template lang="pug">
.leuckentext-quiz
  LeuckentextEditor(v-if='inEditorMode')
  template(v-else-if='leuckentext && (leuckentext.id || leuckentext.editorId)')
    template(v-if='!isMobileVersion')
      HTMLRenderer.leuckentext-title.h4(:html='leuckentext.title || ""')
      HTMLRenderer.leuckentext-desciption.h5(:html='leuckentext.description || ""')
      KetchUpButton.primary.start-quiz(@click.native='$emit("show-mobile-version")', v-if='isMobileDevice')
        h5 {{ $t('courses.leuckentext.tryTestOnLesson') }}
    .leuckentext-quiz-container
      .leuckentext-quiz-mobile-version(v-if='isMobileVersion')
        HTMLRenderer.h4.current-lesson-title(:html='currentLesson?.title || ""')
        .count-lesson-cta-statement-question
          .count-lesson-cta
            h1(v-if='inResultsMode') {{ $t('courses.leuckentext.evaluation') }}
            h4(v-else) {{ statementStatus }}
            h4.back-to-lesson(@click='backToLesson') {{ $t('courses.leuckentext.backToLesson') }}
          .statement-question-item(v-if='!inResultsMode && currentQuestionItem')
            LeuckentextQuestionItem(:item='currentQuestionItem', :selected-answer='getSelectedAnswer(droppedItem)')
        .leuckentext-results-wrapper(v-if='inResultsMode')
          LoadingSpinner(v-if='fetchingLeuckentextCompletion')
          LeuckentextResultsMode(
            :attempt='attempt',
            :correct-item-ids='correctItemIds',
            :failure-message='leuckentext.failureMessage || ""',
            :leuckentext-items='leuckentextResultItems',
            :success-message='leuckentext.successMessage || ""',
            @try-again='tryQuizAgain',
            v-else
          )
        LeuckentextAnswersPaneMobile(
          :choices='itemAnswers',
          :disable-previous-button='currentQuestionIndex === 0',
          :loading-results='fetchingResults',
          :show-submit-text='inLastItem',
          @next-or-submit='nextItemOrSubmit',
          @on-choice-click='chooseAnswer($event)',
          @previous='previousQuestionItem',
          v-else
        )
      template(v-else-if='!isMobileDevice')
        .leuckentext-results-wrapper(v-if='inResultsMode')
          LoadingSpinner(v-if='fetchingLeuckentextCompletion')
          LeuckentextResultsMode(
            :attempt='attempt',
            :correct-item-ids='correctItemIds',
            :failure-message='leuckentext.failureMessage || ""',
            :leuckentext-items='leuckentextResultItems',
            :success-message='leuckentext.successMessage || ""',
            @try-again='tryQuizAgain',
            v-else
          )
        .items-button(v-else)
          ul.leuckentext-question-items
            LeuckentextQuestionItem(
              :class='{ dragged: droppedItem === item.id }',
              :item='item',
              :key='item.id',
              :selected-answer='getSelectedAnswer(item.id || "")',
              @drag-end='resetAnswer',
              @dragenter.native='onAnswerDragEnter(item.id || "")',
              @dragover.native.prevent,
              @drop.native.prevent='onAnswerDrop',
              @on-answer-drag-start='onAnswerDragStart($event)',
              @reset-answer='resetSelectedItem',
              v-for='item in leuckentext.leuckentextItems'
            )
          KetchUpButton.primary(
            :disabled='leuckentext.leuckentextItems.length !== userAnswers.length',
            @click.native='submitAnswers'
          )
            LoadingSpinner(v-if='fetchingResults')
            template(v-else)
              h5 {{ $t('toTheResults') }}
              SVGRenderer(:has-hover='false', :icon='arrowRight', :stroke-color='"var(--primary-foreground-color)"')
        LeuckentextAnswersPane(
          :choices='itemAnswers',
          :in-results-mode='inResultsMode',
          @drag-end='resetAnswer',
          @dragged-answer='draggedAnswer = $event',
          @reset-all-answers='resetAllAnswers'
        )
</template>

<script setup lang="ts">
  import { computed, ref, watch } from 'vue'
  import LeuckentextQuestionItem from '@/components/course/leuckentext/LeuckentextQuestionItem.vue'
  import LeuckentextAnswersPane from '@/components/course/leuckentext/LeuckentextAnswersPane.vue'
  import LeuckentextEditor from '@/components/course/leuckentext/LeuckentextEditor.vue'
  import useBreakpoint from '@/composables/useBreakpoint'
  import KetchUpButton from '@/components/common/KetchUpButton.vue'
  import useCommonMixin from '@/composables/useCommonMixin'
  import LoadingSpinner from '@/components/common/LoadingSpinner.vue'
  import CourseApi from '@/services/api/CourseApi'
  import useCourse from '@/composables/useCourse'
  import useEditor from '@/composables/useEditor'
  import HTMLRenderer from '@/components/common/HTMLRenderer.vue'
  import LeuckentextAnswersPaneMobile from '@/components/course/leuckentext/LeuckentextAnswersPaneMobile.vue'
  import LeuckentextResultsMode from '@/components/course/leuckentext/LeuckentextResultsMode.vue'
  import SVGRenderer from '@/components/common/SVGRenderer.vue'
  import useIcons from '@/composables/useIcons'
  import useSegment from '@/composables/useSegment'
  import eventBus from '@/main'
  import useI18n from '@/composables/useI18n'
  import type { PropType } from 'vue'
  import type {
    LeuckentextChoice,
    LeuckentextQuiz as ILeuckentextQuiz,
    LeuckentextQuestionItem as ILeuckentextQuestionItem,
  } from '@/services/interfaces/Course'
  import { useRoute } from 'vue-router/composables'

  const props = defineProps({
    leuckentext: {
      type: Object as PropType<ILeuckentextQuiz | null>,
      default: null,
    },
    isMobileVersion: Boolean,
  })

  const emit = defineEmits(['back-to-lesson', 'show-mobile-version'])

  const route = useRoute()
  const { translateString } = useI18n()
  const { inEditorMode: editMode, decodeAndParseSource } = useEditor(route)
  const { isMobileDevice, isLargeDesktop } = useBreakpoint()
  const { leuckentextQuizCompleted } = useSegment()
  const { shuffleArray } = useCommonMixin()
  const { course, currentModule, currentLesson } = useCourse()
  const { arrowRight } = useIcons()

  const itemAnswers = ref<LeuckentextChoice[]>([])
  const userAnswers = ref<{ id: string; text: string; answer: string }[]>([])
  const draggedAnswer = ref('')
  const droppedItem = ref('')
  const itemChoiceDraggedInto = ref('')
  const itemChoiceDraggedFrom = ref('')
  const fetchingResults = ref(false)
  const currentQuestionIndex = ref(0)
  const fetchingLeuckentextCompletion = ref(false)
  const inResultsMode = ref(false)
  const attempt = ref(1)
  const correctItemIds = ref<string[]>([])
  const leuckentextResultItems = ref<ILeuckentextQuestionItem[]>([])

  const inEditorMode = computed(() => isLargeDesktop.value && editMode.value)

  const getSelectedAnswer = computed(() => (itemId?: string) => {
    if (!itemId) return ''

    const userItem = answeredItem(itemId)
    if (userItem) return userItem.answer
    const item = itemAnswers.value.find((item) => item.id === draggedAnswer.value)
    return itemId === droppedItem.value ? (item?.text ? item.text : '') : ''
  })

  const selectedAnswerId = computed(() => (itemId: string) => {
    const answer = itemAnswers.value.find((item) => item.text === getSelectedAnswer.value(itemId))
    return answer ? answer.id : ''
  })

  const currentQuestionItem = computed(() => {
    return props.leuckentext?.leuckentextItems?.[currentQuestionIndex.value]
  })

  const statementStatus = computed(() => {
    return translateString('courses.leuckentext.statementCountStatus', {
      current: currentQuestionIndex.value + 1,
      total: props.leuckentext?.leuckentextItems?.length ?? 0,
    })
  })

  const itemAnsweredIndexInUserAnswers = computed(() => {
    return (itemId: string) => {
      return userAnswers.value.findIndex((answer) => answer.id === itemId)
    }
  })

  const itemAlreadyAnswered = computed(() => {
    return (itemId: string) => {
      return itemAnsweredIndexInUserAnswers.value(itemId) > -1
    }
  })

  const inLastItem = computed(
    () => currentQuestionIndex.value === (props.leuckentext?.leuckentextItems?.length ?? 0) - 1,
  )

  const resetSelectedItem = (itemId: string) => {
    if (itemAlreadyAnswered.value(itemId)) {
      const item = itemAnswers.value.find(
        (item) => item.text === userAnswers.value[itemAnsweredIndexInUserAnswers.value(itemId)].answer,
      )
      item!.selectable = true
      userAnswers.value.splice(itemAnsweredIndexInUserAnswers.value(itemId), 1)
    }
  }

  const answeredItem = (itemId: string) => {
    return userAnswers.value.find((item) => item.id === itemId)
  }

  const onAnswerDragEnter = (itemId?: string) => {
    if (!itemId) return

    if (itemAlreadyAnswered.value(itemId)) {
      itemChoiceDraggedInto.value = answeredItem(itemId)!.id
    } else {
      itemChoiceDraggedInto.value = ''
    }
    droppedItem.value = itemId
  }

  const onAnswerDragStart = (itemId: string) => {
    draggedAnswer.value = selectedAnswerId.value(itemId)
    itemChoiceDraggedFrom.value = itemId
  }

  const setSelectedItem = () => {
    const item = itemAnswers.value.find((item) => item.id === draggedAnswer.value)
    item!.selectable = false
  }

  const setUserAnswers = () => {
    const originalItem = props.leuckentext?.leuckentextItems?.find((item) => item.id === droppedItem.value)
    if (originalItem) {
      const decodedItemText = decodeAndParseSource(originalItem.text)
      const selectedAnswer = getSelectedAnswer.value(droppedItem.value)
      if (originalItem) {
        userAnswers.value.push({
          id: droppedItem.value,
          answer: selectedAnswer,
          text: encodeURIComponent(decodedItemText.replace(/{{.*}}/g, `{{${selectedAnswer}}}`)),
        })
      }
    }
  }

  const chooseAnswer = (choice: LeuckentextChoice) => {
    if (!choice.selectable) return

    draggedAnswer.value = choice.id
    if (itemAlreadyAnswered.value(droppedItem.value)) {
      resetSelectedItem(droppedItem.value)
    }
    setUserAnswers()
    setSelectedItem()
  }

  const onAnswerDrop = () => {
    if (itemChoiceDraggedFrom.value) {
      resetSelectedItem(itemChoiceDraggedFrom.value)
      itemChoiceDraggedFrom.value = ''
    }
    if (draggedAnswer.value && droppedItem.value) {
      if (itemChoiceDraggedInto.value) {
        resetSelectedItem(itemChoiceDraggedInto.value)
        itemChoiceDraggedInto.value = ''
      }
      setUserAnswers()
      setSelectedItem()
    }
  }

  const resetAnswer = () => {
    draggedAnswer.value = ''
    droppedItem.value = ''
    itemChoiceDraggedInto.value = ''
    itemChoiceDraggedFrom.value = ''
  }

  const resetAllAnswers = () => {
    userAnswers.value = []
    itemAnswers.value.forEach((ans) => (ans.selectable = true))
  }

  const tryQuizAgain = () => {
    inResultsMode.value = false
    if (isMobileDevice.value) {
      resetAllAnswers()
    }
    getAnswers()
  }

  const nextItemOrSubmit = () => {
    if (inLastItem.value) {
      // first check if all items have been answered then submit
      if (userAnswers.value.length !== props.leuckentext!.leuckentextItems.length) {
        eventBus.$toasted.error(translateString('courses.leuckentext.selectOneItem'))
      } else {
        submitAnswers()
      }
    } else {
      resetAnswer()
      currentQuestionIndex.value = currentQuestionIndex.value + 1
    }
  }

  const previousQuestionItem = () => {
    if (currentQuestionIndex.value === 0) return
    currentQuestionIndex.value = currentQuestionIndex.value - 1
  }

  const submitAnswers = () => {
    fetchingResults.value = true
    CourseApi.postLeuckentextAnswers(props.leuckentext!.id!, {
      lessonId: currentLesson.value!.id,
      leuckentextItems: userAnswers.value.map((item) => ({ id: item.id, text: item.text })),
    })
      .then(async (resp) => {
        inResultsMode.value = true
        await fetchLeuckentextCompletion(resp.completionId)
        await leuckentextQuizCompleted(
          props.leuckentext!.id!,
          resp.completionId,
          leuckentextResultItems.value.length === correctItemIds.value.length,
          course.value!.id,
          course.value!.title,
          currentModule.value.id,
          currentModule.value.name,
          currentLesson.value!.id,
          currentLesson.value!.title,
        )
      })
      .finally(() => {
        fetchingResults.value = false
      })
  }

  const fetchLeuckentextCompletion = (completionId?: string) => {
    fetchingLeuckentextCompletion.value = true
    CourseApi.getLeuckentextCompletion(
      props.leuckentext!.id!,
      completionId ? completionId : props.leuckentext!.latestCompletionId!,
    )
      .then((resp) => {
        attempt.value = resp.attempt
        correctItemIds.value = resp.correctItemIds
        leuckentextResultItems.value = resp.leuckentextItems.sort((a, b) => a.orderNumber - b.orderNumber)

        userAnswers.value = []
        resp.leuckentextItems.forEach((item) => {
          const decodedItemText = decodeAndParseSource(item.text)
          const textMatch = decodedItemText.match(/{{.*}}/)
          userAnswers.value.push({
            id: item.id!,
            answer: textMatch?.[0].replace(/[{}]/g, '') || '',
            text: item.text,
          })
        })
        itemAnswers.value.forEach((item) => {
          item.selectable = false
        })
      })
      .finally(() => (fetchingLeuckentextCompletion.value = false))
  }

  const setDroppedItem = () => {
    droppedItem.value = props.leuckentext?.leuckentextItems?.[currentQuestionIndex.value].id || ''
  }

  const getAnswers = () => {
    itemAnswers.value = shuffleArray(
      props.leuckentext?.leuckentextItems?.map((item) => {
        const decodedItemText = decodeAndParseSource(item.text)
        const textMatch = decodedItemText.match(/{{.*}}/)
        return {
          id: item.id,
          selectable: !itemAlreadyAnswered.value(item.id!),
          text: textMatch?.[0].replace(/[{}]/g, '') || '',
        }
      }) || [],
    )
  }

  const checkToSetResultsMode = () => {
    if (props.leuckentext?.latestCompletionId) {
      inResultsMode.value = true
      fetchLeuckentextCompletion()
    }
  }

  const backToLesson = () => {
    emit('back-to-lesson')
    resetAllAnswers()
  }

  watch(currentQuestionIndex, () => {
    setDroppedItem()
    const answeredChoice = itemAnswers.value.find((ans) => !ans.selectable)
    if (answeredChoice && itemAlreadyAnswered.value(droppedItem.value)) {
      draggedAnswer.value = answeredChoice.id
    }
  })

  watch(inEditorMode, (value) => {
    // set answers again once you're out of edit mode
    if (!value) {
      getAnswers()
    }
  })

  getAnswers()
  if (isMobileDevice.value) {
    setDroppedItem()
  }
  checkToSetResultsMode()
</script>

<style lang="postcss">
  .leuckentext-quiz {
    @apply ketch-space-y-c15;
    .leuckentext-title {
      @apply ketch-font-bold;
    }
    &-container {
      @apply ketch-flex ketch-flex-col md:ketch-flex-row ketch-overflow-hidden;
      @apply md:ketch-rounded-normal md:ketch-justify-between;
    }
    .leuckentext-quiz-mobile-version {
      @apply ketch-flex ketch-flex-col;
      .current-lesson-title {
        @apply ketch-mb-c35;
        > * {
          @apply ketch-font-bold;
        }
      }
      .count-lesson-cta-statement-question {
        @apply ketch-flex ketch-flex-col ketch-space-y-c8 ketch-mb-c20;
        .count-lesson-cta {
          @apply ketch-flex ketch-items-center ketch-justify-between;
          h1 {
            @apply ketch-font-bold ketch-text-[22px];
          }
          .back-to-lesson {
            @apply ketch-underline ketch-cursor-pointer;
          }
        }
      }
    }
    .items-button,
    .leuckentext-results-wrapper {
      @apply ketch-bg-primary-color ketch-bg-opacity-5 ketch-px-c15 ketch-py-c15 ketch-w-full;
    }
    .items-button {
      @apply ketch-p-c10 ketch-flex ketch-flex-col ketch-justify-between;
      button {
        @apply ketch-w-auto ketch-max-w-[170px];
      }
    }
    .leuckentext-question-items {
      @apply ketch-flex ketch-flex-col md:ketch-flex-auto;
      @apply ketch-relative;
      li {
        @apply ketch-pl-c25 ketch-mb-c15;
        &:before {
          content: '\2022';
          @apply ketch-inline-block ketch-whitespace-nowrap ketch-left-c5 ketch-absolute;
        }
      }
    }
    button.start-quiz {
      @apply ketch-w-full;
      h5 {
        @apply ketch-font-bold;
      }
    }
    .leuckentext-results-wrapper {
      .loading {
        @apply ketch-mx-auto;
      }
    }
  }
</style>
