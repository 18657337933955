<template lang="pug">
.course-module-duration
  .module
    SVGRenderer(
      :fill-color='"var(--primary-color)"',
      :has-hover='false',
      :icon='moduleIcon',
      :stroke-color='"var(--primary-color)"'
    )
    SVGRenderer(
      :fill-color='"var(--primary-text-color)"',
      :has-hover='false',
      :icon='eclipseLoaderIcon',
      v-if='numberOfModules === null',
      width='20'
    )
    h5
      span(v-if='numberOfModules !== null') {{ numberOfModules }}
      span {{ numberOfModulesText }}
  .duration
    SVGRenderer(:has-hover='false', :icon='durationIcon', :stroke-color='"var(--primary-color)"')
    h5 {{ courseDuration }} {{ $t('content') }}
</template>

<script setup lang="ts">
  import { computed } from 'vue'
  import SVGRenderer from '@/components/common/SVGRenderer.vue'
  import useIcons from '@/composables/useIcons'
  import useDuration from '@/composables/useDuration'
  import useCourse from '@/composables/useCourse'
  import { CourseModule } from '@/store/modules/course'
  import useI18n from '@/composables/useI18n'
  import type { PropType } from 'vue'
  import type { CourseItem } from '@/services/interfaces/Course'

  const props = defineProps({
    course: {
      type: Object as PropType<CourseItem | null>,
      required: true,
    },
    numberOfModules: {
      type: Number,
    },
  })

  const { translateString } = useI18n()
  const { durationIcon, moduleIcon, eclipseLoaderIcon } = useIcons()
  const { formatDurationToHHMM } = useDuration()
  const { guidedQuizDuration } = useCourse()

  const courseDuration = computed(() => {
    const moduleDuration =
      CourseModule.coursesModuleMap[props.course?.id || '']?.reduce((acc, mod) => {
        acc += guidedQuizDuration.value(mod.guidedQuiz?.numberOfQuestions || 0)
        return acc
      }, 0) || 0
    return formatDurationToHHMM((props.course?.duration || 0) + moduleDuration)
  })

  const numberOfModulesText = computed(() => {
    return props.numberOfModules === 1 ? translateString('courses.module') : translateString('courses.modules')
  })
</script>

<style lang="postcss" scoped>
  .course-module-duration {
    @apply ketch-flex ketch-flex-col xs4:ketch-flex-row ketch-space-y-c10 xs4:ketch-space-x-c20 xs4:ketch-space-y-0;
    .module h5 {
      @apply ketch-space-x-c5;
    }
    .module,
    .duration {
      @apply ketch-flex ketch-items-center ketch-space-x-c10;
    }
  }
</style>
