<template lang="pug">
.star-rating
  .stars
    SVGRenderer(
      :fill-color='isDarkMode ? "#111122" : "white"',
      :has-hover='false',
      :icon='star5Icon',
      :stroke-color='color'
    )
  .stars(:style='{ width: `${ratePercentage}%` }')
    SVGRenderer(:fill-color='color', :has-hover='false', :icon='star5Icon', :stroke-color='color')
</template>

<script setup lang="ts">
  import { computed } from 'vue'
  import SVGRenderer from '@/components/common/SVGRenderer.vue'
  import useIcons from '@/composables/useIcons'
  import useCommonMixin from '@/composables/useCommonMixin'

  const props = defineProps({
    rating: {
      required: true,
      type: Number,
    },
    color: {
      type: String,
      default: 'var(--primary-text-color)',
    },
  })

  const { star5Icon } = useIcons()
  const { isDarkMode } = useCommonMixin()
  const stars = 5

  const ratePercentage = computed(() => {
    return Math.round((props.rating / stars) * 100)
  })
</script>

<style lang="postcss">
  .star-rating {
    @apply ketch-relative ketch-w-c80 ketch-h-c16;
    .stars {
      @apply ketch-absolute;
    }
    .stars:last-of-type {
      @apply ketch-z-[2] ketch-overflow-hidden;
    }
  }
</style>
