<template lang="pug">
.gurus-registration-wrapper(:class='{ loading: loadingProperties }')
  LoadingSpinner(v-if='loadingProperties')
  .registration-container(v-else-if='customSignupProperties')
    .brand-section-wrapper
      .brand-section
        .logo-section(v-if='!isMobileDevice')
          img.logo(:src='customSignupProperties.signUpLogoUrl || defaultLogo')
          h6(v-html='"powered by <b>KetchUp</b>"', v-if='customSignupProperties.showPoweredBy')
        .text-wrapper
          h1.header-text(:style='{ color: pageTitleColor }') {{ customSignupProperties.pageTitle }}
          h4.sub-text {{ customSignupProperties.pageSubtitle }}
          .benefits(v-if='customSignupProperties.benefits')
            .benefit(:key='benefit', v-for='benefit in customSignupProperties.benefits')
              .icon
                SVGRenderer(
                  :has-hover='false',
                  :icon='specialCheckmark',
                  :stroke-color='"var(--header-foreground-color)"'
                )
              HTMLRenderer.h5(:html='benefit', skip-glossary-check)
      img.brand-image(:src='customSignupProperties.imageUrl')
    .signup-section
      .logo-section(v-if='isMobileDevice')
        img.logo(:src='customSignupProperties.signUpLogoUrl', v-if='customSignupProperties.signUpLogoUrl')
        h6(v-html='"powered by <b>KetchUp</b>"', v-if='customSignupProperties.showPoweredBy')
      .register-direction
        h3.register-here {{ $t('onboarding.customSignup.registerHereForFree') }}
        SVGRenderer(:fill-color='"var(--primary-text-color)"', :has-hover='false', :icon='curvedDownArrow')
      Signup(show-additional-login-action)
</template>

<script setup lang="ts">
  import { computed, onMounted, watch } from 'vue'
  import Signup from '@/components/Signup.vue'
  import SVGRenderer from '@/components/common/SVGRenderer.vue'
  import HTMLRenderer from '@/components/common/HTMLRenderer.vue'
  import LoadingSpinner from '@/components/common/LoadingSpinner.vue'
  import useIcons from '@/composables/useIcons'
  import useBreakpoint from '@/composables/useBreakpoint'
  import { UserModule } from '@/store/modules/user'
  import CookieHelper from '@/helpers/CookieHelper'
  import type { Company } from '@/services/interfaces/Auth0'
  import useSegment from '@/composables/useSegment'

  const { specialCheckmark, curvedDownArrow, defaultLogo } = useIcons()
  const { isMobileDevice } = useBreakpoint()
  const { trackPage, pageViewed } = useSegment()

  const customSignupProperties = computed(() => UserModule.signupPageProperties)

  const loadingProperties = computed(() => customSignupProperties.value === null)

  const companySubdomainDetails = computed(() => UserModule.companySubdomainDetails || UserModule.company)

  const pageTitleColor = computed(() => {
    return customSignupProperties.value?.customTitleColor
      ? customSignupProperties.value.customTitleColor
      : 'var(--header-foreground-color)'
  })

  watch(companySubdomainDetails, (value: Company | null) => {
    if (value) {
      CookieHelper.setCrossDomainCookie('company_subdomain_details', JSON.stringify(companySubdomainDetails.value))
    }
  })

  onMounted(() => {
    if (UserModule.onboarded) {
      window.location.href = '/'
    } else if (!UserModule.hasValidUser) {
      trackPage('Registration')
      pageViewed('Registration')
    }
  })

  if (companySubdomainDetails.value) {
    CookieHelper.setCrossDomainCookie('company_subdomain_details', JSON.stringify(companySubdomainDetails.value))
  }
</script>

<style lang="postcss">
  .gurus-registration-wrapper {
    &:not(.loading) {
      @apply ketch-flex ketch-flex-col ketch-items-center ketch-justify-center ketch-w-full;
      @apply md:ketch-flex-row xl:ketch-p-c100 ketch-bg-background-color md:ketch-h-screen;
    }

    > .loading {
      @apply ketch-mx-auto ketch-mt-c20 ketch-py-c150;
      .dot {
        @apply ketch-w-c20 ketch-h-c20;
      }
    }
    .logo-section {
      @apply ketch-flex ketch-flex-col ketch-items-center;
      @apply md:ketch-flex-row md:ketch-space-x-c10;
      .logo {
        @apply ketch-max-h-c100;
      }
      h6 {
        @apply ketch-mt-c10 ketch-mb-c15 md:ketch-mt-0 md:ketch--mb-c20;
      }
    }
    .registration-container {
      @apply ketch-flex ketch-flex-col-reverse sm:ketch-min-h-[650px] md:ketch-flex-row;
      @apply xl:ketch-min-h-[650px] xl:ketch-w-[1240px];
      .brand-section-wrapper {
        @apply ketch-w-full ketch-bg-header-background-color ketch-text-header-foreground-color;
        @apply ketch-flex ketch-flex-col ketch-pt-c20 md:ketch-pt-c40 md:ketch-w-1/2 lg:ketch-rounded-l-large;
        .brand-section {
          @apply ketch-px-c20 md:ketch-px-c40;
          > * {
            @apply ketch-text-header-foreground-color;
          }
          .logo-section {
            @apply ketch-mb-c40;
          }
          .text-wrapper {
            @apply ketch-flex ketch-flex-col ketch-items-start ketch-pb-c20 lg:ketch-pb-0;
            .header-text {
              @apply ketch-font-bold lg:ketch-text-[42px];
            }
            .sub-text {
              @apply ketch-mt-c10 ketch-mb-c50;
            }
            .benefits {
              @apply ketch-flex ketch-flex-col ketch-space-y-c15;
              .benefit {
                @apply ketch-flex ketch-items-center ketch-space-x-c10;
                .icon {
                  @apply ketch-w-c20;
                }
              }
            }
          }
        }
        .brand-image {
          @apply ketch-w-full ketch-h-auto;
        }
      }
      .signup-section {
        @apply ketch-w-full md:ketch-w-1/2 ketch-flex ketch-flex-col ketch-items-center ketch-justify-center;
        @apply ketch-bg-white ketch-py-c20 sm:ketch-py-c40 lg:ketch-rounded-r-large;
        .register-direction {
          @apply ketch-flex;
          .register-here {
            @apply ketch-text-[#121E50] ketch--mt-c15 ketch--mr-c10;
          }
        }
        .signup-wrapper {
          @apply ketch-px-c20 md:ketch-max-w-[325px] md:ketch-px-0 ketch-p-0;
        }
      }
    }
  }
</style>
