import { render, staticRenderFns } from "./CourseModulesLoader.vue?vue&type=template&id=9122ca8c&scoped=true&lang=pug"
import script from "./CourseModulesLoader.vue?vue&type=script&setup=true&lang=ts"
export * from "./CourseModulesLoader.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./CourseModulesLoader.vue?vue&type=style&index=0&id=9122ca8c&prod&scoped=true&lang=postcss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9122ca8c",
  null
  
)

export default component.exports