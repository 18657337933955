<template lang="pug">
.group-courses-tab-view
  LoadingSpinner(v-if='!Object.keys(groupedCourses).length')
  template(v-else, v-for='(courses, key) in groupedCourses')
    .courses(:class='key', :key='key', v-if='courses.length')
      .course-header(@click='setCollapsedState(String(key))')
        h6
          span.title {{ headerTitle(String(key)) }}
          span.number [{{ courses.length }}]
        .svg-wrap(:class='{ collapsed: collapsedStates[key] }')
          SVGRenderer(:has-hover='false', :icon='arrowDown', :stroke-color='"var(--primary-color)"', width='11')
      transition(name='menu-slide')
        .courses-container(v-if='!collapsedStates[key]')
          .course(
            :class='{ new: isNewSelection(course) }',
            :key='course.id',
            @click='onCourseSelection(course)',
            v-for='course in courses'
          )
            .course-items
              Choice.without-border(:svg-renderer-props='choiceIcon(course)', :title='course.title')
</template>

<script setup lang="ts">
  import { computed, ref, watch } from 'vue'
  import useIcons from '@/composables/useIcons'
  import Choice from '@/components/common/Choice.vue'
  import LoadingSpinner from '@/components/common/LoadingSpinner.vue'
  import SVGRenderer from '@/components/common/SVGRenderer.vue'
  import CourseApi from '@/services/api/CourseApi'
  import { UserModule } from '@/store/modules/user'
  import { CourseModule } from '@/store/modules/course'
  import eventBus from '@/main'
  import useI18n from '@/composables/useI18n'
  import type { PropType } from 'vue'
  import type { GroupCourseItem } from '@/services/interfaces/Course'

  const props = defineProps({
    groupId: {
      required: true,
      type: String,
    },
    groupedCourses: {
      type: Object as PropType<{ [key: string]: GroupCourseItem[] }>,
      required: true,
    },
    headerTitle: {
      type: Function as PropType<(key: string) => string>,
      required: true,
    },
    submitCourses: {
      type: Boolean,
      default: false,
    },
  })

  const emit = defineEmits(['submitting-courses', 'has-selected-courses', 'has-unassigned-courses'])

  const { translateString } = useI18n()
  const { checkedCheckboxSquareWhite, checkboxSquareWhite, arrowDown } = useIcons()
  const selectedCourses = ref([] as GroupCourseItem[])
  const unassignedCourses = ref([] as GroupCourseItem[])
  const collapsedStates = ref({} as { [key: string]: boolean })

  const assignedCourses = computed(() => CourseModule.groupAssignedCourses(props.groupId))

  const isSelected = computed(() => {
    return (courseId: string) => {
      return (
        selectedCourses.value.some((c) => c.id === courseId) || assignedCourses.value.some((c) => c.id === courseId)
      )
    }
  })

  const isNewSelection = computed(() => (course: GroupCourseItem) => {
    return selectedCourses.value.some((c) => c.id === course.id)
  })

  const choiceIcon = computed(() => {
    return (course: GroupCourseItem) => {
      const svgProps: any = {
        strokeColor: 'var(--primary-text-color)',
        icon: checkboxSquareWhite.value,
      }
      if (isSelected.value(course.id)) {
        svgProps.icon = checkedCheckboxSquareWhite.value
      }
      return svgProps
    }
  })

  const mutateAssignedCourses = (course: GroupCourseItem) => {
    const index = assignedCourses.value!.findIndex((c) => c.id === course.id)
    if (index >= 0) {
      assignedCourses.value.splice(index, 1)
    } else {
      assignedCourses.value.push(course)
    }
  }

  const unassignCourse = (course: GroupCourseItem) => {
    const assignedCourseIndex = assignedCourses.value.findIndex((c) => c.id === course.id)
    if (assignedCourseIndex >= 0) {
      // unassign
      mutateAssignedCourses(course)
      unassignedCourses.value.push(course)
      emit('has-unassigned-courses', true)
    } else {
      const index = selectedCourses.value!.findIndex((c) => c.id === course.id)
      // unassign new course
      selectedCourses.value.splice(index, 1)
      emit('has-selected-courses', false)
    }
  }

  const assignCourse = (course: GroupCourseItem) => {
    const unassignedCourseIndex = unassignedCourses.value.findIndex((c) => c.id === course.id)
    if (unassignedCourseIndex >= 0) {
      // assigned but unassigning
      unassignedCourses.value.splice(unassignedCourseIndex, 1)
      // add assigned course back
      mutateAssignedCourses(course)
      emit('has-unassigned-courses', false)
    } else {
      // new course assignment
      selectedCourses.value.push(course)
      emit('has-selected-courses', true)
    }
  }

  const onCourseSelection = (course: GroupCourseItem) => {
    if (isSelected.value(course.id)) {
      unassignCourse(course)
    } else {
      assignCourse(course)
    }
  }

  const submitAssignedCourses = async () => {
    if (!(selectedCourses.value.length || unassignedCourses.value.length)) return
    emit('submitting-courses', true)

    if (unassignedCourses.value.length) {
      await CourseApi.deleteCompanyCourseFromGroup(
        props.groupId,
        UserModule.currentCompany!.id,
        unassignedCourses.value.map((c) => c.id),
      )
      unassignedCourses.value = []
    }
    if (selectedCourses.value.length) {
      await CourseApi.assignCompanyCourseToGroup(
        props.groupId,
        UserModule.currentCompany!.id,
        selectedCourses.value.map((c) => c.id),
      )
      selectedCourses.value = []
    }
    await CourseModule.getGroupAssignedCourses(props.groupId)

    eventBus.$toasted.success(translateString('homepage.assignedCourses.successfullyAssigned') as string, {
      duration: 5000,
    })
    emit('submitting-courses', false)
    emit('has-selected-courses', false)
    emit('has-unassigned-courses', false)
  }

  const setCollapsedState = (key: string) => {
    if (key in collapsedStates.value) {
      collapsedStates.value[key] = !collapsedStates.value[key]
    } else {
      eventBus.$set(collapsedStates.value, key, true)
    }
  }

  watch(
    () => props.submitCourses,
    (value) => {
      if (value) {
        submitAssignedCourses()
      }
    },
  )
</script>

<style lang="postcss">
  .group-courses-tab-view {
    @apply ketch-border ketch-border-border-color ketch-rounded-normal ketch-rounded-tl-none;
    .course {
      &.new {
        @apply ketch-bg-primary-color ketch-bg-opacity-10;
        > * {
          @apply ketch-text-primary-color;
        }
      }
      .choice {
        @apply ketch-p-0;
        svg {
          @apply ketch-mt-c5;
        }
      }
      .price {
        @apply ketch-font-bold ketch-ml-c30;
      }
    }
  }
</style>
