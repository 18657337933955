import { render, staticRenderFns } from "./KnowledgeCheckInfo.vue?vue&type=template&id=0bab1f82&scoped=true&lang=pug"
import script from "./KnowledgeCheckInfo.vue?vue&type=script&setup=true&lang=ts"
export * from "./KnowledgeCheckInfo.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./KnowledgeCheckInfo.vue?vue&type=style&index=0&id=0bab1f82&prod&scoped=true&lang=postcss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0bab1f82",
  null
  
)

export default component.exports