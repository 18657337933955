<template lang="pug">
.move-course-to-section-modal
  h3.title-with-underline {{ $t('trainings.changeCourseSection') }}
  h5.info {{ $t('trainings.moveCourseToCourseSectionInfo') }}
  .course-sections(v-if='courseSections')
    Choice(
      :id='section.id',
      :key='section.id',
      :selected='isChoiceSelected(section.id)',
      :svg-renderer-props='choiceIcon(section.id)',
      :title='section.name',
      @click.native='chosenSectionId = section.id',
      v-for='section in courseSections'
    )
  .apply
    KetchUpButton.primary(:disabled='assigning || !hasSectionChanges', @click.native='moveToCourseSection')
      LoadingSpinner(v-if='assigning')
      h5(v-else) {{ $t('homepage.applyChanges') }}
  h6.or(v-if='currentSectionId') {{ $t('or') }}
  .unassign-from-section(v-if='currentSectionId')
    KetchUpButton.tertiary(:disabled='unassigning || assigning', @click.native='unassignFromSection')
      LoadingSpinner(v-if='unassigning')
      h5(v-else) {{ $t('trainings.unassignCourseFromSection') }}
</template>

<script setup lang="ts">
  import { computed, ref } from 'vue'
  import Choice from '@/components/common/Choice.vue'
  import KetchUpButton from '@/components/common/KetchUpButton.vue'
  import LoadingSpinner from '@/components/common/LoadingSpinner.vue'
  import useIcons from '@/composables/useIcons'
  import { EditorModule } from '@/store/modules/editor'
  import CourseApi from '@/services/api/CourseApi'
  import { UserModule } from '@/store/modules/user'
  import { CourseModule } from '@/store/modules/course'
  import { v4 as uuidv4 } from 'uuid'
  import eventBus from '@/main'
  import useI18n from '@/composables/useI18n'
  import type { PropType } from 'vue'
  import type { EditorCourseSection } from '@/services/interfaces/Course'

  const props = defineProps({
    courseSections: {
      type: Array as PropType<{ id: string; name: string }[]>,
      required: true,
    },
    currentSectionId: {
      type: String,
      default: null,
    },
    courseId: {
      type: String,
      required: true,
    },
  })

  const { checkedCircle, uncheckedCircle } = useIcons()
  const { translateString } = useI18n()
  const assigning = ref(false)
  const unassigning = ref(false)
  const chosenSectionId = ref('')

  const hasSectionChanges = computed(() => !!chosenSectionId.value)

  const isChoiceSelected = computed(() => (sectionId: string) => sectionId === chosenSectionId.value)

  const choiceIcon = computed(() => {
    return (sectionId: string) => {
      const svgProps: any = {
        strokeColor: 'var(--primary-text-color)',
        icon: uncheckedCircle.value,
      }
      if (isChoiceSelected.value(sectionId)) {
        svgProps.icon = checkedCircle.value
      }
      return svgProps
    }
  })

  const unassignedCoursesSection = computed(() => EditorModule.courseSections?.find((s) => s.unassignedCourses.length))

  const setEditorCourseSections = () => {
    EditorModule.setCourseSections(CourseModule.courseSections).then(() => {
      EditorModule.courseSections?.forEach((section) => {
        eventBus.$set(section, 'editorId', uuidv4())
      })
    })
  }

  const patchCourseSections = async (action: 'save' | 'revert') => {
    eventBus.$emit('turn-on-loader')
    if (action === 'save') {
      await CourseModule.getCourseSections(UserModule.currentCompany!.id)
      setEditorCourseSections()
      eventBus.$toasted.success(translateString('trainings.movedToSectionSuccessfully'))
    } else if (action === 'revert') {
      setEditorCourseSections()
      eventBus.$toasted.error(translateString('trainings.couldNotMovedToSection'))
    }
    eventBus.$emit('turn-off-loader')
  }

  const setAssignedCoursesPayload = (section: EditorCourseSection) => {
    return section.assignedCourses.map((c, index) => ({
      courseId: c.id,
      promoted: c.promoted,
      visible: c.visibleToCourseSection,
      orderNumber: index,
    }))
  }

  const moveToCourseSection = async () => {
    assigning.value = true
    const sectionToMoveTo = EditorModule.courseSections?.find((s) => s.id === chosenSectionId.value)
    const currentSection = EditorModule.courseSections?.find((s) => s.id === props.currentSectionId)
    if (sectionToMoveTo && (currentSection || unassignedCoursesSection.value)) {
      let courseIndex
      const courseSectionPromises: any = []

      if (props.currentSectionId && currentSection) {
        // check course from assignedCourses in currentSection
        courseIndex = currentSection.assignedCourses.findIndex((c) => c.id === props.courseId)

        sectionToMoveTo.assignedCourses.push(currentSection?.assignedCourses[courseIndex])
        currentSection?.assignedCourses.splice(courseIndex, 1)

        courseSectionPromises.push(
          CourseApi.updateCourseSection(UserModule.currentCompany!.id, sectionToMoveTo.id!, {
            courseSection: {
              assignedCourses: setAssignedCoursesPayload(sectionToMoveTo),
            },
          }),
        )

        courseSectionPromises.push(
          CourseApi.updateCourseSection(UserModule.currentCompany!.id, currentSection.id!, {
            courseSection: {
              assignedCourses: setAssignedCoursesPayload(currentSection),
            },
          }),
        )
      } else if (unassignedCoursesSection.value) {
        // check course from unassignedCourses in unassignedCoursesSection
        courseIndex = unassignedCoursesSection.value.unassignedCourses.findIndex((c) => c.id === props.courseId)

        sectionToMoveTo.assignedCourses.push(unassignedCoursesSection.value.unassignedCourses[courseIndex])
        unassignedCoursesSection.value.unassignedCourses.splice(courseIndex, 1)

        courseSectionPromises.push(
          CourseApi.updateCourseSection(UserModule.currentCompany!.id, sectionToMoveTo.id!, {
            courseSection: {
              assignedCourses: setAssignedCoursesPayload(sectionToMoveTo),
            },
          }),
        )
      }

      await Promise.all(courseSectionPromises)
        .then(() => {
          patchCourseSections('save')
        })
        .catch(() => patchCourseSections('revert'))
        .finally(() => {
          assigning.value = false
          eventBus.$emit('close-modal')
        })
    }
  }

  const unassignFromSection = () => {
    unassigning.value = true
    const currentSection = EditorModule.courseSections?.find((s) => s.id === props.currentSectionId)
    if (currentSection && unassignedCoursesSection.value) {
      CourseApi.deleteCourseFromSection(UserModule.currentCompany!.id, currentSection.id!, props.courseId)
        .then(() => {
          const courseIndex = currentSection.assignedCourses.findIndex((c) => c.id === props.courseId)

          unassignedCoursesSection.value!.unassignedCourses.push(currentSection.assignedCourses[courseIndex])
          currentSection.assignedCourses.splice(courseIndex, 1)
          patchCourseSections('save')
        })
        .catch(() => patchCourseSections('revert'))
        .finally(() => {
          unassigning.value = false
          eventBus.$emit('close-modal')
        })
    }
  }
</script>

<style lang="postcss">
  .move-course-to-section-modal {
    .info {
      @apply ketch-pb-c10;
    }
    .course-sections {
      @apply ketch-flex ketch-flex-col ketch-space-y-c10;
      .choice {
        @apply ketch-w-full;
      }
    }
    .apply {
      @apply ketch-flex ketch-justify-end ketch-mt-c20;
      button {
        @apply ketch-w-full;
      }
    }
    h6.or {
      @apply ketch-text-center ketch-py-c15;
    }
    .unassign-from-section {
      button {
        @apply ketch-w-full;
      }
    }
  }
</style>
