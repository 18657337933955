<template lang="pug">
.custom-company-widgets(:style='{ bottom: widgetsBottomPixels }')
  NewReleaseVersion
  .custom-widgets(v-if='filteredWidgets')
    CustomWidgetsWrapper(:key='widget.orderingNumber', :widget='widget', v-for='widget in filteredWidgets')
</template>

<script setup lang="ts">
  import { computed } from 'vue'
  import NewReleaseVersion from '@/components/common/NewReleaseVersion.vue'
  import { UserModule } from '@/store/modules/user'
  import CustomWidgetsWrapper from '@/components/common/CustomWidgetsWrapper.vue'

  const props = defineProps({
    calendlyWidgetHeight: {
      type: Number,
      default: 0,
    },
  })
  const widgetsBottomPixels = computed(() => {
    return props.calendlyWidgetHeight > 0 ? `${props.calendlyWidgetHeight + 30}px` : '20px'
  })

  const companyWidgets = computed(() => UserModule.currentCompany?.widgets)

  const filteredWidgets = computed(() =>
    companyWidgets.value
      ?.filter((widget) => widget.type !== 'calendly' && widget.url && widget.displayText)
      .sort((a, b) => a.orderingNumber - b.orderingNumber),
  )
</script>

<style lang="postcss">
  .custom-company-widgets {
    @apply ketch-fixed ketch-z-10 ketch-right-c20;
    .custom-widgets {
      @apply ketch-flex ketch-flex-col ketch-items-end ketch-space-y-c15 ketch-mt-c15;
    }
  }
</style>
