<template lang="pug">
.last-activity
  .container
    h6 {{ $t(label) }}:
    h6.date {{ formatDate(lastActivity, 'dd.MM.yyyy') }}
    h6.difference {{ daysDifference(lastActivity) }}
  slot
</template>

<script setup lang="ts">
  import { computed } from 'vue'
  import useCommonMixin from '@/composables/useCommonMixin'
  import useI18n from '@/composables/useI18n'

  defineProps({
    lastActivity: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      default: 'homepage.lastActivity',
    },
  })

  const { formatDate, daysDifferenceFromNow } = useCommonMixin()
  const { translateString } = useI18n()

  const daysDifference = computed(() => {
    return (date: string) => {
      if (!daysDifferenceFromNow(date)) return ''
      const difference = translateString('homepage.daysAgo', { days: daysDifferenceFromNow(date) })
      return `(${difference})`
    }
  })
</script>

<style lang="postcss" scoped>
  .last-activity {
    @apply ketch-opacity-[0.6] ketch-flex ketch-justify-between ketch-items-center;
    .container {
      @apply ketch-space-x-c5;
      h6 {
        @apply ketch-inline;
      }
      .date,
      .difference {
        @apply ketch-font-bold;
      }
    }
  }
</style>
