<template lang="pug">
UsersAndGroupsLoader(v-if='loadingData')
.users-and-groups-wrapper(v-else)
  .empty-state(v-if='!userMembers')
    img.hero(:src='usersEmptyStateHero', v-if='isDesktopDevice')
    .invite-users-section
      h2 {{ $t('homepage.inviteUsers') }}
      h5 {{ $t('homepage.inviteUsersInfo') }}
      .checked-items
        .check(:key='item', v-for='item in checkedItems')
          img(:src='specialBlackCheckmark')
          h5(v-html='item')
      KetchUpButton.primary(@click.native='openInviteUserModal')
        h5 {{ $t('homepage.addMembers') }}
        SVGRenderer(:icon='arrowRight', :stroke-color='"var(--primary-foreground-color)"')
  .users-and-groups(v-else)
    .headline-overview-info
      h1.users-and-groups-headline {{ $t('navigation.admin.usersAndGroups') }}
      h5.overview-info {{ $t('homepage.overviewOfMembers') }}
    Tabs(
      :active-tab-index='usersAndGroupsTabIndex',
      :normal-tab='true',
      :tabs='usersAndGroupTabs',
      @tab-index='usersAndGroupsTabIndex = $event'
    )
    transition(mode='out-in', name='fade')
      keep-alive
        UserManagementTabView(
          :open-invite-user-modal='openInviteUserModal',
          :reload-users='reloadUsers',
          @create-new-group='createNewGroupAction',
          @reloaded-users='reloadUsers = false',
          v-if='usersAndGroupsTabIndex === 0'
        )

        GroupManagementTabView(@reload-user-members='reloadUsers = true', v-else-if='usersAndGroupsTabIndex === 1')
        CompanyActivityLog(v-else-if='usersAndGroupsTabIndex === 2')
</template>

<script setup lang="ts">
  import { computed, onMounted, ref, watch } from 'vue'
  import KetchUpButton from '@/components/common/KetchUpButton.vue'
  import SVGRenderer from '@/components/common/SVGRenderer.vue'
  import CompanyActivityLog from '@/components/user-management/CompanyActivityLog.vue'
  import useIcons from '@/composables/useIcons'
  import useBreakpoint from '@/composables/useBreakpoint'
  import { UserModule } from '@/store/modules/user'
  import useSegment from '@/composables/useSegment'
  import Tabs from '@/components/common/Tabs.vue'
  import UserManagementTabView from '@/components/user-management/UserManagementTabView.vue'
  import GroupManagementTabView from '@/components/user-management/GroupManagementTabView.vue'
  import useCommonMixin from '@/composables/useCommonMixin'
  import UsersAndGroupsLoader from '@/components/loaders/UsersAndGroupsLoader.vue'
  import { useRouter } from 'vue-router/composables'
  import eventBus from '@/main'
  import useI18n from '@/composables/useI18n'

  const router = useRouter()
  const { usersEmptyStateHero, specialBlackCheckmark, arrowRight } = useIcons()
  const { isDesktopDevice } = useBreakpoint()
  const { trackPage, pageViewed } = useSegment()
  const { userMembersPerPageLimit, setIsLoadingComponentViewData } = useCommonMixin()
  const { translateString } = useI18n()

  const checkedItems = [
    translateString('homepage.checkItem1'),
    translateString('homepage.checkItem2'),
    translateString('homepage.checkItem3'),
  ]

  const usersAndGroupsTabIndex = ref(0)
  const reloadUsers = ref(false)

  const userMembers = computed(() => UserModule.userMembers)

  const userGroups = computed(() => UserModule.userGroups)

  const loadingData = computed(() => userMembers.value === null || userGroups.value === null)

  const usersAndGroupTabs = computed(() => [
    translateString('homepage.usersManagement'),
    translateString('homepage.groupManagement'),
    translateString('homepage.activityLog.activityLog'),
  ])

  const openInviteUserModal = () => {
    eventBus.$emit('show-modal', {
      modalContentComponent: 'InviteUserModal',
      cssClass: 'invite-user-modal',
      modalCloseCallback: (callback: () => void) => {
        if (typeof callback === 'function') callback()
      },
    })
  }

  const bootstrapUsersAndGroups = async () => {
    const groupIds = userGroups.value!.map((g) => g.id)
    await UserModule.getUserMembers({
      perPage: userMembersPerPageLimit.value,
      page: 1,
      roles: ['COMPANY_ADMIN', 'EDITOR', 'USER'],
      groups: groupIds,
    })
  }

  const createNewGroupAction = () => {
    usersAndGroupsTabIndex.value = 1
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }

  watch(loadingData, (value) => {
    setIsLoadingComponentViewData(value)
  })

  watch(userGroups, (value) => {
    if (value) {
      bootstrapUsersAndGroups()
    }
  })

  onMounted(() => {
    trackPage('Admin Start')
    pageViewed('Admin Start')
    if (!UserModule.isCompanyAdmin) {
      router.push('/').catch(() => {
        return
      })
    }
  })

  if (userGroups.value) {
    bootstrapUsersAndGroups()
  }
  UserModule.getGroupColors()
  UserModule.getUserRoles()
</script>

<style lang="postcss">
  .users-and-groups-wrapper {
    @apply ketch-flex ketch-flex-col;
    .users-and-groups {
      .empty-state {
        @apply ketch-flex;

        .hero {
          @apply ketch-mr-c60;
        }

        .invite-users-section {
          @apply ketch-flex ketch-flex-col;

          h2 {
            @apply ketch-font-bold ketch-mb-c25;
          }

          .checked-items {
            @apply ketch-space-y-c5 ketch-my-c30;

            .check {
              @apply ketch-flex ketch-items-center;

              img {
                @apply ketch-mr-c5;
              }
            }
          }

          .primary {
            @apply ketch-w-auto ketch-space-x-c20 ketch-border-primary-color;
          }
        }
      }
      .headline-overview-info {
        @apply ketch-flex ketch-flex-col ketch-space-y-c15 ketch-mb-c50;

        .users-and-groups-headline {
          @apply ketch-font-big-daily-short md:ketch-text-2xl;
        }
      }
      .company-activity-log {
        @apply ketch-mt-c45;
      }
    }
  }
</style>
