<template lang="pug">
.tab-grouped-courses
  Tabs(
    :active-tab-index='activeTabIndex',
    :inactive-tab-indexes='inactiveTabIndexes',
    :tabs='courseGroupings',
    @tab-index='activeTabIndex = $event'
  )
  .grouped-courses(v-if='filteredCourses(activeTabIndex).length')
    CourseTile(
      :course='course',
      :hide-background='true',
      :key='course.id',
      :section-id='""',
      v-for='course in filteredCourses(activeTabIndex)'
    )
  h5.empty-grouped-courses(v-else) {{ $t('trainings.noCourses') }}
</template>

<script setup lang="ts">
  import { computed, ref } from 'vue'
  import Tabs from '@/components/common/Tabs.vue'
  import CourseTile from '@/components/course/sections/CourseTile.vue'
  import useI18n from '@/composables/useI18n'
  import type { PropType } from 'vue'
  import type { CourseItem } from '@/services/interfaces/Course'

  const props = defineProps({
    courses: {
      type: Array as PropType<CourseItem[]>,
      required: true,
    },
  })

  const { translateString } = useI18n()

  const activeTabIndex = ref(0)
  const inactiveTabIndexes = ref([] as number[])

  const courseGroupings = computed(() => [
    `${translateString('trainings.tabbed.inPossession')} <span>${filteredCourses.value(0)?.length}</span>`,
    `${translateString('trainings.tabbed.inTest')} <span>${filteredCourses.value(1)?.length}</span>`,
    `${translateString('trainings.tabbed.further')} <span>${filteredCourses.value(2)?.length}</span>`,
  ])

  const filteredCourses = computed(() => (activeTab: number) => {
    return props.courses.filter((c) => {
      if (activeTab === 0) {
        return c.purchased
      } else if (activeTab === 1) {
        return c.trial && !c.purchased
      } else {
        return !c.trial && !c.purchased
      }
    })
  })

  const setInactiveTabIndexes = () => {
    // we have 3 tabs
    for (let i = 0; i < 3; i++) {
      if (!(filteredCourses.value(i).length > 0)) {
        inactiveTabIndexes.value.push(i)
      }
    }
  }

  const setActiveTabIndex = () => {
    for (let i = 2; i >= 0; i--) {
      if (filteredCourses.value(i).length > 0) {
        activeTabIndex.value = i
      }
    }
  }

  setActiveTabIndex()
  setInactiveTabIndexes()
</script>

<style lang="postcss">
  .tab-grouped-courses {
    .tabs {
      @apply ketch-space-x-c10 ketch-mb-c15;
      .tab {
        @apply ketch-w-full ketch-justify-center ketch-border-none ketch-rounded-normal ketch-py-c10 ketch-px-c12;
        &.active {
          @apply ketch-bg-black ketch-bg-opacity-[0.15];
          span {
            @apply ketch-bg-white ketch-text-primary-text-color;
          }
        }
        span {
          @apply ketch-bg-black ketch-bg-opacity-[0.10] ketch-text-primary-text-color;
        }
      }
    }
    .grouped-courses {
      @apply ketch-space-y-c15;
    }
    .empty-grouped-courses {
      @apply ketch-text-center ketch-pt-c15;
    }
  }
</style>
