<template lang="pug">
.exam-start-page(:class='{ "exam-info": onKnowledgeCheckInfo && isDesktopDevice }')
  h2 {{ $t('courses.examSimulation.title') }}
  .video-description-wrapper
    .video-completions-wrapper
      .video-container(v-if='courseExam && courseExam.videoTeaserUrl')
        VideoPlayer(
          :course-id='course?.id || ""',
          :poster-image='getPosterImage(courseExam.videoTeaserUrl)',
          :video-source='courseExam.videoTeaserUrl',
          content-type='course_exam_video'
        )
      ExamResultsCompletions(
        :exam-results='examResults || []',
        :fetched-all-completions='fetchedAllCompletions',
        :fetching-completion-results='fetchingCompletionResults',
        @fetch-more-completion-results='fetchMoreCompletionResults',
        v-if='onKnowledgeCheckInfo && isDesktopDevice'
      )
    .exam-description
      HTMLRenderer.description(
        :html='courseExam.description || ""',
        link-target='_blank',
        v-if='courseExam && courseExam.description'
      )
      .exam-buttons
        .primary-cta(
          :class='{ disabled: !course?.shopifyProductId }',
          @click='() => createShopifyCheckout()',
          v-if='!isPurchasedCourse && isTrialCourse'
        )
          h5 {{ $t('courses.examSimulation.purchaseTrainingToStartExam') }}
        template(v-else-if='isCourseExamPaused')
          KetchUpButton.primary(@click.native='startCourseExam', data-cy='continue-exam-cta')
            h5 {{ $t('courses.examSimulation.continueExam') }}
          KetchUpButton.tertiary(@click.native='abortCourseExam', data-cy='abort-exam')
            LoadingSpinner(v-if='abortingExam')
            template(v-else)
              h5 {{ $t('courses.examSimulation.cancel') }}
        KetchUpButton.primary(@click.native='startCourseExam', data-cy='start-exam', v-else)
          h5 {{ $t('courses.examSimulation.start') }}
  ExamResultsCompletions(
    :exam-results='examResults || []',
    :fetched-all-completions='fetchedAllCompletions',
    :fetching-completion-results='fetchingCompletionResults',
    @fetch-more-completion-results='fetchMoreCompletionResults',
    v-if='!onKnowledgeCheckInfo || !isDesktopDevice'
  )
</template>

<script setup lang="ts">
  import { computed, onMounted, ref } from 'vue'
  import LoadingSpinner from '@/components/common/LoadingSpinner.vue'
  import HTMLRenderer from '@/components/common/HTMLRenderer.vue'
  import KetchUpButton from '@/components/common/KetchUpButton.vue'
  import VideoPlayer from '@/components/VideoPlayer.vue'
  import useCourse from '@/composables/useCourse'
  import useVideo from '@/composables/useVideo'
  import { CourseModule } from '@/store/modules/course'
  import useSegment from '@/composables/useSegment'
  import { useRouter } from 'vue-router/composables'
  import useBreakpoint from '@/composables/useBreakpoint'
  import ExamResultsCompletions from '@/components/course/ExamResultsCompletions.vue'
  import useShopifyClient from '@/composables/useShopifyClient'

  const router = useRouter()
  const {
    course,
    currentCourseId,
    isCourseExamPaused,
    courseExamId,
    isPurchasedCourse,
    isTrialCourse,
    latestExamCompletionId,
    onKnowledgeCheckInfo,
  } = useCourse()
  const { getPosterImage } = useVideo()
  const { trackPage, pageViewed, examAborted } = useSegment()
  const { isDesktopDevice } = useBreakpoint()
  const { createShopifyCheckout } = useShopifyClient()
  const fetchingCompletionResults = ref(false)
  const fetchingMoreCompletionResults = ref(false)
  const fetchedAllCompletions = ref(false)
  const currentCompletionsPage = ref(1)

  const courseExam = computed(() => course.value?.exam)

  const examResults = computed(() => CourseModule.examResults)

  const startCourseExam = () => {
    router.push({ name: 'CourseExamContent' }).catch(() => {
      return
    })
  }

  const fetchCompletionStatus = () => {
    if (latestExamCompletionId.value) {
      CourseModule.getExamCompletionState(latestExamCompletionId.value)
    }
  }

  const fetchExamResults = () => {
    CourseModule.getExamResults().finally(() => {
      fetchingCompletionResults.value = false
      if (examResults.value && examResults.value.length < 5) {
        fetchedAllCompletions.value = true
      }
    })
  }

  const fetchMoreCompletionResults = () => {
    currentCompletionsPage.value = currentCompletionsPage.value + 1
    fetchingMoreCompletionResults.value = true
    CourseModule.getMoreExamResults(currentCompletionsPage.value)
      .then((resp) => {
        if (resp.no_more_results) {
          fetchedAllCompletions.value = true
        }
      })
      .finally(() => {
        fetchingMoreCompletionResults.value = false
      })
  }

  const abortingExam = ref(false)
  const abortCourseExam = () => {
    abortingExam.value = true
    CourseModule.abortExam({
      selectedAnswerIds: [],
    })
      .then(() => {
        fetchExamResults()
        examAborted(courseExamId.value, currentCourseId.value, course.value!.title)
      })
      .finally(() => {
        abortingExam.value = false
      })
  }

  fetchCompletionStatus()
  fetchExamResults()

  onMounted(() => {
    trackPage('Course Exam Start Page')
    pageViewed('Course Exam Start Page')
  })
</script>

<style lang="postcss" scoped>
  .exam-start-page {
    &.exam-info {
      .video-description-wrapper {
        @apply ketch-flex ketch-space-x-c20;
        .video-completions-wrapper {
          @apply ketch-flex ketch-flex-col;
          .video-container {
            @apply ketch-mb-0 md:ketch-max-h-[265px] xl:ketch-max-h-[295px];
            .video-player {
              @apply md:ketch-max-h-[265px] xl:ketch-max-h-[295px];
            }
          }
        }
        .video-completions-wrapper,
        .exam-description {
          @apply ketch-w-1/2;
        }
      }
      .completions-wrapper {
        @apply lg:ketch-mt-0;
      }
    }
    > h2 {
      @apply ketch-font-bold ketch-relative ketch-pb-c10 ketch-mb-c40;
      &:after {
        content: '';
        @apply ketch-h-c2 ketch-left-0 ketch-w-c50 ketch-bg-primary-color ketch-absolute ketch-bottom-0;
      }
    }
    .video-container {
      @apply ketch-rounded-large ketch-overflow-hidden ketch-mb-c30;
      .video-player {
        @apply ketch-max-h-[195px] xs3:ketch-max-h-[220px] sm:ketch-max-h-[410px] md:ketch-max-h-[325px] xl:ketch-max-h-[365px];
      }
    }
    .exam-description {
      @apply ketch-border ketch-border-secondary-color ketch-bg-secondary-color ketch-bg-opacity-[0.05] ketch-rounded-normal;
      @apply ketch-p-c20 ketch-flex ketch-flex-col ketch-space-y-c30;
      .exam-buttons {
        @apply ketch-space-y-c10 ketch-flex ketch-flex-col ketch-items-center;
        .primary-cta {
          @apply ketch-cursor-pointer;
          &.disabled {
            @apply ketch-pointer-events-none;
          }
        }
        button {
          @apply ketch-w-c250;
          &.primary {
            @apply ketch-border-primary-color;
          }
        }
      }
    }
  }
</style>
