<template lang="pug">
.create-course-modal
  CreateOrUpdateCourse(
    :editor-title='String($t("editor.createCourse.createCourseTitle"))',
    @update-course-section='updateCourseSection',
    create-course
  )
</template>

<script setup lang="ts">
  import CreateOrUpdateCourse from '@/components/editor/CreateOrUpdateCourse.vue'
  import CourseApi from '@/services/api/CourseApi'
  import { UserModule } from '@/store/modules/user'
  import useCourse from '@/composables/useCourse'
  import { CourseModule } from '@/store/modules/course'
  import { useRouter } from 'vue-router/composables'
  import eventBus from '@/main'
  import useI18n from '@/composables/useI18n'
  import type { CourseItem } from '@/services/interfaces/Course'

  const props = defineProps({
    sectionId: {
      type: String,
      required: true,
    },
  })

  const router = useRouter()
  const { courseSections } = useCourse()
  const { translateString } = useI18n()

  const assignCourseGroups = (courseId: string, course: CourseItem, courseGroupIds: string[]) => {
    const courseGroupPromises: any[] = []
    courseGroupIds.forEach((gId) => {
      courseGroupPromises.push(CourseApi.assignCompanyCourseToGroup(gId, UserModule.currentCompany!.id, [courseId]))
    })
    Promise.all(courseGroupPromises).then(() => {
      CourseModule.updateGroupAssignedCourses({
        groupIds: courseGroupIds,
        course: course,
        action: 'add',
      })
    })
  }

  const updateCourseSection = (payload: { courseId: string; courseGroupIds: string[] }) => {
    CourseApi.getCourseById(payload.courseId).then(async (course) => {
      const _courseSection = courseSections.value?.find((s) => s.id === props.sectionId)
      if (_courseSection) {
        if (!course['visibleToCourseSection']) {
          course['visibleToCourseSection'] = true
        }
        course.visibleToCourseSection = true
        _courseSection.assignedCourses.push(course)
        assignCourseGroups(payload.courseId, course, payload.courseGroupIds)
        const courseSectionApiPayload = {
          courseSection: {
            assignedCourses: _courseSection.assignedCourses.map((c, index) => ({
              courseId: c.id,
              promoted: c.promoted,
              visible: c.visibleToCourseSection,
              orderNumber: index,
            })),
          },
        }
        CourseApi.updateCourseSection(UserModule.currentCompany!.id, props.sectionId, courseSectionApiPayload).then(
          () => {
            eventBus.$toasted.success(translateString('editor.createCourse.createdCourseSuccessfully'))
            router
              .push({
                name: 'CourseDetails',
                params: { courseId: payload.courseId },
              })
              .catch(() => {
                return
              })
            eventBus.$emit('close-modal')
          },
        )
      }
    })
  }
</script>

<style lang="postcss" scoped>
  .create-course-modal {
    @apply ketch-px-c20 lg:ketch-px-c30;
  }
</style>
