<script setup lang="ts">
  import { computed, onMounted, ref } from 'vue'
  import useSegment from '@/composables/useSegment'
  import useCommonMixin from '@/composables/useCommonMixin'
  import Signup from '@/components/Signup.vue'
  import TextRenderer from '@/components/editor/TextRenderer.vue'
  import { UserModule } from '@/store/modules/user'
  import AcademyProfileUpdateForm from '@/components/signup/AcademyProfileUpdateForm.vue'
  import useI18n from '@/composables/useI18n'
  import CourseApi from '@/services/api/CourseApi'
  import CookieHelper from '@/helpers/CookieHelper'
  import OnboardingSurvey from '@/components/onboarding-survey/OnboardingSurvey.vue'

  const { trackPage, pageViewed } = useSegment()
  const {
    joinArrayOfStringsWithNewlineCharacter,
    setIsLoadingComponentViewData,
    academySignupBenefits,
    highlightedCourseBundlePrefix,
    fromEmbeddedQuiz,
    desktopLogo,
  } = useCommonMixin()
  const { translateString } = useI18n()

  const courseBundleSignupIntroText = ref('')
  const routingToNextOnboardingPage = ref(false)

  const stringifiedAcademyBenefits = computed(() => {
    if (!academySignupBenefits.value.length) return ''
    return joinArrayOfStringsWithNewlineCharacter(academySignupBenefits.value!)
  })

  const hasValidUser = computed(() => {
    return UserModule.hasValidUser
  })

  const userHasOnboarded = computed(() => {
    return UserModule.onboarded
  })

  const shouldShowProfileUpdate = computed(
    () =>
      (!userHasOnboarded.value &&
        hasValidUser &&
        (UserModule.currentCompany?.enableOnboardingSurvey
          ? !UserModule.currentCompany?.onboardingSurveyCompleted
          : true)) ||
      routingToNextOnboardingPage,
  )

  const showSetUserAccountView = computed(() => hasValidUser.value && !userHasOnboarded.value)

  const registerOrSetAccountText = computed(() =>
    showSetUserAccountView.value
      ? translateString('onboarding.profilePage.createUserAccount')
      : courseBundleSignupIntroText.value
        ? translateString('onboarding.customSignup.registerHereForFree')
        : translateString('onboarding.customSignup.register'),
  )

  const showOnboardingSurvey = computed(
    () => UserModule.currentCompany?.enableOnboardingSurvey && !UserModule.currentCompany?.onboardingSurveyCompleted,
  )

  const setIsAcademySignupCookie = () => {
    const isAcademySignup = CookieHelper.getCookieValue('is-academy-signup')

    if (!isAcademySignup) {
      CookieHelper.setCrossDomainCookie('is-academy-signup', 'true')
    }
  }

  const fetchCourseBundleSignupIntroText = () => {
    if (fromEmbeddedQuiz.value) {
      CookieHelper.setCrossDomainCookie('fromEmbeddedQuiz', fromEmbeddedQuiz.value)
      courseBundleSignupIntroText.value = translateString('onboarding.customSignup.freeExamQuestions')
    }

    if (highlightedCourseBundlePrefix.value) {
      CookieHelper.setCrossDomainCookie('bundlePrefix', highlightedCourseBundlePrefix.value)
      CourseApi.getCourseBundleSignupIntroText(highlightedCourseBundlePrefix.value).then(
        (resp) => (courseBundleSignupIntroText.value = resp.signupIntroText),
      )
    }
  }

  onMounted(() => {
    setIsAcademySignupCookie()

    if (userHasOnboarded.value) {
      CookieHelper.removeCrossDomainCookie('is-academy-signup')
      window.location.href = '/'
    } else if (!UserModule.hasValidUser) {
      trackPage('Academy Registration')
      pageViewed('Academy Registration')
    }

    fetchCourseBundleSignupIntroText()
    setIsLoadingComponentViewData(false)
  })
</script>

<template lang="pug">
.academy-signup(data-cy='academy-signup')
  .academy-signup-wrapper
    .academy-signup-container
      .academy-logo(v-if='desktopLogo')
        img(:src='desktopLogo', alt='logo')
      .signup-texts(v-if='!showOnboardingSurvey')
        h2.register-or-set-account {{ registerOrSetAccountText }}
        h3 {{ courseBundleSignupIntroText }}
      template
        Signup(v-if='!hasValidUser')

        .ketch-flex.ketch-justify-center.ketch-w-full(v-else-if='showOnboardingSurvey && hasValidUser')
          OnboardingSurvey

        .academy-onboarding-container.ketch-flex.ketch-justify-center.ketch-w-full(v-else-if='shouldShowProfileUpdate')
          transition(mode='out-in', name='next-slide')
            AcademyProfileUpdateForm(
              :first-name='UserModule.firstName || ""',
              :last-name='UserModule.lastName || ""',
              @routing-to-next-onboarding-page='routingToNextOnboardingPage = true'
            )
      .signup-course-texts(v-if='stringifiedAcademyBenefits')
        h6.access-to {{ $t('onboarding.customSignup.instantAccessTo') }}
        TextRenderer.bullet-points(:source='stringifiedAcademyBenefits', skip-glossary-check)
      .login-cta(v-if='!hasValidUser && !userHasOnboarded')
        h6
          span {{ $t('onboarding.alreadyHaveAnAccount') }}
          span.ketch-underline
            router-link(:to='{ name: "Login" }') {{ $t('onboarding.loginHere') }}
</template>

<style lang="postcss">
  .academy-signup {
    .academy-signup-wrapper {
      @apply ketch-h-full sm:ketch-h-auto ketch-bg-[center_80%];

      @screen sm {
        background-image: url('../../assets/images/signupBackground.svg');
        background-repeat: no-repeat;
      }

      .academy-signup-container {
        @apply ketch-flex ketch-flex-col ketch-items-center ketch-space-y-c15 ketch-py-c20;
        .signup-texts {
          @apply ketch-text-center ketch-text-[#00254A] ketch-px-c10 sm:ketch-px-0;
          .register-or-set-account {
            @apply ketch-font-bold;
          }
        }

        .signup-wrapper {
          @apply ketch-py-0 ketch-max-w-[500px];
          .signup-form {
            @apply ketch-p-c20 sm:ketch-p-c25 ketch-rounded-xl;
            input {
              @apply focus:ketch-border-[#008A40];
            }
            button {
              @apply ketch-rounded-xlarge ketch-bg-[#008A40];
              h3 {
                @apply ketch-text-white;
              }
            }
            .oauth-connections {
              @apply ketch-flex ketch-items-center ketch-justify-center;
              .google {
                @apply ketch-px-c10;
              }
            }
          }
        }

        .academy-onboarding-wrapper {
          @apply ketch-w-full ketch-bg-white ketch-rounded-xl ketch-max-w-[500px];
          h3 {
            @apply ketch-text-[#00254A];
          }
          .your-details {
            @apply ketch-mt-0;
          }
        }

        .signup-course-texts {
          @apply ketch-flex ketch-flex-col ketch-items-center ketch-space-y-c5;
          @apply ketch-text-[#00254A];
          .access-to {
            @apply ketch-font-bold ketch-mb-c5;
          }
          .bullet-points {
            @apply ketch-text-center ketch-px-c5 sm:ketch-px-0;
            .content-container {
              @apply ketch-flex ketch-flex-col ketch-items-center sm:ketch-flex-row sm:ketch-items-baseline;
              p,
              ul li,
              ol li {
                &:before {
                  @apply ketch-bg-[#008A40];
                  -webkit-mask: url('../../assets/images/checkmark.svg') no-repeat 100% 100%;
                  mask: url('../../assets/images/checkmark.svg') no-repeat 100% 100%;
                  content: '';
                  -webkit-mask-size: cover;
                  mask-size: cover;
                }
              }
            }
          }
        }

        .login-cta {
          @apply ketch-text-[#00254A];
          h6 {
            @apply ketch-font-bold ketch-space-x-c5;
          }
        }
      }
    }
  }
</style>
