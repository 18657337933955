import { render, staticRenderFns } from "./CourseSectionLoader.vue?vue&type=template&id=6fca3772&scoped=true&lang=pug"
import script from "./CourseSectionLoader.vue?vue&type=script&setup=true&lang=ts"
export * from "./CourseSectionLoader.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./CourseSectionLoader.vue?vue&type=style&index=0&id=6fca3772&prod&lang=postcss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6fca3772",
  null
  
)

export default component.exports