<template lang="pug">
.downloadable-download-url-editor
  .edit-download-url-header
    .download-url-info
      h5 {{ $t('editor.editDownloadFileTitle') }}
      h6 {{ $t('editor.editDownloadLinkDescription') }}
    .download-url-cta(v-if='url')
      h6(@click='resetUrl') {{ $t('editor.uploadDownloadFile') }}
  TextRenderer(
    :source='url',
    @text-input='setDownloadUrl("", $event)',
    is-text-input-read-only,
    show-text-input,
    v-if='url'
  )
  MediaUploader(:file-type='"lesson_downloadable"', @download_data='setDownloadUrl', v-else, zip-or-pdf-only)
</template>

<script setup lang="ts">
  import { ref, watch } from 'vue'
  import MediaUploader from '@/components/common/MediaUploader.vue'
  import { EditorModule } from '@/store/modules/editor'
  import TextRenderer from '@/components/editor/TextRenderer.vue'
  import { useRoute } from 'vue-router/composables'
  import type { PropType } from 'vue'
  import type { LessonDownloadable } from '@/services/interfaces/Course'

  const props = defineProps({
    downloadUrl: {
      type: String,
      required: true,
    },
    editorState: String as PropType<'lessonDownloadable'>,
    editorProperty: String as PropType<keyof LessonDownloadable>,
  })

  const route = useRoute()
  const url = ref(props.downloadUrl || '')
  const downloadData = ref('')

  const setDownloadUrl = (fileData: string, downloadUrl: string) => {
    url.value = downloadUrl
    EditorModule.setDownloadableDownloadUrl(downloadUrl)

    downloadData.value = fileData
    EditorModule.setDownloadableRealDownloadData(fileData)
  }

  const resetUrl = () => {
    url.value = ''
    downloadData.value = ''
  }

  watch(
    () => props.downloadUrl,
    (downloadUrl) => {
      url.value = downloadUrl
    },
  )

  watch(url, (url) => {
    if (props.editorState && props.editorProperty) {
      EditorModule.setEditableState({
        key: `${props.editorState}${props.editorProperty}`,
        path: route.path,
        state: props.editorState,
        property: props.editorProperty,
        value: {
          url,
          downloadData: downloadData.value,
        },
      })
    }
  })
</script>

<style lang="postcss" scoped>
  .downloadable-download-url-editor {
    @apply ketch-flex ketch-flex-col ketch-space-y-c20;
    .edit-download-url-header {
      @apply ketch-flex ketch-justify-between ketch-items-end;
      h5,
      h6 {
        @apply ketch-text-editor-primary-color;
      }
      .download-url-info {
        h5 {
          @apply ketch-font-bold;
        }
      }
      .download-url-cta {
        h6 {
          @apply ketch-cursor-pointer hover:ketch-underline;
        }
      }
    }
  }
</style>
