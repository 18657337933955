<template lang="pug">
.notes(data-cy='notes')
  h3 {{ $t('courses.notes.yourNotes') }}
  section
    transition(name='flash')
      .note-saved(v-if='noteSaved')
        h5 {{ $t('courses.notes.noteSaved') }}
        SVGRenderer(:has-hover='false', :icon='checkmark', stroke-color='var(--primary-text-color)', width='12')
    KetchUpButton.quaternary(
      :disable='isSavingNotes',
      @click.native='saveNotes',
      data-cy='save-notes',
      v-if='!noteSaved && showSaveButton'
    )
      LoadingSpinner(v-if='isSavingNotes')
      h5(v-else) {{ $t('courses.notes.saveANote') }}
    h6.updated-date(v-if='updatedDate && myNotes.content') {{ $t('courses.notes.lastChanged', { updatedDate }) }}
  ExpandableTextAreaInput.message-box(
    :do-not-update-size='isSavingNotes',
    :text-area-placeholder='String($t("courses.notes.placeholder"))',
    :text-message='typedNotes',
    @text-change='typedNotes = $event'
  )
</template>

<script setup lang="ts">
  import { computed, onBeforeUnmount, ref, watch } from 'vue'
  import LoadingSpinner from '@/components/common/LoadingSpinner.vue'
  import useIcons from '@/composables/useIcons'
  import KetchUpButton from '@/components/common/KetchUpButton.vue'
  import SVGRenderer from '@/components/common/SVGRenderer.vue'
  import ExpandableTextAreaInput from '@/components/common/ExpandableTextAreaInput.vue'
  import CourseApi from '@/services/api/CourseApi'
  import useCourse from '@/composables/useCourse'
  import type { Note } from '@/services/interfaces/Course'
  import { DateTime } from 'luxon'
  import useGamification from '@/composables/useGamification'
  import useSegment from '@/composables/useSegment'

  const { checkmark } = useIcons()
  const { trackGamificationActivity } = useGamification()
  const myNotes = ref({ id: '', content: '', updatedAt: '' } as Note)
  const isSavingNotes = ref(false)
  const noteSaved = ref(false)
  const { currentCourseId, moduleId, lessonId, setLessonHasNote, course, currentModule, currentLesson } = useCourse()
  const { lessonNoteSaved } = useSegment()
  const typedNotes = ref('')
  const timeoutId = ref<NodeJS.Timeout | undefined>(undefined)

  CourseApi.getLessonNote(currentCourseId.value, moduleId.value, lessonId.value).then((note) => {
    if (note) {
      myNotes.value = note
      typedNotes.value = note.content
    }
  })

  const showSaveButton = computed(() => typedNotes.value !== myNotes.value.content)
  const updatedDate = computed(() => {
    if (!myNotes.value.updatedAt) return null
    const d = DateTime.fromSQL(myNotes.value.updatedAt)
    return d.toFormat('dd.MM.yyyy')
  })

  const saveNotes = () => {
    if (isSavingNotes.value) return
    isSavingNotes.value = true
    CourseApi.saveLessonNote(currentCourseId.value, moduleId.value, lessonId.value, typedNotes.value)
      .then((note) => {
        if (!myNotes.value.id) {
          trackGamificationActivity('LESSON_NOTES_ADDED', {
            courseId: currentCourseId.value,
            moduleId: moduleId.value,
            lessonId: lessonId.value,
          })
        }
        lessonNoteSaved(
          currentCourseId.value,
          course.value!.title,
          moduleId.value,
          currentModule.value.name,
          lessonId.value,
          currentLesson.value!.title,
        )
        myNotes.value = note
        noteSaved.value = true
        setLessonHasNote(lessonId.value)
        setTimeout(() => {
          noteSaved.value = false
        }, 5000)
      })
      .finally(() => {
        isSavingNotes.value = false
      })
  }

  watch(typedNotes, () => {
    if (!noteSaved.value && showSaveButton.value) {
      clearTimeout(timeoutId.value)

      timeoutId.value = setTimeout(() => {
        saveNotes()
      }, 2500)
    }
  })

  onBeforeUnmount(() => {
    clearTimeout(timeoutId.value)
  })
</script>

<style lang="postcss" scoped>
  .notes {
    @apply ketch-grid ketch-gap-y-c15 md:ketch-gap-y-0 md:ketch-gap-x-c50;
    @screen xs {
      grid-template-columns: auto;
    }
    @screen md {
      grid-template-columns: 200px auto;
    }
    h3 {
      @apply md:ketch-mb-c15 ketch-font-bold ketch-col-span-1;
    }
    section {
      @apply ketch-flex ketch-flex-col ketch-col-start-1 ketch-row-start-5 md:ketch-row-start-2 ketch-w-[fit-content];
      .updated-date {
        @apply ketch-mt-c4 ketch-text-primary-text-color ketch-text-opacity-50;
      }
      .note-saved {
        @apply ketch-flex ketch-space-x-c10;
      }
      button {
        @apply ketch-p-0;
        .loading {
          @apply ketch-m-0;
        }
      }
    }
    .message-box {
      @apply ketch-row-start-2 ketch-col-start-1 md:ketch-row-start-1 md:ketch-col-start-2 ketch-row-span-3;
      @apply ketch-border ketch-border-border-color ketch-rounded-large ketch-p-c12;
      ::after,
      textarea {
        @apply ketch-p-c12;
      }
      textarea {
        &::placeholder {
          @apply ketch-text-primary-text-color ketch-text-opacity-60;
        }
        &::-webkit-scrollbar {
          @apply ketch-w-c10;
        }
        &::-webkit-scrollbar-thumb {
          @apply ketch-rounded-normal ketch-bg-primary-text-color ketch-bg-opacity-40;
        }
      }
    }
  }
</style>
