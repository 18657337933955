<template lang="pug">
a.custom-widget(:href='widget.url', :target='widgetTarget')
  h5 {{ widget.displayText }}
</template>

<script setup lang="ts">
  import { computed } from 'vue'
  import type { PropType } from 'vue'
  import type { CompanyWidget } from '@/services/interfaces/Auth0'

  const props = defineProps({
    widget: {
      type: Object as PropType<CompanyWidget>,
      required: true,
    },
  })

  const widgetTarget = computed(() => (props.widget.type === 'link' ? '_blank' : 'self'))
</script>

<style lang="postcss">
  .custom-widget {
    @apply ketch-bg-primary-color ketch-text-primary-foreground-color ketch-rounded-large;
    @apply ketch-flex ketch-items-center ketch-justify-center ketch-px-c30 ketch-h-c45 ketch-w-fit;
    box-shadow: rgb(0 0 0 / 25%) 0 2px 5px;
    h5 {
      @apply ketch-font-bold;
    }
  }
</style>
