<template lang="pug">
.placeholder-shimmer
  .shimmer-wrapper(
    :class='{ animate: animationStatus, "shimmer-bg": renderingStatus }',
    :style='{ height: isLoadingResource ? height : "auto", width: width }'
  )
    slot(:is-loading-resource='isLoadingResource', :on-resource-loaded='onResourceLoaded')
</template>

<script setup lang="ts">
  import { computed, ref } from 'vue'

  const props = defineProps({
    animate: {
      type: Boolean,
      default: false,
    },
    height: {
      type: String,
      default: '100%',
    },
    width: {
      type: String,
      default: '100%',
    },
    slotStatus: {
      type: Boolean,
      default: undefined,
    },
  })
  const isLoadingResource = ref(true)

  const animationStatus = computed(() => {
    if (isLoadingResource.value) return props.animate
    return isLoadingResource.value
  })

  const renderingStatus = computed(() => {
    if (typeof props.slotStatus === 'boolean') return props.slotStatus
    return isLoadingResource.value
  })

  const onResourceLoaded = () => {
    isLoadingResource.value = false
  }
</script>

<style lang="postcss" scoped>
  .placeholder-shimmer {
    display: contents;
    .shimmer-wrapper:not(.shimmer-bg) {
      @apply ketch-contents sm:ketch-inline md:ketch-block;
    }
    .shimmer-bg {
      @apply ketch-block ketch-bg-black ketch-bg-opacity-5 ketch-w-full ketch-mb-c5 ketch-rounded-2xl;
    }
    .animate {
      @apply ketch-bg-black ketch-bg-opacity-5 ketch-rounded-md ketch-animate-shimmer ketch-bg-c200 ketch-bg-shimmer-start;
      background: linear-gradient(to right, #d0d0d0 8%, #e0dede 58%, #d0d0d0 92%);
    }
    .shimmer-asset {
      @apply ketch-opacity-0;
    }
  }
  @keyframes placeHolderShimmer {
    0% {
      @apply ketch-bg-shimmer-end;
    }
    50% {
      @apply ketch-opacity-50;
    }
    to {
      @apply ketch-bg-shimmer-start;
    }
  }
</style>
