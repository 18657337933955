import { render, staticRenderFns } from "./DownloadMaterials.vue?vue&type=template&id=335364df&scoped=true&lang=pug"
import script from "./DownloadMaterials.vue?vue&type=script&setup=true&lang=ts"
export * from "./DownloadMaterials.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./DownloadMaterials.vue?vue&type=style&index=0&id=335364df&prod&lang=postcss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "335364df",
  null
  
)

export default component.exports