<script setup lang="ts">
  import { computed, onMounted, ref } from 'vue'
  import type { PropType } from 'vue'
  import type { MemberGroup } from '@/services/interfaces/Auth0'
  import SVGRenderer from '@/components/common/SVGRenderer.vue'
  import KetchUpButton from '@/components/common/KetchUpButton.vue'
  import LoadingSpinner from '@/components/common/LoadingSpinner.vue'
  import useIcons from '@/composables/useIcons'
  import TextRenderer from '@/components/editor/TextRenderer.vue'
  import UsersApi from '@/services/api/UsersApi'
  import { UserModule } from '@/store/modules/user'
  import eventBus from '@/main'
  import * as Sentry from '@sentry/vue'
  import useEditor from '@/composables/useEditor'
  import type { EditorStatePayload } from '@/services/interfaces/Course'
  import { useRoute } from 'vue-router/composables'

  const props = defineProps({
    group: {
      type: Object as PropType<MemberGroup>,
      required: true,
    },
    cancel: {
      type: Function as PropType<(groupId: string) => void>,
      required: true,
    },
  })

  const emit = defineEmits(['reset-editing-key', 'reload-user-members'])

  const route = useRoute()
  const { checkmark, checkboxSquare, checkedCheckboxSquare } = useIcons()
  const { hasLocalChanges } = useEditor(route)

  const payload = ref<{
    [key: string]: {
      id: string
      name: string
      description?: string
      includeInGroupProfileQuestion: boolean
      colorId?: string
    }
  }>({})
  const isSubmitting = ref(false)

  const canSaveGroupDetails = computed(() => {
    if (!payload.value[props.group.id]?.name) return false
    return hasLocalChanges.value(props.group, payload.value[props.group.id])
  })

  const mutateAndQueueFunc = (groupId: string) => {
    return async (data: EditorStatePayload) => {
      const currentData: any = payload.value[groupId]

      eventBus.$set(payload.value, groupId, {
        ...currentData,
        id: groupId,
        [data.property as string]: data.value,
      })
    }
  }

  const toggleIncludeInProfileQuestion = (groupId: string) => {
    payload.value[groupId].includeInGroupProfileQuestion = !payload.value[groupId].includeInGroupProfileQuestion
  }

  const onSubmit = async (group: MemberGroup) => {
    if (group.name === 'All  Users') return
    isSubmitting.value = true

    const data = payload.value[group.id]
    try {
      if (group.id === '') {
        Object.assign(data, { id: undefined })
        await UsersApi.createMemberGroup(UserModule.currentCompany!.id, data)
      } else {
        await UsersApi.updateMemberGroup(UserModule.currentCompany!.id, data)
        emit('reload-user-members')
        emit('reset-editing-key')
      }
      eventBus.$delete(payload.value, group.id)
      await UserModule.getMembersGroups()
      await UserModule.getCompanyActivityLog()
    } catch (error) {
      Sentry.captureException(error)
    }
    isSubmitting.value = false
  }

  const populatePayload = () => {
    if (props.group) {
      eventBus.$set(payload.value, props.group.id, {
        ...props.group,
        id: props.group.id,
      })
    }
  }

  onMounted(() => {
    populatePayload()
  })
</script>

<template lang="pug">
.user-group-editor.title-description.editing
  .title-section
    .left-side
      TextRenderer.title(
        :allow-edit='"yes"',
        :editor-state='`group-name-${group.id}`',
        :mutate-and-queue-func='mutateAndQueueFunc(group.id)',
        :placeholder='String($t("homepage.addGroup.placeholder"))',
        :show-text-input='true',
        :source='group?.name || ""',
        editor-property='name'
      )
  TextRenderer.description(
    :allow-edit='"yes"',
    :editor-state='`group-description-${group.id}`',
    :mutate-and-queue-func='mutateAndQueueFunc(group.id)',
    :placeholder='String($t("homepage.newGroupDescPlaceholder"))',
    :source='group.description || ""',
    editor-property='description',
    show-extra-text-tools,
    show-toolbar-text-block-only
  )
  .include-in-profile-question(@click='toggleIncludeInProfileQuestion(group.id)')
    img(:src='checkedCheckboxSquare', v-if='payload[group.id]?.includeInGroupProfileQuestion')
    img(:src='checkboxSquare', v-else)
    h5 {{ $t('homepage.includeInProfileQuestion') }}
  .buttons
    KetchUpButton.quaternary(@click.native.stop='cancel(group.id)')
      h5 {{ $t('cancel') }}
    KetchUpButton.primary(:disabled='isSubmitting || !canSaveGroupDetails', @click.native.stop='onSubmit(group)')
      LoadingSpinner(v-if='isSubmitting')
      SVGRenderer(:fill-color='"var(--primary-color)"', :has-hover='false', :icon='checkmark', v-else, width='16')
</template>

<style lang="postcss">
  .user-group-editor {
    .left-side {
      @apply ketch-flex-1;
      .title {
        @apply ketch-w-full;
        input {
          @apply ketch-rounded-normal;
        }
      }
    }
    .ck-outside-header .right {
      @apply ketch-hidden;
    }
    .description .ck-editor .ck-content {
      @apply ketch-min-h-[120px];
    }
    .include-in-profile-question {
      @apply ketch-flex ketch-items-center ketch-space-x-c10 ketch-cursor-pointer ketch-mt-c15;
    }
    .buttons {
      @apply ketch-flex ketch-justify-end ketch-items-center ketch-space-x-c15 ketch-mt-c15;
      button {
        @apply ketch-w-auto;
        svg {
          @apply ketch-w-c16;
        }
        &.quaternary {
          @apply ketch-p-0;
        }
      }
    }
  }
</style>
