<template lang="pug">
.company-courses
  LoadingSpinner(v-if='!Object.keys(groupedCourses).length')
  template(v-else, v-for='(courses, key) in groupedCourses')
    .courses(:class='String(key)', :key='String(key)', v-if='courses.length')
      .course-header(@click='setCollapsedState(String(key))')
        h6
          span.title {{ headerTitle(String(key)) }}
          span.number [{{ courses.length }}]
        .svg-wrap(:class='{ collapsed: collapsedStates[key] }')
          SVGRenderer(:has-hover='false', :icon='arrowDown', :stroke-color='"var(--primary-color)"', width='11')
      transition(name='menu-slide')
        .courses-container(v-if='!collapsedStates[key]')
          .course(
            :class='{ purchased: isPurchasedItem(item), new: isNewSelection(item) }',
            :key='getItemKey(item)',
            @click='onCourseSelection(item)',
            v-for='item in courses'
          )
            .course-items(:class='{ bundle: key === "bundles" }')
              Choice.without-border(:svg-renderer-props='choiceIcon(item)', :title='item.title')
              h6.price(v-if='productPrice(item)') {{ currencyFormatDE(productPrice(item)) }}
            LastActivity(:last-activity='getCourseActivity(item)?.lastActivity || ""', v-if='getCourseActivity(item)')
              h6.progress {{ getCourseActivity(item)?.completionPercentage || 0 }}%
</template>

<script setup lang="ts">
  import { computed, ref, watch } from 'vue'
  import useIcons from '@/composables/useIcons'
  import { CourseModule } from '@/store/modules/course'
  import Choice from '@/components/common/Choice.vue'
  import useCommonMixin from '@/composables/useCommonMixin'
  import CourseApi from '@/services/api/CourseApi'
  import { UserModule } from '@/store/modules/user'
  import LoadingSpinner from '@/components/common/LoadingSpinner.vue'
  import SVGRenderer from '@/components/common/SVGRenderer.vue'
  import LastActivity from '@/components/common/LastActivity.vue'
  import eventBus from '@/main'
  import useI18n from '@/composables/useI18n'
  import type { PropType } from 'vue'
  import type { CourseItem, CoursePackage } from '@/services/interfaces/Course'
  import type { UserCourseActivity } from '@/services/interfaces/Auth0'
  import useCourse from '@/composables/useCourse'

  const props = defineProps({
    auth0Id: {
      type: String,
      required: true,
    },
    groupedCourses: {
      type: Object as PropType<{ [key: string]: (CourseItem | CoursePackage)[] }>,
      required: true,
    },
    headerTitle: {
      type: Function as PropType<(key: string) => string>,
      required: true,
    },
    isPurchasedItem: {
      type: Function as PropType<(item: CourseItem | CoursePackage) => boolean>,
      required: true,
    },
    isPurchasedCourse: {
      type: Function as PropType<(courseId: string) => boolean>,
      required: true,
    },
    getUserActivity: {
      type: Function as PropType<(courseId?: string) => UserCourseActivity | undefined>,
      required: true,
    },
    submitCourses: {
      type: Boolean,
      default: false,
    },
  })
  const emit = defineEmits(['submitting-courses', 'disable-submit-c-t-a'])

  const { checkedCheckboxSquareWhite, checkboxSquareWhite, arrowDown } = useIcons()
  const { currencyFormatDE } = useCommonMixin()
  const { translateString } = useI18n()
  const { productPrice } = useCourse()

  const selectedCourses = ref([] as CourseItem[])
  const submitting = ref(false)
  const collapsedStates = ref({} as { [key: string]: boolean })

  const isSelected = computed(() => {
    return (courseId: string) => {
      return props.isPurchasedCourse(courseId) || selectedCourses.value.some((c) => c.id === courseId)
    }
  })

  const courses = (item: CourseItem | CoursePackage) => {
    return (item as CourseItem).id ? [item as CourseItem] : (item as CoursePackage).courses
  }

  const isNewSelection = computed(() => (item: CourseItem | CoursePackage) => {
    return courses(item).every((course) => selectedCourses.value.map((c) => c.id).includes(course.id))
  })

  const choiceIcon = computed(() => {
    return (item: CourseItem | CoursePackage) => {
      const svgProps: any = {
        strokeColor: 'var(--primary-text-color)',
        icon: checkboxSquareWhite.value,
      }
      if (courses(item).every((c) => isSelected.value(c.id))) {
        svgProps.icon = checkedCheckboxSquareWhite.value
      }
      return svgProps
    }
  })

  const getItemKey = computed(() => (item: CourseItem | CoursePackage) => {
    return (item as CourseItem).id || (item as CoursePackage).courseBundleId
  })

  const onCourseSelection = (item: CourseItem | CoursePackage) => {
    if (props.isPurchasedItem(item)) return

    courses(item).forEach((course) => {
      const index = selectedCourses.value.findIndex((c) => c.id === course.id)
      if (index >= 0) {
        selectedCourses.value.splice(index, 1)
      } else {
        selectedCourses.value.push(course)
      }
    })
    if (selectedCourses.value.length) return emit('disable-submit-c-t-a', false)
    emit('disable-submit-c-t-a', true)
  }

  const submitAssignedCourses = () => {
    if (!selectedCourses.value.length) return
    emit('submitting-courses', true)
    submitting.value = true

    CourseApi.assignCompanyCourseToUser(
      props.auth0Id,
      UserModule.currentCompany!.id,
      selectedCourses.value.map((c) => c.id),
    )
      .then(async () => {
        await CourseModule.getMemberCourses({ auth0Id: props.auth0Id })
        selectedCourses.value = []
        eventBus.$toasted.success(translateString('homepage.assignedCourses.successfullyAssigned'), {
          duration: 5000,
        })
      })
      .finally(() => {
        submitting.value = false
        emit('submitting-courses', false)
      })
  }

  const setCollapsedState = (key: string) => {
    if (key in collapsedStates.value) {
      collapsedStates.value[key] = !collapsedStates.value[key]
    } else {
      eventBus.$set(collapsedStates.value, key, true)
    }
  }

  const getCourseActivity = (item: CourseItem | CoursePackage) => {
    const courseId = (item as CourseItem).id
    return courseId ? props.getUserActivity(courseId) : undefined
  }

  watch(
    () => props.submitCourses,
    (value) => {
      if (value) {
        submitAssignedCourses()
      }
    },
  )
</script>

<style lang="postcss">
  .company-courses {
    @apply ketch-border ketch-border-border-color ketch-rounded-normal ketch-rounded-tl-none;
    .course {
      &.new {
        @apply ketch-bg-primary-color ketch-bg-opacity-10;
        > * {
          @apply ketch-text-primary-color;
        }
      }
      .choice {
        @apply ketch-p-0;
        svg {
          @apply ketch-mt-c5;
        }
      }
      .price {
        @apply ketch-font-bold ketch-ml-c30;
      }
    }
  }
</style>
