<script setup lang="ts">
  import ProgressBar from '@/components/common/ProgressBar.vue'
  import KetchUpButton from '@/components/common/KetchUpButton.vue'
  import SVGRenderer from '@/components/common/SVGRenderer.vue'
  import useIcons from '@/composables/useIcons'

  const { arrowRight } = useIcons()

  defineProps({
    progressPercentage: {
      type: Number,
      required: true,
    },
  })

  defineEmits(['toggle-continue'])
</script>

<template lang="pug">
.course-progress-overview
  .progress-details
    h2.progress-value {{ progressPercentage }}%
    ProgressBar(:percentage='progressPercentage')
  KetchUpButton.primary(@click.native='$emit("toggle-continue")')
    h5 {{ $t('courses.continue') }}
    SVGRenderer(:has-hover='false', :icon='arrowRight', stroke-color='var(--primary-foreground-color)')
</template>

<style scoped lang="postcss">
  .course-progress-overview {
    @apply ketch-flex ketch-space-x-c20;
    .progress-value {
      @apply ketch-leading-sm ketch-pt-c5;
    }
    .progress-details {
      @apply ketch-relative ketch-flex ketch-flex-col ketch-items-end ketch-flex-1;
      .progress-bar-wrapper {
        @apply ketch-mb-c4;
      }
    }
    button {
      @apply ketch-w-auto;
    }
  }
</style>
