<template lang="pug">
.course-exam-container
  CourseExamHeader(
    :continue-exam-request='continueExamRequest',
    :is-exam-paused='isCourseExamPaused',
    :is-part-one-done='showPartOneDone',
    :loading-exam-content='loadingExamContent',
    :no-questions-answered-yet='noQuestionsAnsweredYet',
    :progress-bar-options='progressBarOptions || []',
    @continue-exam='continueExam',
    @pause-or-end-exam='pauseOrEndExam'
  )
  .part-duration(v-if='!loadingExamContent')
    h5.part-name(v-if='!inCourseExamResultsPage')
      span {{ $t('courses.examSimulation.part') }} {{ currentPartIndex + 1 }}:
      span {{ currentPartName }}
    .duration-wrapper
      h5.duration(data-cy='duration', v-if='!reviewingQuestion') {{ showDuration }}
  template(v-if='inCourseExamResultsPage')
    .course-exam-results
      CourseExamResults
  template(v-else)
    LoadingSpinner(v-if='loadingCourseContent')
    CourseExamContent(
      :continue-exam-request='continueExamRequest',
      :loading-exam-content='loadingExamContent',
      :show-part-one-done='showPartOneDone',
      @continue-exam='continueExam',
      @set-no-questions-answered-yet='noQuestionsAnsweredYet = $event',
      @set-show-part-one-done-value='showPartOneDone = $event',
      v-else
    )
</template>

<script setup lang="ts">
  import { computed, ref, watch, onBeforeUnmount, onMounted } from 'vue'
  import LoadingSpinner from '@/components/common/LoadingSpinner.vue'
  import CourseExamHeader from '@/components/course/CourseExamHeader.vue'
  import CourseExamContent from '@/components/course/CourseExamContent.vue'
  import CourseExamResults from '@/views/CourseExamResults.vue'
  import useCourse from '@/composables/useCourse'
  import { CourseModule } from '@/store/modules/course'
  import CookieHelper from '@/helpers/CookieHelper'
  import useSegment from '@/composables/useSegment'
  import { useRouter } from 'vue-router/composables'
  import eventBus from '@/main'
  import useI18n from '@/composables/useI18n'
  import useCommonMixin from '@/composables/useCommonMixin'
  import CourseApi from '@/services/api/CourseApi'

  const router = useRouter()
  const {
    course,
    currentCourseId,
    courseExamId,
    isCourseExamPaused,
    isCourseExamTimedOut,
    inCourseExamResultsPage,
    currentExamQuestion,
    reviewingQuestion,
    totalPartQuestions,
    examTimeRemaining,
    latestExamCompletionId,
    examCompletionIdParam,
    answeredQuestions,
  } = useCourse()
  const { examStarted, examTimedOut, examPaused, examContinued } = useSegment()
  const { isKnowledgeCheckCompany } = useCommonMixin()
  const { translateString } = useI18n()
  const timeRemainingValue = ref(0)
  const timerStarted = ref(false)
  const intervalId = ref(0)
  const showPartOneDone = ref(false)
  const noQuestionsAnsweredYet = ref(true)
  const continueExamRequest = ref(false)

  const loadingCourseContent = computed(() => course.value === null)

  const loadingExamContent = computed(() => {
    return currentExamQuestion.value === null || loadingExamCompletionState.value || reviewingQuestion.value
      ? answeredQuestions.value.length === 0
      : false
  })

  const loadingExamCompletionState = computed(() => CourseModule.fetchingExamCompletionState)

  const progressBarOptions = computed(() => {
    return course.value?.exam?.parts.map((_part, index) => {
      let percentage = 0
      if (index < currentPartIndex.value && !inCourseExamResultsPage.value) percentage = 100
      else if (index === currentPartIndex.value) {
        const qNo = currentExamQuestion.value?.questionNumberOnPart || 1
        // const fullyAnsweredPart = showPartOneDone.value && qNo === totalPartQuestions.value(index)
        if (showPartOneDone.value || inCourseExamResultsPage.value) percentage = 100
        else {
          percentage = Math.round(((qNo - 1) / totalPartQuestions.value(index)) * 100)
        }
      } else if (inCourseExamResultsPage.value) percentage = 100
      return {
        name: `${translateString('courses.examSimulation.part')} ${index + 1}`,
        percentage,
        totalPartQuestions: totalPartQuestions.value(index),
      }
    })
  })

  const currentPartIndex = computed(() => {
    if (!showPartOneDone.value && currentExamQuestion.value?.partId) {
      return course.value?.exam!.parts.findIndex((part) => part.partId === currentExamQuestion.value!.partId) ?? -1
    }
    return 0
  })

  const currentPartName = computed(() => {
    if (!course.value?.exam) return ''
    return course.value!.exam.parts[currentPartIndex.value].name
  })

  const showDuration = computed(() => {
    return inCourseExamResultsPage.value || showPartOneDone.value
      ? translateString('courses.examSimulation.done')
      : formatTimeRemaining.value
  })

  const formatTimeRemaining = computed(() => {
    const min = Math.floor(timeRemainingValue.value / 60)
    const secs = timeRemainingValue.value - min * 60
    return `${min.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`
  })

  const currentPartDuration = computed(() => {
    return (course.value?.exam!.parts[currentPartIndex.value].durationInMinutes || 0) * 60
  })

  const timeRemaining = () => {
    return examTimeRemaining.value || currentPartDuration.value
  }

  watch(currentExamQuestion, (value) => {
    if (value) {
      if (
        !loadingExamContent.value &&
        !inCourseExamResultsPage.value &&
        !reviewingQuestion.value &&
        !isCourseExamPaused.value
      ) {
        setTimeAndStartIt()
        if (value.questionNumber === 1) {
          examStarted(courseExamId.value, currentCourseId.value, course.value!.title)
        }
      }
    }
  })

  watch(inCourseExamResultsPage, (value) => {
    if (value) {
      CourseModule.getCourseExamQuestionsAnswered(examCompletionIdParam.value)
      stopTimer()
    }
  })

  watch(reviewingQuestion, (value) => {
    if (value) {
      stopTimer()
    }
  })

  watch(showPartOneDone, (value) => {
    if (value) {
      CookieHelper.setCookieValue('exam_paused_by_system', 'true')
      pauseExam()
    } else {
      setTime()
    }
  })

  const examHasTimedOut = async () => {
    await CourseModule.postExamAnswer({
      selectedAnswerIds: [],
      state: 'timedOut',
      numberAnswer: null,
    }).then(() => {
      examTimedOut(
        courseExamId.value,
        totalPartQuestions.value(currentPartIndex.value),
        currentExamQuestion.value?.questionNumberOnPart ? currentExamQuestion.value?.questionNumberOnPart - 1 : 1,
        currentCourseId.value,
        course.value!.title,
      )
    })
    openExamTimeoutModal()
  }

  const openExamTimeoutModal = () => {
    eventBus.$emit('show-modal', {
      modalContentComponent: 'CourseExamTimeoutModal',
      cssClass: 'course-exam-timeout-modal',
      hideCloseButton: true,
      modalCloseCallback: () => {
        return
      },
    })
  }

  const pauseExam = () => {
    if (!latestExamCompletionId.value) return
    CourseModule.pauseExam(timeRemainingValue.value).then(() => {
      stopTimer()
      examPaused(currentCourseId.value, course.value!.title, courseExamId.value, timeRemainingValue.value)
    })
  }

  const pauseOrEndExam = () => {
    if (inCourseExamResultsPage.value || reviewingQuestion.value || noQuestionsAnsweredYet.value) {
      router.push({ name: isKnowledgeCheckCompany.value ? 'KnowledgeCheckInfo' : 'ExamStartPage' }).catch(() => {
        return
      })
    } else {
      pauseExam()
    }
  }

  const continueExam = () => {
    continueExamRequest.value = true
    CourseModule.getNextQuestion(true)
      .then(() => {
        CookieHelper.removeCookie('exam_paused_by_system')
        examContinued(
          currentCourseId.value,
          course.value!.title,
          courseExamId.value,
          currentExamQuestion.value?.timeRemainingInSeconds ?? 0,
        )
      })
      .finally(() => {
        timeRemainingValue.value = currentExamQuestion.value?.timeRemainingInSeconds ?? 0
        if (showPartOneDone.value) {
          showPartOneDone.value = false
        }
        continueExamRequest.value = false
        if (!timerStarted.value) {
          startTimer()
        }
      })
  }

  const stopTimer = () => {
    timerStarted.value = false
    window.clearInterval(intervalId.value)
  }

  const startTimer = () => {
    timerStarted.value = true
    intervalId.value = window.setInterval(() => {
      timeRemainingValue.value--
      if (timeRemainingValue.value <= 0) {
        examHasTimedOut()
        stopTimer()
      }
    }, 1000)
  }

  const setTimeAndStartIt = () => {
    if (!isCourseExamTimedOut.value && !timerStarted.value) {
      timeRemainingValue.value = timeRemaining()
      startTimer()
    }
  }

  const setTimeRemaining = () => {
    if (isCourseExamPaused.value && examTimeRemaining.value) timeRemainingValue.value = examTimeRemaining.value
  }

  const setTime = () => {
    timeRemainingValue.value =
      isCourseExamPaused.value && examTimeRemaining.value ? examTimeRemaining.value : currentPartDuration.value
  }

  const updateCompletionTimeRemaining = () => {
    stopTimer()
    if (
      latestExamCompletionId.value &&
      !isCourseExamPaused.value &&
      !isCourseExamTimedOut.value &&
      !reviewingQuestion.value &&
      !inCourseExamResultsPage.value
    ) {
      CourseApi.updateExamCompletionTimeRemaining(
        course.value!.id,
        course.value!.exam!.id,
        latestExamCompletionId.value,
        timeRemainingValue.value,
      )
    }
  }

  const getCompletionStatusAndSetTimeRemaining = () => {
    if (latestExamCompletionId.value) {
      CourseModule.getExamCompletionState(
        reviewingQuestion.value ? examCompletionIdParam.value : latestExamCompletionId.value,
      ).finally(() => {
        setTimeRemaining()
      })
    }
  }

  if (!course.value) {
    CourseModule.getCourse({ courseId: currentCourseId.value }).finally(() => {
      getCompletionStatusAndSetTimeRemaining()
      if (!CourseModule.coursesModuleMap[currentCourseId.value]) {
        CourseModule.getCourseModules(currentCourseId.value)
      }
    })
  } else if (!inCourseExamResultsPage.value) {
    getCompletionStatusAndSetTimeRemaining()
  }

  onBeforeUnmount(() => {
    window.removeEventListener('beforeunload', updateCompletionTimeRemaining)
  })

  onMounted(() => {
    window.addEventListener('beforeunload', updateCompletionTimeRemaining)
  })
</script>

<style lang="postcss" scoped>
  .course-exam-container {
    @apply ketch-fixed ketch-top-0 ketch-bottom-0 ketch-left-0 ketch-right-0 ketch-z-[2] ketch-overflow-y-auto;
    > .loading {
      @apply ketch-pt-c40;
    }
    .part-duration {
      @apply ketch-flex ketch-justify-center ketch-space-x-c20 ketch-pt-c25;
      .part-name {
        @apply ketch-space-x-c5;
      }
      .duration-wrapper {
        @apply ketch-w-c40;
        .duration {
          @apply ketch-font-bold;
        }
      }
    }
    .course-exam-results {
      @apply ketch-mt-[85px] ketch-pb-c25;
    }
  }
</style>
