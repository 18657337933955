import { UserModule } from '@/store/modules/user'
import UsersApi from '@/services/api/UsersApi'
import {
  UserGamificationActivity,
  UserGamificationActivityResource,
  UserGamificationStats,
  UserLevel,
} from '@/services/interfaces/Auth0'
import { computed, ComputedRef } from 'vue'
import useCommonMixin from '@/composables/useCommonMixin'
import CommonApi from '@/services/api/CommonApi'

interface useGamificationReturn {
  userStats: ComputedRef<UserGamificationStats | null>
  totalPoints: ComputedRef<number>
  currentLevel: ComputedRef<UserLevel | null>
  trackGamificationActivity: (
    activity: UserGamificationActivity,
    resource: UserGamificationActivityResource | null,
  ) => void
  companyHasSetGamificationActivity: ComputedRef<(activity: UserGamificationActivity) => boolean>
}

export default function useGamification(): useGamificationReturn {
  const { isKnowledgeCheckCompany } = useCommonMixin()

  const companyGamificationActivities = computed(() => {
    if (!UserModule.companyGamificationActivities.length) return []
    return UserModule.companyGamificationActivities.map((activity) => activity.name)
  })

  const userStats = computed(() => {
    return UserModule.userGamificationStats
  })

  const totalPoints = computed(() => {
    if (!userStats.value) return 0
    return userStats.value.totalPoints
  })

  const currentLevel = computed(() => {
    if (!UserModule.userGamificationStats?.level) return null
    return UserModule.userGamificationStats.level
  })

  const companyHasSetGamificationActivity = computed(
    () => (activity: UserGamificationActivity) => companyGamificationActivities.value.includes(activity),
  )

  const trackGamificationActivity = async (
    activity: UserGamificationActivity,
    resource: UserGamificationActivityResource | null,
  ) => {
    if (isKnowledgeCheckCompany.value) return
    if (companyGamificationActivities.value.includes(activity)) {
      UserModule.setUserGamificationPointsEarned(0)
      if (!userStats.value?.level) {
        await UserModule.getUserGamificationStats()
      } else {
        UsersApi.postUserGamificationPoints(activity, resource, userStats.value.level.id, UserModule.currentCompany!.id)
          .then(async (pointsEarned) => {
            UserModule.setUserGamificationPointsEarned(pointsEarned)
            UserModule.addUserGamificationPoints(pointsEarned)
            const currentLevelMaxPoints = userStats.value!.level.maximumPoints
            if (currentLevelMaxPoints <= userStats.value!.totalPoints + pointsEarned) {
              await UserModule.getUserGamificationStats()
            }
          })
          .catch((error) => {
            if (error.response && typeof error.response.status === 'number' && error.response.status !== 401) {
              CommonApi.postSlackMessage(
                `Could not post gamification activity.\nResponse: ${error.response.data}\nUser Email: ${UserModule.email} -- Current Company: ${UserModule.currentCompany?.name}`,
              )
            }
          })
      }
    }
  }

  return {
    userStats,
    totalPoints,
    currentLevel,
    companyHasSetGamificationActivity,
    trackGamificationActivity,
  }
}
