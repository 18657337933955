<template lang="pug">
.checkout(:class='{ "in-modal": inModal }', data-cy='checkout')
  LoadingSpinner(v-if='!courseProduct')
  template(v-else)
    .copy(v-if='!inModal')
      SVGRenderer(:has-hover='false', :icon='diamondIcon', :stroke-color='"var(--primary-color)"')
      template(v-if='courseProduct')
        h1.title.one-time {{ courseProduct.title }}
        h5.description {{ courseProduct.teaserDescription }}
    .checkout-container
      .checkout-form
        img.teaser-image(:src='courseTeaserImage', v-if='!inModal && !isPackageCheckout')
        .checkout-form-inputs
          h3 {{ $t('checkout.formTitle') }}
          h5 {{ $t('checkout.formDescription') }}
          .iban-choices
            .choices
              template(v-for='choice in paymentOptions')
                Choice(
                  :append-icon='choice.icon',
                  :data-cy='choice.id',
                  :key='choice.id',
                  :selected='form.paymentOption === choice.id',
                  :svg-renderer-props='prependIcon(choice.id)',
                  :title='choice.title',
                  @click.native='form.paymentOption = choice.id'
                )
            transition(name='menu-slide')
              .form-row(v-if='isSepaPaymentOption')
                .iban-header
                  h4 {{ $t('checkout.iban') }}
                  LoadingSpinner(v-if='!ibanElementReady')
                #iban-element
                //- A Stripe Element will be inserted here.
                .error-message(v-if='error')
                  h5 {{ error }}
                .mandate-acceptance
                  h5 {{ $t('checkout.mandateAcceptance') }}
              .form-row(v-else-if='isPaypalPaymentOption')
                .paypal-header
                  h4 {{ $t('checkout.payWithPaypal') }}
                #paypal-element
                //- A paypal Element will be inserted here.
                .error-message(v-if='error')
                  h5 {{ error }}
        .bottom-section(:class='{ "has-coupon-input": isShowingCouponBox }')
          .billing-address-container
            .add-address(:class='{ selected: isAddingBillingAddress }')
              SVGRenderer(
                :has-hover='false',
                :icon='isAddingBillingAddress ? checkedCheckboxSquareWhite : checkboxSquareWhite',
                :stroke-color='"var(--primary-text-color)"'
              )
              h5 {{ $t('checkout.needAnInvoice') }}
            transition(name='menu-slide')
              .address-form(v-if='isAddingBillingAddress && form.paymentOption === "SEPA"')
                .form-row(:key='index', v-for='(row, index) in formInputs')
                  .form-group(:key='input.id', v-for='input in row')
                    h6 {{ input.title }}
                    .country-box-wrapper(v-if='input.id === "country"')
                      CountrySelector(@selected-country='setSelectedCountry($event)')
                      h5.error(v-if='validationError(input.id).length') {{ validationError(input.id)[0] }}
                    template(v-else)
                      input(
                        :class='{ "has-error": validationError(input.id)[0] }',
                        :data-cy='input.id',
                        :placeholder='input.title',
                        @blur='inputEventHandler(input.id)',
                        @input='inputEventHandler(input.id)',
                        v-model='form[input.id]'
                      )
                      h5.error(v-if='validationError(input.id).length') {{ validationError(input.id)[0] }}
          .coupon-buttons
            Coupon(
              :course-feedback-emails='courseFeedbackEmails',
              :course-product-title='courseProduct.title',
              ref='couponEl',
              v-if='courseProduct',
              v-model='form.coupon'
            )
            .button-container
              .cta(v-if='product')
                h3.amount {{ currencyFormatDE(productPrice) }}
              KetchUpButton.primary(
                :disabled='disableCheckoutButton',
                @click.native='processPaymentOption',
                data-cy='submit-payment-button',
                v-if='["SEPA", "Card"].includes(form.paymentOption)'
              )
                LoadingSpinner(v-if='redirectToStripe || isSubmittingIbanPayment')
                template(v-else)
                  SVGRenderer(
                    :has-hover='false',
                    :icon='diamondBlackIcon',
                    :stroke-color='"var(--primary-foreground-color)"'
                  )
                  h5 {{ $t('checkout.buyNow') }}

      .checkout-sidebar(v-if='!inModal')
        .package-details(v-if='isPackageCheckout && courseProduct')
          h2.package-details-heading {{ $t('courses.package.details') }}
          .courses(v-if='isPackageProduct(courseProduct)')
            .course(:key='course.id', v-for='course in courseProduct.courses')
              h5.bold {{ course.title }}
              h5 {{ course.teaserDescription }}
        .my-benefits
          h2.course-details-heading(v-if='!isPackageCheckout') {{ $t('courses.courseDetails') }}
          .benefits
            .benefit(:key='benefit.title', v-for='benefit in oneTimeBenefits')
              img(:src='benefit.icon')
              h5 {{ benefit.title }}
        .course-benefits(v-if='course && course.benefits && course.benefits.length')
          h2.course-benefits-heading {{ $t('courses.courseBenefits') }}
          ul
            li(:key='index', v-for='(benefit, index) in course.benefits')
              TextRenderer.h5(:source='benefit')
        PackagePriceSection(
          :product-amount='productPrice',
          v-if='!isPackageCheckout && hasNotPurchasedAllCoursesInBundle'
        )
    FAQs(v-if='!inModal')
</template>

<script setup lang="ts">
  import { computed, onBeforeUnmount, onMounted, reactive, ref, watch, nextTick } from 'vue'
  import type { PropType } from 'vue'
  import LoadingSpinner from '@/components/common/LoadingSpinner.vue'
  import SVGRenderer from '@/components/common/SVGRenderer.vue'
  import KetchUpButton from '@/components/common/KetchUpButton.vue'
  import FAQs from '@/components/common/FAQs.vue'
  import Choice from '@/components/common/Choice.vue'
  import useCommonMixin from '@/composables/useCommonMixin'
  import useIcons from '@/composables/useIcons'
  import useSegment from '@/composables/useSegment'
  import { useVuelidate } from '@vuelidate/core'
  import { required } from '@vuelidate/validators'
  import { CourseModule } from '@/store/modules/course'
  import type {
    BillingInfo,
    Subscription,
    Coupon as ICoupon,
    CheckoutFieldsToBeValidated,
  } from '@/services/interfaces/Checkout'
  import type { Nullable } from '@/services/interfaces/Content'
  import { UserModule } from '@/store/modules/user'
  import CheckoutApi from '@/services/api/CheckoutApi'
  import * as Sentry from '@sentry/vue'
  // @ts-ignore
  import type { SeverityLevel } from '@sentry/types'
  import type { SvgRendererProps } from '@/services/interfaces/Common'
  import Coupon from './Coupon.vue'
  import useCourse from '@/composables/useCourse'
  import { loadScript } from '@paypal/paypal-js'
  import useBreakpoint from '@/composables/useBreakpoint'
  import CookieHelper from '@/helpers/CookieHelper'
  import PackagePriceSection from '@/components/course/PackagePriceSection.vue'
  import UsersApi from '@/services/api/UsersApi'
  import { useRoute, useRouter } from 'vue-router/composables'
  import eventBus from '@/main'
  import useI18n from '@/composables/useI18n'
  import type { CourseItem, CoursePackage, CourseProduct } from '@/services/interfaces/Course'
  import TextRenderer from '@/components/editor/TextRenderer.vue'
  import CountrySelector from '@/components/common/CountrySelector.vue'

  const props = defineProps({
    inModal: {
      type: Boolean,
      default: false,
    },
    user: Object as PropType<{ id: string; name: string }>,
    userRoleCategory: {
      type: String,
      default: '',
    },
    packageCheckout: {
      type: Boolean,
      default: false,
    },
  })

  const router = useRouter()
  const route = useRoute()
  const {
    checkedRadio,
    uncheckedCircle,
    diamondIcon,
    diamondBlackIcon,
    sepa,
    mastercard,
    checkboxSquareWhite,
    checkedCheckboxSquareWhite,
    certificate,
    lesson,
    paypalIcon,
    device,
  } = useIcons()
  const { trackPage, pageViewed, coursePurchased, packagePurchased } = useSegment()
  const { course, currentCourseId, courseBundle, packageId, fetchAllCourseModules } = useCourse()
  const { isMobileDevice } = useBreakpoint()
  const { translateString } = useI18n()
  const hasNotPurchasedAllCoursesInBundle = ref(false)
  const emit = defineEmits(['done', 'show-package-cta'])

  const isPackageCheckout = computed(() => route.name === 'PackageCheckout' || props.packageCheckout)

  const courseFeedbackEmails = computed(() => {
    const packageCourseFeedbackEmails: string[] = []
    courseBundle.value?.courses?.forEach((c) => packageCourseFeedbackEmails.push(c.sendFeedbackEmail))
    return isPackageCheckout.value ? [...new Set(packageCourseFeedbackEmails)] : [course.value!.sendFeedbackEmail]
  })

  let cancelUrl = window.location.origin + '/courses/{product}/checkout?status=cancel'
  let successUrl = window.location.origin + '/courses/{product}/checkout/success'

  if (isPackageCheckout.value) {
    cancelUrl = window.location.origin + '/packages/{product}/checkout?status=cancel'
    successUrl = window.location.origin + '/packages/{product}/checkout/success'
  }

  if (props.inModal) {
    const url =
      window.location.origin +
      window.location.pathname +
      '?user=' +
      btoa(props.user!.id) +
      '$userRoleCategory=' +
      btoa(props.userRoleCategory) +
      '&packageCheckout=' +
      props.packageCheckout
    cancelUrl = url + '&status=cancel'
    successUrl = url + '&status=success'
  }

  const stripe: any = ref(null)
  const paypal: any = ref(null)
  const iban: any = ref(null)
  const error = ref('')

  const { isDarkMode, locale, currencyFormatDE } = useCommonMixin()

  const formInputs = [
    [
      {
        id: 'firstName',
        title: translateString('checkout.billing.firstName'),
      },
      {
        id: 'lastName',
        title: translateString('checkout.billing.lastName'),
      },
    ],
    [
      {
        id: 'streetAddress',
        title: translateString('checkout.billing.streetAddress'),
      },
      {
        id: 'country',
        title: translateString('checkout.billing.country'),
      },
    ],
    [
      {
        id: 'postalCode',
        title: translateString('checkout.billing.postalCode'),
      },
      {
        id: 'city',
        title: translateString('checkout.billing.city'),
      },
    ],
    [
      {
        id: 'company',
        title: translateString('checkout.billing.company'),
      },
      {
        id: 'taxNumber',
        title: translateString('checkout.billing.taxNumber'),
      },
    ],
  ]

  const validationError = computed(() => {
    return (id: string) => {
      switch (id) {
        case 'firstName':
          return firstNameErrors.value
        case 'lastName':
          return lastNameErrors.value
        case 'streetAddress':
          return streetAddressErrors.value
        case 'postalCode':
          return postalCodeErrors.value
        case 'city':
          return cityErrors.value
        case 'country':
          return countryErrors.value
        default:
          return []
      }
    }
  })

  const couponEl = ref(null as any)
  const isShowingCouponBox = computed(() => couponEl.value?.isShowingCouponBox)

  const oneTimeBenefits = computed(() => {
    return [
      {
        icon: device.value,
        title: translateString('courses.accessOnAllDevices'),
      },
      {
        icon: lesson.value,
        title: translateString('courses.totalLessonsInAllModules', {
          lessons: lessonsAndModules.value.totalLessons,
          modules: lessonsAndModules.value.totalModules,
        }) as string,
      },
      {
        icon: certificate.value,
        title: translateString('courses.personalCertificate'),
      },
    ]
  })

  const userFullName = computed(() => UserModule.fullname)
  const userEmail = computed(() => UserModule.email)

  const form: { [key: string]: string } = reactive({
    firstName: UserModule.firstName,
    lastName: UserModule.lastName,
    streetAddress: '',
    country: '',
    postalCode: '',
    city: '',
    company: '',
    taxNumber: '',
    coupon: '',
    email: userEmail.value,
    paymentOption: '' as 'PayPal' | 'SEPA' | 'Card',
  })

  const paymentOptions = [
    {
      id: 'SEPA',
      icon: sepa.value,
      title: translateString('checkout.paymentOption2'),
    },
    {
      id: 'Card',
      icon: mastercard.value,
      title: translateString('checkout.paymentOption3'),
    },
    {
      id: 'PayPal',
      icon: paypalIcon.value,
      title: translateString('checkout.paymentOption1'),
    },
  ]

  const isSepaPaymentOption = computed(() => form.paymentOption === 'SEPA')
  const isPaypalPaymentOption = computed(() => form.paymentOption === 'PayPal')

  watch(isSepaPaymentOption, (value) => {
    if (value) {
      nextTick(() => {
        setupIbanElements()
      })
    } else if (iban.value) {
      resetIban()
    }
  })

  watch(isPaypalPaymentOption, (value) => {
    if (value) {
      nextTick(() => {
        setupPaypalElements()
      })
    }
  })

  const setSelectedCountry = (countryIso: string) => {
    selectedCountry.value = countryIso
    form.country = countryIso
  }

  const resetIban = () => {
    if (iban.value) {
      iban.value.unmount()
      iban.value.destroy()
    }
    iban.value = null
    ibanElementReady.value = false
  }

  const sessionId = ref('')
  const redirectToStripe = ref(false)
  const couponResponse = computed((): ICoupon | null => couponEl.value?.couponResponse)
  const handleCardPayment = async (): Promise<void> => {
    openCheckoutModal()
    redirectToStripe.value = true
    try {
      const payload: Subscription = {
        cancelUrl: cancelUrl.replace(
          '{product}',
          route.params.product || CourseModule.course?.id || CourseModule.courseBundle?.courseBundleId || '',
        ),
        successUrl: successUrl.replace(
          '{product}',
          route.params.product || CourseModule.course?.id || CourseModule.courseBundle?.courseBundleId || '',
        ),
        mode: 'payment',
        priceId: product.value?.price?.stripePriceId || '',
        promoCode: couponResponse.value?.valid ? form.coupon : '',
        billingAddressCollection: isAddingBillingAddress.value ? 'required' : 'auto',
        locale: locale.value,
      }
      if (props.user) {
        payload.auth0Id = props.user.id
      }

      if (isPackageCheckout.value) {
        payload.courseBundleId = CourseModule.courseBundle!.courseBundleId
        await packagePurchased(payload.courseBundleId, courseBundle.value!.title, productPrice.value)
      } else {
        payload.courseId = CourseModule.course!.id
        await coursePurchased(payload.courseId, courseProduct.value!.title, productPrice.value)
      }

      const data = await CheckoutApi.createSession(payload)
      sessionId.value = data.sessionId
      stripe.value.redirectToCheckout({
        sessionId: data.sessionId,
      })
    } catch (error) {
      Sentry.captureException(error)
    }
  }

  const elementStyle = computed((): { base: any; invalid: any } => {
    return {
      base: {
        color: isDarkMode.value ? '#fff' : '#000',
        fontSize: '16px',
        '::placeholder': {
          color: '#828282',
        },
        ':-webkit-autofill': {
          color: '#32325d',
        },
      },
      invalid: {
        color: '#fa755a',
        iconColor: '#fa755a',
        ':-webkit-autofill': {
          color: '#fa755a',
        },
      },
    }
  })

  const ibanElementReady = ref(false)
  const ibanElementPassedValidation = ref(false)
  const setupIbanElements = (): void => {
    if (stripe.value) {
      const elements = stripe.value.elements()

      const options = {
        style: elementStyle.value,
        supportedCountries: ['SEPA'],
        // Elements can use a placeholder as an example IBAN that reflects
        // the IBAN format of your customer's country. If you know your
        // customer's country, we recommend that you pass it to the Element as the
        // placeholderCountry.
        // placeholderCountry: 'DE',
      }

      // Create an instance of the IBAN Element
      iban.value = elements.create('iban', options)
      // Add an instance of the IBAN Element into the `iban-element` <div>
      const ibanElement = document.getElementById('iban-element')
      if (ibanElement) iban.value.mount('#iban-element')
      iban.value.on('change', (e: { complete: boolean; empty: boolean; error: { code: string; message: string } }) => {
        ibanElementPassedValidation.value = e.complete && !e.empty
        if (e.error) {
          error.value = e.error.message
        } else {
          error.value = ''
        }
      })
      iban.value.on('ready', () => {
        ibanElementReady.value = true
      })
    } else {
      eventBus.$toasted.error(translateString('checkout.errors.unableToConnectToStripe'), {
        duration: 5000,
      })
    }
  }
  const setupPaypalElements = () => {
    if (paypal.value) {
      const customId: {
        promoCode: string
        courseBundleId?: string
        slug?: string
        auth0Id: string
        paid_by: 'user' | 'company_admin'
      } = {
        promoCode: '',
        auth0Id: props.user?.id || UserModule.auth0Id,
        paid_by: 'user',
        slug: isPackageCheckout.value ? CourseModule.courseBundle?.slug : CourseModule.course?.slug,
      }
      if (props.inModal) {
        customId.paid_by = 'company_admin'
      }
      paypal.value
        ?.Buttons?.({
          createOrder: function (_data: any, actions: any) {
            customId.promoCode = couponResponse.value?.valid ? form.coupon : ''
            // This function sets up the details of the transaction, including the amount and line item details.
            return actions.order.create({
              purchase_units: [
                {
                  amount: {
                    currency_code: product.value?.price?.currency.toUpperCase() || 'EUR',
                    value: productPrice.value,
                  },
                  custom_id: JSON.stringify(customId),
                },
              ],
              items: [
                {
                  name: product.value?.name,
                  description: courseProduct.value?.description,
                  unit_amount: {
                    currency_code: product.value?.price?.currency.toUpperCase() || 'EUR',
                    value: product.value?.price.amount,
                  },
                  quantity: 1,
                },
              ],
            })
          },
          onApprove: function (_data: any, actions: any) {
            return actions.order.capture().then(async (orderData: any) => {
              Sentry.addBreadcrumb({
                category: 'paypal-order-data',
                data: orderData,
                level: 'info' as SeverityLevel,
              })
              if (props.inModal) emit('done')

              if (isPackageCheckout.value) {
                const courseIds = courseBundle.value!.courses.map((course) => course.id)
                CookieHelper.setCookieValue('purchased_course_id', JSON.stringify(courseIds))
                await packagePurchased(
                  courseBundle.value!.courseBundleId,
                  courseBundle.value!.title,
                  productPrice.value,
                )
                if (!props.inModal) {
                  if (customId.promoCode && customId.promoCode.startsWith('FRIEND-')) {
                    // this is a referralPurchase
                    sendReferralPurchaseToReferral(customId.promoCode)
                  }
                  await router.push({ name: 'PackageCheckoutSuccess' })
                }
              } else {
                CookieHelper.setCookieValue('purchased_course_id', JSON.stringify([CourseModule.course!.id]))
                await coursePurchased(CourseModule.course!.id, courseProduct.value!.title, productPrice.value)
                if (!props.inModal) {
                  if (customId.promoCode && customId.promoCode.startsWith('FRIEND-')) {
                    // this is a referralPurchase
                    sendReferralPurchaseToReferral(customId.promoCode)
                  }
                  await router.push({ name: 'OneOffCheckoutSuccess' })
                }
              }
            })
          },
          onError: function (err: any) {
            Sentry.captureException(err)
          },
        })
        .render('#paypal-element')
    }
  }

  const openCheckoutModal = () => {
    if (!props.inModal) {
      eventBus.$emit('show-modal', {
        modalContentComponent: 'CheckoutProgressModal',
        cssClass: 'checkout-progress',
        hideCloseButton: true,
        modalCloseCallback: () => {
          return
        },
      })
    }
  }

  const isSubmittingIbanPayment = ref(false)
  const submitIbanPayment = async (): Promise<void> => {
    $v.value.$touch()
    if (
      (!isAddingBillingAddress.value || !$v.value.$invalid) &&
      iban.value &&
      ibanElementPassedValidation.value &&
      ibanElementReady.value
    ) {
      openCheckoutModal()
      isSubmittingIbanPayment.value = true
      const billingInfo: BillingInfo = {
        firstName: form.firstName,
        lastName: form.lastName,
        company: form.company,
      }
      if (props.user) {
        billingInfo.auth0Id = props.user.id
      }
      try {
        if (isAddingBillingAddress.value && isBillingAddressValid.value) {
          billingInfo.address = {
            line1: billingAddress.value.streetAddress,
            city: billingAddress.value.city,
            country: billingAddress.value.country,
            postalCode: billingAddress.value.postalCode,
            state: billingAddress.value.state,
          }
        }
        await handleIbanOneOffPayment(billingInfo)
      } catch (error) {
        Sentry.captureException(error)
      }
      closeCheckoutProcessing()
    } else if (!ibanElementPassedValidation.value) {
      error.value = translateString('checkout.errors.incompleteIban')
    }
  }

  const closeCheckoutProcessing = () => {
    if (!props.inModal) eventBus.$emit('close-modal')
    isSubmittingIbanPayment.value = false
  }

  const sendReferralPurchaseToReferral = (promoCode: string) => {
    // we have to submit the browserIp and userAgent as empty values for now
    UsersApi.sendReferralPurchaseToReferralCandy({
      discountCode: promoCode,
      invoiceAmount: `${productPrice.value}`,
    })
  }

  const handleIbanOneOffPayment = async (billingInfo: BillingInfo) => {
    if (isPackageCheckout.value) {
      billingInfo.courseBundleId = CourseModule.courseBundle?.courseBundleId
    } else {
      billingInfo.courseId = CourseModule.course?.id
    }
    billingInfo.amount = product.value?.price.amount
    billingInfo.promoCode = couponResponse.value?.valid ? form.coupon : ''
    const { clientSecret } = await CheckoutApi.postOneTimeBillingInfo(billingInfo)
    const debitData = await stripe.value.confirmSepaDebitPayment(clientSecret, {
      payment_method: {
        sepa_debit: iban.value,
        billing_details: {
          name: userFullName.value,
          email: userEmail.value,
        },
      },
    })
    if (debitData.error) {
      error.value = debitData.error.message
    } else {
      if (props.inModal) emit('done')
      if (isPackageCheckout.value) {
        await packagePurchased(courseBundle.value!.courseBundleId, courseBundle.value!.title, productPrice.value)
        if (!props.inModal) {
          if (billingInfo.promoCode && billingInfo.promoCode.startsWith('FRIEND-')) {
            // this is a referralPurchase
            sendReferralPurchaseToReferral(billingInfo.promoCode)
          }
          await router.push({ name: 'PackageCheckoutSuccess' })
        }
      } else {
        if (!props.inModal) {
          if (billingInfo.promoCode && billingInfo.promoCode.startsWith('FRIEND-')) {
            // this is a referralPurchase
            sendReferralPurchaseToReferral(billingInfo.promoCode)
          }
          await router.push({ name: 'OneOffCheckoutSuccess' })
        }
      }
    }
  }

  onBeforeUnmount((): void => {
    resetIban()
  })

  const lessonsAndModules = computed(() => {
    let courses: { courseId: string; courseGroupIds: string[] }[] = [
      {
        courseId: CourseModule.course?.id || '',
        courseGroupIds: CourseModule.course?.courseGroups?.map((g) => g.id) || [],
      },
    ]
    if (isPackageCheckout.value) {
      courses =
        CourseModule.courseBundle?.courses?.map((c) => ({
          courseId: c.id,
          courseGroupIds: c.courseGroups.map((g) => g.id),
        })) || []
    }
    return {
      totalModules: courses.reduce((acc, course) => {
        acc += CourseModule.totalCourseGroupModules(course.courseId, course.courseGroupIds) || 0
        return acc
      }, 0),
      totalLessons: courses.reduce((acc, course) => {
        acc += CourseModule.totalCourseGroupLessons(course.courseId, course.courseGroupIds)
        return acc
      }, 0),
    }
  })

  const product = ref(null as Nullable<CourseProduct>)
  const setProductValue = async () => {
    if (isPackageCheckout.value) {
      if (!courseBundle.value && packageId.value) {
        await CourseModule.getPackageBundle(packageId.value)
      }
      if (courseBundle.value) {
        fetchAllCourseModules(courseBundle.value.courses?.map((course) => course.id))
        product.value = courseBundle.value.product
      }
    } else {
      if (!course.value && currentCourseId.value) {
        await CourseModule.getCourse({ courseId: currentCourseId.value })
      }
      if (course.value) {
        fetchAllCourseModules([course.value.id])
        product.value = course.value.product
      }
    }
  }

  const checkIfAllCoursesInBundleIsPurchased = async () => {
    if (course.value?.courseBundle && !isPackageCheckout.value) {
      await CourseModule.getPackageBundle(course.value.courseBundle.courseBundleId)
      if (CourseModule.courseBundle) {
        hasNotPurchasedAllCoursesInBundle.value = !!CourseModule.courseBundle.courses?.every(
          (c) => c.state === 'ready' && !c.purchased,
        )
        emit('show-package-cta', hasNotPurchasedAllCoursesInBundle.value)
      }
    }
  }

  onMounted(async (): Promise<void> => {
    if ((window as any).Stripe) {
      stripe.value = (window as any).Stripe(process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY, {
        stripeAccount: process.env.VUE_APP_STRIPE_ACCOUNT,
        locale: locale.value,
      })
      form.paymentOption = 'SEPA'
    }
    await setProductValue()
    await checkIfAllCoursesInBundleIsPurchased()
    await trackPage('Checkout Page')
    await pageViewed('Checkout Page')

    loadScript({
      clientId: process.env.VUE_APP_PAYPAL_CLIENT_ID as string,
      disableFunding: 'credit,card,paylater,bancontact,blik,eps,giropay,ideal,mercadopago,mybank,p24,sepa,sofort,venmo',
      currency: 'EUR',
      locale: locale.value === 'de' ? 'de_DE' : 'en_US',
      intent: 'capture',
    })
      .then((pp) => {
        paypal.value = pp
      })
      .catch((error: Error) => {
        Sentry.captureMessage('failed to load the PayPal JS SDK script')
        Sentry.captureException(error)
      })

    if (route.query.status === 'cancel') {
      eventBus.$toasted.error(translateString('checkout.cancel'), { duration: 5000 })
    }

    isAddingBillingAddress.value = true
  })

  const productPrice = computed(() => {
    const amount = product.value?.price.amount || 0
    if (couponResponse.value?.valid) {
      if (couponResponse.value.percentOff) return roundNumber(amount - amount * couponResponse.value.percentOff)
      return roundNumber(amount - (couponResponse.value.amountOff || 0))
    }
    return amount
  })

  const roundNumber = (num: number) => {
    return Math.round((num + Number.EPSILON) * 100) / 100
  }

  const prependIcon = computed(() => {
    return (paymentOption: string): SvgRendererProps => {
      if (paymentOption === form.paymentOption) {
        return {
          icon: checkedRadio.value,
          strokeColor: 'var(--primary-text-color)',
          fillColor: 'var(--primary-text-color)',
        }
      } else {
        return {
          icon: uncheckedCircle.value,
          strokeColor: 'var(--primary-text-color)',
        }
      }
    }
  })

  const processPaymentOption = () => {
    if (error.value) return
    switch (form.paymentOption) {
      case 'Card':
        handleCardPayment()
        return
      case 'SEPA':
        submitIbanPayment()
        return
    }
  }

  const isBillingAddressValid = computed(() => {
    return Object.keys(billingAddress.value).every((key) => form[key] !== '')
  })

  const billingAddress = computed(() => {
    const requiredFormFields = ['firstName', 'lastName', 'streetAddress', 'country', 'postalCode', 'city']
    const address: { [key: string]: string } = {}
    Object.keys(form)
      .filter((k) => requiredFormFields.includes(k))
      .forEach((key) => {
        address[key] = form[key]
      })
    return address
  })
  const isAddingBillingAddress = ref(false)
  const selectedCountry = ref('')

  const rules = {
    firstName: { required },
    lastName: { required },
    streetAddress: { required },
    postalCode: { required },
    city: { required },
    country: { required },
  }

  const inputEventHandler = (id: string) => {
    if (['taxNumber', 'company'].includes(id)) return
    const validator = ($v.value as any)[id]
    validator.$touch()
  }

  const $v: any = useVuelidate(rules, form as unknown as CheckoutFieldsToBeValidated)
  const firstNameErrors = computed(() => {
    const errors: string[] = []
    if (!$v.value.firstName.$dirty) return errors
    else if ($v.value.firstName.required.$invalid) {
      errors.push(translateString('checkout.errors.requiredField'))
    }

    return errors
  })

  const lastNameErrors = computed(() => {
    const errors: string[] = []
    if (!$v.value.lastName.$dirty) return errors
    else if ($v.value.lastName.required.$invalid) {
      errors.push(translateString('checkout.errors.requiredField'))
    }

    return errors
  })

  const streetAddressErrors = computed(() => {
    const errors: string[] = []
    if (!$v.value.streetAddress.$dirty) return errors
    else if ($v.value.streetAddress.required.$invalid) {
      errors.push(translateString('checkout.errors.requiredField'))
    }

    return errors
  })

  const postalCodeErrors = computed(() => {
    const errors: string[] = []
    if (!$v.value.postalCode.$dirty) return errors
    else if ($v.value.postalCode.required.$invalid) {
      errors.push(translateString('checkout.errors.requiredField'))
    }

    return errors
  })

  const countryErrors = computed(() => {
    const errors: string[] = []
    if (!$v.value.country.$dirty) return errors
    else if ($v.value.country.required.$invalid) {
      errors.push(translateString('checkout.errors.requiredField'))
    }

    return errors
  })

  const cityErrors = computed(() => {
    const errors: string[] = []
    if (!$v.value.city.$dirty) return errors
    else if ($v.value.city.required.$invalid) {
      errors.push(translateString('checkout.errors.requiredField'))
    }

    return errors
  })

  const courseTeaserImage = computed(() => {
    return isMobileDevice.value ? CourseModule.course?.cardTeaserImageUrl : CourseModule.course?.teaserImageUrl
  })

  const courseProduct = computed(() => {
    if (isPackageCheckout.value) return courseBundle.value as CoursePackage
    return course.value as CourseItem
  })

  const disableCheckoutButton = computed(() => {
    return (
      form.paymentOption === '' ||
      error.value !== '' ||
      (!ibanElementReady.value && form.paymentOption === 'SEPA') ||
      (!ibanElementPassedValidation.value && form.paymentOption === 'SEPA') ||
      redirectToStripe.value ||
      isSubmittingIbanPayment.value
    )
  })

  const isPackageProduct = (product: CourseItem | CoursePackage): product is CoursePackage => {
    return 'courses' in product
  }
</script>

<style lang="postcss">
  .checkout {
    .logo-area {
      @apply ketch-absolute md:ketch-top-c30 md:ketch-left-c30 xl:ketch-left-c40;
    }
    .copy {
      @apply ketch-flex ketch-flex-col ketch-items-center ketch-mt-c60 md:ketch-mt-0 ketch-mb-c30 md:ketch-mb-c100;
      .title {
        @apply ketch-font-big-daily-short ketch-text-3xl ketch-leading-lg ketch-mt-c15 ketch-mb-c30 ketch-text-center;
        &.one-time {
          @apply ketch-font-body ketch-text-xl ketch-leading-lg ketch-font-bold;
        }
      }
      .description {
        @apply sm:ketch-w-c600 ketch-text-center;
        span {
          @apply ketch-font-bold;
        }
      }
    }
    &-container {
      @apply ketch-flex ketch-flex-col md:ketch-flex-row md:ketch-items-start;
      @apply ketch-space-y-c40 md:ketch-space-y-0 md:ketch-justify-center;
      .checkout-form {
        @apply ketch-border ketch-border-border-color ketch-flex-auto;
        @apply md:ketch-mr-c30 xl:ketch-mr-c60 md:ketch-h-[fit-content] ketch-rounded-large md:ketch-max-w-[700px];
        .checkout-form-inputs {
          @apply ketch-p-c20 sm:ketch-p-c30;
        }
        > h3 {
          @apply ketch-mb-c10 ketch-font-bold;
        }
        .teaser-image {
          @apply ketch-min-w-full ketch--mt-c4 ketch-rounded-t-large;
        }
        .iban-choices {
          @apply ketch-mb-c25 ketch-space-y-c30;
          .form-row {
            @apply ketch-mb-0;
            .iban-header,
            .paypal-header {
              @apply ketch-flex ketch-items-center ketch-mb-c10 ketch-space-x-c20;
            }
            #iban-element {
              @apply ketch-bg-transparent ketch-border ketch-border-border-color ketch-rounded-normal;
              @apply ketch-h-c40 ketch-px-c15 ketch-py-c10 ketch-outline-none ketch-text-white ketch-w-full;
            }
            .mandate-acceptance {
              @apply ketch-mt-c30 ketch-text-secondary-text-color;
            }
            .error-message {
              @apply ketch-text-red-500 ketch-px-c8 ketch-py-c2 ketch-border ketch-border-red-200;
              @apply ketch-mb-c20 ketch-rounded ketch-bg-red-100 ketch-mt-c10;
            }
          }
        }
        .choices {
          @apply ketch-flex ketch-flex-col md:ketch-flex-row ketch-space-y-c10;
          @apply md:ketch-space-y-0 md:ketch-space-x-c20 ketch-mt-c30;
          .choice {
            @apply ketch-w-full;
          }
        }
        .bottom-section {
          @apply ketch-border-t ketch-border-border-color ketch-pb-c2 sm:ketch-pb-c30 ketch-px-c20 sm:ketch-px-c30;
          .billing-address-container {
            @apply ketch-py-c30;
            .add-address {
              @apply ketch-flex ketch-opacity-50 ketch-items-center ketch-cursor-pointer ketch-space-x-c10;
              &.selected {
                @apply ketch-filter-none ketch-opacity-100 ketch-pb-c25;
              }
            }
            .address-form {
              @apply ketch-space-y-c20;
              .form-group {
                @apply ketch-w-full;
                h6 {
                  @apply ketch-mb-c5 ketch-text-secondary-text-color;
                }
                input {
                  caret-color: #464646;
                }
                input,
                .country-box {
                  @apply ketch-bg-transparent ketch-border ketch-border-border-color ketch-rounded-normal;
                  @apply ketch-h-c40 ketch-px-c15 ketch-py-c10 ketch-outline-none ketch-text-primary-text-color ketch-w-full;
                }
                .country-box-wrapper {
                  @apply ketch-flex ketch-flex-col;
                }
                .vue-country-select {
                  @apply ketch-border-none focus-within:ketch-bg-transparent focus-within:ketch-shadow-none focus-within:ketch-border-none;
                  .dropdown,
                  .dropdown.open {
                    @apply hover:ketch-bg-transparent ketch-bg-transparent;
                  }
                  .dropdown-list {
                    @apply ketch-border-border-color ketch-left-[-9px] ketch-top-[34px];
                    @apply ketch-w-[calc(100vw-60px)] sm:ketch-w-[380px];
                    .dropdown-item {
                      @apply ketch-text-xs ketch-leading-sm;
                      strong {
                        @apply ketch-font-normal;
                      }
                      &.highlighted {
                        @apply ketch-text-primary-text-color;
                      }
                    }
                  }
                }
                .error {
                  @apply ketch-text-red-500 ketch-mt-c5;
                }
                input.has-error {
                  @apply ketch-border-red-500;
                }
              }
              .form-row {
                @apply ketch-flex ketch-flex-col md:ketch-flex-row md:ketch-justify-between;
                @apply ketch-space-y-c20 md:ketch-space-y-0 md:ketch-space-x-c20;
              }
            }
          }
          .coupon-buttons {
            @apply ketch-flex ketch-flex-col sm:ketch-space-y-0 ketch-space-y-c30 ketch-mb-c20;
            @apply sm:ketch-flex-row sm:ketch-justify-between sm:ketch-items-center sm:ketch-mb-0;
          }
          &.has-coupon-input {
            @apply sm:ketch-items-end;
          }
          .button-container {
            @apply ketch-flex ketch-items-center ketch-justify-end ketch-space-x-c10;
            .cta {
              @apply ketch-text-right;
              flex: 30%;
              h3,
              h5 {
                @apply ketch-font-bold;
              }
            }
            button {
              @apply ketch-outline-none ketch-space-x-c10;
              h5 {
                @apply ketch-font-bold;
              }
              &[disabled='disabled'] {
                @apply ketch-bg-opacity-50 ketch-cursor-not-allowed hover:ketch-bg-opacity-50 hover:ketch-bg-primary-color;
              }
            }
          }
        }
      }
      .checkout-sidebar {
        @apply ketch-space-y-c40 md:ketch-space-y-c50 md:ketch-max-w-[360px];
        @screen md {
          flex: 0 0 360px;
        }
        .package-details-heading,
        .course-details-heading,
        .course-benefits-heading {
          @apply ketch-font-big-daily-short ketch-mb-c30;
        }
        .package-details {
          .courses {
            @apply ketch-space-y-c20;
            .course {
              @apply ketch-flex ketch-flex-col ketch-space-y-c10 ketch-pb-c20 ketch-border-b ketch-border-border-color;
              &:last-of-type {
                @apply ketch-border-b-0;
              }
              .bold {
                @apply ketch-font-bold;
              }
            }
          }
        }
        .my-benefits {
          .benefit {
            @apply ketch-flex ketch-items-center ketch-mb-c15;
            img {
              @apply ketch-mr-c10 ketch-w-c20;
              filter: invert(1);
            }
          }
        }
        .course-benefits {
          ul {
            @apply ketch-space-y-c15 ketch-list-disc ketch-ml-c20 ketch-m-0 ketch-p-0;
          }
        }
      }
    }
    &.in-modal .checkout-container {
      .checkout-form {
        @apply ketch-mr-0 ketch-rounded-none ketch-bg-background-color ketch-border-background-color;
        .bottom-section .billing-address-container .vue-country-select .dropdown-list {
          @apply ketch-w-[330px];
          li {
            @apply ketch-overflow-x-hidden ketch-text-ellipsis ketch-whitespace-nowrap;
          }
        }
      }
    }
    .faqs {
      @apply ketch-mt-c40 sm:ketch-mt-c80;
    }
  }
  .ketch-dark .checkout {
    .logo-area {
      img {
        filter: invert(1);
      }
    }
    &-container {
      .checkout-form {
        .choices {
          &.selected {
            @apply ketch-border-white;
          }
        }
        .bottom-section {
          .billing-address-container {
            .address-form {
              .form-group {
                input {
                  caret-color: #fff;
                }
              }
            }
          }
        }
      }
      .my-benefits {
        .benefit {
          img {
            filter: none;
          }
        }
      }
    }
  }
</style>
