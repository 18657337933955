<template lang="pug">
.multiple-progress-bar
  .progress-bar-container(:key='bar', :style='{ width: `${barWidth(bar)}px` }', v-for='bar in progressBarSize')
    .progress-bar(
      :class='{ done: progressBarPercentage(bar - 1) === 100 }',
      :style='{ width: `${progressBarPercentage(bar - 1)}%` }'
    )
    h6.progress-bar-name {{ progressBarName(bar - 1) }}
</template>

<script setup lang="ts">
  import { computed } from 'vue'
  import type { PropType } from 'vue'
  import type { CourseProgress } from '@/services/interfaces/Course'

  const props = defineProps({
    progressBarOptions: {
      type: Array as PropType<CourseProgress[]>,
      required: true,
    },
    totalQuestions: {
      type: Number,
      required: true,
    },
  })

  const progressBarSize = computed(() => props.progressBarOptions.length)
  const progressBarName = computed(() => {
    return (index: number) => props.progressBarOptions[index].name
  })
  const progressBarPercentage = computed(() => {
    return (index: number) => props.progressBarOptions[index].percentage
  })

  const barWidth = (index: number) => {
    return Math.ceil((props.progressBarOptions[index - 1].totalPartQuestions / props.totalQuestions) * 300)
  }
</script>

<style lang="postcss" scoped>
  .multiple-progress-bar {
    @apply ketch-border ketch-border-primary-text-color ketch-bg-white;
    @apply ketch-flex ketch-rounded-normal ketch-w-c300 ketch-h-c10;
    .progress-bar-container {
      @apply ketch-relative;
      &:before {
        @apply ketch-absolute ketch-top-c8 ketch--left-c2 ketch-font-bold ketch-text-xxs;
        content: '|';
      }
      &:first-of-type .progress-bar {
        @apply ketch-rounded-l-normal;
      }
      .progress-bar-name {
        @apply ketch-absolute ketch--left-c4 ketch--bottom-c30 ketch-font-bold;
      }
      .progress-bar {
        @apply ketch-bg-primary-text-color ketch-rounded-r-normal ketch-h-full;
        @apply ketch-ease-in-out ketch-duration-1000 ketch-transition-width;
        &.done {
          @apply ketch-rounded-r-none;
        }
      }
      &:last-of-type .progress-bar {
        &.done {
          @apply ketch-rounded-r-normal;
        }
      }
    }
  }
</style>
