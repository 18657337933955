import { render, staticRenderFns } from "./MobileCourseSectionLoader.vue?vue&type=template&id=5eec4be8&scoped=true&lang=pug"
import script from "./MobileCourseSectionLoader.vue?vue&type=script&setup=true&lang=ts"
export * from "./MobileCourseSectionLoader.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./MobileCourseSectionLoader.vue?vue&type=style&index=0&id=5eec4be8&prod&scoped=true&lang=postcss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5eec4be8",
  null
  
)

export default component.exports