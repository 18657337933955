<script setup lang="ts">
  import PlaceholderShimmer from '@/components/common/PlaceholderShimmer.vue'
  import useBreakpoint from '@/composables/useBreakpoint'
  const { isMobileDevice, isDesktopDevice } = useBreakpoint()
</script>

<template lang="pug">
.course-modules-loader
  .course-modules-wrapper
    .header-section
      .module
        PlaceholderShimmer(:animate='true', height='30px', width='150px')
    .modules-container-wrapper
      .modules-container
        .course-module(:key='item', v-for='item in 3')
          .module-wrapper
            .order-block(v-if='isDesktopDevice')
              .order
                PlaceholderShimmer(:animate='true', height='50px')
            .info-container
              .name-block
                .name
                  PlaceholderShimmer(:animate='true', :width='isMobileDevice ? "250px" : "200px"', height='25px')
              .module-lessons-info-block
                .module-info
                  PlaceholderShimmer(:animate='true', height='100px')
                  .duration-lessons
                    .duration
                      PlaceholderShimmer(:animate='true', height='30px', width='150px')
                    .lessons
                      PlaceholderShimmer(:animate='true', height='30px', width='100px')
                .lessons-info(v-if='isDesktopDevice')
                  .lesson
                    .overall-info
                      PlaceholderShimmer(:animate='true', height='25px', width='300px')
                    .meta-info
                      PlaceholderShimmer(:animate='true', height='25px', width='50px')
</template>

<style scoped lang="postcss">
  .course-modules-loader {
    .course-modules-wrapper {
      .header-section {
        @apply md:ketch-mb-[60px];
      }
      .course-module {
        @apply ketch-border-b ketch-border-border-color ketch-py-c30;
        &:last-child {
          @apply ketch-border-b-0;
        }
        .module-wrapper {
          @apply ketch-flex ketch-space-x-c25;
          .order-block {
            @apply ketch-w-c55;
            .order {
              @apply ketch-w-c55;
            }
          }
          .info-container {
            @apply ketch-flex ketch-flex-col ketch-space-y-c20 ketch-w-full;
            .module-lessons-info-block {
              @apply ketch-flex;
              .module-info {
                @apply ketch-flex ketch-flex-col ketch-space-y-c20 ketch-w-full;
                @screen md {
                  flex: 0 0 360px;
                }
                .duration-lessons {
                  @apply ketch-flex ketch-flex-col ketch-space-y-c10;
                  @apply md:ketch-space-y-0 md:ketch-flex-row md:ketch-items-center md:ketch-justify-between;
                }
              }
              .lessons-info {
                @apply ketch-flex ketch-flex-col ketch-space-y-c5 ketch-flex-auto md:ketch-pl-[70px];
                .lesson {
                  @apply ketch-flex ketch-items-center ketch-justify-between;
                  .overall-info {
                    @apply ketch-flex ketch-space-x-c15 ketch-mr-c5;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
</style>
