<script setup lang="ts">
  import { onMounted, ref, watch } from 'vue'
  import CookieHelper from '@/helpers/CookieHelper'
  import eventBus from '@/main'
  import { UserModule } from '@/store/modules/user'
  import useCourse from '@/composables/useCourse'
  import useSegment from '@/composables/useSegment'
  import useCommonMixin from '@/composables/useCommonMixin'

  const { currentCourseId, moduleId, currentLesson } = useCourse()
  const { lessonViewed, languageTranslationToggled } = useSegment()
  const { languageTranslations } = useCommonMixin()

  const selectedLanguageCode = ref('de')
  const reRenderKey = ref('')

  const setLanguage = (languageEvent: any) => {
    if (typeof languageEvent === 'undefined') return
    if (!languageEvent || !languageEvent.code || !languageEvent.name) return

    selectedLanguageCode.value = languageEvent.code
    trackLessonViewedLanguage(languageEvent.code)

    if (languageEvent.code === 'de') {
      eventBus.$i18n.locale = 'de'
    } else {
      // we only track if it's not de
      languageTranslationToggled(languageEvent.name, languageEvent.code)
    }
  }

  const trackLessonViewedLanguage = (translationCode: string) => {
    if (
      eventBus.$route.name === 'Lesson' &&
      UserModule.currentCompany &&
      currentLesson.value &&
      moduleId &&
      currentCourseId.value
    ) {
      lessonViewed(
        UserModule.currentCompany.id,
        currentLesson.value.id,
        currentLesson.value.title,
        moduleId.value,
        currentCourseId.value,
        translationCode || 'de',
      )
    }
  }

  watch(selectedLanguageCode, (value) => {
    if (value) {
      CookieHelper.setCrossDomainCookie('language-code', value)
    }
  })

  onMounted(() => {
    reRenderKey.value = Math.random().toString(36).substring(2)
    const languageCode = CookieHelper.getCookieValue('language-code')
    if (languageCode) {
      selectedLanguageCode.value = languageCode
    }
  })
</script>

<template lang="pug">
.language-translate-toggle
  GoogleTranslateSelect(
    :default-language-code='selectedLanguageCode',
    :fetch-browser-language='false',
    :key='reRenderKey',
    :languages='languageTranslations',
    @select='setLanguage',
    default-page-language-code='de',
    trigger='click'
  )
</template>

<style lang="postcss">
  .language-translate-toggle {
    @apply ketch-flex;
    .google-translate-select {
      @apply ketch-w-full;
    }
    .google-translate-select-dropdown__activator {
      @apply ketch-px-c5 ketch-space-x-c10 ketch-rounded-small ketch-border;
    }
    .google-translate-select-dropdown__menu {
      @apply ketch-w-full ketch-left-auto ketch-right-0;
      ul {
        li {
          @apply ketch-px-c10 ketch-h-auto;
        }
      }
    }
  }
</style>
