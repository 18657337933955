<template lang="pug">
.reset-password-wrapper.auth-flow
  .reset-password-container
    h1 {{ $t('onboarding.resetPassword') }}
    h4.description {{ $t('onboarding.resetPasswordDescription') }}
    .reset-password
      .success-message(v-if='showSuccessMessage')
        SVGRenderer(:icon='checkmark', :stroke-color='"var(--primary-color)"', height='32', width='43')
        h5 {{ $t('onboarding.resetEmailSent') }}
      form.request-reset-instructions(@submit.prevent='triggerResetPassword', v-else)
        h3.email-title Email
        input.h3(placeholder='yourname@email.com', type='email', v-model='email')
        KetchUpButton.primary(:class='{ "default-academy": isKetchUpCompany }')
          LoadingSpinner(v-if='requestingResetPassword')
          template(v-else)
            h5 {{ $t('onboarding.resetPassword') }}
            SVGRenderer(:icon='arrowRight', :stroke-color='"var(--primary-foreground-color)"')
      .left-column-ctas
        router-link.ketch-underline(:to='{ name: "Login" }') {{ $t('onboarding.toLogin') }}
</template>

<script setup lang="ts">
  import { ref, watch } from 'vue'
  import KetchUpButton from '@/components/common/KetchUpButton.vue'
  import SVGRenderer from '@/components/common/SVGRenderer.vue'
  import useIcons from '@/composables/useIcons'
  import LoadingSpinner from '@/components/common/LoadingSpinner.vue'
  import useAuthentication from '@/composables/useAuthentication'
  import eventBus from '@/main'
  import useI18n from '@/composables/useI18n'
  import useCommonMixin from '@/composables/useCommonMixin'

  const { arrowRight, checkmark } = useIcons()
  const { requestingResetPassword, requestResetPassword } = useAuthentication()
  const { translateString } = useI18n()
  const { isKetchUpCompany } = useCommonMixin()
  const email = ref('')
  const showSuccessMessage = ref(false)

  const triggerResetPassword = () => {
    if (email.value) {
      requestResetPassword(email.value)
    } else {
      eventBus.$toasted.error(translateString('onboarding.pleaseEnterEmail'))
    }
  }

  watch(requestingResetPassword, (value: boolean) => {
    if (!value) {
      showSuccessMessage.value = true
    }
  })
</script>

<style lang="postcss" scoped>
  .reset-password-wrapper {
    .reset-password-container {
      @apply ketch-w-full sm:ketch-w-fit;
      .reset-password {
        @apply ketch-flex ketch-flex-col md:ketch-flex-row;
        .success-message {
          @apply ketch-flex ketch-flex-col ketch-items-center ketch-space-y-c25 ketch-pt-[27px];
          h5 {
            @apply ketch-max-w-[265px];
          }
        }
        .request-reset-instructions {
          @apply ketch-flex ketch-flex-col md:ketch-w-[250px];
          .email-title {
            @apply ketch-font-bold;
          }
          input {
            @apply ketch-border ketch-px-c15 ketch-py-c10 ketch-h-c45 ketch-outline-none ketch-mt-c5 ketch-mb-c15;
            @apply focus:ketch-border-primary-color;
          }
          button {
            @apply ketch-w-full ketch-justify-between;
            .loading {
              @apply ketch-mx-auto;
            }
            &.default-academy {
              @apply ketch-bg-black;
            }
          }
        }
      }
    }
  }
</style>
