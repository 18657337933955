<template lang="pug">
.tag-filter(v-if='!isMobileDevice')
  .filters
    h6.filter {{ $t('filter') }}:
    h6.all-courses(:class='{ active: activeTag === "" }', @click='setActiveTag("")') {{ $t('courses.allSubjectAreas') }}
    h6.tag(
      :class='{ active: activeTag === tag.id }',
      :key='tag.id',
      @click.stop='setActiveTag(tag.id)',
      v-for='tag in tags'
    ) {{ tag.title }}
  .total-courses(v-if='!isMobileDevice')
    span {{ totalCourses }}
    span {{ $t('trainings.title') }}
</template>

<script setup lang="ts">
  import { computed, ref } from 'vue'
  import useBreakpoint from '@/composables/useBreakpoint'
  import type { PropType } from 'vue'
  import type { CourseItem } from '@/services/interfaces/Course'

  const props = defineProps({
    courses: {
      required: true,
      type: Array as PropType<CourseItem[]>,
    },
    tags: {
      required: true,
      type: Array as PropType<{ id: string; title: string }[]>,
    },
  })
  const emit = defineEmits(['active-tag'])

  const { isMobileDevice } = useBreakpoint()
  const activeTag = ref('')

  const totalCourses = computed(() => {
    if (activeTag.value === '') return props.courses.length
    return props.courses.filter((course) => course.tags?.some((tag) => tag.id === activeTag.value)).length
  })

  const setActiveTag = (tag: string): void => {
    activeTag.value = tag
    emit('active-tag', tag)
  }
</script>

<style lang="postcss" scoped>
  .tag-filter {
    @apply ketch-flex ketch-flex-col md:ketch-flex-row ketch-justify-between;
    .filters {
      @apply ketch-flex ketch-flex-col md:ketch-flex-row ketch-space-x-c12 md:ketch-items-center;
      @apply md:ketch-overflow-auto md:ketch-max-w-[calc(100%-60px)] ketch-rounded-b-normal;
      @apply ketch-border ketch-border-border-color md:ketch-rounded-none md:ketch-border-none;
      .tag,
      .all-courses {
        @apply ketch-text-primary-text-color ketch-border-b ketch-border-border-color md:ketch-border-none;
        @apply md:hover:ketch-text-primary-color ketch-whitespace-nowrap;
        @apply ketch-px-c8 ketch-py-[3px] md:ketch-cursor-pointer;
        &:last-of-type {
          @apply ketch-border-b-0;
        }
        &.active {
          @apply ketch-font-bold ketch-bg-primary-color ketch-bg-opacity-[0.15] ketch-rounded-[5px];
          @apply ketch-cursor-default hover:ketch-text-primary-text-color;
        }
      }
      .filter {
        @apply ketch-text-[#999];
      }
    }
    .total-courses {
      @apply ketch-text-secondary-text-color ketch-space-x-c2;
    }
  }
</style>
