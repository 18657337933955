<script setup lang="ts">
  import PlaceholderShimmer from '@/components/common/PlaceholderShimmer.vue'
  import useBreakpoint from '@/composables/useBreakpoint'
  import LoadingSpinner from '@/components/common/LoadingSpinner.vue'
  const { isMobileDevice } = useBreakpoint()
</script>

<template lang="pug">
.course-info-loader
  LoadingSpinner.ketch-mt-c50(v-if='isMobileDevice')
  .course-details-wrapper(v-else)
    .course-info-container
      .course-info
        .course-overview-section
          .video-teaser-image-container
            .video-player
              PlaceholderShimmer(:animate='true')
          .info-sub-section
            .tags
              PlaceholderShimmer(:animate='true', height='15px', width='50%')
            .course-title
              PlaceholderShimmer(:animate='true', height='30px')
            .course-description
              PlaceholderShimmer(:animate='true', height='100px')
            .module-duration-date
              .course-module-duration
                .module
                  PlaceholderShimmer(:animate='true', height='20px', width='100px')
                .duration
                  PlaceholderShimmer(:animate='true', height='20px', width='100px')
              .date
                PlaceholderShimmer(:animate='true', height='20px', width='150px')
        .teaser-info-section
          .teaser-info-container
            .teaser-image-section
              PlaceholderShimmer(:animate='true', :height='isMobileDevice ? "160px" : "220px"')
            .teaser-content-section
              .course-progress-overview(v-if='isMobileDevice')
                PlaceholderShimmer(:animate='true', height='40px')
              .course-progress
                .course-progress-item(:key='item', v-for='item in 2')
                  .name-progress.has-name
                    .group-name
                      PlaceholderShimmer(:animate='true', height='25px', width='200px')
                    .progress-value
                      PlaceholderShimmer(:animate='true', height='25px', width='50px')
                  .progress-container
                    PlaceholderShimmer(:animate='true', height='40px')
      .host-course-details
        .host
          PlaceholderShimmer(:animate='true', height='30px', width='120px')
          .host-container
            .host-image
              PlaceholderShimmer(:animate='true')
            .host-info
              .name
                PlaceholderShimmer(:animate='true', height='30px', width='150px')
              .position
                PlaceholderShimmer(:animate='true', height='15px', width='200px')
            .description
              PlaceholderShimmer(:animate='true', height='70px')
        .course-details
          PlaceholderShimmer(:animate='true', height='30px', width='120px')
          .details
            .detail(:key='item', v-for='item in 3')
              PlaceholderShimmer(:animate='true', height='20px')
</template>

<style scoped lang="postcss">
  .course-info-loader {
    .course-module-duration {
      @apply ketch-flex ketch-flex-col xs4:ketch-flex-row ketch-space-y-c10 xs4:ketch-space-x-c20 xs4:ketch-space-y-0;
    }
  }
</style>
