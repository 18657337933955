import { render, staticRenderFns } from "./SetPrimaryEmail.vue?vue&type=template&id=4b7fe24d&scoped=true&lang=pug"
import script from "./SetPrimaryEmail.vue?vue&type=script&setup=true&lang=ts"
export * from "./SetPrimaryEmail.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./SetPrimaryEmail.vue?vue&type=style&index=0&id=4b7fe24d&prod&scoped=true&lang=postcss"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4b7fe24d",
  null
  
)

export default component.exports