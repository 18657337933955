// @ts-nocheck
import { Module, VuexModule, Mutation, Action, getModule } from 'vuex-module-decorators'
import store from '../index'
import type {
  User as UserState,
  GroupColor,
  MemberGroup,
  CompanyActivityLog,
  CustomSignupPageProperties,
  OnboardingPages,
  OnboardingFlowProperties,
  UserGamificationStats,
  UserLevel,
  CompanyOnboardingPopupDetails,
  UserMembersResponse,
  UserMember,
  UserMembersRolesResponse,
  GamificationActivity,
  UserCourseLog,
  UserExamAppointments,
  CompanyOnboardingSurveyQuestion,
} from '@/services/interfaces/Auth0'
import type { Nullable } from '@/services/interfaces/Content'
import UsersApi from '@/services/api/UsersApi'
import * as jose from 'jose'
import type { Company, UserRole } from '@/services/interfaces/Auth0'
import CookieHelper from '@/helpers/CookieHelper'
import type { UserCourseActivity } from '@/services/interfaces/Auth0'
import auth_config from '../../../auth_config.json'
import type { GlossaryItem } from '@/services/interfaces/Course'
import type { LanguageTranslation } from '@/services/interfaces/Common'
import CommonApi from '@/services/api/CommonApi'

const { staging, production } = auth_config
const tokenKey = process.env.APP_ENV === 'production' ? production.tokenKey : staging.tokenKey
const companyKey = process.env.APP_ENV === 'production' ? production.companyKey : staging.companyKey

@Module({ name: 'user', store, dynamic: true })
class User extends VuexModule implements UserState {
  public auth0Id = ''
  public id = ''
  public createdAt = ''
  public firstName = ''
  public lastName = ''
  public roles: UserRole[] = []
  public email = ''
  public emailVerified = false
  public name = ''
  public onboarded = false
  public activeSubscription = false
  public company: Company | null = null
  public companies: Company[] = []
  public emptyUser = {
    id: '',
    auth0Id: '',
    firstName: '',
    lastName: '',
    roles: ['MEMBER'],
    email: '',
    name: '',
    onboarded: false,
    activeSubscription: false,
    company: null,
  }
  public groupColors: GroupColor[] = []
  public userRoles: UserRole[] = []
  public userMembers: UserMembersResponse | null = null
  public searchedUserMembers: UserMembersRolesResponse[] | null = null
  public userGroups: MemberGroup[] | null = null
  public activityLogs: CompanyActivityLog[] = []
  public trialCourseIds: string[] = []
  public purchasedCourseIds: string[] = []
  public signupPageProperties: CustomSignupPageProperties | null = null
  public onboardingPages: OnboardingPages[] = []
  public companySubdomainDetails: Company | null = null
  public companyGamificationActivities: GamificationActivity[] = []
  public userGamificationStats: UserGamificationStats | null = null
  public userGamificationPointsEarned = 0
  public allowDarkMode = false
  public companyOnboardingPopupDetails: CompanyOnboardingPopupDetails | null = null
  public userCourseActivities: Nullable<UserCourseActivity[]> = null
  public reTriggerOnboardingPopup = false
  public glossaryItems: GlossaryItem[] = []
  public bannersTotalHeight = 0
  public userReferralCode = ''
  public loadingComponentViewContent = true
  public languagesTranslations: LanguageTranslation[] = []
  public lastUserCourseLog: UserCourseLog | null = null
  public showGetStartedPanel = true
  public windowWidth = 0
  public inviteSource = ''
  public token: any | null = null
  public hasSelectedTrial = false
  public hasSetExamDate = false
  public examAppointments: UserExamAppointments[] | null = null
  public currentIddMinutesCount = 0
  public desktopLogoUrl = ''
  public mobileLogoUrl = ''
  public headerImageUrl = ''
  public companyOnboardingSurveyQuestions: CompanyOnboardingSurveyQuestion[] | null = null

  get fullname(): string {
    return this.name || this.firstName + ' ' + this.lastName
  }

  get userIdFromToken(): string | null {
    const token = CookieHelper.getCookieValue(tokenKey) || this.token
    if (!token) return null
    const decodedToken = jose.decodeJwt(token) as { [key: string]: any }
    return decodedToken?.sub
  }

  get hasValidUser(): boolean {
    return !!this.userIdFromToken
  }

  get isLoggedInUser(): boolean {
    const token = CookieHelper.getCookieValue(tokenKey) || this.token
    return !!(token && this.auth0Id && this.email)
  }

  get initials(): string | null {
    if (!this.firstName || !this.lastName) return ''
    return this.firstName[0] + this.lastName[0]
  }

  get isCompanyAdmin(): boolean {
    return this.roles.some((role) => role.name === 'COMPANY_ADMIN' && role.companyId === this.company?.id)
  }

  get isCompanyEditor(): boolean {
    return this.roles.some((role) => role.name === 'EDITOR' && role.companyId === this.company?.id)
  }

  get isB2bCompany(): boolean {
    return this.company?.isB2b || false
  }

  get groupColorValue() {
    return (id: string) => this.groupColors.find((color) => color.id === id)
  }

  get bannersTotalHeightValue() {
    return this.bannersTotalHeight
  }

  get currentCompany() {
    return this.company || this.companySubdomainDetails
  }

  @Mutation
  private SET_USER(payload: UserState) {
    this.id = payload.id
    this.auth0Id = payload.auth0Id
    this.createdAt = payload.createdAt
    this.firstName = payload.firstName
    this.lastName = payload.lastName
    this.roles = payload.roles
    this.email = payload.email
    this.emailVerified = payload.emailVerified
    this.name = payload.name
    this.onboarded = payload.onboarded
    this.activeSubscription = payload.activeSubscription
    this.companies = payload.companies || []
    this.userReferralCode = payload.userReferralCode ?? ''
    this.inviteSource = payload.inviteSource ?? ''
    this.hasSelectedTrial = payload.hasSelectedTrial
    this.hasSetExamDate = payload.hasSetExamDate
    this.examAppointments = payload.examAppointments
    this.currentIddMinutesCount = payload.currentIddMinutesCount
  }

  @Mutation
  private SET_TOKEN(token: any) {
    this.token = token
  }

  @Mutation
  private SET_COMPANY(company: Company) {
    this.company = company
  }

  @Mutation
  private SET_SUBDOMAIN_COMPANY(company: Company) {
    this.companySubdomainDetails = company
  }

  @Mutation
  private SET_ONBOARDED(onboarded: boolean) {
    this.onboarded = onboarded
  }

  @Mutation
  private SET_GROUP_COLORS(colors: GroupColor[]) {
    this.groupColors = colors
  }

  @Mutation
  private SET_USER_ROLES(roles: { id: string; name: string }[]) {
    this.userRoles = roles
  }

  @Mutation
  private SET_USER_GROUPS(groups: MemberGroup[]) {
    this.userGroups = groups
  }

  @Mutation
  private SET_USER_MEMBERS(members: UserMembersResponse) {
    this.userMembers = members
  }

  @Mutation
  private SET_SEARCHED_USER_MEMBERS(searches: UserMembersRolesResponse[] | null) {
    this.searchedUserMembers = searches
  }

  @Mutation
  private SET_MORE_SEARCHED_USER_MEMBERS(data: { members: UserMembersResponse; roles: string[]; loadAll: boolean }) {
    if (data.members.roles && data.roles) {
      const roleIndexToAddUsersTo = this.userMembers?.roles.findIndex((r) => r.roleName === data.roles[0])
      if (this.searchedUserMembers && typeof roleIndexToAddUsersTo === 'number') {
        if (data.loadAll) {
          this.searchedUserMembers[roleIndexToAddUsersTo].currentPage = data.members.roles[0].pageItems
          this.searchedUserMembers[roleIndexToAddUsersTo].users = data.members.roles[0].users
        } else {
          this.searchedUserMembers[roleIndexToAddUsersTo].currentPage = data.members.roles[0].currentPage
          this.searchedUserMembers[roleIndexToAddUsersTo].users = this.searchedUserMembers[
            roleIndexToAddUsersTo
          ].users.concat(data.members.roles[0].users)
        }
      }
    }
  }

  @Mutation
  private SET_MORE_ROLE_USER_MEMBERS(data: { members: UserMembersResponse; roles: string[]; loadAll: boolean }) {
    if (this.userMembers?.roles && data.roles) {
      const roleIndexToAddUsersTo = this.userMembers?.roles.findIndex((r) => r.roleName === data.roles[0])
      if (typeof roleIndexToAddUsersTo === 'number') {
        if (data.loadAll) {
          // we replace currently loaded users in the role with the response
          this.userMembers.roles[roleIndexToAddUsersTo].currentPage = data.members.roles[0].pageItems
          this.userMembers.roles[roleIndexToAddUsersTo].users = data.members.roles[0].users
        } else {
          this.userMembers.roles[roleIndexToAddUsersTo].currentPage = data.members.roles[0].currentPage
          this.userMembers.roles[roleIndexToAddUsersTo].users = this.userMembers.roles[
            roleIndexToAddUsersTo
          ].users.concat(data.members.roles[0].users)
        }
      }
    }
  }

  @Mutation
  private SET_MORE_GROUP_USER_MEMBERS(data: { members: UserMembersResponse; groups: string[]; loadAll: boolean }) {
    if (this.userMembers?.groups && data.groups) {
      const groupIndexToAddUsersTo = this.userMembers.groups.findIndex((g) => g.groupId === data.groups[0])
      if (typeof groupIndexToAddUsersTo === 'number') {
        if (data.loadAll) {
          // we replace currently loaded users in the group with the response
          this.userMembers.groups[groupIndexToAddUsersTo].currentPage = data.members.groups[0].pageItems
          this.userMembers.groups[groupIndexToAddUsersTo].users = data.members.groups[0].users
        } else {
          this.userMembers.groups[groupIndexToAddUsersTo].currentPage = data.members.groups[0].currentPage
          this.userMembers.groups[groupIndexToAddUsersTo].users = this.userMembers.groups[
            groupIndexToAddUsersTo
          ].users.concat(data.members.groups[0].users)
        }
      }
    }
  }

  @Mutation
  private SET_INVITED_USERS(users: UserMember[]) {
    // we append the newly invited user to the default USER role
    if (this.userMembers?.roles) {
      const defaultUserRoleIndex = this.userMembers.roles.findIndex((r) => r.roleName === 'USER')
      if (typeof defaultUserRoleIndex === 'number' && defaultUserRoleIndex > -1) {
        this.userMembers.roles[defaultUserRoleIndex].users.unshift(...users)
      }
    }
  }

  @Mutation
  private SET_UPDATED_ROLE_USERS(data: { users: UserMember[]; roles: UserRole[] }) {
    const user = data.users[0]
    data.roles.forEach((role) => {
      const roleIndex = this.userMembers?.roles.findIndex((r) => r.roleName === role.name) ?? -1
      if (roleIndex > -1) {
        const roleData = this.userMembers!.roles[roleIndex]
        if (role._destroy) {
          // Remove the user from the role
          const userIndexInRole = roleData.users.findIndex((u) => u.id === user.id)
          if (userIndexInRole > -1) {
            roleData.users.splice(userIndexInRole, 1)
            roleData.totalUsers--
          }
        } else {
          // Add the user to the role
          const userAlreadyInRole = roleData.users.find((u) => u.id === user.id)

          if (userAlreadyInRole) {
            userAlreadyInRole.roles = user.roles
          } else {
            roleData.users.push(user)
            roleData.totalUsers++
          }
        }
      }
    })

    // update user roles in USER role category
    const userRoleIndex = this.userMembers?.roles.findIndex((r) => r.roleName === 'USER') ?? -1
    if (userRoleIndex > -1) {
      const userIndexInUserRole = this.userMembers?.roles[userRoleIndex].users.findIndex((u) => u.id === user.id) ?? -1
      if (userIndexInUserRole > -1) {
        this.userMembers!.roles[userRoleIndex].users[userIndexInUserRole].roles = user.roles
      }
    }
  }

  @Mutation
  private SET_UPDATED_GROUP_USERS(data: { users: UserMember[]; groupIds: string[] }) {
    if (!data.users.length) return

    const updatedUser = data.users[0]

    // replace the updated user in role users
    this.userMembers!.roles.forEach((role, rIndex) => {
      role.users.forEach((user) => {
        if (user.id === updatedUser.id) {
          const possibleUserToReplace = role.users.find((u) => u.id === updatedUser.id)
          const userToReplaceIndex = role.users.findIndex((u) => u.id === updatedUser.id)
          if (possibleUserToReplace && JSON.stringify(possibleUserToReplace) !== JSON.stringify(updatedUser)) {
            if (userToReplaceIndex > -1) {
              this.userMembers!.roles[rIndex].users.splice(userToReplaceIndex, 1, updatedUser)
            }
          }
        }
      })
    })

    this.userMembers!.groups.forEach((group) => {
      const userIndex = group.users.findIndex((u) => u.id === updatedUser.id)
      const userIsInGroup = userIndex > -1
      const userShouldBeInGroup = data.groupIds.includes(group.groupId)

      if (userShouldBeInGroup) {
        if (userIsInGroup) {
          if (JSON.stringify(group.users[userIndex]) !== JSON.stringify(updatedUser)) {
            group.users.splice(userIndex, 1, updatedUser)
          }
        } else {
          group.users.push(updatedUser)
          group.totalUsers++
        }
      } else if (userIsInGroup) {
        group.users.splice(userIndex, 1)
        group.totalUsers--
      }
    })
  }

  @Mutation
  private SET_COMPANY_ACTIVITY_LOGS(activityLogs: CompanyActivityLog[]) {
    this.activityLogs = activityLogs
  }

  @Mutation
  private SET_USER_COURSE_ACTIVITY(activities: UserCourseActivity[] | null) {
    this.userCourseActivities = activities
  }

  @Mutation
  private SET_ONBOARDING_FLOW_PROPERTIES(onboardingProperties: OnboardingFlowProperties | null) {
    if (onboardingProperties) {
      this.signupPageProperties = onboardingProperties.signUpPage
      this.onboardingPages = onboardingProperties.onboardingPages
    }
  }

  @Mutation
  private SET_COMPANY_GAMIFICATION_ACTIVITIES(activities: GamificationActivity[] | []) {
    this.companyGamificationActivities = activities
  }

  @Mutation
  private SET_USER_GAMIFICATION_STATS(stats: UserGamificationStats | null) {
    this.userGamificationStats = stats
  }

  @Mutation
  private SET_USER_GAMIFICATION_LEVEL(level: UserLevel) {
    if (this.userGamificationStats) this.userGamificationStats.level = level
  }

  @Mutation
  private SET_USER_GAMIFICATION_POINTS_EARNED(points: number) {
    this.userGamificationPointsEarned = points
  }

  @Mutation
  private ADD_USER_GAMIFICATION_POINTS(points: number) {
    if (this.userGamificationStats) this.userGamificationStats.totalPoints += points
  }

  @Mutation
  private SET_ALLOW_DARK_MODE(allow: boolean) {
    this.allowDarkMode = allow
  }

  @Mutation
  private SET_ONBOARDING_POPUP_DETAILS(popupDetails: CompanyOnboardingPopupDetails) {
    this.companyOnboardingPopupDetails = popupDetails
  }

  @Mutation
  private SET_RE_TRIGGER_ONBOARDING_POPUP(value: boolean) {
    this.reTriggerOnboardingPopup = value
  }

  @Mutation
  private SET_SHOW_COMPANY_ONBOARDING_POPUP(value: boolean) {
    if (this.company) {
      this.company.showCompanyPopup = value
    }
  }

  @Mutation
  private SET_SKIP_SETTING_EXAM_APPOINTMENT(value: boolean) {
    if (this.company) {
      this.company.skipSettingExamAppointment = value
    }
  }

  @Mutation
  private SET_ONBOARDING_SURVEY_COMPLETED(value: boolean) {
    if (this.company) {
      this.company.onboardingSurveyCompleted = value
    }
  }

  @Mutation
  private SET_GLOSSARY_ITEMS(items: GlossaryItem[]) {
    this.glossaryItems = items
  }

  @Mutation
  private SET_BANNERS_TOTAL_HEIGHT_VALUE(value: number) {
    this.bannersTotalHeight = value
  }

  @Mutation
  private SET_USER_REFERRAL_CODE(value: string) {
    this.userReferralCode = value
  }

  @Mutation
  private SET_LOADING_COMPONENT_VIEW_DATA(value: boolean) {
    this.loadingComponentViewContent = value
  }

  @Mutation
  private SET_LANGUAGE_TRANSLATIONS(languageTranslations: LanguageTranslation[]) {
    this.languagesTranslations = languageTranslations
  }

  @Mutation
  private SET_USER_LAST_COURSE_LOG(log: UserCourseLog | null) {
    this.lastUserCourseLog = log
  }

  @Mutation
  private SET_SHOW_GET_STARTED_PANEL(value: boolean) {
    this.showGetStartedPanel = value
  }

  @Mutation
  private SET_WINDOW_WIDTH(value: number) {
    this.windowWidth = value
  }

  @Mutation
  private SET_LOGO_URLS(urls: { desktopLogoUrl: string; mobileLogoUrl: string; headerImageUrl: string }) {
    this.desktopLogoUrl = urls.desktopLogoUrl
    this.mobileLogoUrl = urls.mobileLogoUrl
    this.headerImageUrl = urls.headerImageUrl
  }

  @Mutation
  private SET_COMPANY_ONBOARDING_SURVEY_QUESTIONS(value: CompanyOnboardingSurveyQuestion[] | null) {
    this.companyOnboardingSurveyQuestions = value
  }

  @Action({ commit: 'SET_USER' })
  async getUser() {
    const token = CookieHelper.getCookieValue(tokenKey)
    this.SET_TOKEN(token)
    if (!token) return this.emptyUser
    const decodedToken = jose.decodeJwt(token)
    if (!decodedToken?.sub) return this.emptyUser
    return UsersApi.getUser(decodedToken.sub)
      .then((user) => user)
      .catch(() => this.emptyUser)
  }

  @Action
  async logout() {
    CookieHelper.removeCrossDomainCookie(companyKey)
    CookieHelper.removeCookie('EDITOR_MODE')
    CookieHelper.removeCrossDomainCookie(tokenKey)
    CookieHelper.removeCookie('TOURS_AND_HINTS')
    localStorage.removeItem('theme')
    localStorage.removeItem('tab_courses')
    CookieHelper.removeCookie('access-jwt')
    CookieHelper.removePaypalCookies()
    CookieHelper.removeCookie('language-code')
    CookieHelper.removeCrossDomainCookie('company_subdomain_details')
  }

  @Action({ commit: 'SET_ONBOARDED' })
  async updateOnboarded(onboarded: boolean) {
    return onboarded
  }

  @Action({ commit: 'SET_GROUP_COLORS' })
  getGroupColors() {
    return UsersApi.getGroupColors()
      .then((colors) => colors)
      .catch(() => [])
  }

  @Action({ commit: 'SET_USER_MEMBERS' })
  getUserMembers(payload: { perPage?: number; page?: number; roles?: string[]; groups?: string[]; sort?: string }) {
    return UsersApi.getMembers({
      companyId: this.currentCompany!.id,
      perPage: payload.perPage,
      page: payload.page,
      roles: payload.roles,
      groups: payload.groups,
      sort: payload.sort,
    })
      .then((members) => members)
      .catch(() => [])
  }

  @Action({ commit: 'SET_SEARCHED_USER_MEMBERS' })
  getSearchedUserMembers(payload: {
    perPage?: number
    page?: number
    term?: string
    roles?: string[]
    groupIds?: string[]
    sort?: string
  }) {
    this.SET_SEARCHED_USER_MEMBERS(null)
    return UsersApi.getMembers({
      companyId: this.currentCompany!.id,
      perPage: payload.perPage,
      page: payload.page,
      term: payload.term,
      roles: payload.roles,
      sort: payload.sort,
    })
      .then((members) => members.roles)
      .catch(() => [])
  }

  @Action
  loadMoreUsers(payload: {
    perPage?: number
    page?: number
    roles?: string[]
    groups?: string[]
    term?: string
    loadAll?: boolean
    sort?: string
    userCategory: 'roles' | 'groups' | 'search'
  }) {
    return UsersApi.getMembers({
      companyId: this.currentCompany!.id,
      perPage: payload.perPage,
      page: payload.page,
      roles: payload.roles,
      groups: payload.groups,
      term: payload.term,
      loadAll: payload.loadAll,
      sort: payload.sort,
    })
      .then((members) => {
        if (payload.userCategory === 'roles')
          return this.SET_MORE_ROLE_USER_MEMBERS({
            members: members,
            roles: payload.roles ?? [],
            loadAll: payload.loadAll ?? false,
          })
        if (payload.userCategory === 'groups')
          return this.SET_MORE_GROUP_USER_MEMBERS({
            members: members,
            groups: payload.groups ?? [],
            loadAll: payload.loadAll ?? false,
          })
        if (payload.userCategory === 'search')
          return this.SET_MORE_SEARCHED_USER_MEMBERS({
            members: members,
            roles: payload.roles ?? [],
            loadAll: payload.loadAll ?? false,
          })
      })
      .catch(() => [])
  }

  @Action
  appendUserMembers(payload: {
    users: UserMember[]
    action: 'invite-user' | 'update-roles' | 'update-groups'
    roles?: UserRole[]
    groupIds?: string[]
  }) {
    if (payload.action === 'invite-user') {
      return this.SET_INVITED_USERS(payload.users)
    }
    if (payload.action === 'update-roles') {
      return this.SET_UPDATED_ROLE_USERS({ users: payload.users, roles: payload.roles ?? [] })
    }
    if (payload.action === 'update-groups') {
      return this.SET_UPDATED_GROUP_USERS({
        users: payload.users,
        groupIds: payload.groupIds ?? [],
      })
    }
  }

  @Action({ commit: 'SET_USER_ROLES' })
  getUserRoles() {
    return UsersApi.getUserRoles()
      .then((roles) => roles)
      .catch(() => [])
  }

  @Action({ commit: 'SET_USER_GROUPS' })
  getMembersGroups() {
    return UsersApi.getMembersGroups(this.currentCompany!.id)
      .then((groups) => groups)
      .catch(() => [])
  }

  @Action({ commit: 'SET_COMPANY_ACTIVITY_LOGS' })
  getCompanyActivityLog() {
    return UsersApi.getCompanyActivityLog(this.currentCompany!.id)
      .then((activityLogs) => activityLogs)
      .catch(() => [])
  }

  @Action({ commit: 'SET_USER_COURSE_ACTIVITY' })
  setUserCourseActivities(payload: { userId?: string; activities?: null | UserCourseActivity[] }) {
    if ('activities' in payload) return payload.activities
    this.SET_USER_COURSE_ACTIVITY(null)
    return UsersApi.getCompanyUserCourseActivity(this.currentCompany!.id, payload.userId!)
      .then((activities) => activities)
      .catch(() => [])
  }

  @Action({ commit: 'SET_COMPANY' })
  setCompany(payload: { company?: Company; companyId?: string }) {
    if (payload.companyId) return this.companies.find((c) => c.id === payload.companyId) as Company
    return payload.company
  }

  @Action({ commit: 'SET_SUBDOMAIN_COMPANY' })
  setSubdomainCompany(company: Company) {
    return company
  }

  @Action({ commit: 'SET_ONBOARDING_FLOW_PROPERTIES' })
  getOnboardingFlowProperties(companyId: string) {
    return UsersApi.getOnboardingFlowProperties(companyId)
      .then((onboardingProperties) => onboardingProperties)
      .catch(() => null)
  }

  @Action({ commit: 'SET_COMPANY_GAMIFICATION_ACTIVITIES' })
  getCompanyGamificationActivities() {
    return UsersApi.getAllGamificationActivities()
      .then((activities) => activities)
      .catch(() => [])
  }

  @Action({ commit: 'SET_USER_GAMIFICATION_STATS' })
  getUserGamificationStats() {
    return UsersApi.getUserGamificationStats(this.currentCompany!.id)
      .then((stats) => stats)
      .catch(() => null)
  }

  @Action({ commit: 'SET_USER_GAMIFICATION_LEVEL' })
  setUserGamificationLevel(level: UserLevel) {
    return level
  }

  @Action({ commit: 'SET_USER_GAMIFICATION_POINTS_EARNED' })
  setUserGamificationPointsEarned(points: number) {
    return points
  }

  @Action({ commit: 'ADD_USER_GAMIFICATION_POINTS' })
  addUserGamificationPoints(points: number) {
    return points
  }

  @Action({ commit: 'SET_ALLOW_DARK_MODE' })
  setAllowDarkMode(allow: boolean) {
    return allow
  }

  @Action({ commit: 'SET_ONBOARDING_POPUP_DETAILS' })
  setCompanyOnboardingPopupDetails() {
    return UsersApi.getCompanyOnboardingPopupDetails(this.currentCompany!.id)
      .then((details) => details)
      .catch(() => null)
  }

  @Action({ commit: 'SET_RE_TRIGGER_ONBOARDING_POPUP' })
  setReTriggerOnboardingPopup(value: boolean) {
    return value
  }

  @Action({ commit: 'SET_SHOW_COMPANY_ONBOARDING_POPUP' })
  setShowCompanyOnboardingPopup(value: boolean) {
    return value
  }

  @Action({ commit: 'SET_SKIP_SETTING_EXAM_APPOINTMENT' })
  setSkipSettingExamAppointment(value: boolean) {
    return value
  }

  @Action({ commit: 'SET_ONBOARDING_SURVEY_COMPLETED' })
  setOnboardingSurveyCompleted(value: boolean) {
    return value
  }

  @Action({ commit: 'SET_GLOSSARY_ITEMS' })
  setGlossaryItems(companyId: string) {
    return UsersApi.getGlossaryItems(companyId)
      .then((items) => items)
      .catch(() => [])
  }

  @Action({ commit: 'SET_BANNERS_TOTAL_HEIGHT_VALUE' })
  setBannersTotalHeight(value: number) {
    return value
  }

  @Action({ commit: 'SET_USER_REFERRAL_CODE' })
  setUserReferralCode(email: string) {
    return UsersApi.generateReferralCode(email)
      .then((resp) => resp.inviteCode)
      .catch(() => '')
  }

  @Action({ commit: 'SET_LOADING_COMPONENT_VIEW_DATA' })
  setLoadingComponentViewData(value: boolean) {
    return value
  }

  @Action({ commit: 'SET_LANGUAGE_TRANSLATIONS' })
  setLanguageTranslations() {
    return CommonApi.fetchLanguageTranslations()
      .then((resp) => resp)
      .catch(() => [])
  }

  @Action({ commit: 'SET_USER_LAST_COURSE_LOG' })
  setUserLastCourseLog() {
    return UsersApi.getLastUserCourseLog(this.auth0Id)
      .then((resp) => resp.lastUserCourseLog)
      .catch(() => [])
  }

  @Action({ commit: 'SET_SHOW_GET_STARTED_PANEL' })
  setShowGetStartedPanel(value: boolean) {
    return value
  }

  @Action({ commit: 'SET_WINDOW_WIDTH' })
  setWindowWidth(value: number) {
    return value
  }

  @Action({ commit: 'SET_LOGO_URLS' })
  setLogoUrls(urls: { desktopLogoUrl: string; mobileLogoUrl: string; headerImageUrl: string }) {
    return urls
  }

  @Action({ commit: 'SET_COMPANY_ONBOARDING_SURVEY_QUESTIONS' })
  async setCompanyOnboardingSurveyQuestions() {
    return UsersApi.getCompanyOnboardingSurveyQuestions().then((resp) => resp.questions)
  }
}

export const UserModule = getModule(User)
