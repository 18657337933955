<template lang="pug">
.account-page-wrapper
  .account-page
    h2.settings-title {{ $t('account.accountSettings') }}
    h3.info {{ $t('account.info') }}
    .account-page
      .personal-data
        h3.fName-text {{ $t('account.firstName') }}
        input.h3.fName-input-box.text-input(
          :placeholder='String($t("account.firstName"))',
          type='text',
          v-model='formUser.firstName'
        )
        h3.lName-text {{ $t('account.lastName') }}
        input.h3.lName-input-box.text-input(
          :placeholder='String($t("account.lastName"))',
          type='text',
          v-model='formUser.lastName'
        )
        h3.email-text
          span Email
          span {{ emailVerifiedStatus }}
        .email
          h3.email {{ email }}
          SVGRenderer(
            :fill-color='"var(--primary-color)"',
            :has-hover='false',
            :icon='checkboxCircleGreen',
            v-if='emailIsVerified'
          )
        .request-email-confirmation(@click='sendEmailVerification', v-if='!emailIsVerified')
          LoadingSpinner(v-if='sendingVerificationRequest')
          template(v-else)
            h4 {{ $t('account.requestEmailConfirmation') }}
        h4.reset-password(@click='triggerResetPassword', v-if='isDirectSignup')
          LoadingSpinner(v-if='requestingResetPassword')
          template(v-else) {{ $t('account.resetPassword') }}
        KetchUpButton.apply-changes.primary(:class='{ directSignup: isDirectSignup }', @click.native='applyChanges')
          LoadingSpinner(v-if='isApplyingChanges')
          template(v-else)
            SVGRenderer(
              :has-hover='false',
              :icon='checkmark',
              :stroke-color='"var(--primary-foreground-color)"',
              width='15'
            )
            h5 {{ $t('account.applyChanges') }}
    .reset-tours-section(v-if='canResetTours && triggerToursAndHints')
      h4 {{ $t('help') }}
      .reset-tour-info
        h4.reset(@click='onResetToursAndHints', data-cy='reset-tours-and-hints') {{ $t('account.resetTours') }}
        h4.reset-info {{ $t('account.resetToursInfo') }}
    .profile-page-questions-section(
      v-if='structuredCompanyProfileQuestions && structuredCompanyProfileQuestions.length'
    )
      .questions-block
        h4 {{ $t('account.personalization') }}
        .questions-wrapper
          ProfileQuestion(
            :key='question.id',
            :on-answer-choice-click='onAnswerChoiceClick',
            :phone-number='question.phoneAnswer || ""',
            :question='question',
            :question-text-answer='question.textAnswer || ""',
            :selected-answers='selectedAnswers',
            :set-answer='setAnswer',
            v-for='question in structuredCompanyProfileQuestions'
          )
      .apply-profile-page-changes
        KetchUpButton.apply-changes.primary(
          :disabled='!mandatoryQuestionsFilled',
          @click.native='applyProfilePageChanges'
        )
          LoadingSpinner(v-if='isApplyingProfilePageChanges')
          template(v-else)
            SVGRenderer(
              :has-hover='false',
              :icon='checkmark',
              :stroke-color='"var(--primary-foreground-color)"',
              width='15'
            )
            h5 {{ $t('account.applyChanges') }}
    .billing-history-section(v-if='!isB2bUser')
      h4 {{ $t('billing.billingHistory') }}
      .view-billing-cta
        router-link.h4.go-to-billing(to='/billing') {{ $t('billing.viewHere') }}
    .delete-account-section
      h4 {{ $t('account.accountDeletion') }}
      .delete-account-info
        h4.delete-account(@click='deleteAccount', data-cy='delete-account') {{ $t('account.deleteAccount') }}
        h4.warning #[span ⚠️] {{ $t('account.deleteAccountWarning') }}
</template>

<script setup lang="ts">
  import { computed, onMounted, reactive, ref, watch } from 'vue'
  import KetchUpButton from '@/components/common/KetchUpButton.vue'
  import LoadingSpinner from '@/components/common/LoadingSpinner.vue'
  import SVGRenderer from '@/components/common/SVGRenderer.vue'
  import CookieHelper from '@/helpers/CookieHelper'
  import { SEEN_PAGE_TOUR, CLOSED_PAGE_HINT } from '@/helpers/Constants'
  import UsersApi from '@/services/api/UsersApi'
  import { UserModule } from '@/store/modules/user'
  import useIcons from '@/composables/useIcons'
  import useSegment from '@/composables/useSegment'
  import useCommonMixin from '@/composables/useCommonMixin'
  import useAuthentication from '@/composables/useAuthentication'
  import useToursAndHints from '@/composables/useToursAndHints'
  import { useRouter } from 'vue-router/composables'
  import eventBus from '@/main'
  import useI18n from '@/composables/useI18n'
  import type { RouteRecordPublic } from 'vue-router'
  import type { CompanyProfileQuestion, StructuredCompanyProfileQuestion } from '@/services/interfaces/Auth0'
  import type { QuestionAnswer } from '@/services/interfaces/QuizQuestion'
  import ProfileQuestion from '@/components/ProfileQuestion.vue'

  const router = useRouter()
  const { checkboxCircleGreen, checkmark } = useIcons()
  const { trackPage, pageViewed, userInfoUpdated, resetToursAndHints, accountDeleted, emailVerificationRequested } =
    useSegment()
  const {
    onAnswerChoiceClick,
    setIsLoadingComponentViewData,
    setPhoneOrTextAnswer,
    profileQuestionIsUnfilled,
    triggerToursAndHints,
  } = useCommonMixin()
  const { showTourAfterFirstMarch } = useToursAndHints()
  const { requestResetPassword, requestingResetPassword } = useAuthentication()
  const { translateString } = useI18n()
  const formUser: { firstName: string; lastName: string } = reactive({
    firstName: '',
    lastName: '',
  })
  const email = ref('')
  const isApplyingChanges = ref(false)
  const isApplyingProfilePageChanges = ref(false)
  const sendingVerificationRequest = ref(false)
  const companyProfileQuestions = ref([] as CompanyProfileQuestion[])
  const selectedAnswers = ref([] as QuestionAnswer[])
  const mandatoryQuestionIds = ref([] as string[])
  const canResetTours = ref(false)

  const auth0Id = computed((): string | null => {
    return UserModule.auth0Id
  })

  const emailIsVerified = computed((): boolean => {
    return UserModule.emailVerified
  })

  const allRoutes = computed((): RouteRecordPublic[] => {
    return router.getRoutes()
  })

  interface ExtendedProfileQuestion extends StructuredCompanyProfileQuestion {
    phoneAnswer?: string
    textAnswer?: string
  }

  const structuredCompanyProfileQuestions = computed(() => {
    return companyProfileQuestions.value?.map(
      (companyProfileQuestion): ExtendedProfileQuestion => ({
        position: companyProfileQuestion.position,
        mandatory: companyProfileQuestion.mandatory,
        ...companyProfileQuestion.profileQuestion,
      }),
    )
  })

  const isB2bUser = computed((): boolean => !!UserModule.currentCompany?.isB2b)

  const isDirectSignup = computed(() => {
    return !!auth0Id.value?.startsWith('auth0')
  })

  const emailVerifiedStatus = computed(() =>
    emailIsVerified.value
      ? `(${translateString('account.emailVerified')})`
      : `(${translateString('account.emailNotVerified')})`,
  )

  const mandatoryQuestionsFilled = computed(() => {
    return mandatoryQuestionIds.value.every((qId) => {
      const profileQuestion = structuredCompanyProfileQuestions.value.find((_q) => _q.id === qId)
      return !profileQuestionIsUnfilled.value(selectedAnswers.value, profileQuestion!)
    })
  })

  const prefillPersonalData = () => {
    formUser.firstName = UserModule.firstName
    formUser.lastName = UserModule.lastName
    email.value = UserModule.email
  }

  const fetchProfileQuestions = () => {
    UsersApi.getUserProfileQuestions()
      .then((questions) => {
        companyProfileQuestions.value = questions
      })
      .finally(() => {
        setSelectedAnswers(structuredCompanyProfileQuestions.value)
        setMandatoryQuestions()
      })
  }

  const setSelectedAnswers = (questions: StructuredCompanyProfileQuestion[]) => {
    questions.forEach((question) => {
      if (question.type === 'text') {
        setAnswer(question.id, question.textAnswer ?? '', 'text')
      } else if (question.type === 'phone_input') {
        setAnswer(question.id, question.phoneAnswer ?? '', 'phone')
      } else {
        question.answers.forEach((answer) => {
          if (answer.selected) {
            onAnswerChoiceClick(
              selectedAnswers.value,
              answer.id,
              question.id,
              question.type === 'dropdown' ? question.type : undefined,
            )
          }
        })
      }
    })
  }

  const setMandatoryQuestions = () => {
    mandatoryQuestionIds.value =
      structuredCompanyProfileQuestions.value?.filter((q) => q.mandatory).map((_q) => _q.id) ?? []
  }

  const setAnswer = (questionId: string, text: string, answerType: 'phone' | 'text') => {
    setPhoneOrTextAnswer(selectedAnswers.value, questionId, text, answerType)
  }

  const triggerResetPassword = () => {
    if (!emailIsVerified.value) {
      eventBus.$toasted.info(translateString('account.verifyYourEmail'))
    } else {
      requestResetPassword(email.value)
    }
  }

  const applyChanges = () => {
    if (formUser.firstName !== UserModule.firstName || formUser.lastName !== UserModule.lastName) {
      isApplyingChanges.value = true
      UsersApi.updatePersonalData({
        auth0Id: UserModule.auth0Id,
        firstName: formUser.firstName,
        lastName: formUser.lastName,
      }).finally(() => {
        userInfoUpdated!()
        isApplyingChanges.value = false
        eventBus.$toasted.success(translateString('account.changesApplied'))
      })
    }
  }

  const applyProfilePageChanges = async () => {
    isApplyingProfilePageChanges.value = true
    UsersApi.updateUserProfileChoices(selectedAnswers.value)
      .then((questions) => {
        companyProfileQuestions.value = questions
        eventBus.$toasted.success(translateString('account.updatedQuestions'), { duration: 5000 })
      })
      .finally(() => {
        isApplyingProfilePageChanges.value = false
      })
  }

  const sendEmailVerification = () => {
    sendingVerificationRequest.value = true
    UsersApi.sendEmailVerification(UserModule.auth0Id)
      .then((status) => {
        if (status === 201) {
          emailVerificationRequested(UserModule.email)
          eventBus.$toasted.success(translateString('account.emailSent'), { duration: 5000 })
        }
      })
      .finally(() => {
        sendingVerificationRequest.value = false
      })
  }

  const onResetToursAndHints = () => {
    allRoutes.value.forEach((route) => {
      const pageTour = SEEN_PAGE_TOUR.replace('{route_name}', route.name?.toUpperCase() as string)
      const pageHint = CLOSED_PAGE_HINT.replace('{route_name}', route.name?.toUpperCase() as string)
      CookieHelper.removeCookie(`${pageTour}`)
      CookieHelper.removeCookie(`${pageHint}`)
    })

    eventBus.$emit('set-tours-and-hints')
    resetToursAndHints()
    eventBus.$toasted.success(translateString('account.resetToursConfirmation'), { duration: 5000 })
  }

  const deleteAccount = () => {
    eventBus.$emit('show-modal', {
      modalContentComponent: 'ConfirmationModal',
      modalTitle: translateString('account.accountDeletion'),
      modalProps: {
        confirmationText: translateString('account.confirmationModalText'),
        okCallback: closeConfirmationAndDeleteAccount,
        cancelButtonText: translateString('cancel'),
      },
      cssClass: 'confirmation',
      modalCloseCallback: (callback: () => void) => {
        if (typeof callback === 'function') callback()
      },
    })
  }

  const closeConfirmationAndDeleteAccount = () => {
    UsersApi.archiveUser().finally(() => {
      accountDeleted()
      eventBus.$emit('close-modal')
      router.push('/logout')
    })
  }

  watch(auth0Id, () => {
    prefillPersonalData()
  })

  prefillPersonalData()
  fetchProfileQuestions()

  onMounted(() => {
    trackPage('Account')
    pageViewed('Account')
    showTourAfterFirstMarch(() => (canResetTours.value = true))
    setIsLoadingComponentViewData(false)
  })
</script>

<style lang="postcss">
  .account-page-wrapper {
    @apply ketch-flex ketch-flex-col;
    .account-page {
      @apply ketch-max-w-[835px];
      .loading {
        @apply ketch-mx-auto;
      }
      .settings-title {
        @apply ketch-font-bold;
      }
      .info {
        @apply ketch-pt-c20;
      }
      .apply-changes {
        @apply ketch-flex ketch-items-center ketch-rounded-large ketch-px-c10 ketch-space-x-c10;
        h5 {
          @apply ketch-font-bold;
        }
      }
      .account-page {
        .personal-data {
          @apply ketch-pt-c30 ketch-pb-c40 ketch-grid ketch-grid-rows-4 ketch-gap-c20;
          grid-template-columns: repeat(3, 1fr);
          @screen sm {
            grid-template-columns: 2fr 2.5fr 3.5fr;
          }
          .fName-text,
          .lName-text,
          .email-text {
            @apply ketch-col-start-1 ketch-flex ketch-items-center;
          }
          .email-text {
            @apply ketch-flex ketch-flex-col ketch-items-start;
            @apply md:ketch-flex-row md:ketch-space-x-c5 md:ketch-items-center;
            span {
              &:last-of-type {
                @apply ketch-text-xs ketch-leading-sm ketch-font-bold ketch-italic;
              }
            }
          }
          input {
            @apply ketch-rounded-normal ketch-bg-transparent;
            @apply ketch-col-start-2 ketch-col-end-4;
            @apply sm:ketch-col-start-2 sm:ketch-col-end-3;
          }
          .reset-password {
            @apply ketch-col-start-1 ketch-underline ketch-text-secondary-text-color ketch-cursor-pointer;
            @apply ketch-flex ketch-items-center;
            @apply sm:ketch-col-start-2;
          }
          .email {
            @apply ketch-flex ketch-items-center ketch-col-start-2 ketch-col-end-4;
            @apply sm:ketch-col-start-2 sm:ketch-col-end-3;
            svg {
              @apply ketch-ml-c10;
            }
          }
          .request-email-confirmation {
            @apply ketch-flex ketch-items-center ketch-text-primary-color ketch-underline ketch-cursor-pointer;
            @apply ketch-col-start-2 ketch-col-end-4;
            @apply sm:ketch-col-start-3;
            .loading {
              @apply ketch-mx-auto;
            }
          }
          .apply-changes {
            @apply ketch-w-full ketch-col-start-2 ketch-col-end-4;
            @apply sm:ketch-col-start-2 sm:ketch-col-end-3;
            &.directSignup {
              @apply ketch-place-self-center ketch-col-end-4;
              @apply sm:ketch-col-start-3 sm:ketch-col-end-4;
            }
            &:not(.directSignup) {
              @apply sm:ketch-col-start-2;
            }
          }
        }
      }
      .profile-page-questions-section {
        @apply ketch-pt-c20 ketch-pb-c20;
        @apply sm:ketch-pt-c40 sm:ketch-pb-c40;
        .questions-block {
          @apply sm:ketch-grid ketch-grid-cols-3 ketch-gap-c20;
          grid-template-columns: repeat(3, 1fr);
          @screen sm {
            grid-template-columns: 2fr 4fr 2fr;
          }
          h4 {
            @apply ketch-mb-c20 sm:ketch-mb-0;
          }
          .questions-wrapper {
            @apply ketch-space-y-c20 ketch-col-start-2 ketch-col-end-3;
          }
        }
        .apply-profile-page-changes {
          @apply ketch-flex ketch-justify-end ketch-mt-c20;
          .apply-changes {
            @apply ketch-w-auto;
          }
        }
      }
      .personal-data,
      .profile-page-questions-section,
      .billing-history-section,
      .reset-tours-section {
        @apply ketch-border-b ketch-border-border-color;
      }
      .reset-tours-section,
      .billing-history-section,
      .delete-account-section {
        @apply ketch-grid ketch-grid-cols-3 ketch-gap-c20 ketch-pt-c20 ketch-pb-c20;
        @apply sm:ketch-pt-c40 sm:ketch-pb-c40;
        grid-template-columns: repeat(3, 1fr);
        @screen sm {
          grid-template-columns: 2fr 4fr 2fr;
        }
        .delete-account-info,
        .view-billing-cta,
        .reset-tour-info {
          @apply ketch-col-start-2 ketch-col-end-4 ketch-flex ketch-flex-col;
          .delete-account,
          .go-to-billing,
          .reset {
            @apply ketch-underline ketch-cursor-pointer;
          }
          .warning,
          .reset-info {
            @apply ketch-pt-c8 ketch-text-secondary-text-color;
            span {
              @apply ketch-pr-c5;
            }
          }
        }
      }
    }
  }
</style>
