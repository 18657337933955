<template lang="pug">
.user-preview-details(data-cy='user-preview')
  .preview-details
    .course-activity
      .detail-header
        span {{ $t('homepage.latestCourseActivity') }}
      .detail-wrapper(:style='{ height: rolesAndGroupsHeight }')
        LoadingSpinner(v-if='courseActivities === null')
        .course-activities(v-else-if='courseActivities && courseActivities.length')
          .activity(:key='index', v-for='(activity, index) in courseActivities')
            .completion-percentage
              h4 {{ activity.completionPercentage }}%
            .course-overview-details
              h5 {{ activity.courseName }}
              LastActivity(:last-activity='activity.lastActivity')
        .no-course-activity(v-else)
          h6 {{ $t('homepage.noCourseActivityYet') }}
    .roles-and-groups(ref='rolesAndGroups')
      .detail-header
        span {{ $t('homepage.roles') }}
      .detail-wrapper
        .loading-user-roles-change(v-if='changingUserRoles')
          LoadingSpinner
        .user-roles
          template(v-for='role in userRoles')
            Choice.without-border(
              :disable-choice='disableChoice(role.name)',
              :key='role.id',
              :selected='userHasRole(role.id)',
              :svg-renderer-props='roleChoiceIcon(role.id)',
              :title='displayUserRole(role.name)',
              @click.native='onRoleSelection(role)',
              v-if='role.name !== "ADMIN"'
            )
      .detail-header
        span {{ $t('homepage.groups') }}
      .detail-wrapper
        .loading-user-groups-change(v-if='changingUserGroups')
          LoadingSpinner
        .user-groups-wrapper
          .user-groups(v-if='userGroups && userGroups.length')
            template(v-for='group in userGroups')
              Choice.without-border(
                :key='group.id',
                :selected='userBelongsToGroup(group.id)',
                :svg-renderer-props='groupChoiceIcon(group.id)',
                :title='group.name',
                @click.native='onGroupSelection(group.id)'
              )
          .user-groups-empty-state(v-else)
            h6 {{ $t('homepage.noGroupsYet') }}
            h6.create-group-cta(@click='$emit("create-new-group")') {{ $t('homepage.youCanCreateYourGroupHere') }}
      .detail-header(v-if='examAppointments')
        span {{ $t('moreInformation') }}
      .detail-wrapper(v-if='examAppointments')
        .appointments
          .appointment(:key='appointment.id', v-for='appointment in examAppointments')
            h6.appointment-course {{ appointmentCourseName(appointment.courseId) }}:
            h6.date {{ appointment.date }}
      .detail-header
        span {{ $t('homepage.timeSpentOnThePlatform') }}
      .detail-wrapper
        BarChart(:chart-data='chartData')

  .user-action-cta
    transition(name='bottom-panel')
      CancelInviteNotification(
        :cancellation-message='deleteCompanyUserMessage',
        :cancelling-user-invite='cancellingUserInvite',
        @cancel='intentToCancelInvite = false',
        @proceed='cancelUserInvite',
        v-if='intentToCancelInvite'
      )
      .assign-course-invite-cta(v-else)
        .assign-courses-container
          KetchUpButton.primary(
            :disabled='purchasedCourses === ""',
            @click.native='openAssignCoursesModal({ id: user.id, name: user.name ?? "", email: user.email })'
          )
            h5 {{ $t('homepage.assignCourses') }}
            SVGRenderer(
              :has-hover='false',
              :icon='arrowRight',
              :stroke-color='"var(--primary-foreground-color)"',
              width='6'
            )
          .courses
            h5 {{ $t('homepage.current') }}:
            SVGRenderer(
              :has-hover='false',
              :icon='eclipseLoaderIcon',
              fill-color='var(--primary-color)',
              v-if='purchasedCourses === ""',
              width='20'
            )
            h5(data-cy='total-courses', v-else) {{ $t('homepage.numberOfCourses', { number: purchasedCourses }) }}
        .resend-cancel-invite-cta(v-if='!user.inviteAccepted')
          KetchUpButton.tertiary(@click.native='resendUserInvite')
            LoadingSpinner(v-if='resendingUserInvite')
            h6(v-else) {{ $t('homepage.inviteUser.resendInvite') }}
          h6.cancel(@click='intentToCancelInvite = true') {{ $t('homepage.inviteUser.cancelInvite') }}
        KetchUpButton.tertiary.delete-company-user(@click.native='triggerDeleteCompanyUser', v-else)
          h5 {{ $t('homepage.deleteUser') }}
</template>

<script setup lang="ts">
  import type { PropType } from 'vue'
  import { computed, ref, watch } from 'vue'
  import LoadingSpinner from '@/components/common/LoadingSpinner.vue'
  import SVGRenderer from '@/components/common/SVGRenderer.vue'
  import KetchUpButton from '@/components/common/KetchUpButton.vue'
  import Choice from '@/components/common/Choice.vue'
  import LastActivity from '@/components/common/LastActivity.vue'
  import useIcons from '@/composables/useIcons'
  import useCommonMixin from '@/composables/useCommonMixin'
  import { UserModule } from '@/store/modules/user'
  import { CourseModule } from '@/store/modules/course'
  import CancelInviteNotification from '@/components/user-management/CancelInviteNotification.vue'
  import UsersApi from '@/services/api/UsersApi'
  import useSegment from '@/composables/useSegment'
  import eventBus from '@/main'
  import useI18n from '@/composables/useI18n'
  import type { UserMember, UserRole } from '@/services/interfaces/Auth0'
  import BarChart from '@/components/common/BarChart.vue'
  import { DateTime } from 'luxon'
  import useCourse from '@/composables/useCourse'

  const props = defineProps({
    user: {
      type: Object as PropType<UserMember>,
      required: true,
    },
  })

  const emit = defineEmits(['delete-user', 'create-new-group', 'reload-user-members'])

  const { checkboxSquareWhite, checkedCheckboxSquareWhite, arrowRight, eclipseLoaderIcon } = useIcons()
  const { displayUserRole, openAssignCoursesModal, isDevCompany, getColorVariableValue } = useCommonMixin()
  const { companyUsersGroupsAssigned } = useSegment()
  const { translateString } = useI18n()
  const { courseSectionsAssignedCourses } = useCourse()

  const selectedRoles = ref([] as { roleId: string; _destroy?: boolean }[])
  const selectedGroupIds = ref([] as string[])
  const intentToCancelInvite = ref(false)
  const changingUserRoles = ref(false)
  const changingUserGroups = ref(false)
  const resendingUserInvite = ref(false)
  const cancellingUserInvite = ref(false)
  const deleteCompanyUserMessage = ref('')
  const rolesAndGroups = ref(null as HTMLDivElement | null)

  const userRoles = computed(() => UserModule.userRoles)

  const userGroups = computed(() => UserModule.userGroups?.filter((g) => g.name !== 'All Users') || [])

  const userHasRole = computed(() => {
    return (roleId?: string) => {
      return selectedRoles.value.some((r) => r.roleId === roleId)
    }
  })

  const userBelongsToGroup = computed(() => {
    return (groupId: string) => {
      return selectedGroupIds.value.includes(groupId)
    }
  })

  const disableChoice = computed(() => {
    return (role: string) => {
      return UserModule.email === props.user.email && role === 'COMPANY_ADMIN'
    }
  })

  const roleChoiceIcon = computed(() => {
    return (roleId?: string) => {
      const svgProps: any = {
        strokeColor: 'var(--primary-text-color)',
        icon: checkboxSquareWhite.value,
      }
      if (selectedRoles.value.some((r) => r.roleId === roleId && !r._destroy)) {
        svgProps.icon = checkedCheckboxSquareWhite.value
      }
      return svgProps
    }
  })

  const groupChoiceIcon = computed(() => {
    return (groupId: string) => {
      const svgProps: any = {
        strokeColor: 'var(--primary-text-color)',
        icon: checkboxSquareWhite.value,
      }
      if (userBelongsToGroup.value(groupId)) {
        svgProps.icon = checkedCheckboxSquareWhite.value
      }
      return svgProps
    }
  })

  const purchasedCourses = computed(() => {
    if (CourseModule.memberCourses === null) return ''
    return CourseModule.purchasedCourses.length
  })

  const userId = computed(() => props.user.id)
  CourseModule.getMemberCourses({
    auth0Id: userId.value,
    reset: true,
    filterDevCourseBundles: isDevCompany.value,
  })

  const courseActivities = computed(() => UserModule.userCourseActivities)

  const rolesAndGroupsHeight = computed(() => `${rolesAndGroups.value?.getBoundingClientRect().height ?? 0}px`)

  const examAppointments = computed(() => props.user.examAppointments)

  const appointmentCourseName = computed(() => (courseId: string) => {
    const course = courseSectionsAssignedCourses.value?.find((course) => course.id === courseId)
    return course ? (course.shortTitle ? course.shortTitle : course.title) : ''
  })

  const userSessionsChartLabels = computed(() => {
    const allDates = props.user?.sessions.map((session) => session.date)
    const uniqueDates = [...new Set(allDates)].slice(0, 7)
    const currentDate = DateTime.local().startOf('day')

    // Create an array of the past 7 dates
    const past7Dates = []
    for (let i = 0; i < 7; i++) {
      const date = currentDate.minus({ days: i }).toISODate()
      past7Dates.push(date)
    }

    // Prefill the missing dates
    const filledDates = past7Dates.filter((date) => !uniqueDates.includes(date!)).concat(uniqueDates)
    // Order the result in ascending order
    return filledDates.sort((a, b) => {
      if (a && b) {
        const aDate = DateTime.fromISO(a)
        const bDate = DateTime.fromISO(b)

        return aDate.toMillis() - bDate.toMillis()
      }

      return 0
    })
  })

  const userSessionsChartData = computed(() => {
    const combinedData: { [key: string]: number } = {}

    // Combine data from the same date
    props.user.sessions.forEach((session) => {
      const { date, startTime, endTime } = session

      if (!combinedData[`${date}`]) {
        combinedData[`${date}`] = 0
      }

      const sessionStart = DateTime.fromISO(startTime)
      const sessionEnd = DateTime.fromISO(endTime)
      const sessionDuration = sessionEnd.diff(sessionStart, 'minutes').minutes

      combinedData[`${date}`] += sessionDuration
    })

    const orderedData = userSessionsChartLabels.value.map((date) => (date ? combinedData[date] : 0))

    return orderedData.slice(0, 7)
  })

  const chartData = computed(() => {
    return {
      labels: userSessionsChartLabels.value,
      datasets: [
        {
          label: translateString('homepage.timeSpentMinutes'),
          backgroundColor: [getColorVariableValue.value('--primary-color')],
          data: userSessionsChartData.value,
        },
      ],
    }
  })

  const updateUserRole = () => {
    changingUserRoles.value = true
    const roles = selectedRoles.value.map((r) => {
      const role: UserRole = {
        roleId: r.roleId,
        companyId: UserModule.currentCompany!.id,
        name: UserModule.userRoles.find((role) => role.id === r.roleId)?.name ?? '',
      }
      const existingRole = '_destroy' in r
      if (existingRole) {
        role.id = props.user.roles.find((role) => role.roleId === r.roleId)?.id
        role._destroy = r._destroy
      }
      return role
    })
    UsersApi.updateUserRole(UserModule.currentCompany!.id, props.user.id, roles)
      .then((user) => {
        UserModule.appendUserMembers({ users: [user], action: 'update-roles', roles: roles })
        eventBus.$toasted.success(translateString('homepage.roleUpdateMessage'))
        emit('reload-user-members')
      })
      .finally(() => (changingUserRoles.value = false))
  }

  const assignGroupToUser = () => {
    UsersApi.assignUsersToGroup(UserModule.currentCompany!.id, [props.user.email], selectedGroupIds.value).then(
      (resp) => {
        changingUserGroups.value = false
        companyUsersGroupsAssigned(UserModule.currentCompany!.id, [props.user.email], selectedGroupIds.value)
        eventBus.$toasted.success(translateString('homepage.assignedGroupSuccessfully'))

        const currentlyAssignedGroupIds = resp.users[0].groups.map((group) => group.id)
        UserModule.appendUserMembers({
          users: resp.users,
          action: 'update-groups',
          groupIds: currentlyAssignedGroupIds,
        })

        CourseModule.getCourseSections(UserModule.currentCompany!.id)
        UserModule.getCompanyActivityLog()
        emit('reload-user-members')
      },
    )
  }

  const unAssignUserFromGroup = (groupId: string) => {
    UsersApi.unAssignUsersToGroup(UserModule.currentCompany!.id, {
      emails: [props.user.email],
      groupIds: [groupId],
    }).then((resp) => {
      changingUserGroups.value = false
      eventBus.$toasted.success(translateString('homepage.unAssignedGroupSuccessfully'))

      const currentlyAssignedGroupIds = resp.users[0].groups.map((group) => group.id)
      UserModule.appendUserMembers({
        users: resp.users,
        action: 'update-groups',
        groupIds: currentlyAssignedGroupIds,
      })

      CourseModule.getCourseSections(UserModule.currentCompany!.id)
      UserModule.getCompanyActivityLog()
      emit('reload-user-members')
    })
  }

  const onRoleSelection = (role: UserRole) => {
    if (disableChoice.value(role.name)) return
    if (role.id) {
      if (userHasRole.value(role.id)) {
        const index = selectedRoles.value.findIndex((r) => r.roleId === role.id)
        if ('_destroy' in selectedRoles.value[index]) {
          selectedRoles.value[index]._destroy = !selectedRoles.value[index]._destroy
        } else {
          selectedRoles.value.splice(index, 1)
        }
      } else {
        selectedRoles.value.push({ roleId: role.id })
      }
    }
    updateUserRole()
  }

  const onGroupSelection = (groupId: string) => {
    // assign or un-assign user to group
    changingUserGroups.value = true
    if (userBelongsToGroup.value(groupId)) {
      const index = selectedGroupIds.value.indexOf(groupId)
      selectedGroupIds.value.splice(index, 1)
      unAssignUserFromGroup(groupId)
    } else {
      selectedGroupIds.value.push(groupId)
      assignGroupToUser()
    }
  }

  const resendUserInvite = () => {
    resendingUserInvite.value = true
    UsersApi.inviteUser({
      companyId: UserModule.currentCompany!.id,
      emails: [props.user.email],
      reInviteUsers: true,
    })
      .then(() => {
        eventBus.$toasted.success(translateString('homepage.inviteUser.inviteResentSuccessfully'))
      })
      .finally(() => (resendingUserInvite.value = false))
  }

  const cancelUserInvite = () => {
    const rolesUserHas = selectedRoles.value.map((role) => role.roleId)
    cancellingUserInvite.value = true
    UsersApi.deleteCompanyUsers(UserModule.currentCompany!.id, { emails: [props.user.email] })
      .then(() => {
        emit('delete-user', { userId: props.user.id, rolesUserHas: rolesUserHas })
        eventBus.$toasted.success(translateString('homepage.inviteUser.userRemovedSuccessfully'))
      })
      .finally(() => (cancellingUserInvite.value = false))
  }

  const triggerDeleteCompanyUser = () => {
    intentToCancelInvite.value = true
    deleteCompanyUserMessage.value = translateString('homepage.deleteUserMessage')
  }

  const setSelectedRoles = () => {
    selectedRoles.value = []
    props.user.roles.forEach((role) => {
      if (role.name !== 'ADMIN') {
        selectedRoles.value.push({
          roleId: role.roleId,
          _destroy: false,
        })
      }
    })
  }

  const setSelectedGroups = () => {
    props.user.groups.forEach((group) => {
      selectedGroupIds.value.push(group.id)
    })
  }

  const getUserCourseActivity = () => {
    if (!userId.value) {
      UserModule.setUserCourseActivities({ activities: [] })
      return
    }
    UserModule.setUserCourseActivities({ userId: userId.value })
  }

  setSelectedRoles()
  setSelectedGroups()
  getUserCourseActivity()

  watch(userId, () => {
    getUserCourseActivity()
  })

  watch(
    () => props.user,
    () => {
      setSelectedRoles()
    },
  )
</script>

<style lang="postcss" scoped>
  .user-preview-details {
    @apply ketch-flex ketch-flex-col ketch--mx-c15;
    .preview-details {
      @apply ketch-grid ketch-grid-cols-2;
      .detail-header {
        @apply ketch-px-c15 ketch-py-[7px] ketch-bg-black ketch-bg-opacity-[0.05];
        span {
          @apply ketch-text-xxs ketch-leading-sm ketch-uppercase ketch-font-bold;
        }
      }
      .detail-wrapper {
        @apply ketch-px-c15 ketch-py-c20 ketch-relative;
        img.checked {
          filter: invert(1);
        }
        .loading-user-roles-change,
        .loading-user-groups-change {
          @apply ketch-flex ketch-flex-col ketch-items-center ketch-justify-center;
          @apply ketch-bg-module-selector-background-color ketch-bg-opacity-[0.5];
          @apply ketch-absolute ketch-left-0 ketch-right-0 ketch-bottom-0 ketch-top-0;
        }
        .user-roles,
        .user-groups {
          @apply ketch-flex ketch-flex-col ketch-space-y-c10;
          .choice {
            @apply ketch-p-0;
          }
        }
        .user-groups-wrapper {
          @apply ketch-h-[82px];
          .user-groups {
            @apply ketch-h-full ketch-overflow-y-auto;
          }
          .user-groups-empty-state {
            @apply ketch-flex ketch-flex-col ketch-items-center ketch-justify-center ketch-h-full;
            .create-group-cta {
              @apply ketch-underline ketch-cursor-pointer;
            }
          }
        }
        .appointments {
          @apply ketch-flex ketch-flex-col ketch-space-y-c5 ketch-max-h-c100 ketch-overflow-y-auto;
          .appointment {
            @apply ketch-flex ketch-items-center ketch-space-x-c5;
            .appointment-course {
              @apply ketch-font-bold;
            }
          }
        }
      }
      .course-activity {
        .detail-wrapper {
          @apply ketch-overflow-y-auto;
          .loading,
          .no-course-activity {
            @apply ketch-h-full ketch-flex ketch-items-center ketch-justify-center;
          }
          .course-activities {
            .activity {
              @apply ketch-py-c15 ketch-border-b ketch-border-border-color ketch-border-dashed;
              @apply ketch-flex ketch-space-x-c10;
              &:first-of-type {
                @apply ketch-pt-0;
              }
              &:last-of-type {
                @apply ketch-border-b-0;
              }
              .completion-percentage {
                @apply ketch-w-c50;
                h4 {
                  @apply ketch-text-primary-color ketch-font-bold ketch-w-[inherit];
                }
              }
              .course-overview-details {
                @apply ketch-space-y-c5;
              }
            }
          }
        }
      }
      .roles-and-groups {
        @apply ketch-border-l ketch-border-border-color;
      }
    }
    .user-action-cta {
      @apply ketch-px-c15 ketch-py-c10 ketch-bg-primary-color ketch-bg-opacity-[0.1];
      .cancel-invite-notification {
        @apply ketch--mx-c15 ketch--my-c10;
      }
      .assign-course-invite-cta {
        @apply ketch-grid ketch-grid-cols-2;
        .assign-courses-container {
          @apply ketch-flex ketch-items-center ketch-space-x-c20 ketch-w-[fit-content];
          button {
            @apply ketch-w-auto;
          }
          .courses {
            @apply ketch-flex ketch-flex-col;
            h5:last-of-type {
              @apply ketch-font-bold;
            }
          }
        }
        .resend-cancel-invite-cta {
          @apply ketch-flex ketch-items-center ketch-justify-between ketch-pl-c15;
          button {
            @apply ketch-bg-module-selector-background-color ketch-w-auto;
          }
          .cancel {
            @apply ketch-underline ketch-cursor-pointer;
          }
        }
        .delete-company-user {
          @apply ketch-justify-self-end;
        }
      }
    }
  }
</style>
