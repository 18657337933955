<template lang="pug">
.cancel-invite-notification
  .cancel-invite-notification-container
    h6.message {{ cancelMessage }}
    .buttons
      KetchUpButton.tertiary(@click.native='$emit("cancel")')
        h5 {{ $t('homepage.noAbort') }}
      KetchUpButton.tertiary(@click.native='$emit("proceed")')
        LoadingSpinner(v-if='cancellingUserInvite')
        h5(v-else) {{ $t('homepage.yesProceed') }}
</template>

<script setup lang="ts">
  import { computed } from 'vue'
  import KetchUpButton from '@/components/common/KetchUpButton.vue'
  import LoadingSpinner from '@/components/common/LoadingSpinner.vue'
  import useI18n from '@/composables/useI18n'

  const props = defineProps({
    cancellingUserInvite: {
      type: Boolean,
      default: false,
    },
    cancellationMessage: {
      type: String,
      default: '',
    },
  })

  defineEmits(['cancel', 'proceed'])

  const { translateString } = useI18n()

  const cancelMessage = computed(() =>
    props.cancellationMessage ? props.cancellationMessage : translateString('homepage.cancellationMessage'),
  )
</script>

<style lang="postcss" scoped>
  .cancel-invite-notification {
    @apply ketch-bg-primary-color;
    &-container {
      @apply ketch-flex ketch-items-center ketch-justify-between ketch-w-full ketch-px-c15 ketch-py-c10;
      .message {
        @apply ketch-max-w-[450px] ketch-text-primary-foreground-color;
      }
      .buttons {
        @apply ketch-space-x-c10 ketch-whitespace-nowrap;
        button {
          @apply ketch-w-auto ketch-border-primary-foreground-color;
          > * {
            @apply ketch-text-primary-foreground-color;
          }
        }
      }
    }
  }
</style>
