<template lang="pug">
.audio-player(@click.stop)
  vue-plyr(:options='options', ref='audioPlayer')
    audio(controls, crossorigin='anonymous', playsinline)
      source(ref='audioSourceEl')
  .loader-info(v-if='loading')
    LoadingSpinner
    h6 {{ $t('editor.readingAudioStream') }}
</template>

<script setup lang="ts">
  import { computed, onBeforeUnmount, onMounted, ref } from 'vue'
  //@ts-ignore
  import VuePlyr from 'vue-plyr'
  import Hls from 'hls.js'
  import LoadingSpinner from '@/components/common/LoadingSpinner.vue'
  import type { Nullable } from '@/services/interfaces/QuizQuestion'
  import useSegment from '@/composables/useSegment'
  import useCourse from '@/composables/useCourse'

  const props = defineProps({
    audioSource: {
      type: String,
      required: true,
    },
    trackPlayEvents: {
      type: Boolean,
      default: false,
    },
  })

  const audioPlayer = ref(null as Nullable<HTMLDivElement>)
  const audioSourceEl = ref(null as Nullable<HTMLSourceElement>)
  const loading = ref(true)

  const hls = new Hls()

  const { course, currentModule, currentLesson } = useCourse()
  const { audioPlayStarted, audioPlayPaused, audioPlayCompleted } = useSegment()

  const options = {
    autoplay: false,
    muted: false,
    captions: {
      active: true,
      language: 'de',
      update: true,
    },
    controls: ['play', 'progress', 'current-time', 'mute', 'volume', 'settings'],
    speed: {
      selected: 1,
      options: [1, 1.25, 1.5],
    },
    tooltips: { controls: true, seek: true },
  }

  const player = computed(() => (audioPlayer.value as any)?.player)

  const trackAudioEvent = (event: 'play' | 'pause' | 'completed') => {
    if (props.trackPlayEvents && course.value?.id && currentModule.value?.id && currentLesson.value?.id) {
      switch (event) {
        case 'play':
          audioPlayStarted(course.value.id, currentModule.value.id, currentLesson.value.id)
          return
        case 'pause':
          audioPlayPaused(course.value.id, currentModule.value.id, currentLesson.value.id)
          return
        case 'completed':
          audioPlayCompleted(course.value.id, currentModule.value.id, currentLesson.value.id)
          return
        default:
          return
      }
    }
  }

  const handleAudioPlaying = () => {
    trackAudioEvent('play')
  }

  const handleAudioPaused = () => {
    trackAudioEvent('pause')
  }

  const handleAudioPlayCompleted = () => {
    trackAudioEvent('completed')
  }

  const bindPlayerEvents = () => {
    player.value.on('play', handleAudioPlaying)
    player.value.on('pause', handleAudioPaused)
    player.value.on('ended', handleAudioPlayCompleted)
  }

  const unbindPlayerEvents = () => {
    player.value.off('playing', handleAudioPlaying)
    player.value.off('pause', handleAudioPaused)
    player.value.off('ended', handleAudioPlayCompleted)
  }

  const bootstrapAudioPlayer = () => {
    if (Hls.isSupported() && hls) {
      hls.loadSource(props.audioSource)
      hls.attachMedia(player.value.media)
      ;(window as any).hls = hls
    } else {
      const sourceEl = audioSourceEl.value as HTMLSourceElement
      sourceEl.src = props.audioSource
    }
  }

  onMounted(() => {
    bindPlayerEvents()

    const intervalId = window.setInterval(async () => {
      const data = await fetch(props.audioSource!)
      if (data.status === 200) {
        window.clearInterval(intervalId)
        bootstrapAudioPlayer()
        loading.value = false
      }
    }, 1000)
  })

  onBeforeUnmount(() => {
    unbindPlayerEvents()

    if (Hls.isSupported() && hls) {
      hls.detachMedia()
      hls.destroy()
    }
  })
</script>

<style lang="postcss">
  .audio-player {
    .loader-info {
      @apply ketch-flex ketch-space-x-c15 ketch-mt-c10;
      h6 {
        @apply ketch-text-xxs ketch-leading-sm ketch-text-red-500;
      }
      .loading {
        @apply ketch-m-0;
      }
    }
    .plyr--audio {
      .plyr__controls {
        @apply ketch-bg-transparent ketch-p-0;
      }
      .plyr__menu__container {
        --plyr-audio-control-background-hover: var(--primary-color);
        --plyr-audio-control-color-hover: var(--primary-foreground-color);
      }
    }
  }
</style>
