<template lang="pug">
.editor-buttons(
  :class='{ show: hasEditButtons && !hideSaveDiscardButtons, training: $route.name === "Trainings" }',
  :style='{ top: editorButtonsTopPadding }'
)
  transition(mode='out-in', name='fade', v-if='!hideSaveDiscardButtons')
    .save-discard-buttons(v-if='hasChanges')
      KetchUpButton.editor(@click.native='saveAllChanges')
        SVGRenderer(
          :has-hover='false',
          :icon='checkmark',
          height='10px',
          stroke-color='var(--editor-foreground-color)'
        )
        h5 {{ $t('editor.saveAllChanges') }}
      KetchUpButton.tertiary(@click.native='discardAllChanges')
        SVGRenderer(:has-hover='false', :icon='closeIcon', height='16px', stroke-color='var(--primary-text-color)')
        h5 {{ $t('editor.discardChanges') }}
  .undo-redo-buttons
    KetchUpButton.editor.undo(:disabled='!canUndo', @click.native='onUndoClick', v-tooltip='$t("undo")')
      SVGRenderer(
        :has-hover='false',
        :icon='undoIcon',
        fill-color='var(--editor-foreground-color)',
        stroke-color='var(--editor-foreground-color)',
        width='26'
      )
    KetchUpButton.editor.redo(:disabled='!canRedo', @click.native='onRedoClick', v-tooltip='$t("redo")')
      SVGRenderer(
        :has-hover='false',
        :icon='undoIcon',
        fill-color='var(--editor-foreground-color)',
        stroke-color='var(--editor-foreground-color)',
        width='26'
      )
</template>

<script setup lang="ts">
  import { computed } from 'vue'
  import KetchUpButton from '@/components/common/KetchUpButton.vue'
  import useIcons from '@/composables/useIcons'
  import SVGRenderer from '@/components/common/SVGRenderer.vue'
  import { EditorModule } from '@/store/modules/editor'
  import useEditor from '@/composables/useEditor'
  import { UserModule } from '@/store/modules/user'
  import { useRoute } from 'vue-router/composables'

  const props = defineProps({
    path: String,
    hideSaveDiscardButtons: {
      type: Boolean,
      default: false,
    },
  })

  const route = useRoute()
  const { undoIcon, checkmark, closeIcon } = useIcons()
  const { hasChanges, saveAllChanges, discardAllChanges } = useEditor(route)

  const canUndo = computed(() => EditorModule.canUndo(props.path || route.path))
  const canRedo = computed(() => EditorModule.canRedo(props.path || route.path))

  const onUndoClick = () => {
    EditorModule.undo(props.path || route.path)
  }
  const onRedoClick = () => {
    EditorModule.redo(props.path || route.path)
  }

  const hasEditButtons = computed(() => {
    return EditorModule.editMode && hasChanges.value
  })

  const showingAdminNav = computed(() => {
    return UserModule.isCompanyAdmin && !route.meta?.hideAdminNav
  })

  const editorButtonsTopPadding = computed(() =>
    showingAdminNav.value ? `${120 + UserModule.bannersTotalHeightValue}px` : '0px',
  )
</script>

<style lang="postcss">
  .editor-buttons {
    @apply ketch-flex ketch-justify-end ketch-items-center ketch-bg-module-selector-background-color ketch-h-c60;
    @apply ketch-sticky ketch-left-0 ketch-right-0 ketch-top-0 ketch-z-10 ketch-mb-c20;
    &.show {
      @apply ketch-justify-between;
    }
    &.training {
      @apply ketch-mb-0 ketch-px-[calc(50%-530px)];
    }
    .save-discard-buttons {
      @apply ketch-flex ketch-space-x-c30;
      flex-basis: 100%;
      button {
        @apply ketch-flex ketch-space-x-c10;
        &.primary {
          @apply ketch-bg-editor-primary-color ketch-w-auto;
          svg {
            @apply ketch-w-c10;
          }
        }
        &.tertiary {
          @apply ketch-w-auto;
          svg {
            @apply ketch-w-c16;
          }
        }
      }
    }
    .undo-redo-buttons {
      @apply ketch-flex;
      button {
        @apply ketch-w-auto ketch-h-[32px] ketch-py-c5 ketch-border-[0.5px];
        @apply ketch-rounded-[16px];
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
        &:disabled {
          @apply ketch-cursor-default ketch-opacity-[0.2];
        }
        &.undo {
          @apply ketch-rounded-r-none ketch-border-r-[#83b88e];
        }
        &.redo {
          @apply ketch-rounded-l-none ketch-border-l-0;
          svg {
            @apply ketch--scale-x-100;
          }
        }
        svg {
          @apply ketch-w-[26px];
        }
      }
    }
  }
</style>
