<template lang="pug">
.edit-authors-wrapper
  template(v-if='authors.length')
    .edit-title-header
      h5 {{ authorText }}
      h6(v-if='authorSubtext') {{ authorSubtext }}
    .authors-wrapper
      .author(:key='author.id', v-for='author in authors')
        .author-image
          img(:src='author.profileImageUrl')
          .edit-icon(@click='removeAuthor(author.id)')
            SVGRenderer(
              :fill-color='"var(--editor-primary-color)"',
              :has-hover='false',
              :icon='trashIcon',
              height='15',
              width='12'
            )
        h5.name {{ author.name }}
        h6 {{ author.position }}
  template(v-if='showAddAuthor')
    .edit-title-header
      h5 {{ getAddAuthorText }}
    Dropdown.editor-dropdown(
      :dropdown-placeholder='String($t("editor.selectAuthor"))',
      :items='allAuthors',
      @input='addAuthor',
      default-dropdown-behavior,
      stroke-color-for-expand-icon='var(--editor-primary-color)'
    )
</template>

<script setup lang="ts">
  import { computed } from 'vue'
  import Dropdown from '@/components/common/Dropdown.vue'
  import SVGRenderer from '@/components/common/SVGRenderer.vue'
  import { EditorModule } from '@/store/modules/editor'
  import useIcons from '@/composables/useIcons'
  import useI18n from '@/composables/useI18n'
  import type { PropType } from 'vue'
  import type { Author } from '@/services/interfaces/Common'

  const props = defineProps({
    authors: {
      type: Array as PropType<Author[]>,
      required: true,
    },
    authorText: {
      type: String,
      default: 'Author',
    },
    authorSubtext: {
      type: String,
      default: '',
    },
    addAuthorText: {
      type: String,
      default: '',
    },
    addAuthor: {
      type: Function as PropType<(authorId: string) => void>,
      required: true,
    },
    removeAuthor: {
      type: Function as PropType<(authorId: string) => void>,
      required: true,
    },
    canAddMoreAuthors: {
      type: Boolean,
      default: true,
    },
  })

  const { trashIcon } = useIcons()
  const { translateString } = useI18n()

  const allAuthors = computed(() => {
    if (!EditorModule.editorAllAuthors) return []
    return EditorModule.editorAllAuthors.map((author) => ({
      type: author.id,
      title: author.name,
      selectable: authorIsSelected.value(author.id),
    }))
  })

  const authorIsSelected = computed(() => {
    return (authorId: string) => {
      return !props.authors.find((a) => a.id === authorId)
    }
  })

  const showAddAuthor = computed(() => (props.canAddMoreAuthors ? true : props.authors.length === 0))

  const getAddAuthorText = computed(() => {
    return props.addAuthorText ? props.addAuthorText : translateString('editor.addAuthor')
  })
</script>

<style lang="postcss" scoped>
  .edit-authors-wrapper {
    @apply ketch-flex ketch-flex-col;
  }
</style>
