import { render, staticRenderFns } from "./VideoTranscript.vue?vue&type=template&id=02303e1c&scoped=true&lang=pug"
import script from "./VideoTranscript.vue?vue&type=script&setup=true&lang=ts"
export * from "./VideoTranscript.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./VideoTranscript.vue?vue&type=style&index=0&id=02303e1c&prod&lang=postcss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "02303e1c",
  null
  
)

export default component.exports