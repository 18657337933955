import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueMeta from 'vue-meta'
//@ts-ignore
import VueMq from 'vue-mq'
import axios from 'axios'
import './assets/styles/index.css'
import VueI18n from 'vue-i18n'
import languages from '../languages.json'
import messages from './i18n/i18n'
import auth_config from '../auth_config.json'
import { Auth0Plugin } from './services/auth'
import VTooltip from 'v-tooltip'
import Toasted from 'vue-toasted'
import MarkdownIt from 'markdown-it'
import * as Sentry from '@sentry/vue'
import { MazPhoneNumberInput } from 'maz-ui'
//@ts-ignore
import GoogleTranslateSelect from '@google-translate-select/vue2'
// @ts-ignore
import Particles from '@tsparticles/vue2'
//@ts-ignore
import { loadFull } from 'tsparticles'
import type { Engine } from '@tsparticles/engine'

if (['production', 'staging', 'qa'].includes(process.env.APP_ENV!)) {
  Sentry.init({
    Vue,
    dsn: 'https://0ad138641d4944328cac4aa057df3381@o680936.ingest.sentry.io/5770680',
    integrations: [
      Sentry.browserTracingIntegration({ router }),
      Sentry.replayIntegration(),
      Sentry.vueIntegration({
        tracingOptions: {
          trackComponents: true,
        },
      }),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,

    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [
      'https://ketchup-api-staging.herokuapp.com',
      'https://api.staging.ketchup.academy',
      'https://api.ketchup.academy',
    ],

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,

    release: process.env.GIT_COMMIT_ID,
    environment: process.env.APP_ENV,

    ignoreErrors: [
      'Network Error',
      'timeout exceeded',
      'Request aborted',
      'Request failed with status code 401',
      /Error: ERR_ACTION_ACCESS_UNDEFINED/,
    ],
  })
}

const { staging, production } = auth_config
// Install the authentication plugin here
Vue.use(Auth0Plugin, {
  domain: process.env.APP_ENV === 'production' ? production.domain : staging.domain,
  clientId: process.env.APP_ENV === 'production' ? production.clientId : staging.clientId,
  audience: process.env.APP_ENV === 'production' ? production.audience : staging.audience,
  tokenKey: process.env.APP_ENV === 'production' ? production.tokenKey : staging.tokenKey,
  onRedirectCallback: (appState: any) => {
    if (appState && appState.targetUrl) {
      window.location.href = appState.targetUrl
    } else if (window.location.pathname !== '/') {
      window.location.href = '/'
    }
  },
})

Vue.use(Toasted, {
  iconPack: 'custom-class',
  duration: 5000,
  position: 'top-center',
})

Vue.use(VTooltip, {
  popover: {
    defaultTrigger: 'hover',
  },
})

Vue.use(VueMeta)

Vue.use(VueMq, {
  breakpoints: {
    'max-xs': 320,
    'max-xs1': 360,
    'max-xs2': 375,
    'max-xs3': 411,
    'max-xs4': 540,
    'max-sm': 768,
    'max-md': 992,
    'max-lg': 1024,
    'max-xl': 1280,
    'max-2xl': 1536,
    large: Infinity,
  },
  defaultBreakpoint: 'max-xs3',
})

if (!localStorage.userTheme) {
  localStorage.userTheme = 'light'
}

const locale = 'de' // languages.supported.includes(navigator.language) ? navigator.language : languages.default
Vue.prototype.$locale = locale
Vue.prototype.$logger = window.console
Vue.prototype.$md = new MarkdownIt({
  html: true,
  linkify: true,
  typographer: true,
})

Vue.use(VueI18n)
const i18n = new VueI18n({
  locale: Vue.prototype.$locale,
  fallbackLocale: languages.default,
  messages,
})

Vue.use(GoogleTranslateSelect)

Vue.use(Particles, {
  init: async (engine: Engine) => {
    await loadFull(engine)
  },
})

Vue.component('MazPhoneNumberInput', MazPhoneNumberInput)

let baseURL = process.env.API_BASE_URL
if (!baseURL) {
  baseURL = 'https://api.staging.ketchup.academy/v1'
}
axios.defaults.baseURL = Vue.prototype.$baseURL = baseURL
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'

Vue.config.productionTip = false

const eventBus = new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app')

export default eventBus
