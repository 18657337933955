<template lang="pug">
.leuckentext-answers-pane-mobile
  h6.helper-text {{ $t('courses.leuckentext.selectOneItem') }}
  .answer-choices
    .answer-choice(:key='choice.id', v-for='choice in choices')
      Choice(
        :selected='!choice.selectable',
        :svg-renderer-props='selectedChoice(choice.selectable)',
        :title='choice.text',
        @click.native='$emit("on-choice-click", choice)'
      )
  .answer-ctas
    KetchUpButton.tertiary.previous(:class='{ disabled: disablePreviousButton }', @click.native='$emit("previous")')
      h5 {{ $t('previous') }}
    KetchUpButton.primary.next(@click.native='$emit("next-or-submit")')
      LoadingSpinner(v-if='loadingResults')
      template(v-else)
        h5 {{ showSubmitText ? $t('toTheResults') : $t('next') }}
</template>

<script setup lang="ts">
  import { computed } from 'vue'
  import Choice from '@/components/common/Choice.vue'
  import useIcons from '@/composables/useIcons'
  import KetchUpButton from '@/components/common/KetchUpButton.vue'
  import LoadingSpinner from '@/components/common/LoadingSpinner.vue'
  import type { PropType } from 'vue'
  import type { LeuckentextChoice } from '@/services/interfaces/Course'
  import type { SvgRendererProps } from '@/services/interfaces/Common'

  defineProps({
    choices: {
      type: Array as PropType<LeuckentextChoice[]>,
      required: true,
    },
    disablePreviousButton: {
      type: Boolean,
      default: true,
    },
    showSubmitText: {
      type: Boolean,
      default: false,
    },
    loadingResults: {
      type: Boolean,
      default: false,
    },
  })
  defineEmits(['on-choice-click', 'previous', 'next-or-submit'])

  const { uncheckedCircle, checkedCircle } = useIcons()

  const selectedChoice = computed(() => {
    return (selectable: boolean): SvgRendererProps => {
      return selectable
        ? { icon: uncheckedCircle.value, strokeColor: 'var(--primary-text-color)' }
        : { icon: checkedCircle.value, strokeColor: 'var(--primary-text-color)' }
    }
  })
</script>

<style lang="postcss" scoped>
  .leuckentext-answers-pane-mobile {
    .helper-text {
      @apply ketch-opacity-50 ketch-mb-c15;
    }
    .answer-choices {
      @apply ketch-space-y-c10 ketch-mb-c35;
      .choice {
        @apply ketch-py-c6;
        &.selected {
          @apply ketch-opacity-20 ketch-border;
        }
      }
    }
    .answer-ctas {
      @apply ketch-flex ketch-items-center ketch-justify-between;
      button.previous {
        @apply ketch-w-c100;
        &.disabled {
          @apply ketch-opacity-30 ketch-outline-none;
        }
      }
      button.next {
        @apply ketch-w-c200;
      }
    }
  }
</style>
