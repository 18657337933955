<template lang="pug">
.search-wrapper
  .in-navigation-bar(
    :class='{ expand: expandSearch }',
    v-if='!inSidePanel && !isMobileDevice',
    v-on-click-outside='openOrCloseSearch',
    v-tooltip='{ content: $t("search.minCharTooltipText"), show: showSearchTooltip && !searchingFromSidePanel, trigger: "manual" }'
  )
    input.h5(
      @keyup.enter='findSearchTerm(false)',
      data-cy='search-input',
      ref='searchInput',
      type='text',
      v-model='searchTerm'
    )
    img.clearSearch(:src='closeIcon', @click='clearSearch', v-if='searchTerm && expandSearch')
    .searchIcon(
      @click='expandSearchInput',
      @mouseleave='hovering = false',
      @mouseover='hovering = true',
      data-cy='search-icon',
      v-else
    )
      SVGRenderer(
        :icon='searchIcon',
        :stroke-color='expandSearch ? "var(--primary-color)" : "var(--header-foreground-color)"',
        :toggle-hover='hovering',
        height='20px',
        stroke-hover-color='--header-foreground-color)',
        v-tooltip.bottom='{ content: $t("search.searchAnything") }'
      )
  .in-side-panel(
    v-else-if='isMobileDevice && inSidePanel',
    v-tooltip='{ content: $t("search.minCharTooltipText"), show: showSearchTooltip && searchingFromSidePanel, trigger: "manual" }'
  )
    input.h5(
      :placeholder='String($t("search.searchText"))',
      @keyup.enter='findSearchTerm(true)',
      type='text',
      v-model='searchTerm'
    )
    img(:src='searchIcon', @click='findSearchTerm(true)')
</template>

<script setup lang="ts">
  import { computed, onMounted, ref, watch } from 'vue'
  import SVGRenderer from '@/components/common/SVGRenderer.vue'
  import useBreakpoint from '@/composables/useBreakpoint'
  import useSegment from '@/composables/useSegment'
  import useIcons from '@/composables/useIcons'
  import { useRoute, useRouter } from 'vue-router/composables'
  import { vOnClickOutside } from '@vueuse/components'

  const emit = defineEmits(['close-side-panel', 'create-space-for-search-input'])

  const props = defineProps({
    openSidePanel: {
      type: Boolean,
      default: false,
    },
    inSidePanel: {
      type: Boolean,
      default: false,
    },
  })

  const router = useRouter()
  const route = useRoute()
  const { isMobileDevice, isSmallerMobileDevice } = useBreakpoint()
  const { contentTermSearched } = useSegment()
  const { closeIcon, searchIcon } = useIcons()
  const searchTerm = ref('')
  const hovering = ref(false)
  const expandSearch = ref(false)
  const showSearchTooltip = ref(false)
  const searchingFromSidePanel = ref(false)
  const searchInput = ref(null as HTMLInputElement | null)

  const routeQuery = computed(() => {
    return route.query
  })

  const findSearchTerm = (searchingFromSidePanel: boolean) => {
    if (searchTerm.value.length < 3) {
      checkToShowSearchTooltip(searchingFromSidePanel)
      return
    }
    if (props.openSidePanel) emit('close-side-panel')
    contentTermSearched(searchTerm.value)
    if (searchTerm.value === route.query.q) return
    router
      .push({
        name: 'SearchResults',
        query: { q: searchTerm.value },
      })
      .catch(() => {
        return
      })
  }

  const checkToShowSearchTooltip = (isSearchingFromSidePanel: boolean) => {
    if (isSearchingFromSidePanel) {
      searchingFromSidePanel.value = true
    }
    showSearchTooltip.value = true
    setTimeout(() => {
      showSearchTooltip.value = false
    }, 4000)
  }

  const expandSearchInput = () => {
    if (isSmallerMobileDevice.value) {
      emit('create-space-for-search-input', true)
    }
    expandSearch.value = true
    searchInput.value?.focus()
  }

  const clearSearch = () => {
    searchTerm.value = ''
    expandSearch.value = false
    emit('create-space-for-search-input', false)
    showSearchTooltip.value = false
    const { query } = route
    if (query.q) {
      router.replace({ query: { ...query, q: undefined } })
    }
  }

  const openOrCloseSearch = () => {
    if (route.query.q) {
      searchTerm.value = route.query.q as string
      expandSearch.value = true
    } else {
      searchTerm.value = ''
      expandSearch.value = false
    }
  }

  watch(routeQuery, () => {
    openOrCloseSearch()
  })

  onMounted(() => {
    openOrCloseSearch()
  })
</script>

<style lang="postcss">
  .search-wrapper {
    @apply ketch-w-full;
    .in-navigation-bar {
      @apply ketch-inline-flex ketch-items-center ketch-justify-end ketch-h-c35 md:ketch-h-c40;
      input {
        @apply ketch-w-0 ketch-h-0 ketch-outline-none;
      }
      input::-moz-placeholder {
        @apply ketch-text-transparent hover:ketch-text-secondary-text-color focus:ketch-text-secondary-text-color;
      }
      input::-webkit-input-placeholder {
        @apply ketch-text-transparent hover:ketch-text-secondary-text-color focus:ketch-text-secondary-text-color;
      }
      &.expand {
        @apply ketch-rounded-large ketch-transition-width ketch-ease-out ketch-duration-300 ketch-bg-background-color;
        @apply ketch-w-[190px] xs1:ketch-w-c220 xs3:ketch-w-c280 xs4:ketch-w-[330px] ketch-absolute ketch-top-[-17px];
        @apply md:ketch--top-c20 ketch-right-0;
        input {
          @apply ketch-appearance-none focus:ketch-outline-none ketch-text-primary-text-color ketch-w-full ketch-h-full;
          @apply ketch-ml-c20 ketch-mr-c10 ketch-bg-background-color;
          &::-webkit-search-cancel-button {
            @apply ketch-appearance-none ketch-h-0 ketch-w-0 ketch-bg-none ketch-pointer-events-none ketch-opacity-0;
          }
        }
        .searchIcon {
          @apply md:ketch-mr-c10;
        }
      }
      img {
        @apply ketch-w-c20 ketch-cursor-pointer focus:ketch-cursor-auto ketch-mr-c10;
      }
      .searchIcon {
        @apply ketch-cursor-pointer;
        svg {
          @apply ketch-outline-none;
        }
      }
    }
    .in-side-panel {
      @apply ketch-relative;
      input {
        @apply ketch-h-c35 ketch-w-full ketch-pl-c15 ketch-outline-none;
        @apply ketch-text-primary-text-color ketch-pr-c25 ketch-rounded-large ketch-border;
      }
      img {
        @apply ketch-ml-c20 ketch-absolute ketch-right-c8 ketch-bottom-c10 ketch-w-c15;
        filter: none;
      }
    }
  }
</style>
