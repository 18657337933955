import { render, staticRenderFns } from "./OnboardingSurveyAnswerResponseView.vue?vue&type=template&id=327951f2&scoped=true&lang=pug"
import script from "./OnboardingSurveyAnswerResponseView.vue?vue&type=script&setup=true&lang=ts"
export * from "./OnboardingSurveyAnswerResponseView.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./OnboardingSurveyAnswerResponseView.vue?vue&type=style&index=0&id=327951f2&prod&scoped=true&lang=postcss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "327951f2",
  null
  
)

export default component.exports