import { render, staticRenderFns } from "./PlaceholderShimmer.vue?vue&type=template&id=35fbc44d&scoped=true&lang=pug"
import script from "./PlaceholderShimmer.vue?vue&type=script&setup=true&lang=ts"
export * from "./PlaceholderShimmer.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./PlaceholderShimmer.vue?vue&type=style&index=0&id=35fbc44d&prod&lang=postcss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "35fbc44d",
  null
  
)

export default component.exports