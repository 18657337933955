<template lang="pug">
.course-trials-wrapper
  h2.in-trials-header {{ $t('courses.courseTrials.inTest') }}
  h5.in-trials-description {{ $t('courses.courseTrials.description') }}
  .in-trial-courses.component-spacing
    router-link.in-trial-course(:key='course.id', :to='`courses/${course.id}/info`', v-for='course in slicedCourses')
      img.course-image(:src='course.cardTeaserImageUrl || course.teaserImageUrl || cardEmptyImage')
      .course-info-cta
        h3.trial-course-title {{ course.title }}
        HTMLRenderer.h5.course-teaser-description(:html='course.teaserDescription || ""', link-target='_blank')
        h5.course-cta {{ $t('courses.courseTrials.continue') }}
  h5.find-more-trainings {{ $t('courses.courseTrials.findMoreTrainingsBelow') }}
</template>

<script setup lang="ts">
  import { computed } from 'vue'
  import useCourse from '@/composables/useCourse'
  import useIcons from '@/composables/useIcons'
  import HTMLRenderer from '@/components/common/HTMLRenderer.vue'

  const { inTrialCourses } = useCourse()
  const { cardEmptyImage } = useIcons()

  const slicedCourses = computed(() => inTrialCourses.value.slice(0, 2))
</script>

<style lang="postcss">
  .course-trials-wrapper {
    @apply ketch-mt-c30 ketch-z-[2] ketch-flex ketch-flex-col ketch-items-center ketch-w-full;
    @apply ketch-border-b ketch-border-black ketch-border-opacity-40 ketch-border-dashed md:ketch-mt-c50;
    .in-trials-header {
      @apply ketch-font-bold;
    }
    .in-trials-description {
      @apply ketch-pt-c8 ketch-pb-c30 ketch-opacity-50;
    }
    .in-trial-courses {
      @apply ketch-flex ketch-flex-col ketch-space-y-c20 ketch-max-w-[875px] ketch-mb-c80;
      .in-trial-course {
        @apply ketch-flex ketch-flex-col ketch-p-c15 ketch-bg-white ketch-rounded-normal ketch-shadow-lg;
        @apply md:ketch-flex-row md:ketch-space-x-c30;
        @apply ketch-flex ketch-space-x-c30;
        .course-image {
          @apply ketch-h-[160px] ketch-max-w-[310px];
        }
        .course-info-cta {
          @apply ketch-flex ketch-flex-col ketch-justify-between;
        }
        .trial-course-title {
          @apply ketch-font-bold ketch-overflow-hidden;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          line-clamp: 3;
          -webkit-box-orient: vertical;
          word-break: break-word;
        }
        .course-teaser-description {
          @apply ketch-opacity-50 ketch-overflow-hidden;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          line-clamp: 3;
          -webkit-box-orient: vertical;
        }
        .course-cta {
          @apply ketch-flex ketch-justify-end ketch-underline;
        }
      }
    }
    .find-more-trainings {
      @apply ketch-opacity-50 ketch-pb-c10;
    }
  }
</style>
