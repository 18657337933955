<template lang="pug">
.course-search-item(@click='showLessonModal', data-cy='search-result-item')
  .image-container
    PlaceholderShimmer(:animate='true', height='120px')
      template(v-slot:default='{ isLoadingResource, onResourceLoaded }')
        img(
          :class='{ "shimmer-asset": isLoadingResource }',
          :src='searchItem.course.cardTeaserImageUrl',
          @load='onResourceLoaded'
        )
  .search-content-container
    SearchBreadcrumbs(:breadcrumbs='crumbs')
    h3.title(v-html='formatPhrase(decodeAndParseSource(searchItem.title))')
    HTMLRenderer.excerpt(
      :format-content='true',
      :html='formatExcerpt(searchItem.excerpt)',
      :style='{ maxHeight: `${maxContentHeight}px` }',
      link-target='_blank',
      ref='excerptRef',
      v-if='searchItem.excerpt'
    )
</template>

<script setup lang="ts">
  import { computed, onMounted, ref } from 'vue'
  import PlaceholderShimmer from '@/components/common/PlaceholderShimmer.vue'
  import HTMLRenderer from '@/components/common/HTMLRenderer.vue'
  import SearchBreadcrumbs from '@/components/curated-content/SearchBreadcrumbs.vue'
  import useCommonMixin from '@/composables/useCommonMixin'
  import useCourse from '@/composables/useCourse'
  import { CourseModule } from '@/store/modules/course'
  import useEditor from '@/composables/useEditor'
  import eventBus from '@/main'
  import type { PropType } from 'vue'
  import type { CourseSearchItem as CourseSearchItemState } from '@/services/interfaces/Course'
  import type { Nullable } from '@/services/interfaces/Content'
  import { useRoute } from 'vue-router/composables'

  const props = defineProps({
    searchItem: {
      required: true,
      type: Object as PropType<CourseSearchItemState>,
    },
  })

  const route = useRoute()
  const { formatPhrase } = useCommonMixin()
  const { showStartCourseForFreeModal, openLessonModal, courseSectionsAssignedCourses, course } = useCourse()
  const { decodeAndParseSource } = useEditor(route)

  const courseIsPurchasedOrInTrial = computed(() => {
    const _course = courseSectionsAssignedCourses.value?.find((c) => c.id === props.searchItem.course.id)
    if (_course) return _course.purchased || _course.trial
    return props.searchItem.course.purchased || props.searchItem.course.trial
  })

  const showLessonModal = async () => {
    const searchItemCourseId = props.searchItem.course.id

    // ensure course is loaded first
    if (course.value?.id !== searchItemCourseId) {
      eventBus.$emit('turn-on-loader')
      await CourseModule.getCourse({ courseId: searchItemCourseId })
      eventBus.$emit('turn-off-loader')
    }

    const loadCourseModules = async () => {
      await CourseModule.getCourseModules(searchItemCourseId)
    }

    if (courseIsPurchasedOrInTrial.value) {
      openLessonModal({
        lessonId: props.searchItem.id,
        moduleId: props.searchItem.module.id,
        courseId: searchItemCourseId,
        teaserImageUrl: props.searchItem.course.teaserImageUrl,
        formatContent: true,
      })
      await loadCourseModules()
    } else {
      eventBus.$emit('turn-on-loader')
      await loadCourseModules()
      eventBus.$emit('turn-off-loader')
      showStartCourseForFreeModal(searchItemCourseId)
    }
  }

  const crumbs = computed(() => {
    return [
      {
        id: props.searchItem.course.id,
        title: props.searchItem.course.title,
        url: `/courses/${props.searchItem.course.id}/info`,
      },
      {
        id: props.searchItem.module.id,
        title: props.searchItem.module.name,
        url: `/courses/${props.searchItem.course.id}/module/${props.searchItem.module.id}/start`,
      },
      {
        id: props.searchItem.id,
        title: props.searchItem.title,
      },
    ]
  })

  const maxContentHeight = ref(0)
  const excerptRef = ref(null as Nullable<any>)

  const formatExcerpt = (excerpt: string) => {
    return excerpt.replaceAll('\\*', '*') || ''
  }

  onMounted(() => {
    if (excerptRef.value) {
      excerptRef.value.$el.childNodes?.forEach((el: HTMLElement) => {
        const { scrollHeight } = el
        if (scrollHeight + maxContentHeight.value <= 84) {
          maxContentHeight.value += scrollHeight
        } else if (maxContentHeight.value + 20 <= 84) {
          maxContentHeight.value += 20
        }
      })
      maxContentHeight.value = Math.max(maxContentHeight.value, 80)
    }
  })
</script>

<style lang="postcss">
  .course-search-item {
    @apply ketch-flex ketch-flex-col sm:ketch-flex-row ketch-space-x-c20 ketch-cursor-pointer;
    .image-container {
      @apply ketch-w-full ketch-h-[fit-content] ketch-overflow-hidden ketch-rounded-normal;
      @apply ketch-mb-c15 sm:ketch-mb-0;
      @screen sm {
        flex: 0 0 220px;
      }
      img {
        @apply ketch-w-full;
      }
      .shimmer-bg {
        @apply ketch-m-0;
      }
    }
    .search-content-container {
      @apply ketch-flex-1;
      .breadcrumb {
        @apply ketch-text-secondary-text-color;
      }
      .title {
        @apply ketch-my-c5 ketch-font-bold;
      }
      .excerpt {
        @apply ketch-overflow-hidden ketch-text-ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        line-clamp: 4;
        -webkit-box-orient: vertical;
        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
          @apply ketch-text-xs ketch-leading-sm ketch-font-bold;
        }
        hr {
          @apply ketch-my-c10;
        }

        img {
          @apply ketch-hidden;
        }
      }
    }
  }
</style>
