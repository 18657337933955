import { render, staticRenderFns } from "./LessonTypes.vue?vue&type=template&id=396c493d&scoped=true&lang=pug"
import script from "./LessonTypes.vue?vue&type=script&setup=true&lang=ts"
export * from "./LessonTypes.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./LessonTypes.vue?vue&type=style&index=0&id=396c493d&prod&lang=postcss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "396c493d",
  null
  
)

export default component.exports