<template lang="pug">
.dropdown(:class='{ defaultBehavior: defaultDropdownBehavior }', v-on-click-outside='closeDropdown')
  .selected-item(:class='{ open: openDropdown }', @click='setOpenDropdown')
    .title-block
      h6(v-if='headerTitle') {{ headerTitle }}
      h5(v-if='activeItem')
        span.prepend-text(v-if='activeItem.prependText') {{ activeItem.prependText }}
        span(v-html='decodeAndParseSource(activeItem.title)')
      h5(v-else-if='defaultDropdownBehavior')
        span(v-html='decodeAndParseSource(activeDropdownItem)', v-if='activeDropdownItem')
        span.choose(v-else) {{ dropdownPlaceholder ? dropdownPlaceholder : $t('chooseAnOption') }}
    .more(:class='{ open: openDropdown }', v-if='!hideToggleDropdown')
      SVGRenderer(:has-hover='false', :icon='expandMoreOptions', :stroke-color='strokeColorForExpandIcon')
  transition(name='menu-slide')
    .dropdown-items(:class='{ open: openDropdown }', v-if='openDropdown')
      h5.item(
        :class='{ active: selectedValue === item.type || activeDropdownItem === item.title, selectable: item.selectable, "has-flags": showCountryFlags }',
        :key='index',
        @click='selectItem(item)',
        @mouseleave='hoveringItemCta(index, false)',
        @mouseover='hoveringItemCta(index, true)',
        v-for='(item, index) in items'
      )
        span(:class='`flag-icon flag-icon-${countryIconCode(String(item.type))}`', v-if='showCountryFlags')
        span.item-text
          span.prepend-text(v-if='item.prependText') {{ item.prependText }}
          span(v-html='decodeAndParseSource(item.title)')
        span.item-cta(v-if='showEditItemIcon')
          .item-cta-icon-wrapper(@click.stop='$emit("edit", item)')
            SVGRenderer(
              :fill-color='"var(--primary-text-color)"',
              :fill-hover-color='"var(--secondary-foreground-color)"',
              :icon='pencilIcon',
              :toggle-hover='itemCTAIsHovered(index)',
              height='15',
              width='15'
            )
          .item-cta-icon-wrapper(@click.stop='$emit("delete", item)')
            SVGRenderer(
              :fill-color='"var(--primary-text-color)"',
              :fill-hover-color='"var(--secondary-foreground-color)"',
              :icon='trashIcon',
              :toggle-hover='itemCTAIsHovered(index)',
              height='15',
              width='15'
            )
      .add-item.item.selectable(@click='$emit("add-item")', v-if='showAddItem')
        h5 + {{ addItemText }}
</template>

<script setup lang="ts">
  import { computed, reactive, ref, watch } from 'vue'
  import type { PropType } from 'vue'
  import type { DropdownItem } from '@/services/interfaces/Common'
  import SVGRenderer from '@/components/common/SVGRenderer.vue'
  import useIcons from '@/composables/useIcons'
  import useEditor from '@/composables/useEditor'
  import { vOnClickOutside } from '@vueuse/components'
  import { useRoute } from 'vue-router/composables'

  const emit = defineEmits(['input', 'edit', 'delete', 'add-item'])

  const props = defineProps({
    items: {
      type: Array as PropType<DropdownItem[]>,
      default: () => [],
    },
    value: [String, Boolean, Number],
    headerTitle: {
      type: String,
      default: '',
    },
    dropdownPlaceholder: String,
    defaultDropdownBehavior: {
      type: Boolean,
      default: false,
    },
    strokeColorForExpandIcon: {
      type: String,
      default: '#999',
    },
    showAddItem: {
      type: Boolean,
      default: false,
    },
    addItemText: {
      type: String,
      default: '',
    },
    openDropdownByDefault: {
      type: Boolean,
      default: false,
    },
    hideToggleDropdown: {
      type: Boolean,
      default: false,
    },
    showEditItemIcon: {
      type: Boolean,
      default: false,
    },
    showCountryFlags: {
      type: Boolean,
      default: false,
    },
  })

  const route = useRoute()
  const { expandMoreOptions, trashIcon, pencilIcon } = useIcons()
  const { decodeAndParseSource } = useEditor(route)

  const openDropdown = ref(props.openDropdownByDefault)
  const activeDropdownItem = ref('')

  const activeItem = computed((): DropdownItem | undefined => {
    return props.items.find((item) => item.type === selectedValue.value)
  })

  const selectedValue = computed({
    get(): string | boolean | number | undefined {
      return props.value
    },
    set(value: string | boolean | number | undefined) {
      emit('input', value)
    },
  })

  const itemCTAIsHovered = computed(
    () => (index: number) => itemCTAHoverState.value && itemCTAHoverState.index === index,
  )

  const countryIconCode = computed(() => (code: string | undefined) => {
    if (!code) return ''

    const parts = code.split('|')
    return parts.length > 1 ? parts[1] : code
  })

  const setItemsSelectedBefore = () => {
    props.items.forEach((item) => {
      if (item.selectedBefore) {
        activeDropdownItem.value = item.title
      }
    })
  }

  const closeDropdown = (callback?: () => void) => {
    setTimeout(() => {
      if (!props.openDropdownByDefault) {
        openDropdown.value = false
      }
      if (typeof callback === 'function') callback()
    }, 150)
  }

  const setOpenDropdown = () => {
    if (!props.openDropdownByDefault) {
      if (openDropdown.value) {
        closeDropdown()
      } else {
        openDropdown.value = true
      }
    }
  }

  const selectItem = (item: DropdownItem) => {
    if (item.selectable) {
      closeDropdown(() => {
        selectedValue.value = item.type as string
        activeDropdownItem.value = item.title
      })
    }
  }

  const itemCTAHoverState = reactive({
    index: 0,
    value: false,
  })
  const hoveringItemCta = (index: number, value: boolean) => {
    itemCTAHoverState.index = index
    itemCTAHoverState.value = value
  }

  watch(
    () => props.openDropdownByDefault,
    (value) => {
      if (value) {
        openDropdown.value = value
      }
    },
  )

  watch(
    () => props.items,
    () => {
      setItemsSelectedBefore()
    },
  )
</script>

<style lang="postcss">
  .dropdown {
    .selected-item {
      @apply ketch-flex ketch-justify-between ketch-items-center ketch-px-c16 ketch-py-c10 ketch-border ketch-border-b-0;
      @apply ketch-cursor-pointer ketch-border-border-color;
      .title-block {
        width: calc(100% - 32px);
        &,
        p {
          @apply ketch-overflow-hidden ketch-text-ellipsis ketch-whitespace-nowrap;
        }
      }
      &.open {
        @apply ketch-border-b-0;
      }
      .more {
        svg {
          @apply ketch-ml-c20 ketch-transition-transform ketch-duration-300;
        }
        &.open svg {
          @apply ketch--rotate-180;
        }
      }
    }
    .title-block .prepend-text,
    .dropdown-items .prepend-text {
      @apply ketch-text-secondary-text-color sm:ketch-w-[52px] ketch-inline-flex ketch-justify-end;
      @apply ketch-min-w-[48px] ketch-max-w-[52px];
      &::after {
        content: '|';
        @apply ketch-relative ketch-mx-c5;
      }
    }
    .dropdown-items {
      .item {
        @apply ketch-px-c16 ketch-py-c10 ketch-border-solid ketch-cursor-pointer;
        @apply ketch-border-t ketch-border-b ketch-border-border-color;
        @apply ketch-flex ketch-items-center ketch-justify-between;
        span.item-cta {
          @apply ketch-flex ketch-items-center ketch-space-x-c5;
          .item-cta-icon-wrapper {
            @apply ketch-flex ketch-items-center ketch-justify-center ketch-p-c5 ketch-rounded-normal;
            @apply ketch-bg-primary-text-color ketch-bg-opacity-[15%] hover:ketch-bg-secondary-hover-color;
          }
        }
        &:not(.selectable) {
          @apply ketch-text-secondary-text-color ketch-cursor-default;
        }
        &:last-child {
          @apply ketch-border-b-0;
        }
        &.has-flags {
          @apply ketch-space-x-c10 ketch-justify-normal;
        }
      }
      .item + .item {
        @apply ketch-border-t-0;
      }
      .item.active {
        @apply ketch-font-bold;
      }
      &:last-child {
        @apply ketch-border-b ketch-border-border-color;
      }
    }
    &.defaultBehavior {
      @apply ketch-grow ketch-relative;
      &.editor-dropdown {
        .selected-item {
          @apply ketch-rounded-none ketch-border-editor-primary-color;
        }
      }
      .selected-item {
        @apply ketch-h-c40 ketch-border-b ketch-border-border-color ketch-rounded-normal;
        .title-block {
          .choose {
            @apply ketch-text-gray-400;
          }
        }
        &.open {
          @apply ketch-rounded-bl-none ketch-rounded-br-none;
        }
      }
      .dropdown-items {
        @apply ketch-absolute ketch-left-0 ketch-right-0 ketch-bg-white ketch-rounded-b-normal ketch-z-[2];
        @apply ketch-border ketch-border-t-0 ketch-border-border-color ketch-shadow-profile-question-dropdown;
        @apply ketch-max-h-c200 ketch-overflow-y-auto;
        .item {
          @apply ketch-flex ketch-items-center ketch-h-auto ketch-py-[7px] ketch-border-t;
          @apply ketch-border-b-0 ketch-border-border-color ketch-cursor-pointer;
          &:first-child {
            @apply ketch-border-t-0;
          }
          &.active {
            @apply ketch-text-primary-color;
          }
          &:not(.selectable) {
            @apply ketch-cursor-default;
          }
        }
      }
    }
  }
  .ketch-dark .dropdown.defaultBehavior {
    .dropdown-items {
      @apply ketch-bg-gray-600;
    }
  }
</style>
