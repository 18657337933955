import { render, staticRenderFns } from "./FollowupLesson.vue?vue&type=template&id=4915d1ab&scoped=true&lang=pug"
import script from "./FollowupLesson.vue?vue&type=script&setup=true&lang=ts"
export * from "./FollowupLesson.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./FollowupLesson.vue?vue&type=style&index=0&id=4915d1ab&prod&lang=postcss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4915d1ab",
  null
  
)

export default component.exports