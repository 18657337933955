<template lang="pug">
.trainings(:class='{ notranslate: isEditor }')
  .loader(v-if='loadingCourseSections')
    CourseSectionLoader(v-if='isDesktopDevice')
    MobileCourseSectionLoader(v-else)
  TabGroupedCourses(:courses='courseSectionsAssignedCourses || []', v-else-if='isMobileDevice')
  section(data-cy='trainings', v-else)
    .course-sections-wrapper(v-if='hasReadyCourses || isEditor')
      .course-section(:key='courseSectionKey(section)', v-for='(section, index) in filteredCourseSections')
        transition(name='fade')
          .section-container(:data-cy='section.id', :id='section.id')
            TextRenderer.section-title(
              :edit-sub-title='String($t("editor.sectionSubTitle"))',
              :edit-title='String($t("editor.sectionTitle"))',
              :editor-state='`courseSections-${section.id}`',
              :mutate-and-queue-func='mutateAndQueueFunc(section.id ?? null)',
              :placeholder='String($t("editor.newSectionTitle"))',
              :show-text-input='true',
              :source='section.name || ""',
              editor-property='name'
            )
            .brief(:class='{ editing: inEditorMode }')
              TextRenderer.description(
                :edit-sub-title='String($t("editor.sectionDescriptionSubtitle"))',
                :edit-title='String($t("editor.description"))',
                :editor-state='`courseSections-${section.id}`',
                :mutate-and-queue-func='mutateAndQueueFunc(section.id ?? null)',
                :source='section.description || ""',
                editor-property='description',
                show-bullet-text-tools,
                show-toolbar-text-block-only
              )
              TextRenderer.bullet-points(
                :edit-sub-title='String($t("editor.sectionBulletSubtitle"))',
                :edit-title='String($t("editor.sectionBulletPoints"))',
                :editor-state='`courseSections-${section.id}`',
                :mutate-and-queue-func='mutateAndQueueFunc(section.id ?? null, true)',
                :placeholder='String($t("editor.newBulletPointsPlaceholder"))',
                :source='stringifyBulletPoints(section.bulletPoints || [])',
                editor-property='bulletPoints',
                show-bullet-text-tools,
                show-toolbar-text-block-only
              )
            TagFilter(
              :courses='filteredTagCourses(section.assignedCourses)',
              :tags='courseTags(filteredTagCourses(section.assignedCourses))',
              @active-tag='setActiveTag($event, section.id)',
              v-if='filteredTagCourses(section.assignedCourses).length'
            )
            transition-group.courses(
              :class='{ dragging: isDragging, "new-section": !section.assignedCourses.length }',
              :name='transitionGroupName',
              data-cy='courses',
              tag='div'
            )
              template(v-for='course in filteredSectionCourses(section.assignedCourses, section.id)')
                CourseTile.eligible(
                  :course='course',
                  :draggable='inEditorMode',
                  :id='course.id',
                  :is-draggable='inEditorMode && isEditorDashboardView',
                  :key='course.id',
                  :max-height='maxHeight(course.id, section.id)',
                  :section-id='section.id',
                  @clicked-drag='triggerMoveCourseToSectionModal(section?.id ?? null, course.id)',
                  @course-data='onCourseData',
                  @dragend.native='onCourseDragEnd',
                  @dragenter.native='onCourseDragEnter(section, course)',
                  @dragover.native.prevent='onCourseDragOver',
                  @dragstart.native='onCourseDragStart(section, course, $event)',
                  @drop.native='onCourseDrop',
                  v-if='course.id'
                )
              .course-tile.new-section(
                :class='{ "drop-target": dropCourse && dropCourse.id === "id-fake" }',
                @dragenter='onCourseDragEnter(section, { id: "id-fake" })',
                @dragover.prevent='onCourseDragOver',
                @drop='onCourseDrop',
                key='new-section',
                v-if='inEditorMode && section.id && !section.assignedCourses.length'
              )
                h4(v-html='$t("trainings.moveACourseHere")')
              .course-tile.create-course(
                @click='openCreateCourseModal(section.id)',
                key='create-course',
                v-else-if='inEditorMode && section.id'
              )
                h3 {{ $t('editor.createCourse.createCourseTitle') }}
            .move-section-cta(v-if='inEditorMode')
              ReorderUpAndDown(
                :current-index='index',
                :items-length='filteredCourseSections.length',
                @move-down='moveSection(index, "down", section.orderingNumber)',
                @move-up='moveSection(index, "up", section.orderingNumber)'
              )
    h5.ketch-text-center.ketch-top-c30(v-else) {{ $t('trainings.noCourses') }}
    hr(v-if='inEditorMode && isEditorDashboardView && false')
    .add-new-section(@click='addNewSection', v-if='inEditorMode && isEditorDashboardView && false')
      h4 {{ $t('trainings.addNewSection') }}
    hr(v-if='inEditorMode && unassignedCourses.length && isEditorDashboardView')
    .unassigned-courses-container(v-if='inEditorMode && unassignedCourses.length && isEditorDashboardView')
      h2 {{ $t('trainings.unassignedCourses') }}
      h5 {{ $t('trainings.unassignedCoursesDescription') }}
      .unassigned-courses
        CourseTile.eligible(
          :course='course',
          :draggable='inEditorMode',
          :is-draggable='isEditorDashboardView',
          :key='course.id',
          :max-height='maxHeight("unassigned-section-id", course.id)',
          @clicked-drag='triggerMoveCourseToSectionModal(null, course.id)',
          @course-data='onCourseData',
          @dragend.native='onCourseDragEnd',
          @dragstart.native='onCourseDragStart(null, course, $event)',
          section-id='unassigned-section-id',
          v-for='course in unassignedCourses'
        )
</template>

<script setup lang="ts">
  import { computed, onBeforeUnmount, onMounted, ref, watch } from 'vue'
  import CourseTile from '@/components/course/sections/CourseTile.vue'
  import { CourseModule } from '@/store/modules/course'
  import TagFilter from '@/components/course/TagFilter.vue'
  import { UserModule } from '@/store/modules/user'
  import TextRenderer from '@/components/editor/TextRenderer.vue'
  import { EditorModule } from '@/store/modules/editor'
  import useEditor from '@/composables/useEditor'
  import useCommonMixin from '@/composables/useCommonMixin'
  import useBreakpoint from '@/composables/useBreakpoint'
  import CourseApi from '@/services/api/CourseApi'
  import useSegment from '@/composables/useSegment'
  import { v4 as uuidv4 } from 'uuid'
  import CourseSectionLoader from '@/components/loaders/CourseSectionLoader.vue'
  import useCourse from '@/composables/useCourse'
  import TabGroupedCourses from '@/components/course/TabGroupedCourses.vue'
  import CookieHelper from '@/helpers/CookieHelper'
  import { useRoute, useRouter } from 'vue-router/composables'
  import eventBus from '@/main'
  import useI18n from '@/composables/useI18n'
  import type {
    CourseItem,
    CourseSection,
    CourseSectionMap,
    CourseSectionUpdatePayload,
    EditorCourseSection,
    EditorStatePayload,
    LessonMap,
  } from '@/services/interfaces/Course'
  import type { Nullable } from '@/services/interfaces/Content'
  import useToursAndHints from '@/composables/useToursAndHints'
  import ReorderUpAndDown from '@/components/common/ReorderUpAndDown.vue'
  import MobileCourseSectionLoader from '@/components/loaders/MobileCourseSectionLoader.vue'

  const route = useRoute()
  const router = useRouter()
  const { inEditorMode: editMode, courseSections, isEditor } = useEditor(route)
  const {
    joinArrayOfStringsWithNewlineCharacter,
    convertStringWithNewLineCharactersIntoArrayOfStrings,
    scrollIntoSection,
    isSachkundeCompany,
    setIsLoadingComponentViewData,
  } = useCommonMixin()
  const { pageViewed, trackPage } = useSegment()
  const { hasPurchasedAtLeastACourse, courseGroupIds, courseSectionsAssignedCourses } = useCourse()
  const { isLargeDesktop, isMobileDevice, isDesktopDevice } = useBreakpoint()
  const { showOrHideToursAndHints } = useToursAndHints()
  const { translateString } = useI18n()

  const inEditorMode = computed(() => isLargeDesktop.value && editMode.value)

  const trainingCourseSections = computed(() =>
    inEditorMode.value
      ? (EditorModule.courseSections as EditorCourseSection[])
      : (CourseModule.courseSections as CourseSection[]),
  )

  const courseSectionKey = (section: CourseSection | EditorCourseSection) => {
    return inEditorMode.value ? (section as EditorCourseSection).editorId : (section as CourseSection).id
  }

  const filteredCourseSections = computed(() => {
    const allSections = trainingCourseSections.value

    if (!allSections || allSections.length === 0) {
      return []
    }

    if (isSachkundeCompany.value && !hasPurchasedAtLeastACourse.value) {
      // show only the first section
      const firstSection = allSections[0]
      const hasReadyCourses = !inEditorMode.value && hasReadyCoursesInSection.value(firstSection.assignedCourses)
      const isEditable = inEditorMode.value && firstSection.isEditable
      const hasFilteredTagCourses = filteredTagCourses.value(firstSection.assignedCourses).length > 0

      return (hasReadyCourses || isEditable) && hasFilteredTagCourses ? [firstSection] : []
    }

    // For other cases, apply the original filtering logic
    return allSections.filter(
      (section) =>
        ((!inEditorMode.value && hasReadyCoursesInSection.value(section.assignedCourses)) ||
          (inEditorMode.value && section.isEditable)) &&
        filteredTagCourses.value(section.assignedCourses).length,
    )
  })

  const activeTags = ref({} as { [key: string]: string })

  const transitionGroupName = ref('shuffle-list')

  const loadingCourseSections = computed(() => {
    return isMobileDevice.value
      ? courseSectionsAssignedCourses.value === null
      : trainingCourseSections.value === null || !(trainingCourseSections.value?.length > 0)
  })

  const unassignedCourses = computed(() => {
    const sectionWithUnassignedCourses = trainingCourseSections.value?.find(
      (section) => section.unassignedCourses.length,
    )
    return sectionWithUnassignedCourses?.unassignedCourses ?? ([] as CourseItem[])
  })

  const hasReadyCoursesInSection = computed(() => {
    return (courses: CourseItem[]) => courses.some((c) => c.state === 'ready')
  })

  const hasReadyCourses = computed(() => {
    if (courseSectionsAssignedCourses.value === null) return false
    return courseSectionsAssignedCourses.value?.some((c) => c.state === 'ready')
  })

  const isEditorDashboardView = computed(() => selectedUserGroup.value === 'editor')

  const accessibleToGroupAndSection = computed(
    () => (selectedUserGroup: string | (string | null)[], courseId: string, visibleToCourseSection: boolean) => {
      if (!selectedUserGroup) return true
      if (selectedUserGroup === 'editor') return true
      const group = UserModule.userGroups?.find((g) => g.name === selectedUserGroup)
      return group ? courseGroupIds.value(courseId).includes(group.id) && visibleToCourseSection : false
    },
  )

  const selectedUserGroup = computed(() => route.query.viewAs)

  const filteredSectionCourses = computed(() => {
    return (courses: CourseItem[], sectionId?: string) => {
      if (!sectionId) return []

      if (!activeTags.value[sectionId]) {
        return courses.filter((c) =>
          inEditorMode.value
            ? selectedUserGroup.value
              ? accessibleToGroupAndSection.value(selectedUserGroup.value, c.id, c.visibleToCourseSection)
              : true
            : (isEditor.value ? true : c.state !== 'in_progress') && c.visibleToCourseSection && c.visibleToGroup,
        )
      }
      return courses.filter(
        (c) =>
          (inEditorMode.value
            ? selectedUserGroup.value
              ? accessibleToGroupAndSection.value(selectedUserGroup.value, c.id, c.visibleToCourseSection)
              : true
            : (isEditor.value ? true : c.state !== 'in_progress') && c.visibleToCourseSection && c.visibleToGroup) &&
          c.tags?.some((t) => t.id === activeTags.value[sectionId]),
      )
    }
  })

  const filteredTagCourses = computed(() => {
    return (courses: CourseItem[]) => {
      return courses.filter(
        (c) =>
          ((isEditor.value ? true : c.state !== 'in_progress') &&
            c.visibleToCourseSection &&
            c.visibleToGroup &&
            c.id) ||
          inEditorMode.value,
      )
    }
  })

  const courseTags = (courses: CourseItem[]) => {
    return Object.values(
      courses.reduce(
        (acc, course) => {
          course.tags?.forEach((tag) => {
            if (!acc[tag.id]) {
              acc[tag.id] = {
                id: tag.id,
                title: tag.title,
              }
            }
          })
          return acc
        },
        {} as { [key: string]: { id: string; title: string } },
      ),
    )
  }

  const setActiveTag = (tagId: string, sectionId?: string) => {
    if (!sectionId) return

    if (sectionId in activeTags.value) {
      activeTags.value[sectionId] = tagId
    } else {
      eventBus.$set(activeTags.value, sectionId, tagId)
    }
    setTimeout(() => {
      eventBus.$emit('reset-background-tile-height')
    }, 500)
  }

  const stringifyBulletPoints = computed(() => {
    return (bulletPoints?: string[]) => {
      if (!bulletPoints?.length) return ''
      return joinArrayOfStringsWithNewlineCharacter(bulletPoints)
    }
  })

  const newSection: CourseSection = {
    name: '',
    description: '',
    companyId: UserModule.currentCompany?.id || '',
    bulletPoints: [] as string[],
    assignedCourses: [] as CourseItem[],
    unassignedCourses: [] as CourseItem[],
    isEditable: true,
    orderingNumber: 0,
  }

  const addNewSection = () => {
    trainingCourseSections.value?.push(newSection)
  }

  const dragCourseSection = ref(null as Nullable<CourseSection>)
  const dragCourse = ref(null as Nullable<CourseItem>)
  const dropCourseSection = ref(null as Nullable<CourseSection>)
  const dropCourse = ref(null as Nullable<CourseItem>)
  const dragElement = ref(null as Nullable<HTMLDivElement>)
  const dropElement = ref(null as Nullable<HTMLDivElement>)
  const dropCourseIndex = ref(-1)

  const onCourseDragStart = (
    section: CourseSection | null,
    course: CourseItem,
    e: { target: Nullable<HTMLDivElement> },
  ) => {
    dragCourseSection.value = section
    dragCourse.value = course
    transitionGroupName.value = ''
    dragElement.value = e.target?.closest('.course-tile') || e.target
    setTimeout(function () {
      dragElement.value?.classList.add('drag-course')
    }, 0)
  }

  const onCourseDragEnter = (section: CourseSection, course: Partial<CourseItem>) => {
    if (dragCourse.value === course) return

    dropCourseSection.value = section
    dropCourse.value = course as CourseItem
  }

  const onCourseDragOver = (e: DragEvent) => {
    const target = e.target as HTMLDivElement
    dropElement.value = target?.closest('.course-tile') || target
    if (
      dragElement.value?.classList.contains('eligible') &&
      dropElement.value?.classList.contains('eligible') &&
      dragElement.value !== dropElement.value &&
      dropCourse.value?.id !== 'id-fake'
    ) {
      const dropElementPosition = dropElement.value!.getBoundingClientRect()
      const nextPosition =
        (e.clientY - dropElementPosition.top) / (dropElementPosition.bottom - dropElementPosition.top) > 0.5 ||
        (e.clientX - dropElementPosition.left) / (dropElementPosition.right - dropElementPosition.left) > 0.5
      dropElement.value!.parentNode!.insertBefore(
        dragElement.value!,
        nextPosition ? dropElement.value!.nextSibling : dropElement.value,
      )
      const courseIndex = dropCourseSection.value!.assignedCourses.findIndex((c) => c.id === dropCourse.value!.id)
      dropCourseIndex.value = nextPosition ? courseIndex + 1 : courseIndex
    }
  }

  const onCourseDragEnd = () => {
    dragCourseSection.value = null
    dragCourse.value = null
    dropCourseSection.value = null
    dropCourse.value = null
    dropElement.value = null
    dragElement.value?.classList.remove('drag-course')
    dragElement.value = null
    dropCourseIndex.value = -1
    setTimeout(() => {
      transitionGroupName.value = 'shuffle-list'
    }, 1000)
  }

  const onCourseDrop = () => {
    if (dropCourse.value && dragCourse.value) {
      const dragSectionId = dragCourseSection.value?.id || ''
      const dropSectionId = dropCourseSection.value!.id!
      const courseId = dragCourse.value!.id
      const courseSectionsCopy = structuredClone(trainingCourseSections.value ?? [])

      eventBus.$emit('turn-on-loader')
      const dragCourseIndex = dragCourseSection.value?.assignedCourses.findIndex((c) => c.id === dragCourse.value!.id)
      if (typeof dragCourseIndex === 'number') dragCourseSection.value!.assignedCourses.splice(dragCourseIndex, 1)

      if (dropCourse.value!.id === 'id-fake') dropCourseIndex.value = dropCourseSection.value!.assignedCourses.length
      dropCourseSection.value!.assignedCourses.splice(dropCourseIndex.value, 0, dragCourse.value)

      const apiPayload = {
        courseSection: {
          assignedCourses: dropCourseSection.value!.assignedCourses.map((c, index) => ({
            courseId: c.id,
            promoted: c.promoted,
            visible: c.visibleToCourseSection,
            orderNumber: index,
          })),
        },
      }
      CourseApi.updateCourseSection(UserModule.currentCompany!.id, dropCourseSection.value!.id!, apiPayload)
        .then(async () => {
          const deletePayload: any = { deleted: false }
          if (dragSectionId !== '' && dragSectionId !== dropSectionId) {
            deletePayload.dragSectionId = dragSectionId
            deletePayload.courseId = courseId
            deletePayload.deleted = true
            await CourseApi.deleteCourseFromSection(UserModule.currentCompany!.id, dragSectionId, courseId)
          }
          cacheStateAndSubscribeToEditorEvents(
            dropSectionId,
            apiPayload,
            deletePayload,
            courseSectionsCopy,
            courseOrderingUndoCallback,
            courseOrderingRedoCallback,
          )
          return
        })
        .then(() => {
          patchCourseSections()
        })
        .catch(() => {
          eventBus.$toasted.error(translateString('editor.messages.courseOrderingFailed'))
        })
    }
  }

  const isDragging = computed(() => !!dragCourseSection.value)

  const patchCourseSections = async () => {
    await CourseModule.getCourseSections(UserModule.currentCompany!.id)
    if (trainingCourseSections.value) {
      EditorModule.setCourseSections(trainingCourseSections.value).then(() => {
        EditorModule.courseSections?.forEach((section) => {
          eventBus.$set(section, 'editorId', uuidv4())
        })
      })
      eventBus.$emit('turn-off-loader')
      eventBus.$toasted.success(translateString('editor.messages.courseOrderedSuccessfully'))
    }
  }

  const cacheStateAndSubscribeToEditorEvents = (
    sectionId: string,
    apiPayload: any,
    deletePayload: { deleted: boolean; dragSectionId?: string; courseId?: string },
    courseSections: CourseSection[],
    discardCallback: (data: EditorStatePayload) => void,
    saveCallback: (data: EditorStatePayload) => void,
  ) => {
    const key = 'section-course-reordering-' + uuidv4()
    EditorModule.addEditorChange({
      key,
      path: route.path,
      skipGrouping: true,
      value: {
        companyId: UserModule.currentCompany!.id,
        sectionId,
        deletePayload,
        courseSections,
      } as CourseSectionMap,
      apiPayload,
    })
    EditorModule.subscribe({
      type: 'discard',
      key,
      callback: discardCallback,
    })
    EditorModule.subscribe({
      type: 'save',
      key,
      callback: saveCallback,
    })
  }

  const courseOrderingUndoCallback = (data: EditorStatePayload) => {
    eventBus.$emit('turn-on-loader')
    const sections = (data.value as CourseSectionMap).courseSections
    const dropSection = sections.find((s) => s.id === (data.value as CourseSectionMap).sectionId)
    const payload = {
      courseSection: {
        assignedCourses: dropSection?.assignedCourses.map((c, index) => ({
          courseId: c.id,
          promoted: c.promoted,
          visible: c.visibleToCourseSection,
          orderNumber: index,
        })),
      },
    }
    CourseApi.updateCourseSection((data.value as CourseSectionMap).companyId!, dropSection!.id!, payload)
      .then(async () => {
        const { deletePayload } = data.value as CourseSectionMap
        if (deletePayload?.deleted) {
          const dragSection = sections.find((s) => s.id === deletePayload!.dragSectionId)
          const payload = {
            courseSection: {
              assignedCourses: dragSection?.assignedCourses.map((c, index) => ({
                courseId: c.id,
                promoted: c.promoted,
                visible: c.visibleToCourseSection,
                orderNumber: index,
              })),
            },
          }
          await CourseApi.updateCourseSection(
            (data.value as CourseSectionMap).companyId!,
            deletePayload!.dragSectionId!,
            payload,
          )
        }
        return
      })
      .then(() => {
        patchCourseSections()
      })
  }

  const courseOrderingRedoCallback = (data: EditorStatePayload) => {
    eventBus.$emit('turn-on-loader')
    const sections = (data.value as CourseSectionMap).courseSections
    const dropSection = sections.find((s) => s.id === (data.value as CourseSectionMap).sectionId)

    CourseApi.updateCourseSection(
      (data.value as CourseSectionMap).companyId!,
      dropSection!.id!,
      data.apiPayload as CourseSectionUpdatePayload,
    )
      .then(async () => {
        const { deletePayload } = data.value as CourseSectionMap
        if (deletePayload?.deleted) {
          await CourseApi.deleteCourseFromSection(
            (data.value as CourseSectionMap).companyId!,
            deletePayload.dragSectionId!,
            deletePayload.courseId!,
          )
        }
        return
      })
      .then(() => {
        patchCourseSections()
      })
  }

  const sectionSaveCallback = (data: EditorStatePayload) => {
    EditorModule.setSectionState({
      sectionId: (data.value as CourseSectionMap).sectionId,
      prop: data.property!,
      value: data.apiPayload as string,
    })
  }

  const mutateAndQueueFunc = (sectionId: string | null, covertToArray?: boolean) => {
    return async (data: EditorStatePayload) => {
      if (!sectionId) return

      const changesPayload = EditorModule.editorChanges[route.path]?.find((c) => c.data.key === data.key)
      const section: any = trainingCourseSections.value?.find((s) => s.id === sectionId)

      const payload = Object.assign(data, {
        apiPayload: covertToArray
          ? convertStringWithNewLineCharactersIntoArrayOfStrings(data.value as string)
          : data.value,
        value: {
          sectionId,
          prevPropValue: (changesPayload?.data.value as CourseSectionMap)?.prevPropValue ?? section?.[data.property!],
        },
      })
      EditorModule.addEditorChange(payload)
      EditorModule.setSectionState({
        sectionId,
        prop: payload.property!,
        value: payload.apiPayload as string,
      })
      EditorModule.subscribe({
        type: 'save',
        key: payload.key,
        callback: sectionSaveCallback,
        allowDuplicate: true,
      })
    }
  }

  const discardChangesCallback = () => {
    // if a new section is available
    const newSectionIndex = trainingCourseSections.value?.findIndex((s) => !s.id)
    if (typeof newSectionIndex === 'number' && newSectionIndex > -1) {
      trainingCourseSections.value!.splice(newSectionIndex, 1)
    }
    EditorModule.setCourseSections(CourseModule.courseSections).then(() => {
      EditorModule.courseSections?.forEach((section) => {
        eventBus.$set(section, 'editorId', uuidv4())
      })
    })
  }

  const courseData = ref(
    {} as {
      [key: string]: {
        [key: string]: {
          sectionId: string
          courseId: string
          rowNum: number
          titleHeight: number
          state: string
        }
      }
    },
  )

  const onCourseData = (data: any) => {
    if (courseData.value[data.sectionId]) {
      if (courseData.value[data.sectionId][data.courseId]) {
        courseData.value[data.sectionId][data.courseId] = data
      } else {
        eventBus.$set(courseData.value[data.sectionId], data.courseId, data)
      }
    } else {
      eventBus.$set(courseData.value, data.sectionId, { [data.courseId]: data })
    }
  }

  const maxHeight = computed(() => {
    return (courseId: string, sectionId?: string) => {
      if (!sectionId) return 0

      const items = Object.values(courseData.value[sectionId] || {})
      const course = items.find((i) => i.courseId === courseId)

      if (course && items.length) {
        return Math.max(
          ...items.filter((i) => i.sectionId === sectionId && i.rowNum === course.rowNum).map((i) => i.titleHeight),
        )
      }
      return 0
    }
  })

  const moveSection = (index: number, direction: 'up' | 'down', orderingNumber: number | null) => {
    if (!orderingNumber) return

    const _courseSectionsCopy = structuredClone(trainingCourseSections.value) as CourseSection[]

    if (direction === 'up') {
      if (index === 0) return
      const sectionAbove = courseSections.value![index - 1]
      const movedSection = courseSections.value![index]

      eventBus.$set(sectionAbove, 'orderingNumber', orderingNumber)
      eventBus.$set(movedSection, 'orderingNumber', orderingNumber - 1)

      courseSections.value?.splice(index, 1, sectionAbove)
      courseSections.value?.splice(index - 1, 1, movedSection)

      registerOrderingChanges(_courseSectionsCopy, courseSections.value!)
    } else if (direction === 'down') {
      if (index === courseSections.value!.length - 1) return
      const sectionBelow = courseSections.value![index + 1]
      const movedSection = courseSections.value![index]

      eventBus.$set(sectionBelow, 'orderingNumber', orderingNumber)
      eventBus.$set(movedSection, 'orderingNumber', orderingNumber + 1)

      courseSections.value?.splice(index, 1, sectionBelow)
      courseSections.value?.splice(index + 1, 1, movedSection)

      registerOrderingChanges(_courseSectionsCopy, courseSections.value!)
    }
  }

  const registerOrderingChanges = (courseSections: EditorCourseSection[], newCourseSections: EditorCourseSection[]) => {
    const key = 'course-sections-reordering-' + uuidv4()
    EditorModule.addEditorChange({
      key,
      path: route.path,
      skipGrouping: true,
      value: {
        prevPropValue: courseSections,
      } as LessonMap,
      apiPayload: newCourseSections,
    })
    EditorModule.subscribe({
      type: 'discard',
      key,
      callback: registerOrderingUndoCallback,
    })
    EditorModule.subscribe({
      type: 'save',
      key,
      callback: registerOrderingRedoCallback,
    })
  }

  const registerOrderingUndoCallback = (data: EditorStatePayload) => {
    EditorModule.setCourseSections((data.value as LessonMap)?.prevPropValue as EditorCourseSection[])
  }

  const registerOrderingRedoCallback = (data: EditorStatePayload) => {
    EditorModule.setCourseSections(data.apiPayload as EditorCourseSection[])
  }

  const loadTypeformScript = () => {
    const tag = document.createElement('script')
    tag.src = '//embed.typeform.com/next/embed.js'
    const firstScriptTag = document.getElementsByTagName('script')[0] as HTMLScriptElement
    firstScriptTag.parentNode?.insertBefore(tag, firstScriptTag)
  }

  const hideSkgQuizPopup = computed(() => {
    return CookieHelper.getCookieValue('hide-skg-quiz-popup')
  })

  const checkToShowSkgQuizPopup = async (onboardingPopupShown: boolean) => {
    const envShowTypeFormPopup = process.env.VUE_APP_SHOW_SKG_TYPEFORM_POPUP
    if (
      onboardingPopupShown &&
      isSachkundeCompany.value &&
      hasPurchasedAtLeastACourse.value &&
      !hideSkgQuizPopup.value &&
      envShowTypeFormPopup === 'true'
    ) {
      await loadTypeformScript()
      eventBus.$emit('show-modal', {
        modalContentComponent: 'SkgQuizModal',
        cssClass: 'skg-quiz-modal',
        modalCloseCallback: (callback: () => void) => {
          if (typeof callback === 'function') callback()
          CookieHelper.setCookieValue('hide-skg-quiz-popup', 'true')
        },
      })
    }
  }

  const openCreateCourseModal = (sectionId: string) => {
    eventBus.$emit('show-modal', {
      modalContentComponent: 'CreateCourseModal',
      modalProps: {
        sectionId,
      },
      closeModalOnOutsideClick: false,
      cssClass: 'create-course-modal',
      modalCloseCallback: (callback: () => void) => {
        if (typeof callback === 'function') callback()
      },
    })
  }

  const triggerMoveCourseToSectionModal = (currentSectionId: string | null, courseId: string) => {
    eventBus.$emit('show-modal', {
      modalContentComponent: 'MoveCourseToSectionModal',
      modalProps: {
        courseSections: trainingCourseSections.value
          ?.map((section) => ({
            id: section.id,
            name: section.name,
          }))
          .filter((s) => s.id !== currentSectionId && s.id),
        currentSectionId,
        courseId,
      },
      cssClass: 'move-course-to-section-modal',
      modalCloseCallback: (callback: () => void) => {
        if (typeof callback === 'function') callback()
      },
    })
  }

  watch(
    () => UserModule.reTriggerOnboardingPopup,
    (value) => {
      if (value) {
        checkToShowSkgQuizPopup(true)
      }
    },
  )

  watch(
    () => CourseModule.courseSections,
    (value) => {
      if (value && inEditorMode.value) {
        EditorModule.setCourseSections(CourseModule.courseSections).then(() => {
          EditorModule.courseSections?.forEach((section) => {
            eventBus.$set(section, 'editorId', uuidv4())
          })
        })
      }
    },
  )

  watch(loadingCourseSections, (value) => {
    setIsLoadingComponentViewData(value)
  })

  watch(
    () => UserModule.company?.showCompanyPopup,
    (value) => {
      if (!value) {
        if (isDesktopDevice.value) showOrHideToursAndHints()
      }
    },
  )

  onBeforeUnmount(() => {
    EditorModule.unsubscribe({ type: 'discard', callback: discardChangesCallback })
    EditorModule.unsubscribe({ type: 'discard', callback: courseOrderingUndoCallback })
    EditorModule.unsubscribe({ type: 'save', callback: courseOrderingRedoCallback })
    EditorModule.unsubscribe({ type: 'save', callback: sectionSaveCallback })
    EditorModule.unsubscribe({ type: 'discard', callback: registerOrderingUndoCallback })
    EditorModule.unsubscribe({ type: 'save', callback: registerOrderingRedoCallback })
  })

  onMounted(() => {
    pageViewed('Trainings')
    trackPage('Trainings')
    if (isDesktopDevice.value && !UserModule.company?.showCompanyPopup) {
      showOrHideToursAndHints()
    }
    checkToShowSkgQuizPopup(UserModule.reTriggerOnboardingPopup)
    EditorModule.subscribe({
      type: 'discard',
      key: 'new-section-changes',
      callback: discardChangesCallback,
    })

    if (!loadingCourseSections.value && trainingCourseSections.value && !isMobileDevice.value) {
      if (route.query.sectionId) {
        setTimeout(() => {
          const sectionId = route.query.sectionId as string
          const element = document.getElementById(sectionId) as HTMLDivElement
          if (element) {
            const top = element.getBoundingClientRect().top
            scrollIntoSection(sectionId, top)
            router.replace('/trainings')
          }
        }, 1500)
      }
    }
  })
</script>

<style lang="postcss">
  .trainings {
    @apply ketch-flex ketch-flex-col;
    > .loading {
      @apply ketch-py-c40;
    }
    section {
      hr {
        @apply ketch-my-c40 ketch-border-border-color ketch-border-dashed;
      }
      .add-new-section {
        @apply ketch-h-c120 ketch-w-full ketch-border-dashed ketch-border ketch-border-border-color;
        @apply ketch-flex ketch-items-center ketch-justify-center ketch-cursor-pointer;
        @apply hover:ketch-border-editor-primary-color ketch-bg-white;
        h4 {
          @apply ketch-text-editor-primary-color;
        }
      }
      .course-sections-wrapper {
        @apply ketch-space-y-c30 md:ketch-space-y-c60;
        .section-container {
          @apply ketch-relative;

          .section-title {
            @apply ketch-mb-c15 md:ketch-mb-c30 xl:ketch-max-w-[50%] xl:ketch-pr-c10;

            h2 {
              @apply ketch-font-big-daily-short ketch-text-xl ketch-leading-lg;
              @apply md:ketch-text-2xl md:ketch-leading-lg ketch-whitespace-pre-wrap;
              word-break: break-word;
            }
          }

          .brief {
            @apply ketch-flex ketch-flex-col md:ketch-flex-row;

            &.editing {
              @apply ketch-space-x-c20;
            }

            .description {
              @apply ketch-mb-c30 md:ketch-mb-0 md:ketch-w-c600 md:ketch-pr-c100;
            }

            &.editing .description {
              @apply ketch-max-w-full ketch-pr-0;
              flex-basis: 50%;
            }

            .bullet-points {
              @apply ketch-mb-c30 md:ketch-mb-c40;
              flex-basis: 50%;

              .content-container {
                p,
                ul li,
                ol li {
                  &:before {
                    @apply ketch-bg-primary-color;
                    -webkit-mask: url('../assets/images/checkmark.svg') no-repeat 100% 100%;
                    mask: url('../assets/images/checkmark.svg') no-repeat 100% 100%;
                    content: '';
                    -webkit-mask-size: cover;
                    mask-size: cover;
                  }
                }
              }
            }
          }

          .courses {
            @apply ketch-grid ketch-grid-cols-1 xs4:ketch-grid-cols-2 md:ketch-grid-cols-3 xl:ketch-grid-cols-4;
            @apply ketch-gap-c20 ketch-mt-c15;

            &.new-section {
              @apply ketch-grid-cols-none;
            }

            .course-tile {
              @apply ketch-transition-all ketch-duration-700;

              &.create-course {
                @apply ketch-text-editor-primary-color ketch-flex ketch-justify-center ketch-items-center;
                @apply ketch-border ketch-border-dashed ketch-border-border-color ketch-shadow-none ketch-bg-white;
                @apply ketch-cursor-pointer ketch-min-h-[350px];
              }

              &.new-section {
                @apply ketch-bg-editor-primary-color ketch-bg-opacity-10 ketch-w-full ketch-h-[350px] ketch-transform-none;
                @apply ketch-border ketch-border-editor-primary-color ketch-flex ketch-justify-center ketch-items-center;

                h4 {
                  @apply ketch-text-editor-primary-color;

                  span {
                    @apply ketch-underline;
                  }
                }

                &.drop-target {
                  @apply ketch-border-2 ketch-border-dashed;
                }
              }

              &.drag-course {
                @apply ketch-border ketch-border-dashed ketch-border-editor-primary-color ketch-bg-transparent;

                > * {
                  @apply ketch-hidden;
                }
              }
            }

            &.dragging {
              .course-tile {
                @apply hover:ketch-scale-100;
              }
            }
          }

          .move-section-cta {
            @apply ketch-absolute ketch-top-0 ketch-left-[-35px];
          }
        }
      }
      .unassigned-courses-container {
        .loading {
          @apply ketch-mt-c20 ketch-mx-auto;
        }
        h2 {
          @apply ketch-font-big-daily-short ketch-leading-lg;
        }
        h5 {
          @apply ketch-mt-c12 ketch-mb-c20 ketch-max-w-[50%];
        }
        .unassigned-courses {
          @apply ketch-grid ketch-grid-cols-4 ketch-gap-c20;
        }
      }
    }
  }
</style>
